import React from 'react';
import Caraousal from '../Caraousal/Caraousal';
import LiveMusic from '../UI/LiveMusic';
import SpecialNight from '../UI/SpecialNight';
import PercussionNight from '../UI/PercussionNight';
import DJNight from '../UI/DjNight';
import Navbar from '../../Navbar/Navbar1';
import FavorateHangoutSponserd from '../../favorate--hangout--page/Favorate--sponserd--banner';


export default function ArtistCard(){
    return(
        <div className="ArtistCard-image"> 
        <Navbar/>
        <Caraousal/>
        <div className='Artist-card'>
        <LiveMusic/>
        <PercussionNight/>
        <SpecialNight/>
        <DJNight/>
        <div className='Artist-footer'>
       <FavorateHangoutSponserd/>
       </div> 
        </div>
        </div>
    );
}