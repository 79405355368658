import FavorateSearchBanner from './Favorate--banner--search';
import FavorateClubCategory from './Favorate--club--category';
import FavorateHangoutThumbnail from './Favorate--hangout--thumbnail';
import './Favorate--hangout.css';
import FavorateHangoutSponserd from './Favorate--sponserd--banner';




export default function FavorateHangoutViewall()
{
    return(
        <div className='Favorate--Hangout--page'>
           
            <div className='Favorate--Hangout--page--1'>
           
           <FavorateHangoutThumbnail/>
        
           <FavorateHangoutSponserd/>
            </div>
        </div>
    );
}