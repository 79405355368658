import "../App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import logo from "../Assets/logo.png";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import farmhouse from "./image/farmhouse.png";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import { useModal } from "../context/context";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import coupon from "./image/coupon.png";
import favorites from "./image/favorites.png";
import { GoSignOut } from "react-icons/go";
import booking from "./image/booking.png";
import ListItemIcon from "@mui/material/ListItemIcon";
import Notiflix from "notiflix";
import Searchbar from "../Homepage/Searchpage";
import userprofile from "./image/user.png";
import eventicon from "./image/event (1).png";
import hearticon from "./image/heart.png";
import transactionhistory from "./image/History.png";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MyFavorites from "./image/MyFavorites.png";
import Overview from "./image/Overview.png";
import Notification from "./image/Notification.png";
import History from "./image/History.png";
import SearchbarMobile from "../Homepage/Searchbar-mobile";
import Signout from "./image/Signout.png";
import WhatsAppicon from "./image/WhatsAppicon.png.webp";
import Settings from "./image/Settings.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import Loginpopup from "./Loginmodal";
import ArtistSearchbar from "../Homepage/ArtistSearchBar";
import { CgProfile } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
// my account end

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 450,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(email);
}

export default function Navbar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // my account start
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMyAccount = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMyAccount = () => {
    setAnchorEl(null);
  };

  const [timeInSeconds, setTimeInSeconds] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const { toggle } = useModal();

  const [response, setResponse] = useState(null);

  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("male");
  const [updatemobile, setUpdateMobile] = useState("");

  const [updateotp, setUpdateOtp] = useState("");
  const [updatename, setUpdateName] = useState("");
  const [updateemail, setUpdateEmail] = useState("");
  const [updatedob, setUpdateDob] = useState(null);
  const [updategender, setUpdateGender] = useState("");
  const [updateprofile, setUpdateProfile] = useState("");
  // const [token, setToken] = useState("");
  const [token, setToken] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const handleEmailChangevalue1 = (e) => {
    setUpdateEmail(e.target.value);
  };

  var url = "https://partywitty.com/master/assets/uploads/";
  useEffect(() => {
    if (toggle) {
      setOpenLogin(true);
    }
  }, [toggle]);

  const handleSubmitupdateprofile = async (e) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userdata.id);
      formData.append("mobile", updatemobile);
      formData.append("name", updatename);
      formData.append("email", updateemail);
      formData.append("dob", updatedob);
      formData.append("gender", updategender);
      if (!validateEmail(updateemail)) {
        return;
      }
      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/UpdateProfile",
        formData
      );

      setResponse(response.data);

      if (response.data.status === true) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));

        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const updateTimer = () => {
      if (timeInSeconds > 0) {
        setTimeInSeconds((prevTime) => prevTime - 1);
      } else {
        setShowResend(true);
      }
    };

    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [timeInSeconds]);

  const formattedTime = `${Math.floor(timeInSeconds / 60)}:${(
    timeInSeconds % 60
  )
    .toString()
    .padStart(2, "0")}`;

  const [openLogin, setOpenLogin] = React.useState(false);
  const handleOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const [openOtp, setOpenOtp] = React.useState(false);
  const handleOpenOtp = () => {
    setOpenOtp(true);
  };
  const handleCloseOtp = () => {
    setOpenOtp(false);
  };
  const [openSignup, setOpenSignup] = React.useState(false);
  const handleOpenSignup = () => {
    setOpenSignup(true);
  };
  const handleCloseSignup = () => {
    setOpenSignup(false);
  };

  const [openupdateprofile, setOpenupdateprofile] = React.useState(false);
  const handleOpenupdateprofile = () => {
    setOpenupdateprofile(true);
  };
  const handleCloseupdateprofile = () => {
    setOpenupdateprofile(false);
  };
  const [openupdateprofileimage, setOpenupdateprofileimage] =
    React.useState(false);
  const handleOpenupdateprofileimage = () => {
    setOpenupdateprofileimage(true);
  };
  const handleCloseupdateprofileimage = () => {
    setOpenupdateprofileimage(false);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const [value, setValue] = useState("");

  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [data, setData] = useState([]);
  const [areadata, setAreaData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [itemsToShow, setItemsToShow] = useState(8);

  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      const ClubApi = new FormData();
      ClubApi.append("latitude", latitude);
      ClubApi.append("longitude", longitude);

      axios
        .post(
          "https://partywitty.com/master/APIs/Common/recommendedClub",
          ClubApi
        )
        .then((response) => {
          const dataArray = Object.values(response.data.data);
          setData(dataArray);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [latitude, longitude]);

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterValue(value);
    applyFilter(value);
  };

  const applyFilter = (value) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };
  const options1 = data.map((item) => ({
    value: item.name.toLowerCase().replace(/\s+/g, "-"),
    label: item.name,
  }));

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [cartdata, setCartData] = useState([]);

  useEffect(() => {
    if (userdata) {
      const userid = new FormData();
      userid.append("user_id", userdata.id);

      axios
        .post(
          "https://partywitty.com/master/Api/getDataPost/getUserCart",
          userid
        )
        .then((response) => {
          if (response.data.status === "true") {
            setCartData([]);
          } else {
            setCartData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleSubmitupdateprofileimage = async (e) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userdata.id);
      formData.append("img", updateprofile);

      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/updateProfileImage",
        formData
      );

      setResponse(response.data);

      if (response.data.status === true) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));

        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const loginData = localStorage.getItem("userData");
    const timestamp = localStorage.getItem("loginTimestamp");

    if (loginData && timestamp) {
      const loginTime = new Date(timestamp);
      const currentTime = new Date();

      const timeDifference = currentTime - loginTime;

      const timeLimit = 24 * 60 * 60 * 1000;

      if (timeDifference > timeLimit) {
        localStorage.removeItem("userData");
        localStorage.removeItem("loginTimestamp");
      }
    }
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getAreaLead")
      .then((response) => {
        setAreaData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleOpenPlanEvent4 = () => {
    if (userdata == null) {
      setShowLoginPopup(true);
    }
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        width: "200px",
        height: "100%",
        backgroundColor: "white",
        color: "black",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div className="menuu">
                    <div className="my-account--profile-name">
                      <>
                        <div>
                          <img src={url + userdata.img} alt="user"></img>
                        </div>
                        <div>
                          <p className="my-account--profile-name--1">
                            {userdata.name}
                          </p>
                          <p className="my-account--profile-name--1--2">
                            User Profile
                          </p>
                        </div>
                      </>
                    </div>
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
     
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <Link
                  to={"./my-booking-history"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="ham--profile">
                    <img src={transactionhistory} alt="history"></img>
                    <p className="m-0">Transaction History</p>
                  </div>
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <Link
                    to={"./favourite-club"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="ham--profile">
                      <img src={hearticon} alt="favorites"></img>
                      <p className="m-0">My Favorites</p>
                    </div>
                  </Link>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      
    

     

      <Divider />
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div
                    className="ham--profile1"
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = "/";
                    }}
                  >
                    <img src={Signout} alt="signout"></img>
                    <p className="m-0">Sign Out</p>
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div className="my--account--help">
                    <div className="my-account--profile-name1">
                      <div>
                        <img src={WhatsAppicon} alt="user"></img>
                      </div>
                      <div>
                        <p className="my-account--profile-name--1">
                          Help Center
                        </p>
                        <p className="my-account--profile-name--1--2">
                          Answer Here
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
  };
  const [isDropdownFocused, setDropdownFocused] = useState(false);
  const inputRef = useRef();

  const handleDropdownClick = () => {
    setDropdownFocused(!isDropdownFocused);
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleInputClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const [selectedOption, setSelectedOption] = useState("All");
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);

  const options = ["All", "Events", "Package", "Party"];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };
  const [checkbox, setCheckbox] = useState("");

  const [currentlocationname, setCurrentlocationname] = useState("");

  const getEighteenYearsAgoDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };
  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    latitude: "",
    longitude: "",
  });

  const handleLocationChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedLocationData = areadata[selectedIndex - 1];

    setSelectedLocation({
      name: selectedLocationData.name,
      latitude: selectedLocationData.latitude,
      longitude: selectedLocationData.longitude,
    });

    localStorage.setItem("selectedLatitude", selectedLocationData.latitude);
    localStorage.setItem("selectedLongitude", selectedLocationData.longitude);
  };
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();
  const handleIconClick = () => {
    const query = userInput.trim() || 'fly'; 
    navigate(`/result?${query}`);
  }
  return (
    <div className="home-page-design6">
      {showLoginPopup && <Loginpopup />}
      <div className="header1 ">
        <div className="header6">
          <div className="header-navbar">
            <div className="logo">
              <Link to="/" style={{ textDecoration: "none" }}>
                <img src={logo}></img>
              </Link>
            </div>
          </div>

          <div class="navbar-search">
            <ArtistSearchbar />
          </div>

          <div className="navbar">
            <div className="menuu">
              {userdata === null ? (
                <button
                  className="signup-button2"
                  onClick={handleOpenPlanEvent4}
                >
                  Login/SignUp
                </button>
              ) : (
                <div
                  className="profile_icon"
                  data-toggle="tooltip"
                  title="My Account"
                >
                  <img
                    onClick={handleClick}
                    src={url + userdata.img}
                    alt="image"
                  ></img>
                  <p onClick={handleClick}>{userdata.name}</p>
                </div>
              )}
            </div>
          </div>
          {/* hamburger--icon start */}
          <div className="navbar--hamburger--sign">
            <IoSearch onClick={handleIconClick} /> 
            {userdata === null ? (
              <CgProfile onClick={handleOpenPlanEvent4} />
            ) : (
              <div>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    
                      <div onClick={toggleDrawer(anchor, true)} className={`menu-toggle ${isOpen ? "open" : ""}`}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                      </div>
                   
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            )}

            <ul className={`navbar--ham ${isOpen ? "open" : ""}`}>
              <li>
                <div className="menuu">
                  {userdata === null ? (
                    <button
                      className="signup-button2"
                      onClick={handleOpenPlanEvent4}
                    >
                      Login/SignUp
                    </button>
                  ) : (
                    <div
                      className="profile_icon"
                      data-toggle="tooltip"
                      title="My Account"
                    >
                      <img src={url + userdata.img} alt="image" />
                      <p onClick={handleClick}>{userdata.name}</p>
                    </div>
                  )}
                </div>
              </li>

              <li>
                <Link to="/book-a-table">
                  <div className="ham--profile">
                    <img src={farmhouse}></img>
                    <p> Venues</p>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/event">
                  <div className="ham--profile">
                    <img src={coupon}></img>
                    <p>Tickets</p>
                  </div>
                </Link>
              </li>

              <li>
                <Link to="/event">
                  <div className="ham--profile">
                    <img src={booking}></img>
                    <p>Events</p>
                  </div>
                </Link>
              </li>
              <li>
                <div className="ham--profile">
                  <img src={favorites}></img>
                  <p>Favorites</p>
                </div>
              </li>
              <div className="club-aboutus-page--discount21--border1"></div>
              <li>
                <Link to="/party-witty-aboutus">
                  <p>About Us</p>
                </Link>
              </li>
              <li>
                <Link to="/term-conditions-policy">
                  <p>T&C</p>
                </Link>
              </li>

              {userdata == null ? null : (
                <li
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/";
                  }}
                >
                  <p>
                    <GoSignOut /> SignOut
                  </p>{" "}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="mobile--view--search-bar">
          <SearchbarMobile />
        </div>
      </div>

      <div>
        {/* my account start */}
        <div>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMyAccount}
            onClose={handleCloseMyAccount}
          >
            <MenuItem onClick={handleCloseMyAccount} disableRipple>
              <div className="my-account--payment">
                <div className="my-account--payments">
                  <div className="my-account--profile-name">
                    {userdata === null ? null : (
                      <>
                        <div>
                          <img src={url + userdata.img}></img>
                        </div>
                        <div>
                          <p className="my-account--profile-name--1">
                            {userdata.name}
                          </p>
                          <p className="my-account--profile-name--1--2">
                            User Profile
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <ul class="no-bullets">
                    <li>
                      <Link
                        to={"./my-booking-history"}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="my-account--payment--wallet">
                          <img src={History} />
                          <p>My Booking</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"./favourite-club"}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="my-account--payment--wallet">
                          <img src={MyFavorites} />
                          <p>My Favorites</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <div className="my--account--sign-out">
                <div className="my--account--sign-out1">
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                </div>
                <p
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/";
                  }}
                  className="my-account--payment--p"
                >
                  {" "}
                  Sign out
                </p>
              </div>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <div className="my-account--profile-name1">
                <div>
                  <img src={WhatsAppicon} alt="user"></img>
                </div>
                <div>
                  <p className="my-account--profile-name--1">Help Center</p>
                  <p className="my-account--profile-name--1--2">Answer Here</p>
                </div>
              </div>
            </MenuItem>
          </StyledMenu>
          <Modal
            open={openupdateprofile}
            onClose={handleCloseupdateprofile}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="responsive-modal">
              <div className="login-content1">
                <h3>Update Profile.</h3>
                <div className="login-content1--text-field">
                  <Box
                    sx={{
                      alignItems: "center",
                      "& > :not(style)": { m: 1 },
                    }}
                  >
                    <TextField
                      className="input-field"
                      required
                      helperText=""
                      id="demo-helper-text-aligned"
                      type="text"
                      label="Name"
                      value={updatename}
                      onChange={(e) => setUpdateName(e.target.value)}
                    />

                    <TextField
                      className="input-field"
                      helperText=""
                      type="email"
                      required
                      id="demo-helper-text-aligned"
                      label="Email"
                      value={updateemail}
                      onChange={handleEmailChangevalue1}
                    />

                    <div className="login-close-button">
                      <label htmlFor="terms" className="plan-yourevent-div">
                        <input
                          type="checkbox"
                          id="terms"
                          name="terms"
                          required
                        />
                        I agree to the terms and conditions
                      </label>
                    </div>
                    <div className="signup--submit--button">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmitupdateprofile();
                        }}
                      >
                        Update Profile
                      </button>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="login-otp-close-button">
                <Button onClick={handleCloseupdateprofile}>
                  <p>X</p>
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal
            open={openupdateprofileimage}
            onClose={handleCloseupdateprofileimage}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="responsive-modal">
              <div className="login-content1">
                <h3>Update Profile Images.</h3>
                <div className="login-content1--text-field">
                  <Box
                    sx={{
                      alignItems: "center",
                      "& > :not(style)": { m: 1 },
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setUpdateProfile(e.target.files[0])}
                    />

                    <div className="login-close-button">
                      <label htmlFor="terms" className="plan-yourevent-div">
                        <input
                          type="checkbox"
                          id="terms"
                          name="terms"
                          required
                        />
                        I agree to the terms and conditions
                      </label>
                    </div>
                    <div className="signup--submit--button">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmitupdateprofileimage();
                        }}
                      >
                        Update Profile
                      </button>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="login-otp-close-button">
                <Button onClick={handleCloseupdateprofileimage}>
                  <p>X</p>
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
        {/* my account end */}
      </div>
    </div>
  );
}
