
import React from 'react';
import { IoIosArrowUp } from "react-icons/io";
import Menu from './Menu';
import Teaser from './Teaser';
import Package from './package';
import Event from './Event';


const AboutClubDetails = () =>{
    return(
        <div className='about-club-details'>
        <div className='about-club-detail-collapse'>
         <p>About this club</p>
         <span><IoIosArrowUp /></span>
        </div>
        <div className='about-club-detail-1'>
        <Menu/>
        <Package/>
        <Teaser/>
        <Event/>
        </div>
        </div>
    );
}
export default AboutClubDetails;