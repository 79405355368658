import Notiflix from "notiflix";

var urlString = "https://partywitty.com/master/Api/getData/";
// var urlString = "http://localhost:8020/API/";
// var urlString = "http://192.168.1.14:8020/API/";

const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        "x-auth-token": JSON.parse(localStorage.getItem("access")),
      },
    })
      .then((response) => {
        if (response.status == 401) {
          window.location.href = "/";
          Notiflix.Notify.failure("Session Expired. Login Again");
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error;
      });
  },
};

export default GetApiCall;
