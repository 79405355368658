import { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import "./Homepage.css";
import { Link } from "react-router-dom";
import Sponsored from "./Sponserbanner";
import BackgroundPattern from "./image/image1.jpeg";
import { Helmet } from "react-helmet-async";
import ClubCard from "../Components/ClubCard";
import PackageCard from "../Components/PackageCard";
import AllTeaser from "../Components/AllTeaser";
import HomepageTab from "./HomepageTab";
import NewlyAddedClub from "./NewlyAddedClub";
import Multiitem from "./Multyitem";
import WebApp from "../Web--to--app/WebApp";
import UpcommingEvents from "./UpcommingEvents";
import { ShimmerThumbnail } from "react-shimmer-effects"
import { ShimmerCircularImage } from "react-shimmer-effects";
import ArtistHomePage from "../Artist Component/UI/ArtistHomepge";

function Homepage() {
  const url = "https://partywitty.com/master/assets/uploads/";
  const [isLoading, setIsLoading] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [data, setData] = useState([]);
  const [package1, setPackage1] = useState([]);
  const [club1, setClub1] = useState([]);
  const [latitude1, setLatitude1] = useState(null);
  const [longitude1, setLongitude1] = useState(null);
  const [city1, setCity1] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/Common/getWebPromotionalBanner")
      .then((response) => setData(response.data.data))
      .catch((error) => console.log(error));
  }, []);
  const filteredData = data.filter(
    (banner) => banner.page_name === "Home" && banner.position === "footer"
  );
  const generateURL = (landingPage, item) => {
    switch (landingPage) {
      case "":
        return "";
      case "party_packages":
        return "https://www.partywitty.com/party-packages-delhi-ncr-view-all";
      case "table_booking":
        return "https://www.partywitty.com/book-a-table-delhi-ncr-view-all";
      case "club_about":
        return `/${item.city
          .trim()
          .replace(/ /g, "-")
          .toLowerCase()}/${item.area
          .trim()
          .replace(/ /g, "-")
          .toLowerCase()}/${item.name
          .trim()
          .split(/ +/)
          .join("-")
          .replace(/ /g, "-")
          .toLowerCase()}`;
      default:
        return "";
    }
  };
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/Common/getAvailableCity")
      .then((response) =>{
        setCity(response.data.data) 
        setIsLoading(false);
      } )
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/Common/getAllPackageTitle")
      .then((response) => {
        setCity1(response.data.data)
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            const { latitude, longitude } = position.coords;
            setLatitude1(latitude.toString());
            setLongitude1(longitude.toString());
            fetchData(latitude, longitude);
          } else {
            console.error(
              "Error: Unable to get coordinates from the position object."
            );
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            const { latitude, longitude } = position.coords;
            setLatitude1(latitude.toString());
            setLongitude1(longitude.toString());
            fetchData1(latitude, longitude);
          } else {
            console.error(
              "Error: Unable to get coordinates from the position object."
            );
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  
  const fetchData = (latitude1, longitude1) => {
    const formData = new FormData();
    formData.append("latitude", latitude1);
    formData.append("longitude", longitude1);
   
    axios
      .post(
        "https://partywitty.com/master/APIs/ClubPackage/homePackages",
        formData
      )
      .then((response) => {
        if (response.data.status === true) {
          setPackage1(response.data.data);
          setIsLoading(false);
        } else {
          setPackage1([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchData1 = (latitude1, longitude1) => {
    const formData1 = new FormData();
    formData1.append("latitude", latitude1);
    formData1.append("longitude", longitude1);
    axios
      .post(
        "https://partywitty.com/master/APIs/Common/recommendedClub",
        formData1
      )
      .then((response) => {
        if (response.data.status === true) {
          setClub1(response.data.data);
          setIsLoading(false);
        } else {
          setClub1([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(isLoading);
  const dummyArray = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }];
  const dummyArray1 = [{ id: 1 }]

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content=" Party Witty is a Place for Finding Best Party Packages Near You, Party Venues, Liquor Party Packages  Best Deals & Offers Veg & Non Veg Food, cocktail  Party Bar,pubs, Group party halls in Delhi Ncr."
        />
        <meta name="robots" content="all" />
        <meta name="GOOGLEBOT" content="Index, Follow" />
        <meta name="Robots" content="Index, Follow" />
        <meta name="Author" content="Party witty" />
        <meta name="language" content="EN-UK" />
        <meta
          property="og:title"
          content="Party Packages | Corporate Party Places, Cocktail Party Bar,Pubs"
        />
        <meta property="og:site_name" content="PartyWitty" />
        <meta property="og:url" content="https://www.partywitty.com" />
        <meta
          property="og:description"
          content="Party Witty is a Place for Finding Best Party Packages Near You, Party Venues, Liquor Party Packages  Best Deals & Offers Veg & Non Veg Food, cocktail  Party Bar,pubs, Group party halls in Delhi Ncr."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.partywitty.com/static/media/logo.7b353465feb370797c03.png"
        />
        <link rel="canonical" href=" https://www.partywitty.com/ " />
      </Helmet>
      <div className="home-page-design">
        <WebApp/>
        <div className="card1">
          <HomepageTab/>
          <div className="banner_slider_update">
           <Multiitem />
          </div>
          <div className="popular--package--9">
            <div className="partygoer1">
              <p className="partygoer--p">
                Looking for a party package - get the best bid
              </p>
            </div>
            <div className="partygoer2">
              <LazyLoad offset={5}>
              <div className="services1-1">
              {isLoading &&  dummyArray.map((item) => (
      <ShimmerCircularImage size={150} />
      )) }
                {city1.map((g, i) => (
                  <Link
                    to={{
                      pathname: `/party-packages/${g.name
                        .replace(/\s+/g, "-")
                        .replace(/\+/g, "-")
                        .replace(/-+/g, "-")
                        .toLowerCase()}`,
                    }}
                    key={i}
                  >
                    <div className="homepage--package--img-name">
                      <div className="homepage--package--img-name--1">
                        <img
                          src={BackgroundPattern}
                          style={{
                            filter: "invert(100%)",
                            opacity: "0.3",
                          }}
                          alt="Your Image"
                        />

                        <div className="homepage--package--img-name--2">
                          {g.img ? (
                            <img src={url + g.img} alt={g.name} />
                          ) : (
                            <img src={BackgroundPattern} alt="Default_Image" />
                          )}
                          <p className="favorite--hangout--place--name2">
                            {g.total_packages}+
                          </p>
                        </div>
                      </div>
                        <p>{g.name}</p>
                    </div>
                  </Link>
                ))}
              </div>
              </LazyLoad>
            </div>
          </div>
          <div className="popular--package--9-1">
            <div className="partygoer1">
              <p className="partygoer--p">Popular Packages</p>
            </div>
             <div className="club_Card_Container">
             {isLoading &&  dummyArray.map((item) => (
        <ShimmerThumbnail key={item.id} height={250} width={310} rounded />
      )) }
              {package1.map((data) => {
                return <PackageCard PackageData={data} />;
              })}
            </div>
            <div className="partygoer1">
              <p className="partygoer--p-1">
                <Link
                  to="/party-packages-delhi-ncr-view-all"
                  style={{ textDecoration: "none" }}
                  className="bookatable-routes"
                >
                  <span>See all</span>
                </Link>
              </p>
            </div>
           <div className="club_Card_Container">
               <AllTeaser />
           </div>
          </div>
          <div className="banner_slider_update">
            <Sponsored />
          </div>
          <div className="popular--package--9-1">
            <div className="partygoer1">
              <p className="partygoer--p">Favorite hangout places</p>
            </div>
            <div className="carnival--homepage--nearby">
              <LazyLoad offset={5}>
              <div className="services1">
                {isLoading &&  dummyArray.map((item) => (
                 <ShimmerThumbnail key={item.id} height={300} width={230} rounded />
                ))}
                  {city &&
                  city.map((f, i) => (
                      <div className="favorite--hangout--card" key={i}>
                        <a
                          href={`/${f.name?.replace(
                            /\s+/g,
                            "-"
                          )}/Table-booking-reservations`}
                        >
                          <img
                            src={url + f.img}
                            alt={`Party Packages ${f.name}`}
                          />
                          <div className="favorite--hangout--place--name">
                            <p className="favorite--hangout--place--name1">
                              {f.name}
                            </p>
                            <p className="favorite--hangout--place--name4">
                              {f.total_club}+ Clubs
                            </p>
                          </div>
                        </a>
                      </div>
                  ))}
                </div>
              </LazyLoad>
            </div>
          </div>
          <div className="popular--package--9-2">
            <div className="partygoer1">
              <p className="partygoer--p">Clubs For Table Reservation</p>
            </div>
            <div className="club_Card_Container">
            {isLoading &&  dummyArray.map((item) => (
            <ShimmerThumbnail key={item.id} height={250} width={310} rounded />
            ))}
            {club1.slice(0, 10).map((item, i) => {
              return <ClubCard ClubData={item} />;
            })}
            </div>
          </div>
          <div className="popular--package--9-2">
           
            <div>
               <UpcommingEvents/>
            </div>
          </div>
          <div className="popular--package--9-2">
            <div className="partygoer1">
              <p className="partygoer--p">Newly Added Club</p>
            </div>
            <div>
               <NewlyAddedClub/>
            </div>
            <div className="partygoer1">
              <p className="partygoer--p-1">
                <Link
                  to="/newly-added-club-delhi-ncr-view-all"
                  style={{ textDecoration: "none" }}
                  className="bookatable-routes"
                >
                  <span>See all</span>
                </Link>
              </p>
            </div>
          </div>
          <div className="popular--package--9-2">
            <div className="partygoer1">
              <p className="partygoer--p">Artist</p>
            </div>
            <div>
               <ArtistHomePage/>
            </div>
            <div className="partygoer1">
              <p className="partygoer--p-1">
                <Link
                  to="/artist-view-all"
                  style={{ textDecoration: "none" }}
                  className="bookatable-routes"
                >
                  <span>See all</span>
                </Link>
              </p>
            </div>
          </div>
          <div className="banner_slider_update--footer">
          {isLoading &&  dummyArray1.map((item) => (
            <ShimmerThumbnail key={item.id} height={160}  rounded />
          ))}
            <Carousel interval={6000} indicators={false}>
              {filteredData.map((e) => (
                <Carousel.Item key={e.id} className="slider_img">
                  {generateURL(e.landing_page, e) ? (
                    <Link to={generateURL(e.landing_page, e)}>
                      <img src={url + e.default_img} alt="img" />
                    </Link>
                  ) : (
                    <img src={url + e.default_img} alt="img" />
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
