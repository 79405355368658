
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
const index = ({ Data,setImageView }) => {
    
    return (
        <Lightbox images={Data} startIndex={0} onClose={()=>setImageView({isActive:false,imageData: []})}/>
    )
}

export default index