import { Fragment, useEffect, useState, useRef, useCallback } from "react";
import styles from './searchResult.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";
import axios from "axios";
import Clubcard from '../ClubCard/index';
import PackageCard from '../PackageCard';
import EventCard1 from '../EventCard1/index';  // Ensure the correct import path

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

function Index() {
    const [searchInput, setSearchInput] = useState("");
    const [selectedOption, setSelectedOption] = useState(0);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [fetchedData, setFetchedData] = useState({
        clubs: [],
        packages: [],
        events: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const inputRef = useRef(null);
    const cancelTokenRef = useRef(null);

    const searchResult = location.search.split('?')[1];
    const decodedString = decodeURIComponent(searchResult.replace(/%20/g, ' '));

    const fetchData = useCallback(async (lat, long, searchInput, cancelToken) => {
        if (!searchInput || !lat || !long) {
            return;
        }
        setLoading(true);
        try {
            const formdata = new FormData();
            formdata.append('keyword', searchInput);
            formdata.append('latitude', lat);
            formdata.append('longitude', long);
            const res = await axios.post(`https://partywitty.com/master/APIs/Web/globalSearch`, formdata, {
                cancelToken: cancelToken.token
            });

            const { status, data } = res.data;
            if (status === true) {
                const clubs = data[0]?.clubs || [];
                const packages = data[1]?.packages || [];
                const events = data[2]?.events || [];
                setFetchedData({ clubs, packages, events });
                setError(null);
                console.log('Fetched Data:', { clubs, packages, events });
            } else {
                setError('Failed to load data');
                console.error('Error:', res.data);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.log('Fetch Error:', error);
                setError('Failed to load data');
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const getLocation = () => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLatitude(latitude.toString());
                        setLongitude(longitude.toString());
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                console.error('Geolocation is not available in this browser.');
            }
        };

        getLocation();
    }, []);

    useEffect(() => {
        const debouncedFetchData = debounce((lat, long, searchInput) => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Operation canceled due to new request.');
            }
            cancelTokenRef.current = axios.CancelToken.source();
            fetchData(lat, long, searchInput, cancelTokenRef.current);
        }, 500);

        if (latitude && longitude && searchInput) {
            debouncedFetchData(latitude, longitude, searchInput);
        }

        return () => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel();
            }
        };
    }, [latitude, longitude, searchInput, fetchData]);

    useEffect(() => {
        setSearchInput(decodedString);
    }, [decodedString]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, []);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
        console.log("Search Input:", e.target.value);
    };

    const handleSearch = (e) => {
        if (e.key === "Enter") {
            const removeSpace = searchInput.replace(/\s/g, '');
            if (removeSpace === "") {
                return alert(`It looks like you didn't search.`);
            }
            navigate(`/result?${searchInput}`);
        }
    };

    const specificSearch = [
        { id: 0, name: 'Club' },
        { id: 1, name: 'Packages' },
        { id: 2, name: 'Events' }
    ];

    const renderResults = () => {
        const data = selectedOption === 0 ? fetchedData.clubs : selectedOption === 1 ? fetchedData.packages : fetchedData.events;
        return data.map((item) => {
            if (selectedOption === 0) return <Clubcard ClubData={item} key={item.id} />;
            if (selectedOption === 1) return <PackageCard PackageData={item} key={item.id} />;
            if (selectedOption === 2) return <EventCard1 EventData={item} key={item.id} />;
            return null;
        });
    };

    return (
        <Fragment>
            <div className={styles.Container}>
                <div className={styles.inputContainer}>
                    <span onClick={handleSearch}><IoMdSearch /></span>
                    <input
                        type="search"
                        placeholder="search club or packages"
                        ref={inputRef}
                        value={searchInput}
                        onChange={handleInputChange}
                        onKeyDown={handleSearch}
                    />
                </div>
                <p id={styles.basetext}>category</p>
                <div className={styles.categoryContainer}>
                    {specificSearch.map((item) => (
                        <button
                            className={selectedOption === item.id ? `${styles.cateBTN} ${styles.active}` : `${styles.cateBTN}`}
                            key={item.id}
                            onClick={() => setSelectedOption(item.id)}
                        >
                            {item.name}
                        </button>
                    ))}
                </div>
            </div>

            <div className={styles.resultDiv}>
                <p id={`${styles.basetext}`}>Result {selectedOption === 0 ? fetchedData.clubs.length : selectedOption === 1 ? fetchedData.packages.length : fetchedData.events.length} found</p>
                <div className={styles.renderResults}>
                    {renderResults()}
                </div>
            </div>
        </Fragment>
    );
}

export default Index;
