import styles from './Menu.module.css'

function index({ MenuDetails, url, setImageView }) {
    const { images, name, id } = MenuDetails;

    const AddImageURl = images.map((item) => {
        return {
            url: url + item.img,
        }
    })
    
    return (
        <div className={styles.MenuContainer} key={id} onClick={() => setImageView({ isActive: true, imageData: AddImageURl })}>
            <img src={`${url}${images[0].img}`} alt={name} />
            <h2>{name}</h2>
            <p>{images?.length} pages</p>
        </div>
    )
}

export default index