import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import star from "../../favorate--hangout--page/image/star.png";
import '../../Homepage/Homepage.css';
import React, { useState, useEffect, useRef } from "react";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";

const EventCard = ({ EventData }) => {
    // Destructure of Event Data
    const { id, name, image, video, clubLogo, address, event_date, event_time, clubName, totalRating, areaName, cityName ,distance } = EventData;
    
    const VALID_URL = cityName ? `/${cityName?.replace(/ /g, '-')}/${areaName?.replace(/ /g, '-')}/${clubName?.trim().split(/ +/).join('-').replace(/ /g, '-')}/event/${name.split('+').map(part => part.trim().replace(/ /g, '-')).join('-').toLowerCase()}` : '/';
    
    // Base URL of Image
    const url = "https://partywitty.com/master/assets/uploads/";
   
  const [activeVideo, setActiveVideo] = useState(null);
  const handleMouseLeave = (videoId) => {
    videoId.pause();
    setActiveVideo(null);
  };

  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };
  
    return (
        <div key={id}>
            <LazyLoad offset={5}>
                <Link to={VALID_URL}>
                <div className="event-view-card1">
            {image ? (
              <img src={url + image} alt={name} />
            ) : video ? (
              <video
                controls
                loop
              >
                <source src={url + video} type="video/mp4" />
              </video>
            ) : (
              <p>No content available</p>
            )}
            <div className="event-view-card1-content">
              <div className="event-view-card1-content1">
                <h4 className="p-0,m-0">{name}</h4>
                <p className="event-view-card1-content1-p">{clubName}</p>
                <p className="event-view-card1-content1-p1">{distance} km</p>
              </div>
              <div className="event-view-card1-content2">
                <div className="date">
                  <span>
                    <AiTwotoneCalendar />
                  </span>{" "}
                  <p>{event_date}</p>
                </div>
                <div className="date">
                  <span>
                    <MdOutlineWatchLater />
                  </span>{" "}
                  <p>{event_time}</p>
                </div>
                <button className="event-view-all-button">Book Now</button>
              </div>
            </div>
          </div>
                </Link>
            </LazyLoad>
        </div>
    );
}

export default EventCard;
