import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import Modal from "@mui/material/Modal";
import crossimage from "./image/crossimage.svg";
import "./Visiting--table.css";
import "../App.css";
import Loginpopup from "../Navbar/Loginmodal";
import { GoDotFill } from "react-icons/go";
import Thanks from "../Navbar/image/Thanks.png";
import Notiflix from "notiflix";
import Googleplay from "../Footer/image/Googleplay.png";
import Applestore from "../Footer/image/appdownloads.png";
import { Link } from "react-router-dom";
import iziToast from "izitoast";
import Multiselect from "multiselect-react-dropdown";
import "izitoast/dist/css/iziToast.min.css";

export default function VisitingTableVenue() {
  const [openPlanEvent, setOpenPlanEvent] = useState(true);

  const handleOpenPlanEvent = () => {
    setOpenPlanEvent(true);
  };
  useEffect(() => {
    const isPopupClosed = localStorage.getItem("isPopupClosed");
    if (isPopupClosed) {
      setOpenPlanEvent(false);
    }
  }, []);
  const handleClosePlanEvent = () => {
    localStorage.setItem("isPopupClosed", "true");
    setOpenPlanEvent(false);
  };
  const [currentStep, setCurrentStep] = useState(1);
  const handleNextStep = () => {
    const totalPeople = parseInt(numberMale) + parseInt(numberFemale);
    if (totalPeople < 10) {
      iziToast.error({
        title: "Error",
        message: "Minimum 10 people required",
        position: "topCenter",
      });
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleToggleItem = (itemId) => {
    let updatedSelectedItems;
    if (selectedItems.includes(itemId)) {
      updatedSelectedItems = selectedItems.filter((id) => id !== itemId);
    } else {
      updatedSelectedItems = [...selectedItems, itemId];
    }

    setSelectedItems(updatedSelectedItems);
  };

  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [openPlanEvent4, setOpenPlanEvent4] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const handleOpenthankyou = () => {
    setThankyou(true);
  };
  const handleClosethankyou = () => {
    setThankyou(false);
  };
  const handleClosePlanEvent4 = () => {
    setOpenPlanEvent4(false);
  };
  const [openPlanEvent2, setOpenPlanEvent2] = useState(false);
  const handleOpenPlanEvent2 = () => {
    if (userdata == null) {
      setShowLoginPopup(true);
    } else {
      setOpenPlanEvent2(true);
    }
  };
  const [openPlanEventpage, setOpenPlanEventpage] = useState(false);

  const handleClosePlanEventpage = () => {
    setOpenPlanEventpage(false);
  };

  const [budget, setBudget] = useState("");
  const [package_id, setPackage_id] = useState("");
  const [numberMale, setnumberMale] = useState("");
  const [numberFemale, setnumberFemale] = useState("");
  const [location1, setLocation1] = useState("");
  const [occation1, setOccation1] = useState("");
  const [kids1, setKids1] = useState("no");
  const [no_of_kids1, setno_of_kids1] = useState("");
  const [choosePackage1, setChoosePackage1] = useState("");
  const [eventDate1, setEventDate1] = useState("");
  const [arrivalTime1, setArrivalTime1] = useState("");
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [amenties, setAmenties] = useState("");
  const [location12, setLocation12] = useState([]); // Location state
  const [cityId, setCityId] = useState([]); // City ID state
  const [packagetitle, setPackagetitle] = useState("");
  const [clubcategorydata, setClubCategorydata] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [packagetitlelist, setPackagetitlelist] = useState([]);
  const [leadarea, setLeadarea] = useState([]);
  const [citydata, setCityData] = useState([]);
  
  const [packtitle, setPacktitle] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  const handlePackagename = (e) => {
    const ptitles = e.target.value;
    setPacktitle(ptitles);
  };
  useEffect(() => {
    axios
      .get(" https://partywitty.com/master/Api/getData/clubCategory")
      .then((response) => {
        setClubCategorydata(response.data.data);
      });
  }, []);

  const handlePackageChange22 = (e) => {
    const selectedId = e.target.value;
    setSelectedPackageId(selectedId);
  };
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedAreaid, setSelectedAreaid] = useState(null);
 
  useEffect(() => {
    // API URL for cityWiseArea
    const apiUrl = "https://partywitty.com/master/APIs/Common/getArea";

    if (cityId.length > 0) {
      const cityIdsString = cityId.join(','); // Convert array to comma-separated string
      const postData = new FormData();
      postData.append("city_id", cityIdsString);

      axios
        .post(apiUrl, postData)
        .then((response) => {
          setLeadarea(response.data.data);
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  }, [cityId]);

  const handleSelect = (selectedList, selectedItem) => {
    setLocation12(selectedList);
    const selectedCityIds = selectedList.map((city) => city.id);
    setCityId(selectedCityIds);
  };

  const handleRemove = (selectedList, removedItem) => {
    setLocation12(selectedList);
    const selectedCityIds = selectedList.map((city) => city.id);
    setCityId(selectedCityIds);
  };

 const handleSelectArea = (selectedList, selectedItem) => {
    setSelectedAreas(selectedList);
  };

  const handleRemoveArea = (selectedList, removedItem) => {
    setSelectedAreas(selectedList);
  };
  const options1 = leadarea.map((area) => ({ id: area.id, name: area.name }));
  const formdata1 = new FormData();
  formdata1.append("male", numberMale);
  formdata1.append("female", numberFemale);
  formdata1.append("name", "");
  formdata1.append("email", "");
  formdata1.append("mobile", "");
  formdata1.append("requirement", "Package");
  formdata1.append("lead_source", "Website");
  formdata1.append("my_choice", packtitle);
  let venueTypesString = selectedItems.join(",");
  formdata1.append("venue_type", venueTypesString);
  formdata1.append("type", packagetitle);
  formdata1.append("user_id", userdata ? userdata.id : null);
  formdata1.append("address", selectedArea ? selectedArea.name : null);
  formdata1.append("whatsapp", userdata ? userdata.whatsapp : null);
  formdata1.append("party_date", eventDate1);
  formdata1.append("party_time", arrivalTime1);
  formdata1.append("kids_friendly", "yes");
  formdata1.append("kids", no_of_kids1);
  formdata1.append("occation", "");
  formdata1.append("latitude", selectedArea ? selectedArea.latitude : null);
  formdata1.append("longitude", selectedArea ? selectedArea.longitude : null);
  formdata1.append("whatsapp_msg", "true");
  formdata1.append("range", "10KM");
  const selectedAreaIds = selectedAreas.map(area => area.id).join(',');
    formdata1.append("area_id", selectedAreaIds);
    formdata1.append("city_id", cityId.join(','));

  const handleSubmitLeadform1 = (e) => {
    const apiUrl =
      "https://partywitty.com/master/APIs/ClubPackage/submitEnquiry";
    Notiflix.Loading.dots({ svgColor: "#00bfff" });
    fetch(apiUrl, {
      method: "POST",
      body: formdata1,
    })
      .then((response) => response.json())
      .then((data) => {
        Notiflix.Loading.remove();
        if (data.status === true) {
          handleOpenthankyou();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleOpenPlanEvent4 = () => {
    if (userdata === null) {
      setShowLoginPopup(true);
    } else {
      handleNextStep();
    }
  };
  const handlePackageChange = (e) => {
    const selectedPackage = e.target.value;
    setPackagetitle(selectedPackage);

    if (selectedPackage) {
      const formData = new FormData();
      formData.append("type", selectedPackage);

      axios
        .post(
          "https://partywitty.com/master/Api/getDataPost/AllPackageTitle",
          formData
        )
        .then((response) => {
          setPackagetitlelist(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleStepChange = (step) => {
    setCurrentStep(step);
  };
  const [areawise, setAreawise] = useState([]);

  useEffect(() => {
    axios
      .get(" https://partywitty.com/master/Api/getData/getArea")
      .then((response) => {
        setAreawise(response.data.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(" https://partywitty.com/master/Api/getData/getAmenityCategory")
      .then((response) => {
        setAmenties(response.data.data);
      });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpenPlanEvent(true);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const texts = [
    "Get ₹200 Off on your First Table Booking",
    "Get ₹500 Off on your First Book A Venue",
  ];
  const [textIndex, setTextIndex] = useState(0);
  const [textParts, setTextParts] = useState([]);
  const [partIndex, setPartIndex] = useState(0);
  const speed = 150;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (partIndex < texts[textIndex].length) {
        const currentPart = texts[textIndex].charAt(partIndex);
        setTextParts((prevParts) => [...prevParts, currentPart]);
        setPartIndex((prevIndex) => prevIndex + 1);
      } else {
        setPartIndex(0);
        setTextParts([]);
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }
    }, speed);

    return () => clearTimeout(timer);
  }, [partIndex, textParts, textIndex, texts]);

  const getPhraseColor = (phrase) => {
    if (phrase === "₹200 Off") {
      return "#FFD527";
    } else if (phrase === "₹500 Off") {
      return "#C6F223";
    } else {
      return "white";
    }
  };

  const [selectedTime, setSelectedTime] = useState("12:00");

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const [isYesSelected, setIsYesSelected] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const firstApiUrl =
      "https://partywitty.com/master/Api/getDataPost/countryWiseState";

    const firstPostData = new FormData();
    firstPostData.append("country_id", "101");

    const combinedData = [];

    axios
      .post(firstApiUrl, firstPostData)
      .then((response) => {
        const stateIds = response.data.data
          .filter(
            (item) =>
              item.id === "4007" || item.id === "4021" || item.id === "4022"
          )
          .map((item) => item.id);

        const secondApiUrl =
          "https://partywitty.com/master/Api/getDataPost/stateWiseCity";

        const secondPostPromises = stateIds.map((stateId) => {
          const secondPostData = new FormData();
          secondPostData.append("state_id", stateId);

          // Send the second POST request using axios for each state ID
          return axios.post(secondApiUrl, secondPostData);
        });

        // Wait for all secondPostPromises to resolve
        return Promise.all(secondPostPromises);
      })
      .then((responses) => {
        // Add the data from each response to the combinedData array
        responses.forEach((response) => {
          combinedData.push(...Array.from(response.data.data));
        });

        // Filter combinedData to keep only specific 'id' values
        const filteredData = combinedData.filter((item) =>
          [
            "134404",
            "134007",
            "133234",
            "131821",
            "58171",
            "132032",
            "131853",
            "133230",
            "132005",
            "131953",
          ].includes(item.id)
        );

        setApiData(filteredData);
      })
      .catch((error) => {
        console.error("API error", error);
      });
  }, []);

  useEffect(() => {}, [apiData]);
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getAreaLead")
      .then((response) => {
        setCityData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [clubcategorydata1, setClubcategorydata1] = useState([]);

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/clubCategory")
      .then((response) => {
        setClubcategorydata1(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const today = new Date().toISOString().split("T")[0];

  const secondtext = "Please help us with a few details.  ";
  const thirdtext =
    "Let us reserve your space. Please let us know your expected date and time of arrival.  ";
  const fourthtext =
    "Please share your preferred location so we can get you the best deals!  ";
  const sixthtext = "Kindly select your package type ";
 
  const options = citydata.map((e) => ({ id: e.id, name: e.name }));

  return (
    <div>
      {/* plan your event pop up start */}
      {showLoginPopup && <Loginpopup />}
      <div>
        {openPlanEvent && (
          <div className="blur-background">
            <Modal
              open={openPlanEvent}
              onClose={handleClosePlanEvent}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box className="responsive-modal-1--popup--1">
                <div className="Plan--your--event--popup">
                  <div className="Plan--your--event--popup--1">
                    <div className="cls--btn" onClick={handleClosePlanEvent}>
                      <img src={crossimage}></img>
                    </div>
                    <div className="Plan--your--event--popup--1--img--group--banner"></div>
                    {currentStep === 1 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                            Let’s get you the BEST DEAL.
                          </p>
                          <p className="visiting--table--booking--content--p2">
                            Let us know your preferences.
                          </p>
                          <div>
                            <div className="visiting--table--booking--content--tabs">
                              {clubcategorydata.map((item) => (
                                <button
                                  key={item.id}
                                  className={`visiting--table--booking--content--tabs--card ${
                                    selectedItems.includes(item.id)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleToggleItem(item.id)}
                                >
                                  {item.name}
                                </button>
                              ))}
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                              <button
                                onClick={handleOpenPlanEvent4}
                                disabled={selectedItems.length === 0}
                                className={
                                  selectedItems.length === 0
                                    ? "selectedwidgetedbutton"
                                    : ""
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 2 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                            {secondtext.toUpperCase()}
                          </p>
                          <p className="visiting--table--booking--content--p2 "></p>
                          <div className="visiting--table--booking-second--page">
                            <div className="visiting--table--booking-second--page--1">
                              <div className="visiting--table--booking-second--page--1--1-h">
                                <label>No. of Male</label>
                                <input
                                  type="number"
                                  placeholder="Enter"
                                  value={numberMale}
                                  onChange={(e) =>
                                    setnumberMale(e.target.value)
                                  }
                                  min="0"
                                />
                              </div>
                              <div className="visiting--table--booking-second--page--1--1-h">
                                <label>No. of Female</label>
                                <input
                                  type="number"
                                  placeholder="Enter"
                                  value={numberFemale}
                                  onChange={(e) =>
                                    setnumberFemale(e.target.value)
                                  }
                                  min="0"
                                />
                              </div>
                              <div className="visiting--table--booking-second--page--1--1-h">
                                <label>Kids</label>
                                <input
                                  type="number"
                                  placeholder="Enter"
                                  value={no_of_kids1}
                                  onChange={(e) =>
                                    setno_of_kids1(e.target.value)
                                  }
                                  min="0"
                                />
                              </div>
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                              <button
                                onClick={handleNextStep}
                                disabled={
                                  numberMale.length === 0 ||
                                  numberFemale.length === 0 ||
                                  no_of_kids1.length === 0
                                }
                                className={
                                  numberMale.length === 0 ||
                                  numberFemale.length === 0 ||
                                  no_of_kids1.length === 0
                                    ? "selectedwidgetedbutton"
                                    : ""
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 3 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                            {thirdtext.toUpperCase()}
                          </p>
                          <p className="visiting--table--booking--content--p2 ">
                            {/* Choose Your Venue Type */}
                          </p>
                          <div className="visiting--table--booking-second--page">
                            <div className="visiting--table--booking-second--page--1">
                              <div className="visiting--table--booking-second--page--1--1 ">
                                <label>Date</label>
                                <input
                                  type="date"
                                  value={eventDate1}
                                  onChange={(e) =>
                                    setEventDate1(e.target.value)
                                  }
                                  min={today}
                                />
                              </div>
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>Time</label>
                                <input
                                  type="time"
                                  placeholder="Enter"
                                  value={arrivalTime1}
                                  onChange={(e) =>
                                    setArrivalTime1(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                              <button
                                onClick={handleNextStep}
                                disabled={
                                  eventDate1.length === 0 ||
                                  arrivalTime1.length === 0
                                }
                                className={
                                  eventDate1.length === 0 ||
                                  arrivalTime1.length === 0
                                    ? "selectedwidgetedbutton"
                                    : ""
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 4 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                        <p className="visiting--table--booking--content--p1">
                            {fourthtext.toUpperCase()}
                          </p>
                          <div className="visiting--table--booking-second--page">
                            <div className="visiting--table--booking-second--page--1">
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>City</label>
                                <Multiselect
                                  options={options}
                                  selectedValues={location12}
                                  onSelect={handleSelect}
                                  onRemove={handleRemove}
                                  displayValue="name"
                                  placeholder="Select City"
                                  dropdownStyle={{ maxHeight: 'auto', overflowY: 'auto' }}
                                />
                              </div>
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>Area</label>
                                <Multiselect
          options={options1}
          selectedValues={selectedAreas}
          onSelect={handleSelectArea}
          onRemove={handleRemoveArea}
          displayValue="name"
          placeholder="Select Area"
          
          />
                              </div>
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                            <button
          onClick={handleNextStep}
          disabled={selectedAreas.length === 0 || location12.length === 0}
          className={
            selectedAreas.length === 0 || location12.length === 0
              ? "selectedwidgetedbutton"
              : ""
          }
        >
          Next
        </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 5 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                            {sixthtext.toUpperCase()}
                          </p>
                          <p className="visiting--table--booking--content--p2 ">
                            {/* Choose Your Venue Type */}
                          </p>
                          <div className="visiting--table--booking-second--page">
                            <div className="visiting--table--booking-second--page--1">
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>Package</label>
                                <select
                                  value={packagetitle}
                                  onChange={handlePackageChange}
                                >
                                  <option value="">Select Package</option>
                                  <option value="Alcoholic">Alcoholic</option>
                                  <option value="Non-Alcoholic">
                                    Non-Alcoholic
                                  </option>
                                </select>
                              </div>
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>PackageName</label>
                                <select
                                  value={packtitle}
                                  onChange={handlePackagename}
                                >
                                  <option>Select Package</option>
                                  {packagetitlelist.map((e) => (
                                    <option key={e.id} value={e.id}>
                                      {e.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="Plan--your--event--popup--1--p--visit--button">
                              <button
                                onClick={handleSubmitLeadform1}
                                disabled={!packagetitle || !packtitle}
                                className={
                                  !packagetitle || !packtitle
                                    ? "selectedwidgetedbutton"
                                    : ""
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="Plan--your--event--popup--steps">
                  <ul className="step-dots">
                    {[1, 2, 3, 4, 5].map((step) => (
                      <li
                        key={step}
                        className={currentStep >= step ? "active" : ""}
                      >
                        {step === currentStep ? (
                          <span style={{ fontSize: "22px" }}>
                            <GoDotFill />
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              currentStep > step && handleStepChange(step)
                            }
                          >
                            <GoDotFill />
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Box>
            </Modal>
          </div>
        )}
        <Modal
          open={thankyou}
          onClose={handleClosethankyou}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box className="responsive-modal-1">
            <div className="thankyou--page--close">
              <button onClick={handleClosethankyou}>X</button>
            </div>
            <div className="lead--genrate--thankew-page">
              <div className="Plan--your--event--popup-page2">
                <img src={Thanks}></img>
                <p>
                  <b>Your booking is confirmed. </b>
                </p>
                <p>
                  It’s time to get the best deals and offers from the nearby
                  venues.
                </p>
                <p>Stay tuned!</p>
                <div className="about-us-playstore-image_widget">
                  <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                    <img src={Googleplay} alt="playstore" />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                    <img src={Applestore} alt="appstore" />
                  </Link>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {/* plan your pop up end */}
    </div>
  );
}
