import "./My--Booking.css";
import React, { useState, useEffect } from "react";
import Arrow from "./image/Arrow.svg";
import { CiSearch } from "react-icons/ci";
import booking from "./image/booking.png";
import { MdStar } from "react-icons/md";
import { FaStarHalfStroke } from "react-icons/fa6";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function MyBooking() {
  const navigate = useNavigate();
  var url = "https://partywitty.com/master/assets/uploads/";
  const [activePrice, setActivePrice] = useState("upcoming");
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [bookinghistory, setBookinghistory] = useState([]);
  const handleButtonPrice = (type) => {
    setActivePrice(type);
  };
  const userid = new FormData();
  userid.append("user_id", "175");
  useEffect(() => {
    axios
      .post(
        "https://partywitty.com/master/Api/getDataPost/SuccessBookingHistory",
        userid
      )
      .then((response) => {
        if (response.data.status === "true") {
          setBookinghistory([]);
        } else {
          setBookinghistory(response.data.data);
        }
      
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="hamburger--My--booking mt-5">
      <div className="mobile--view--hamburger--my--booking">
        <div className="mobile--view--hamburger--my--booking--1">
          <img
            src={Arrow}
            className="left-aligned-img"
            alt="Arrow Icon"
            onClick={() => navigate(-1)}
          />
          <p className="centered-text">My Booking</p>
        </div>
        <div className="mobile--view--hamburger--my--booking--tab">
          <button
            onClick={() => handleButtonPrice("upcoming")}
            style={{
              color: activePrice === "upcoming" ? "#000" : "#66666E",
            }}
          >
            Upcoming Booking
          </button>
          <button
            onClick={() => handleButtonPrice("booking")}
            style={{
              color: activePrice === "booking" ? "#000" : "#66666E",
            }}
          >
            Booking History
          </button>
        </div>

        <div className="mobile--view--hamburger--my--booking--search">
          <input type="text" placeholder="Search..."></input>
         
        </div>
        <div className="mobile--view--hamburger--my--booking--border--line"></div>
        {activePrice === "upcoming" && (
          <div className="mobile--view--hamburger--my--booking--card--card2">
            <div className="mobile--view--hamburger--my--booking--card">
              <div className="mobile--view--hamburger--my--booking--card1">
                <div className="mobile--view--hamburger--my--booking--card1--party--name">
                  <p>Event</p>
                </div>
                <div className="mobile--view--hamburger--my--booking--card1--content">
                  <p className="mobile--view--hamburger--my--booking--card1--content--p--1--2--1">
                    Event Name
                  </p>
                  <div className="My--Favorite--hamburger--card--12--2--1--1">
                    <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                      Club Name
                    </p>
                    <div className="hamburger--review--card">
                      <span>
                        <MdStar />
                      </span>
                      <p>4.5</p>
                    </div>
                  </div>
                  <div className="mobile--view--hamburger--my--booking--card1--content--address">
                    <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                      <span>Date of Booking:</span>12-11-2023
                    </p>
                    <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                      <span>Address:</span>Rajouri Garden Main...
                    </p>
                  </div>
                </div>
              </div>
              <div className="mobile--view--hamburger--my--booking--card2">
                <img src={booking}></img>
              </div>
            </div>
          </div>
        )}
        {activePrice === "booking" && (
          <div className="mobile--view--hamburger--my--booking--history--card--card2">
            {bookinghistory.map((e) => (
              <div className="mobile--view--hamburger--my--booking--card">
                <div className="mobile--view--hamburger--my--booking--card1">
                  <div className="mobile--view--hamburger--my--booking--card1--party--name">
                    <p>{e.type}</p>
                  </div>
                  <div className="mobile--view--hamburger--my--booking--card1--content">
                    <p className="mobile--view--hamburger--my--booking--card1--content--p--1--2--1">
                      {e.packageName}
                    </p>
                    <div className="My--Favorite--hamburger--card--12--2--1--12">
                      <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                        {e.clubName}
                      </p>
                      <div className="hamburger--review--card">
                        <span>
                          <MdStar />
                        </span>
                        <p>4.5</p>
                      </div>
                    </div>
                    <div className="mobile--view--hamburger--my--booking--card1--content--address">
                      <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                        <span>Date of Booking:</span>
                        {e.date}
                      </p>
                      <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                        <span>Address:</span>
                        {e.clubAddress}
                      </p>
                    </div>
                  </div>
                  <div className="My--Favorite--hamburger--card--12--2--1--12">
                    <p className="mobile--view--hamburger--my--booking--card1--content--p--1--2--1">
                      Rating
                    </p>
                    <div className="hamburger--review--card1">
                      <Stack spacing={1}>
                        <Rating name="size-medium" defaultValue={2} />
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className="mobile--view--hamburger--my--booking--card2">
                  <img src={url + e.clubThumbnail}></img>
                  <button>Book Again</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
