import React, { useEffect, useState } from "react";
import axios from "axios";

import "./MyBooking.css";

export default function Table() {
    const url = "https://partywitty.com/master/assets/uploads/";
  const [bookings, setBookings] = useState([]);
  const userdata = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    const fetchData = async () => {
      const formdata = new FormData();
      formdata.append("user_id", userdata ? userdata.id : null);
      formdata.append("type", "pending");

      try {
        const response = await axios.post(
          "https://partywitty.com/master/APIs/ClubPackage/bookingHistory",
          formdata
        );
        const bookingHistory = response.data.data;
        setBookings(bookingHistory);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchData();
  }, [userdata]);

  return (
    <div className="booking-history-card">
      {bookings && bookings.length > 0 ? (
        bookings.map((booking) => (
          <div
            className="web--view--Notification--bar--heading--and--cross--card"
            key={booking.id}
          >
            <div className="web--view--Notification--bar--heading--and--cross--card1">
              <div className="web-view-img">
                <img src={url+booking.clubLogo} alt="Booking" />
              </div>
              <div className="web--view--Notification--bar--heading--and--cross--card1--1">
                <div className="transaction-history-card">
                  <p className="web--view--notification--1--p">
                    {booking.party_date}
                  </p>
                  <p className="web--view--notification--1--p1">
                    {booking.clubName}
                  </p>
                  <p className="web--view--notification--1--p">
                    {parseInt(booking.kids_qty) +
                      parseInt(booking.male_qty) +
                      parseInt(booking.female_qty)}{" "}
                    Pax
                  </p>
                </div>
                <div className="web--view--Notification--bar--heading--and--cross--card2">
                  <p className="web--view--notification--1--p">
                    Order Id: {booking.order_id}
                  </p>
                  <p className="web--view--notification--1--p">
                    {booking.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>You have no bookings.</p>
      )}
    </div>
  );
}
