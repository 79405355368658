import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routerpage from "./Routerpage";
import { ModalContextProvider } from "./context/context";
import { SelectedItemProvider } from "./context/store";
import { LoginProvider } from "./context/contextlogin";
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ModalContextProvider>
        <SelectedItemProvider>
          <LoginProvider>
            <Routerpage />
          </LoginProvider>
        </SelectedItemProvider>
      </ModalContextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
