import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import About from "./About";
import Rating from "../Components/ReviewComp"
import Dealsoffer from "./Deals-and-offer";
import PackageOffers from "./Packagetab";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "./Menu";
import Gallerysection from "./Gallerysection";
import Teaser from "./Teaser"

import EventCard from "../Club-aboutus/event-card";

const useStyles = makeStyles((theme) => ({
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  scrollableTabs: {
    overflowX: "auto",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function BasicTabsSearchclub(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [innerValue, setInnerValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabScroll = () => {
  
    const selectedTab = document.querySelector(`#simple-tab-${value}`);

 
    if (
      selectedTab &&
      selectedTab.getBoundingClientRect().right > window.innerWidth
    ) {
     
      const scrollAmount =
        selectedTab.getBoundingClientRect().right - window.innerWidth;
      document.querySelector(".scrollableTabs").scrollLeft += scrollAmount;
    }
  };

  return (
    <Box sx={{ width: "100%", borderColor: "white", color: "white" }}>
      <div className={classes.scrollableTabs}>
        <Box sx={{ borderBottom: 1, borderColor: "white", color: "white" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ borderBottom: 1, borderColor: "white", color: "white" }}
            variant="scrollable"
            scrollButtons="auto"
            className="scrollable-tabs"
            onScroll={handleTabScroll}
          >
           
            <Tab
              label="Menu"
              {...a11yProps(0)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
              className={classes.customTab}
            />

            <Tab
              label="Package"
              {...a11yProps(1)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
            />

            <Tab
              label="Events"
              {...a11yProps(2)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
            />

            <Tab
              label="Gallery"
              {...a11yProps(3)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
            />

            <Tab
              label="Teaser"
              {...a11yProps(4)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
            />
            <Tab
              label="Review"
              {...a11yProps(5)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
            />
             <Tab
              label="About Us"
              {...a11yProps(6)}
              sx={{ color: "white" }}
              style={{ minWidth: "20%" }}
              className={classes.customTab}
            />
          </Tabs>
        </Box>
      
        <TabPanel value={value} index={0}>
          <Menu />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PackageOffers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EventCard/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Gallerysection />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Teaser/>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Rating />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <About />
        </TabPanel>
      </div>
    </Box>
  );
}
