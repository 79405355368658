import "../App.css";
import "../Club-page/Clubpage.css";
import "../Web--to--app/WebApp.css";
import star from "../favorate--hangout--page/image/star.png";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaDirections } from "react-icons/fa";
import axios from "axios";
import {useLocation, useParams } from "react-router-dom";
import { useEffect, useState} from "react";
import { AiOutlineMail } from "react-icons/ai";
import addimage from "../Assets/BnB Ad Slide 1.png";
import Booking from "../Components/Table_booking";
import { BsFacebook, BsWhatsapp } from "react-icons/bs";
import { BsShareFill } from "react-icons/bs";
import GetApiCall from "../Assets/API/GetApi";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BiHeart, BiRupee } from "react-icons/bi";
import React from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import logo from "../Assets/logo.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Clubaboutuspagebanneruser from "./ClubaboutusbannerforNormaluser";
import moment from "moment";
import { useLoginContext } from "../context/contextlogin";
import BasicTabsSearchclub from "../Club-page/Club-search-tab";
import { MdOutlineContentCopy } from "react-icons/md";
import ChildModal from "../Navbar/ChildModel";
import Loginpopup from "../Navbar/Loginmodal";
import CancelPopup from "../Navbar/CancelPopup";
import { MdFavorite } from "react-icons/md";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";

const useStyles12 = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 1,
};
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B40431",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B40431",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B40431",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
  },
});
const useStyles1 = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
  },
});
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 1,
};
function Clubaboutuspagesearch(props) {
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [location12, setLocation12] = useState(""); // Location state
  const [apiData, setApiData] = useState(null);
  const [leadarea, setLeadarea] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [bookdata, setBookData] = useState([]);
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes12 = useStyles12();
  const [citydata, setCityData] = useState([]);
  const [cityId, setCityId] = useState("");
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [selectedVenu, setSelectedVenu] = useState([]);
  const [data, setData] = useState([]);
  const [event, setEvent] = useState([]);
  const [banner, setBanner] = useState([]);
  const [banner1, setBanner1] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [clubdata, setClubdata] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedAreaid, setSelectedAreaid] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedPax, setSelectedPax] = useState(null);
  const [isReserved, setReserved] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [clubId, setClubId] = useState(null);
  const [isTextFieldOpen, setIsTextFieldOpen] = useState(false);
  const [clubcategorydata, setClubcategorydata] = useState([]);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [active, setActive] = useState(1);
  const [categories, setCategories] = useState([]);
  const [dataids, setDataid] = useState([]);
  const [heartActive, setHeartActive] = useState(false);
  const [remainingDates, setRemainingDates] = useState([]);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [showCard1, setShowCard1] = useState(true);
  const [showCard2, setShowCard2] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const { city, area, clubname } = useParams();
  const clubN = clubname;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
 
  const url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if (status === "success" && !isReserved) {
      setReserved(true);
    }
  }, [status, isReserved]);

  useEffect(() => {
    if (status === "failed" && !cancel) {
      setCancel(true);
    }
  }, [status, cancel]);

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);
  
  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        setData(response.data.data);
        setClubId(response.data.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [clubN,banner]);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      const ClubApi = new FormData();
      ClubApi.append("latitude", latitude);
      ClubApi.append("longitude", longitude);

      axios
        .post(
          "https://partywitty.com/master/APIs/Common/recommendedClub",
          ClubApi
        )
        .then((response) => {
          const dataArray = Object.values(response.data.data);

          setClubdata(dataArray);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [latitude, longitude]);
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/ClubPackage/getVenuType")
      .then((response) => {
        const clubCategories = response.data.data;
        setClubcategorydata(clubCategories);
        setSelectedVenu(clubCategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getAreaLead")
      .then((response) => {
        setCityData(response.data.data);
      })
      .catch((error) => {
        console.log("Error fetching area data:", error);
      });
  }, []);

  useEffect(() => {
    const firstApiUrl =
      "https://partywitty.com/master/Api/getDataPost/countryWiseState";

    const formData = new FormData();
    formData.append("country_id", "101");

    const combinedData = [];

    axios
      .post(firstApiUrl, formData)
      .then((response) => {
        const stateIds = response.data.data
          .filter(
            (item) =>
              item.id === "4007" || item.id === "4021" || item.id === "4022"
          )
          .map((item) => item.id);

        const secondApiUrl =
          "https://partywitty.com/master/Api/getDataPost/stateWiseCity";

        const secondPostPromises = stateIds.map((stateId) => {
          const formData = new FormData();
          formData.append("state_id", stateId);
          return axios.post(secondApiUrl, formData);
        });

        return Promise.all(secondPostPromises);
      })
      .then((responses) => {
        responses.forEach((response) => {
          combinedData.push(...Array.from(response.data.data));
        });

        const filteredData = combinedData.filter((item) =>
          [
            "134404",
            "134007",
            "133234",
            "131821",
            "58171",
            "132032",
            "131853",
            "133230",
            "132005",
            "131953",
          ].includes(item.id)
        );

        setApiData(filteredData);
      })
      .catch((error) => {
        console.error("API error", error);
      });
  }, []);
  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      club_id: clubId,
    }));
  }, [clubId]);

  const [formData, setFormData] = useState({
    club_id: clubId,
    user_id: userdata ? userdata.id : "",
    guest: "",
    kids: "",
    no_of_kids: "",
    booking_date: "",
    booking_time: "",
  });
  const clubid = new FormData();
  clubid.append("club_id", clubId);

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("data", clubId);
    axios
      .post(
        "https://partywitty.com/master/Api/getDataPost/clubBanner",
        formdata
      )
      .then((response) => {
        if (response.data.status === "true") {
          setBanner([]);
        } else {
          setBanner(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const eventcategoriwise = new FormData();
  eventcategoriwise.append("club_id", clubId);
  const cardCount = document.querySelectorAll(".card-container11").length;

  const updateCarousel = () => {
    document.querySelectorAll(".card-container11").forEach((element, i) => {
      const offset = ((active - i + cardCount) % cardCount) / 3;
      const direction = Math.sign(active - i);
      const absOffset = Math.abs(active - i) / 3;
      const isActive = i === active ? 1 : 0;
      const opacity = Math.abs(active - i) <= 1 ? 1 : 0;

      element.style.setProperty("--offset", offset);
      element.style.setProperty("--direction", direction);
      element.style.setProperty("--abs-offset", absOffset);
      element.style.setProperty("--active", isActive);
      element.style.setProperty("--opacity", opacity);
    });
  };

  useEffect(() => {
    updateCarousel();
  }, [active]);

  useEffect(() => {
    axios
      .post(
        "https://partywitty.com/master/Api/getDataPost/clubGallery",
        clubid ? clubid : clubId
      )
      .then((response) => {
        if (response.data.status === "true") {
          setCategories([]);
        } else {
          setCategories(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const beverages =
    categories?.length > 0
      ? categories.filter((item) => item.category_name === "Beverages")
      : [
          {
            img: "1695534304_d299c9466252e20448f9.jpg",
          },
        ];

  const filteredData1 =
    categories?.length > 0
      ? categories.filter((item) => item.category_name === "Food")
      : [
          {
            img: "1695534192_f0e6ac5304e4cd2d3a28.jpg",
          },
        ];

  const rotateAnimationHandler = (props, state) => {
    const transitionTime = props.transitionTime + "ms";
    const transitionTimingFunction = "ease-in-out";
    let slideStyle = {
      display: "block",
      maxHeight: "630px",

      transitionTimingFunction: transitionTimingFunction,
      msTransitionTimingFunction: transitionTimingFunction,
      MozTransitionTimingFunction: transitionTimingFunction,
      WebkitTransitionTimingFunction: transitionTimingFunction,
      OTransitionTimingFunction: transitionTimingFunction,
      transform: `rotate(0)`,
      position:
        state.previousItem === state.selectedItem ? "relative" : "absolute",
      inset: "0 0 0 0",
      zIndex: state.previousItem === state.selectedItem ? "1" : "-2",
      opacity: state.previousItem === state.selectedItem ? "1" : "0",
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
    return {
      slideStyle,
      selectedStyle: {
        ...slideStyle,
        opacity: 1,
        position: "relative",
        zIndex: 2,
        filter: `blur(0)`,
      },
      prevStyle: {
        ...slideStyle,
        transformOrigin: " 0 100%",
        transform: `rotate(${
          state.previousItem > state.selectedItem ? "-45deg" : "45deg"
        })`,
        opacity: "0",
        filter: `blur( ${
          state.previousItem === state.selectedItem ? "0px" : "5px"
        })`,
      },
    };
  };
  const currentURL = window.location.href;
  const shareText = "Check out this link: " + currentURL;
  const handleShare = (platform) => {
    let shareURL = "";

    if (platform === "facebook") {
      shareURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}&quote=${shareText}`;
    } else if (platform === "whatsapp") {
      shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareText
      )}%20${currentURL}`;
    } else if (platform === "twitter") {
      shareURL = `https://twitter.com/intent/tweet?url=${currentURL}&text=${encodeURIComponent(
        shareText
      )}`;
    } else if (platform === "email") {
      shareURL = `mailto:?subject=${encodeURIComponent(
        shareText
      )}&body=${encodeURIComponent(`Check it out: ${currentURL}`)}`;
    }

    window.open(shareURL, "_blank");
  };
  const addtocart = async (e) => {
    try {
      if (!userdata) {
        setResponseMessage("User data is missing");
        return;
      }
      const formDataToSend = new FormData();
      formDataToSend.append("club_id", clubId);
      formDataToSend.append("user_id", userdata.id);
      const response = await fetch(
        "https://partywitty.com/master/Api/sendData/addClubFavourite",
        {
          method: "POST",
          body: formDataToSend,
        }
      );
      const responseData = await response.json();
      if (responseData.status === true) {
        setIsFavorite(!isFavorite);
        setResponseMessage(responseData.msg);
        setBookData(responseData.data);
        if (responseData.data) {
        } else {
          setShowResponse(true);
        }
      } else {
        setResponseMessage("Error in processing the request");
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };

  const clubid1 = new FormData();

  useEffect(() => {
    if (userdata && userdata.id) {
      clubid1.append("user_id", userdata.id);

      axios
        .post(
          "https://partywitty.com/master/Api/getDataPost/getUserFavouriteClub",
          clubid1
        )
        .then((response) => {
          if (response.data.status === "true") {
            setDataid([]);
          } else {
            const dataIds = response.data.data.map((item) => item.club_id);
            setDataid(dataIds);
            setHeartActive(dataIds.includes(clubId));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    today.setHours(0, 0, 0, 0);

    const initialStartTime = moment(startTime, "h:mm A").toDate();
    const initialEndTime = moment(endTime, "h:mm A").toDate();

    initialStartTime.setMinutes(
      Math.ceil(initialStartTime.getMinutes() / 60) * 60
    );

    const intervals = [];
    const remainingDates = [];

    for (let i = 0; i < 3; i++) {
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const lastDayOfMonth = new Date(
        currentYear,
        currentMonth + 1,
        0
      ).getDate();

      for (let j = today.getDate(); j <= lastDayOfMonth; j++) {
        const date = new Date(currentYear, currentMonth, j);
        remainingDates.push(date);
      }

      today.setMonth(currentMonth + 1);
      today.setDate(1);
    }

    while (initialStartTime <= initialEndTime) {
      const hours = initialStartTime.getHours();
      const minutes = initialStartTime.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedTime = `${formattedHours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm}`;
      intervals.push(formattedTime);

      initialStartTime.setTime(initialStartTime.getTime() + 30 * 60 * 1000);
    }

    setTimeIntervals(intervals);
    setRemainingDates(remainingDates);
  }, [startTime, endTime]);

  useEffect(() => {}, [startTime, endTime]);

  useEffect(() => {
    const interval1 = setInterval(() => {
      setShowCard1(!showCard1);
    }, 4000);

    const interval2 = setInterval(() => {
      setShowCard2(!showCard2);
    }, 2000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [showCard1, showCard2]);
  const numbersArray = [];

  for (let i = 1; i <= 100; i++) {
    numbersArray.push(i);
  }

  const [showNoOfKids, setShowNoOfKids] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const [partyvideo, setPartyvideo] = useState([]);
  const Teaser = new FormData();

  useEffect(() => {
    Teaser.append("club_id", clubId);

    axios
      .post("https://partywitty.com/master/APIs/Common/getTeaser", Teaser)
      .then((response) => {
        if (response.data.status === true) {
          setPartyvideo(response.data.data);
        } else {
          console.log("No data found");
        }
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, []);

 
  const eventUrl = (e) => {
    return `/${(e.cityName?.replace(/ /g, "-") || "").toLowerCase()}/${(
      e.areaName?.replace(/ /g, "-") || ""
    ).toLowerCase()}/${(
      e.name?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}`;
  };

  return (
    <>
      <Helmet>
        <title>{`${data.name} | ${data.address}`}</title>
        <meta name="description" content={`${data.name} | ${data.address}`} />
      </Helmet>
      <div className="club_about mt-5">
        <div className="recommended-club">
          <div className="club--aboutus--ads--banner mb-3">
            <img src={addimage} alt="images" />
          </div>
          <div className="club-aboutus-img-caraousel">
            <Clubaboutuspagebanneruser />
            <div class="location mt-3">
              <div class="location-part">
                <div className="clubname--dirction--icon">
                  <h2 class="h2 m-0">{data.name} </h2>
                </div>
                <div className="details-text">
                  <span>
                    <HiOutlineLocationMarker />
                  </span>
                  <p> {data.address}</p>
                </div>
                <div className="details-text">
                  <span>
                    <AiOutlineFieldTime />
                  </span>{" "}
                  <p> Open from {data.open_time}</p>
                  <br />
                  <p> Close from {data.close_time}</p>
                </div>
              </div>
            </div>
            <div class="SocialShareIcon">
                    <a
                      rel="noreferrer"
                      href={`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${data.latitude},${data.longitude}&travelmode=driving`}
                      target="_blank"
                    >
                      <button className="direction-icon">
                        <FaDirections />
                      </button>
                    </a>

                    <button className="direction-icon2"
                      onClick={addtocart}
                      style={{ color: isFavorite ? "red" : "white" }}
                    >
                      <MdFavorite />
                    </button>

                    <button onClick={handleOpen2} className="direction-icon1" >
                      <BsShareFill />
                    </button>
            </div>
          </div>
        </div>
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="share-menu">
              <p className="facebook" onClick={() => handleShare("facebook")}>
                <BsFacebook />
              </p>
              <p className="whatsapp" onClick={() => handleShare("whatsapp")}>
                <BsWhatsapp />
              </p>
              <p className="twitter" onClick={() => handleShare("twitter")}>
              <FaSquareXTwitter />
              </p>
              <p className="gmail" onClick={() => handleShare("email")}>
                <AiOutlineMail />
              </p>
            </div>
          </Box>
        </Modal>

        <div className="Club-about-us">
          <div class="left-side">
            <div class="left">
              <BasicTabsSearchclub />
            </div>
          </div>
          <div className="right-side">
             <Booking clubId={clubId} />
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            sx={{
              ...style1,
              width: 400,
              height: 560,
            }}
          >
            <Carousel
              showIndicators
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  hasNext && (
                    <button
                      className="nav_btn nav_btn_right"
                      onClick={clickHandler}
                    >
                      <MdNavigateNext />
                    </button>
                  )
                );
              }}
              renderArrowPrev={(clickHandler, hasNext) => {
                return (
                  hasNext && (
                    <button
                      onClick={clickHandler}
                      className="nav_btn nav_btn_left"
                    >
                      <GrFormPrevious />
                    </button>
                  )
                );
              }}
              renderIndicator={(clickHandler, isSelected, index) => {
                return (
                  <li
                    onClick={clickHandler}
                    className={`ind ${isSelected ? "active" : ""}`}
                    key={index}
                    role="button"
                  />
                );
              }}
              transitionTime={310}
              animationHandler={rotateAnimationHandler}
              swipeable={false}
            >
              {beverages.map((URL, index) => (
                <div className="slide">
                  <img alt="sample_file" src={url + URL.img} key={index} />
                </div>
              ))}
            </Carousel>
          </Box>
        </Modal>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            sx={{
              ...style1,
              width: 400,
              height: 560,
            }}
          >
            <Carousel
              showIndicators
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  hasNext && (
                    <button
                      className="nav_btn nav_btn_right"
                      onClick={clickHandler}
                    >
                      <MdNavigateNext />
                    </button>
                  )
                );
              }}
              renderArrowPrev={(clickHandler, hasNext) => {
                return (
                  hasNext && (
                    <button
                      onClick={clickHandler}
                      className="nav_btn nav_btn_left"
                    >
                      <GrFormPrevious />
                    </button>
                  )
                );
              }}
              renderIndicator={(clickHandler, isSelected, index) => {
                return (
                  <li
                    onClick={clickHandler}
                    className={`ind ${isSelected ? "active" : ""}`}
                    key={index}
                    role="button"
                  />
                );
              }}
              transitionTime={310}
              animationHandler={rotateAnimationHandler}
              swipeable={false}
            >
              {filteredData1.map((URL, index) => (
                <div className="slide">
                  <img alt="sample_file" src={url + URL.img} key={index} />
                </div>
              ))}
            </Carousel>
          </Box>
        </Modal>

        <div className="recommended-club mt-3">
          <h2 class="h2">Recommended club</h2>
          <div className="services1">
            {clubdata.map((e) => (
             
                <div
                  key={e.id}
                 
                  className="Favorate--hangout--all--thumbnail--card6"
                  onClick={() => (window.location.href = eventUrl(e))}
                >
                  <div className="Favorate--hangout--all--thumbnail--card--img">
                    <img src={url + e.profile_pic} alt="img" />
                    <div className="Favorate--hangout--all--thumbnail--card--img--1"></div>
                    <div className="Favorate--hangout--all--thumbnail--card--2">
                      <div className="Favorate--hangout--all--thumbnail--card--2--1">
                        <div className="favorate--add--on">
                          <div className="favorate--add--on--card">
                            <p className="favorate--add--on--card--p">
                              {e.addon}
                            </p>
                            <p className="favorate--add--on--card--p--1">%</p>
                          </div>
                          <div className="favorate--add--on--p">
                            <p className="favorate--add--on--p--1">Add On</p>
                            <p className="favorate--add--on--p--2">EXTRA</p>
                          </div>
                        </div>
                        <div className="favorate--offered--card">
                          <p className="favorate--offered--card--1">
                            Offered in
                          </p>
                          <div className="favorate--offered--card--days">
                            <p>Last 30 Days</p>
                          </div>
                        </div>
                        <div className="favorate--add--on">
                          <div className="favorate--add--on--p">
                            <p className="favorate--add--on--p--1--1">
                              Overall
                            </p>
                            <p className="favorate--add--on--p--2--1">
                              Discount
                            </p>
                          </div>
                          <div className="favorate--add--on--card1">
                            <p className="favorate--add--on--card--p">
                              {e.discount}
                            </p>
                            <p className="favorate--add--on--card--p--1">%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Favorate--hangout--card--content">
                    <div className="Favorate--hangout--card--content--1">
                      <p className="Favorate--hangout--card--content--1--p1">
                        {e.name.substring(0, 16)}...
                      </p>
                      <p className="favorate--hangout--address--p5">
                        {e.address.substring(0, 16)}...
                      </p>
                    </div>
                    <div className="favorate--hangout--address">
                      {e.totalRating !== 0 ? (
                        <div className="favorate--hangout--review--card1">
                          <div className="favorate--hangout--review--card">
                            <img src={star} alt="Star Icon" />
                            <p>{e.totalAverageRating}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="favorate--hangout--review--card5"></div>
                      )}
                       <p className="favorate--hangout--address--p2">{e.distance} km</p>
                    </div>
                  </div>
                </div>
            
            ))}
          </div>
        </div>

        <div>
          {isReserved && (
            <ChildModal
              Description={responseMessage}
              heading={"Table Reserve"}
              setOpen={setReserved}
              Open={isReserved}
            />
          )}
        </div>
        <div>
          {cancel && (
            <CancelPopup
              Description={responseMessage}
              heading={"Table Reserve failed"}
              setOpen={setCancel}
              Open={cancel}
            />
          )}
        </div>
      </div>
      {showLoginPopup && <Loginpopup />}
    </>
  );
}

export default Clubaboutuspagesearch;

function Card1({ Data }) {
  return (
    <div className="strips--dis">
      <div className="Discount--addon--coupons--strip">
        <p className="m-0">{Data.discount}%</p>
      </div>
    </div>
  );
}
function Card2({ Data }) {
  const [maxDiscount, setMaxDiscount] = useState(null);

  return (
    <div className="strips--dis">
      <div className="Discount--addon--coupons--strip1">
        <p className="m-0">{maxDiscount}20%</p>
      </div>
    </div>
  );
}

function Card3({ Data }) {
  return (
    <div className="strips--dis">
      <div className="Discount--addon--coupons--strip2">
        <p className="m-0">Starts@{Data.minPrice}</p>
      </div>
    </div>
  );
}

export const ClubTiming = [
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
];
