import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelectedItem } from "../context/store";
import Loginpopup from "../Navbar/Loginmodal";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

const EventBookNow = () => {
  const { selectedItem, handleItemClick } = useSelectedItem();
  const params = useParams();
  const [eventdetailsTicket, setEventdetailsTicket] = useState([]);
  const [eventdetails1, setEventdetails1] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [ticketCounts, setTicketCounts] = useState({});
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [responseId, setResponseId] = useState("");
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [amount, setAmount] = useState(0);
  const url = "https://partywitty.com/master/assets/uploads/";

  const clubid = new FormData();
  clubid.append("event_id", params.id);

const loadScripts = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
};
  const createRazorpayOrder = async (amount) => {
    try {
        const formdata1 = new FormData();
        formdata1.append("user_id", userdata ? userdata.id : null);
        formdata1.append("amount", amount);
        const response = await axios.post("https://partywitty.com/master/APIs/ClubEvent/createOrder", formdata1);
        console.log("orderid", response.data.order_id);
        const orderId = response.data.order_id;
        return orderId;
    } catch (error) {
        console.error("Error creating Razorpay order:", error);
        return null;
    }
};
const handleRazorpayScreen = async (orderId, amount) => {
  try {
      const res = await loadScripts("https://checkout.razorpay.com/v1/checkout.js");
      if (!res) {
          throw new Error("Failed to load Razorpay script");
      }
      const options = {
          key: "rzp_live_V8WPfxrtHG2Bud",
          amount: amount * 100,
          currency: "INR",
          name: "partywitty",
          description: "Payment to partywitty",
          order_id: orderId,
          handler: async function (response) {
              await verifyRazorpayPayment(response);
              alert("Payment successful");
              window.location.href = "/success";
          },
          prefill: {
              name: "partywitty",
              email: "partywitty@gmail.com",
          },
          theme: {
              color: "#f4c438",
          },
          modal: {
              ondismiss: async function () {
                  await verifyRazorpayPayment({ status: "failed" });
                  alert("Payment failed or dismissed");
              }
          }
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
  } catch (error) {
      console.error("Error opening Razorpay:", error);
      alert("Error processing payment. Please try again later.");
  }
};
const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userdata) {
      setShowLoginPopup(true);
      return;
    }
    const selectedTickets = eventdetailsTicket.filter(ticket => ticketCounts[ticket.id] > 0);
    const qty = selectedTickets.map(ticket => ticketCounts[ticket.id]).join(',');
    const ticket_id = selectedTickets.map(ticket => ticket.id).join(',');
    const formdata = new FormData();
    formdata.append("user_id", userdata.id);
    formdata.append("qty", qty);
    formdata.append("ticket_id", ticket_id);
    formdata.append("platform", "web");
    
    try {
      const response = await axios.post("https://partywitty.com/master/APIs/ClubEvent/placeOrder", formdata);
      const data = response.data;
        if (data.status) {
        if (data.data.rzp_order_id === "") {
          window.location.href = "/success";
        } else {
          await handleRazorpayScreen(data.data.rzp_order_id, data.data.net_payable_amount);
        }
      } else {
        setResponseMessage(data.msg || "Failed to book tickets");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      setResponseMessage("Error placing order");
    }
  };
const verifyRazorpayPayment = async (razorpayResponse) => {
  try {
      const formdata = new FormData();
      formdata.append("response", JSON.stringify(razorpayResponse));
      const response = await axios.post("https://partywitty.com/master/APIs/ClubEvent/verifyRzpPayment", formdata);
      const verificationResponse = response.data;
      console.log("Verification Response:", verificationResponse);
        if (verificationResponse.status) {
          iziToast.success({
              title: 'Success',
              message: verificationResponse.msg,
              timeout: 20000, 
              position: 'topCenter',// 20 seconds
          });
      } else {
          iziToast.error({
              title: 'Error',
              message: verificationResponse.msg,
              timeout: 20000,
              position: 'topCenter', // 20 seconds
          });
      }
  } catch (error) {
      console.error("Error verifying payment:", error);
      iziToast.error({
          title: 'Error',
          message: 'An error occurred while verifying the payment.',
          timeout: 20000, 
          position: 'topCenter',// 20 seconds
      });
  }
};
const getTotalAmount = () => {
    return eventdetailsTicket.reduce((total, ticket) => {
        const count = ticketCounts[ticket.id] || 0;
        return total + ticket.price * count;
    }, 0);
};

useEffect(() => {
    axios
      .post(
        "https://partywitty.com/master/Api/getDataPost/eventTicketType",
        clubid
      )
      .then((response) => {
        if (response.data.status === "true") {
          setEventdetailsTicket([]);
        } else {
          setEventdetailsTicket(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(
        "https://partywitty.com/master/Api/getDataPost/eventDetails",
        clubid
      )
      .then((response) => {
        setEventdetails1(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleTicketCountChange = (id, count) => {
    setTicketCounts((prevCounts) => {
      const newCount = Math.max(count, 0); 
      const updatedCounts = { ...prevCounts, [id]: newCount };
      if (newCount === 0) {
        delete updatedCounts[id];
      }
      return updatedCounts;
    });
  };
  

  const handleItemClickEnhanced = (ticket) => {
    handleItemClick(ticket);
    setTicketCounts((prevCounts) => ({
      ...prevCounts,
      [ticket.id]: prevCounts[ticket.id] || 1,
    }));
  };

  return (
    <div className="artist-about-us--book-now">
      <h2>
        {eventdetails1.name}
        <span> &nbsp; &nbsp;( {eventdetails1.categoryName})</span>
      </h2>
      <h4>{eventdetails1.clubName}</h4>

      <div className="artist-about-us--book-now--address">
        <p>{eventdetails1.clubAddress}</p>
        <p>
          {eventdetails1.event_date} | {eventdetails1.event_time}
        </p>
      </div>

    <div className="artist-about-us--book-now--address--all-card-and-total">
    <div className="artist-about-us--book-now--address--all-card">
      {Array.isArray(eventdetailsTicket) &&
        eventdetailsTicket.map((ticket) => (
          <div
            key={ticket.id}
            onClick={() => handleItemClickEnhanced(ticket)}
            className="artist-about-us--book-now--address-content-card"
          >
            <div className="artist-about-us--book-now--address-card">
              <div className="artist-about-us--book-now--address-card1">
                <div className="artist-about-us--book-now--address-card1--pass">
                  <div className="artist-about-us--book-now--address-card1--pass-detail">
                    <p className="event_ticket_name">{ticket.name}</p>
                    <p className="event_ticket_price">
                      {Number(ticket.price) === 0 ? "Free" : `@ INR ${ticket.price}/-`}
                    </p>
                    <p
                      className="event_ticket_contain"
                      dangerouslySetInnerHTML={{ __html: ticket.des }}
                    ></p>
                  </div>
                </div>
              </div>
              <div className="artist-about-us--book-now--address-card2">
                {ticketCounts[ticket.id] > 0 ? (
                  <div className="add_quantity_inc_dec">
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTicketCountChange(
                          ticket.id,
                          ticketCounts[ticket.id] - 1
                        );
                      }}
                    >
                      -
                    </p>
                    <p>{ticketCounts[ticket.id] || 1}</p>
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTicketCountChange(
                          ticket.id,
                          (ticketCounts[ticket.id] || 1) + 1
                        );
                      }}
                    >
                      +
                    </p>
                  </div>
                ) : (
                  <button onClick={(e) => { e.stopPropagation(); handleItemClickEnhanced(ticket); }}>
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>

      <div className="your-order3 mt-3">
        <p>Total : Rs. {getTotalAmount()}/-</p>
        <button disabled={Object.keys(ticketCounts).length === 0} onClick={handleSubmit}>BUY NOW</button>
        {showLoginPopup && <Loginpopup />}
      </div>

      {showResponse && <div className="response-message">{responseMessage}</div>}
    </div>
  );
};

export default EventBookNow;

 