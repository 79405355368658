import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ControlledAccordions() {
//   const [expanded, setExpanded] = React.useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };
const [age, setAge] = React.useState('');

const handleChange = (event) => {
  setAge(event.target.value);
};

  return (
    <div className=''>
      {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
       sx={{  flexShrink: 0,bgcolor: '#0c0522' , borderColor: 'error.main'}}
      >
        <AccordionSummary
        //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
         
        >
          <Typography sx={{ width: '33%', flexShrink: 0,bgcolor: '#0c0522' , color:'white', borderColor: 'error.main' }}>
            Select Packages
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <button>login</button><button>login</button><button>login</button><button>login</button><button>login</button>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
        //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Users</Typography>
        
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            test
          </Typography>
        </AccordionDetails>
      </Accordion> */}
     {/* import * as React from 'react';


export default function SelectSmall() {
  */}

  {/* return ( */}
    <FormControl sx={{ m: 0, minWidth: 320,bgcolor:'white'  }} size="small">
      <InputLabel id="demo-select-small-label">Select Packages</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Select Packages"
        onChange={handleChange}
      >
       
        <MenuItem value={10}>General</MenuItem>
        <MenuItem value={20}>Exclusive</MenuItem>
        <MenuItem value={30}>Medium</MenuItem>
      </Select>
    </FormControl>
    <FormControl sx={{ m: 0, minWidth: 320,bgcolor:'white'  }} size="small">
      <InputLabel id="demo-select-small-label">Select Packages</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Select Packages"
        onChange={handleChange}
       
      >
       
        <MenuItem value={10}>General</MenuItem>
        <MenuItem value={20}>Exclusive</MenuItem>
        <MenuItem value={30}>Medium</MenuItem>
      </Select>
    </FormControl>
    <FormControl sx={{ m: 0, minWidth: 320,bgcolor:'white'  }} size="small">
      <InputLabel id="demo-select-small-label">Select Packages</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Select Packages"
        onChange={handleChange}
      >
       
        <MenuItem value={10}>General</MenuItem>
        <MenuItem value={20}>Exclusive</MenuItem>
        <MenuItem value={30}>Medium</MenuItem>
      </Select>
    </FormControl>
    <FormControl sx={{ m: 0, minWidth: 320,bgcolor:'white'  }} size="small">
      <InputLabel id="demo-select-small-label">Select Packages</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={age}
        label="Select Packages"
        onChange={handleChange}
      >
       
        <MenuItem value={10}>General</MenuItem>
        <MenuItem value={20}>Exclusive</MenuItem>
        <MenuItem value={30}>Medium</MenuItem>
      </Select>
    </FormControl>
 
    </div>
  );
} 