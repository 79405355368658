import FavorateSearchBanner from './Favorate--banner--search';
import FavorateClubCategory from './Favorate--club--category';
import FavorateHangoutThumbnail from './Favorate--hangout--thumbnail';
import './Favorate--hangout.css';
import FavorateHangoutSponserd from './Favorate--sponserd--banner';
import TableBookingBanner from './TableBooking--banner';




export default function TableBookingViewall()
{
    return(
        <div className='Favorate--Hangout--page'>
            <TableBookingBanner/>
            <div className='Favorate--Hangout--page--1'>
            <FavorateHangoutSponserd/>
            <FavorateClubCategory/>
           <div className='favorate--hangout--search--result'>
             <p>Search Result (500)</p>
           </div>
           <FavorateHangoutThumbnail/>
        
           <FavorateHangoutSponserd/>
            </div>
        </div>
    );
}