import React, { useEffect, useState } from "react";
import "./Carnival.css";
import Group from "./image/Group.png";


import couple from "./image/couple.png";
import open1 from "./image/open1.png";

const Summerypage = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  var url = "https://partywitty.com/master/assets/uploads/";
  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
  };

  const [nextPageData, setNextPageData] = useState(null);
  const [payableamount, setPayableamount] = useState("");
  const [qty, setQty] = useState("");
  const [orderid, setOrderid] = useState("");
  const [guestlist, setGuestlist] = useState([]);
  const [guestlist1, setGuestlist1] = useState("");
  const [couponscode, setCouponscode] = useState("");
  const [discount, setDiscount] = useState("");
  const [mainamount, setMainamount] = useState("");
  const [passname, setPassname] = useState([]);
  const [clubname, setClubname] = useState([]);
  const [add, setAdd] = useState([]);
  // const parsedData = JSON.parse(localStorage.getItem("summerydata"));

  const fetchData = () => {
    try {
      const parsedData = JSON.parse(localStorage.getItem("summerydata"));
      console.log('Summary: ', parsedData);

      setAdd(parsedData[0].clubAddress);
      setClubname(parsedData[0].clubName);
      setNextPageData(parsedData);
      setPayableamount(parsedData[0].payable_amount);
      setPassname(parsedData[0].passDetails);

      var femaleQty = parseInt(parsedData[0].female_qty);
      var maleQty = parseInt(parsedData[0].male_qty);
      var coupleQty = parseInt(parsedData[0].couple_qty);
      setGuestlist(parsedData[0].guest_details[0]);
      setQty(femaleQty + maleQty + coupleQty);

      setCouponscode(parsedData[0].coupon_code);
      setDiscount(parsedData[0].discounted_amount);
      setMainamount(parsedData[0].total_amount);
      setOrderid(parsedData[0].order_id);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  /*
  useEffect(() => {
    fetchData(); // Fetch data when component mounts
  }, []);
  */

  const handleSubmitLeadform = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("order_id", orderid);
    formData.append("payment_gateway", "phonepe");

    const form = e.target;

    fetch(form.action, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log('Payment Response: ', response);
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        const redirectUrl = data && data.redirectUrl; // Replace with the actual property name in your response
        console.log('Payment redirectUrl: ', redirectUrl);
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          // Handle the response data if no redirection URL is found
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const totalAmount = () => {
    if (!nextPageData) {
      return 0;
    }

    const {
      male_amount = 0,
      male_qty = 0,
      female_amount = 0,
      female_qty = 0,
      couple_amount = 0,
      couple_qty = 0,
    } = nextPageData;

    const maleTotal = male_amount * male_qty;
    const femaleTotal = female_amount * female_qty;
    const coupleTotal = couple_amount * couple_qty;
    const otherCharges = 5.9;

    return maleTotal + femaleTotal + coupleTotal + otherCharges;
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts

    if (guestlist && guestlist.gender && guestlist.name) {
      const genders = guestlist.gender.split(",");
      const names = guestlist.name.split(",");

      // Merge gender and name into a new array
      const mergedData = [];
      for (var i = 0; i < names.length; i++) {
        mergedData.push({
          name: names[i],
          gender: genders[i],
        });
      }

      setGuestlist1(mergedData); // Set the merged data in state
    } else {
      console.log("guestlist or its properties are undefined");
    }
  }, [guestlist]);

  return (
    <div className="mt-2">
      <div className="New--year--summury--page">
        <div className="new--year--your--order--1">
          {/**
          <Link to={"/new--year"}>
            <img src={arrow}></img>
          </Link>
           */}
          <p className="New--year--page--design--All--passes1--card--content--p--blue">
            Summary
          </p>
        </div>
        <div className="new--year--your--order--book-a-table--card1">
          <div className="New--year--page--design--All--passes1--card1--1--2-1">
            <div className="New--year--page--design--All--passes1--card--img">
              <img src={url + passname.icon}></img>
              <div className="new--year--page--img--button--dj">
                <div className="dj--nights--img">
                  <img src={Group}></img>
                  <button className="dj--nights--img-btn1">
                    {passname.entertainment}
                  </button>
                </div>
                <div className="dj--nights--img">
                  <button className="dj--nights--img-btn3">Pass</button>
                </div>
              </div>
            </div>

            <div className="New--year--page--design--All--passes1--card--content">
              <div className="New--year--page--design--All--passes1--card--content--1">
                <p className="New--year--page--design--All--passes1--card--content--1--p-1">
                  {passname.name}
                </p>
              </div>
              <div className="new--year--card--content2--1">
                <p className="New--year--page--design--All--passes1--card--content--p--blue">
                  {clubname}
                </p>
                <div className="new--year--card--content2--1">
                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1--2">
                    {passname.start_time} - {passname.end_time}
                  </p>
                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1--2">
                    {add}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="summury--stag--male--1--2--1">
            <div className="summury--stag--male--1-3">
              <div className="summury--stag--male">
                <div className="summury--stag--male--1">
                  <img src={couple}></img>
                  <p>Guest List</p>
                </div>
                <div className="summury--stag--male--1--2">
                  <p>{guestlist1.length}</p>
                  <img src={open1} onClick={toggleContentVisibility}></img>
                </div>
                <div>
                  <p>₹{payableamount}</p>
                </div>
              </div>
              <div>
                {isContentVisible && (
                  <div className="summury--stag--male--1--content p-3">
                    {guestlist1.map((e) => (
                      <div className="new--year--order--cart1 ">
                        <p className="New--year--page--add--more--5 ">
                          {e.name}({e.gender})
                        </p>
                        {/* <p className="New--year--page--add--more--5">₹985</p> */}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="new--year--order--cart1">
            <p className="New--year--page--add--more">Detail</p>
          </div>
          <div className="new--year--page--border--line1-1"></div>
          <div className="new--year--order--cart1">
            <p className="New--year--page--add--more">Sub Total</p>
            <p className="New--year--page--add--more">₹{mainamount - 5.9}</p>
          </div>
          <div className="new--year--order--cart1">
            <p className="New--year--page--add--more">Plat & Other Charges</p>
            <p className="New--year--page--add--more">₹5.90</p>
          </div>
          <div className="new--year--order--cart1">
            <p className="New--year--page--add--more">Grand Total</p>
            <p className="New--year--page--add--more">₹{payableamount}</p>
          </div>

          <div className="new--year--page--border--line1-1"></div>
          <div className="new--year--order--cart1--1">
            <button>{couponscode}</button>
            <p className="New--year--page--add--more--1">₹{discount}</p>
          </div>
          <div className="new--year--page--border--line1-1"></div>
          {/* <Link to={"/new--year--summury"}> */}
          <div className="new--year--your--order--1--2">
            <p className="New--year--page--add--more">Net Pay </p>
            <p className="New--year--page--add--more">₹{payableamount}</p>
          </div>

          <div className="mobile--view--book--a--table--button">
            <button>
              {" "}
              <div className="new--year--order--cart1--5">
                <p className="New--year--page--add--more">
                  {qty} Passes ₹{payableamount}
                </p>
                <form
                  action="https://partywitty.com/master/APIs/Carnival/submitCarnivalPass"
                  method="post"
                  onsubmit="handleSubmitLeadform(event)"
                  className="summery--page--pay--now"
                >
                  <input type="hidden" name="order_id" value={orderid} />
                  <input type="hidden" name="payment_gateway" value="phonepe" />
                  <input
                    className="summery--page--submit"
                    type="submit"
                    name="submit"
                    value="Pay Now"
                  />
                </form>
              </div>
            </button>
          </div>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Summerypage;
