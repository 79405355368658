import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";

import styles from "./club_details.module.css?v=1.1";
import footer_img from "./assets/images/Untitled design8.png";
import { AiOutlineMail } from "react-icons/ai";
import { BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
import Carousel from "react-bootstrap/Carousel";
import { IoIosInformationCircleOutline } from "react-icons/io";
import OfferComp from "../Components/ProductOffer";
import MenuComp from "../Components/Menu_comp";
import VideoCard from '../Components/videoCard'
import ImageViewLigthBox from "../Components/ImageView_LigthBox";
import EventComp from "../Components/EventCard/index";
import ReviewComp from "../Components/ReviewComp/index";
import GalleryCard from "../Components/GalleryCard";
import Pakages from '../Components/Pakages'
import PackageCard from '../Components/PackageCard';

import Booking from "../Components/Table_booking"
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import NearMeIcon from "@mui/icons-material/NearMe";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ClubCard from "../Components/ClubCard";
// import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const style = {
  bookingContainer: {
    display: 'flex', // Default display
    // Add other common styles here
  },
  // Define other styles as needed
};

const responsiveStyles = {
  '@media screen and (max-width: 768px)': {
    '.bookingContainer': {
      display: 'flex',
      flexDirection: 'column'
    },
  },
};

// Merge the styles
const mergedStyles = { ...style.bookingContainer, ...responsiveStyles.bookingContainer };



export default function ClubDetails() {
  // URL
  const url = "https://partywitty.com/master/assets/uploads/";
  // Use State
  const [selectedSubID, setselectedSubID] = useState(0);
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [bookdata, setBookData] = useState([]);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [data, setData] = useState([]);
  const [promotionalBanner, setPromotionalBanner] = useState([]);
  const [OfferDetails, setOfferDetails] = useState();
  const [club1, setClub1] = useState([]);
  const [package1, setPackage1] = useState([]);
  

  const [latitude1, setLatitude1] = useState(null);
  const [longitude1, setLongitude1] = useState(null);
  const [bannerImages, setBannerImages] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [clubId, setClubId] = useState(0);
  const [showResponse, setShowResponse] = useState(false);

  const [ImageView, setImageView] = useState({
    isActive: false,
    imageData: [],
  });

  const { city, area, clubname } = useParams();
  // const clubN = clubname ? clubname.replace(/^club-/, '') : '';

  // console.log("status", status)
  const clubN = clubname;

  const { search } = useLocation();
  // const ENCODED_ID = search.split('?')[1]
  // const DECODED_ID = Base64.decode(ENCODED_ID)


  // subNav Option
  const options = [
    // {
    //   id: 1,
    //   name: "Offer",
    // },
    {
      id: 2,
      name: "menu",
    },
    {
      id: 3,
      name: "Package",
    },
    {
      id: 4,
      name: "events",
    },
    {
      id: 5,
      name: "reviews",
    },
    {
      id: 6,
      name: "gallery",
    },
    {
      id: 7,
      name: "Teaser",
    },
  ];

  const handleItemClick = (id) => {
    setselectedSubID(id);
    // Scroll to the top of the card when an item is clicked
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const [banner, setBanner] = useState([]);
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        // console.log("API Response:", response.data); // Log entire response
        setData(response.data.data)
        setBannerImages(response.data.data.banner);
        
        // setStartTime(response.data.data[0].open_time);
        // setEndTime(response.data.data[0].close_time);
        const clubId = response.data.data.id; // Corrected: response.data.data.id
        setClubId(clubId);
        // console.log("clubId", clubId); // Log clubId after setting it
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // Add setData as a dependency

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/Common/getWebPromotionalBanner")
      .then((response) => setPromotionalBanner(response.data.data))
      .catch((error) => console.log(error));
  }, []);
  const filteredData = promotionalBanner.filter(
    (banner) => banner.page_name === "Home" && banner.position === "footer"
  );

  const generateURL = (landingPage, item) => {
    switch (landingPage) {
      case "":
        return "";
      case "party_packages":
        return "https://www.partywitty.com/party-packages-delhi-ncr-view-all";
      case "table_booking":
        return "https://www.partywitty.com/book-a-table-delhi-ncr-view-all";
      case "club_about":
        return `/${item.city.trim().replace(/ /g, '-').toLowerCase()}/${item.area.trim().replace(/ /g, '-').toLowerCase()}/${item.name.trim().split(/ +/).join('-').replace(/ /g, '-').toLowerCase()}`;
      default:
        return "";
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            const { latitude, longitude } = position.coords;
            setLatitude1(latitude.toString());
            setLongitude1(longitude.toString());
            fetchData1(latitude, longitude);
          } else {
            console.error(
              "Error: Unable to get coordinates from the position object."
            );
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

 

  const currentURL = window.location.href;
  const shareText = "Check out this link: " + currentURL;

  const handleShare = (platform) => {
    let shareURL = "";

    if (platform === "facebook") {
      shareURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}&quote=${shareText}`;
    } else if (platform === "whatsapp") {
      shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareText
      )}%20${currentURL}`;
    } else if (platform === "twitter") {
      shareURL = `https://twitter.com/intent/tweet?url=${currentURL}&text=${encodeURIComponent(
        shareText
      )}`;
    } else if (platform === "email") {
      shareURL = `mailto:?subject=${encodeURIComponent(
        shareText
      )}&body=${encodeURIComponent(`Check it out: ${currentURL}`)}`;
    }

    window.open(shareURL, "_blank");
  };

  const addtocart = async (e) => {
    try {
      if (!userdata) {
        // Handle the case where userdata is null or undefined
        setResponseMessage("User data is missing");
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("club_id", clubId);
      formDataToSend.append("user_id", userdata.id);

      const response = await fetch(
        "https://partywitty.com/master/Api/sendData/addClubFavourite",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const responseData = await response.json();

      if (responseData.status === true) {
        setResponseMessage(responseData.msg);
        setBookData(responseData.data);

        if (responseData.data) {
          // Handle success case
        } else {
          setShowResponse(true);
        }
      } else {
        setResponseMessage("Error in processing the request");
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData1 = (latitude1, longitude1) => {
    const formData1 = new FormData();
    formData1.append("latitude", latitude1);
    formData1.append("longitude", longitude1);
    axios
      .post(
        "https://partywitty.com/master/APIs/Common/recommendedClub",
        formData1
      )
      .then((response) => {
        if (response.data.status === true) {
          setClub1(response.data.data);
        } else {
          setClub1([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Destructure of Data
  const {
    address,
    about,
    club_menu,
    name,
    banner,
    review,
    teasers,
    recommended_club,
    id,
    logo,
    gallery,
    party_packages,
    totalAverageRating,
  } = data;
  // console.log(data, "dd");


  return (
    <div className={`${styles.Big_wrapper}`}>
      <div className={`${styles.banner_img}`}>
        <Carousel>
          {bannerImages.map((banner, index) => (
            <Carousel.Item key={index}>
              <div
                style={{ position: "relative", width: "100%", height: "630px" }}
              >
                <img
                  src={`https://partywitty.com/master/assets/uploads/${banner.img}`}
                  alt={banner.title}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }} // Updated styling
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "300px", // Adjut the height of the gradient overlay
                    backgroundImage:
                      "linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))",
                  }}
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <div className={styles.buttons}>
          <Stack spacing={2} direction="row">
            <a
              href={`https://www.google.com/maps/dir/?api=1&origin=${data.latitude},${data.longitude}&destination=${data.latitude},${data.longitude}&travelmode=driving`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }} // Assuming you want to remove underline
            >
              <Button
                variant="outlined"
                startIcon={
                  <NearMeIcon
                    style={{
                      fontSize: 30,
                      marginLeft: "8px",
                      color: "#0099ff",
                    }}
                  />
                }
                style={{
                  borderColor: "#fff",
                  color: "#fff",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                }}
              ></Button>
            </a>
            <Button
              onClick={addtocart}
              variant="outlined"
              startIcon={
                <FavoriteIcon
                  style={{ fontSize: 30, marginLeft: "8px", color: "#FE262F" }}
                />
              }
              style={{
                borderColor: "#fff",
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
            />
            <Button
              onClick={handleOpen2}
              variant="outlined"
              startIcon={
                <ShareIcon
                  style={{ fontSize: 30, marginLeft: "8px", color: "#fff" }}
                />
              }
              style={{
                borderColor: "#fff",
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
            />
          </Stack>
        </div>


        <div className={styles.ClubInfo}>
          <h2>
            <b>{name || "name"}</b>
          </h2>
          <h3>{address || "add"}</h3>
          <h3>
            Timings: 11am - 11:50pm (Today){" "}
            {/* <span className={styles.infoSign}>
              <IoIosInformationCircleOutline />{" "}
            </span> */}
          </h3>
        </div>
      </div>

      {/* third div */}

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="share-menu"
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <p
              className="facebook"
              onClick={() => handleShare("facebook")}
              style={{
                backgroundColor: "#1877F2",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
              }}
            >
              <BsFacebook />
            </p>
            <p
              className="whatsapp"
              onClick={() => handleShare("whatsapp")}
              style={{
                backgroundColor: "#25D366",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
              }}
            >
              <BsWhatsapp />
            </p>
            <p
              className="twitter"
              onClick={() => handleShare("twitter")}
              style={{
                backgroundColor: "#1DA1F2",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
              }}
            >
              <BsTwitter />
            </p>
            <p
              className="gmail"
              onClick={() => handleShare("email")}
              style={{
                backgroundColor: "#F7931A",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
              }}
            >
              <AiOutlineMail />
            </p>
          </div>
        </Box>
      </Modal>

       {/* Video card TEASERS */}


      {/* menu div */}
      <section >
        <div className={`${styles.ContentContainer} `} >
        <div className={styles.contentWrapper}>
          <div className={styles.menu_div}>
          {options.map((item) => {
  return (
    <div
      className={`${styles.menu_nav} ${selectedSubID === item.id ? styles.active : ''}`}
      onClick={() => setselectedSubID(item.id)}
    >
      <a href={`#${item.name}`}>{item?.name}</a>
    </div>
    
  );
})}
          </div>
        </div>
        <div className="booking-container">
      <div class="d-none d-md-block">
      <Booking/>
      </div>
      </div>
        
        </div>
        

        {/* <div style={{display: 'flex',}}> */}
        {/* <div className="bookingContainer" style={mergedStyles}> */}
        <div className={`${styles.menucontianer} `}>
        <div className="d-flex flex-container flex-md-row flex-wrap-container" >
      <div style={{width: 'auto', overflowY: 'scroll',scrollbarWidth: "thin",
          scrollbarColor: "#141420 #141420"  }}>
        
      {/* <div className={style.AllCard}> */}
   
      {/* <OfferComp id="offer" OfferDetails={setOfferDetails} heading="Products" /> */}
      <MenuComp id="menu"
        MenuDetails={data?.club_menu}
        headLine={"Menu"}
        url={url}
        setImageView={setImageView}
      />
      {ImageView.isActive && (
        <ImageViewLigthBox
          Data={ImageView.imageData}
          setImageView={setImageView}
        />
      )}

<div
        style={{
          padding: "0.5rem 0 0 1rem",
          color: "#fff",
          marginLeft: "20px",
          marginTop: "40px",
        }}
      >
        <h2
          style={{
            fontSize: "22px",
            fontFamily: "Helvetica",
            fontWeight: "600",
            
          }}
        >
          Package
        </h2>
      </div>


<div 
        style={{
          position: "relative",
          overflow: "hidden",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          overflowX: "scroll",
          columnGap: "25px",
          width: "68%",
          padding: '0.5rem 0 0 0rem',
          marginLeft: "20px",
          // overflowY: 'scroll', // Add a comma after marginLeft
          scrollbarWidth: "thin",
          scrollbarColor: "#141420 #141420" /* For Firefox */,
          // WebkitOverflowScrolling: 'touch', /* For iOS momentum scrolling */
        }}
      >
      <Pakages id="Package" />
      </div>

                
        
      <EventComp id="events" headLine={"Events"} />
      <ReviewComp id="reviews"
        ReviewData={data.review}
        totalAverageRating={totalAverageRating}
      />

<div
        style={{
          position: "relative",
          overflow: "hidden",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          overflowX: "scroll",
          overflowY: 'hidden',
          columnGap: "25px",
          // width: "60%",
          // padding: '0.5rem 0 0 3rem',
          marginLeft: "40px",
          // overflowY: 'scroll', // Add a comma after marginLeft
          scrollbarWidth: "thin",
          scrollbarColor: "#141420 #141420" /* For Firefox */,
          // WebkitOverflowScrolling: 'touch', /* For iOS momentum scrolling */
        }}
      >
<GalleryCard id="gallery"
        GalleryData={data?.gallery}
        url={url}
        setImageView={setImageView}
      />
      {ImageView.isActive && (
        <ImageViewLigthBox
          Data={ImageView.imageData}
          setImageView={setImageView}
        />
      )}      </div>

      

<div
        style={{
          position: "relative",
          overflow: "hidden",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          overflowX: "scroll",
          columnGap: "25px",
          width: "70%",
          // padding: '0.5rem 0 0 3rem',
          marginLeft: "40px",
          // overflowY: 'scroll', // Add a comma after marginLeft
          scrollbarWidth: "thin",
          scrollbarColor: "#141420 #141420" /* For Firefox */,
          // WebkitOverflowScrolling: 'touch', /* For iOS momentum scrolling */
        }}
      >
<VideoCard id="Teaser" videoInfo={teasers?.slice(0, 4)} />
      </div>



      <div
        style={{
          padding: "0.5rem 0 0 1rem",
          color: "#fff",
          marginLeft: "40px",
          marginTop: "40px",
          marginBottom: "10px",
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            fontFamily: "Helvetica",
            fontWeight: "400",
            
          }}
        >
          EXPLORE OTHER PLACES AROUND
        </h2>
      </div>
        

      <div
        style={{
          position: "relative",
          overflow: "hidden",
          display: "flex",
          height: "auto",
          flexDirection: "row",
          overflowX: "scroll",
          columnGap: "25px",
          width: "68%",
          // padding: '0.5rem 0 0 3rem',
          marginLeft: "40px",
          // overflowY: 'scroll', // Add a comma after marginLeft
          scrollbarWidth: "thin",
          scrollbarColor: "#141420 #141420" /* For Firefox */,
          // WebkitOverflowScrolling: 'touch', /* For iOS momentum scrolling */
        }}
      >
        {/* {club1.slice(0,10).map((item ,i) => { return <ClubCard ClubData={item} /> })}  */}
        {club1.slice(0, 10).map((item, i) => {
          return <ClubCard ClubData={item} isFulldetails />;
        })}
      </div>

      {/* <div
        style={{
          padding: "0.5rem 0 0 3rem",
          color: "#fff",
          marginLeft: "20px",
          marginTop: "40px",
          marginBottom: "10px",
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            fontFamily: "Helvetica",
            fontWeight: "400",
          }}
        >
          SIMILAR CLUB
        </h2>
      </div> */}

      <div
        // style={{
        //   position: "relative",
        //   overflow: "hidden",
        //   display: "flex",
        //   height: "auto",
        //   flexDirection: "row",
        //   overflowX: "scroll",
        //   columnGap: "25px",
        //   // width: "60%",
        //   // padding: '0.5rem 0 0 3rem',
        //   marginLeft: "50px",
        //   // overflowY: 'scroll', // Add a comma after marginLeft
        //   scrollbarWidth: "thin",
        //   scrollbarColor: "#141420 #141420" /* For Firefox */,
        //   // WebkitOverflowScrolling: 'touch', /* For iOS momentum scrolling */
        // }}
      >
        {/* {club1.slice(0,10).map((item ,i) => { return <ClubCard ClubData={item} /> })}  */}
        {/* {club1.slice(0, 10).map((item, i) => {
          return <ClubCard ClubData={item} isFulldetails />;
        })} */}
      </div>
      </div>

       
      </div>

      <div className="d-md-none">
    <Booking />
  </div>
      </div>
      </section>




     
      
      

      {/* <div className="foot_img">
        <img className="foot_img_inner" src={footer_img} alt="#" />
      </div> */}
      <div className="foot_img">
      <div className="banner_slider_update--footer">
          <Carousel interval={6000} indicators={false}>
      {filteredData.map((e) => (
        <Carousel.Item key={e.id} className="slider_img">
          {generateURL(e.landing_page, e) ? (
            <Link to={generateURL(e.landing_page, e)}>
              <img src={url + e.default_img} alt="img" />
            </Link>
          ) : (
            <img src={url + e.default_img} alt="img" />
          )}
        </Carousel.Item>
      ))}
    </Carousel>
    </div>
    </div>
    </div>
  );
}
