import { Rating } from 'react-simple-star-rating';
import style from './Review.module.css';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function Index() {
  const { city, area, clubname } = useParams();
  const clubN = clubname;
  const url = "https://partywitty.com/master/assets/uploads/";
  const [reviews, setReviews] = useState([]);
  const [totalAverageRating, setTotalAverageRating] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("city", city);
        formData.append("area", area);
        formData.append("club", clubN);

        const response = await axios.post("https://partywitty.com/master/APIs/Web/getClubDetails", formData);
        console.log("API Response:", response.data);

        setReviews(response.data.data.review);
        setTotalAverageRating(parseFloat(response.data.data.totalAverageRating));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [city, area, clubN]);

  return (
    <div className={style.review_section} id="reviews">
      <h2>REVIEWS</h2>
      <div className={style.review_inner_div}>
        <div className={style.div_1}>
          <div className={style.box}>
            <div className={style.rating}>
              <div className={style.rating_no}>
                <h3>{totalAverageRating}</h3>
              </div>
              <h4>{reviews.length} reviews</h4>
              <div className={style.starts}>
                <Rating initialValue={totalAverageRating} allowFraction allowHover={false} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="div_2">
                
<div class={style.progress_section} data-aos="fade-left" data-aos-once="true">
  <div class={style.task_progress}>
    <p>Food</p>
      <progress class={style.progress } className={style.progress1} max="100" value="75"></progress>
    <span>100</span>
  </div>
</div>
<div class={style.progress_section} data-aos="fade-left" data-aos-once="true">
  <div class={style.task_progress}>
    <p>Ambience</p>
      <progress class={style.progress } className={style.progress1} max="100" value="75"></progress>
    <span>11</span>
  </div>
</div>
<div class={style.progress_section} data-aos="fade-left" data-aos-once="true">
  <div class={style.task_progress}>
    <p>Hygiene</p>
      <progress class={style.progress } className={style.progress1} max="100" value="75"></progress>
    <span>3</span>
  </div>
</div>
<div class={style.progress_section} data-aos="fade-left" data-aos-once="true">
  <div class={style.task_progress}>
    <p>Pricing</p>
      <progress class={style.progress } className={style.progress1} max="100" value="75"></progress>
    <span>8</span>
  </div>
</div>
<div class={style.progress_section} data-aos="fade-left" data-aos-once="true">
  <div class={style.task_progress}>
    <p>Service</p>
      <progress class={style.progress } className={style.progress1} max="100" value="75"></progress>
    <span>1</span>
  </div>
</div>
                   
                </div> */}
      </div>
      {reviews.map((review, index) => (
        <div key={index} className={style.testimonial}>
          <div className={style.testimonial_Box}>
            <div className={style.image_section}>
              <img src={url + review.user_profile} alt="User" className='image' />
            </div>
            <div className={style.review_section2}>
              <div className={style.review_name}>
                <h3>{review.username}</h3>
                <p>24 January 2023</p>
              </div>
              <div className={style.starts2}>
                <Rating initialValue={review.rating} allowFraction allowHover={false} />
              </div>
            </div>
          </div>
          <div className={style.testimonial_para}>
            <p>{review.msg}</p>
            {review.images && review.images.length > 0 && (
              <img src={review.images[0]} alt="Review Image" />
            )}
          </div>
        </div>
      ))}
      {reviews.length === 0 && (
        <p>No reviews available.</p>
      )}
      <div className={style.seemore}>
        {/* <p>View More</p> */}
      </div>
    </div>
  );
}

export default Index;
