import React from "react";
import '../App.css'
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import './OurArtist.css'

import ControlledAccordions from "./Artist";


function OurArtist() {
    return (
        <div className='home-page-design pt-5'>
           
            <div className='home-page-design1'>
                <div className="artist-page">
                    <div className="artist-sidebar">
                    <ControlledAccordions /> 
                    </div>
                    <div className="artist">
                       
                    </div>
                    {/* <Artist /> */}
                </div>
            </div>
            <div className='footer-section'>
              
            </div>
        </div>
    );
}

export default OurArtist;
