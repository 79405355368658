import './WebApp.css';
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Googleplay from './googleStore1.png';
import Applestore from './appstore.png';
import { Link } from "react-router-dom";
import webtoapp from './appweb.png';
import { RxCross2 } from "react-icons/rx";
import appmobile from './appmobile.png';



export default function WebApp() {
    const [openPlanEvent, setOpenPlanEvent] = useState(false);
    useEffect(() => {
        const hasShownPopUp = localStorage.getItem('hasShownPopUp');
        if (!hasShownPopUp) {
            setOpenPlanEvent(true);
            localStorage.setItem('hasShownPopUp', 'true');
        }
    }, []);
    const handleClosePlanEvent = () => {
        setOpenPlanEvent(false);
    };
    return (
        <div className='Web--app--popup'>
            <div>
                {openPlanEvent && (
                    <div className="blur-background">
                        <Modal
                            open={openPlanEvent}
                            onClose={handleClosePlanEvent}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box className="responsive-modal-1--popup--webApp">
                                <div className="Plan--your--event--popup--webapp">
                                <button
                    className="modal-button_plan"
                    onClick={() => setOpenPlanEvent(false)}
                  >
                 <RxCross2 />
                  </button>
                                    <div className="Plan--your--event--popup--webapp--img">
                                        <img src={webtoapp}></img>
                                    </div>
                                    <div className="Plan--your--event--popup--webapp--img--6">
                                        <img src={appmobile}></img>
                                    </div>
                                    <div className='plan--yor--event--mobile--view'>
                                        <div className='app-popup-row'>
                                        <p className='Plan--your--event--popup--webapp--p1'>Download App & Get Carrot Points worth <b>₹500</b> 
                                        </p>
                                        <p className='Plan--your--event--popup--webapp--p2'>Party Witty app is now available on Google Play Store & App Store. Get instant 500 carrot points in your account worth ₹500 on download. Avail them on your next table booking!
                                        </p>
                                        </div>
                                        <div className='app-popup-row'>
                                        <p className='app-popup-row-p'>Hurry Up! Download Party Witty App Now</p>
                                        <div className='app-popup-row1'>
                                        <p className='app-popup-row-p1'>Hurry Up!</p>
                                        <p className='app-popup-row-p1'>Download Party Witty App Now</p></div>
                                        <div className='Plan--your--event--popup--webapp--download--img'>
                                            <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                                                <img src={Googleplay} />
                                            </Link>
                                            <Link to="https://apps.apple.com/in/app/partywitty/id6478442502 ">
                  <img src={Applestore} alt="appstore"/>
                </Link></div>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );
}