import styles from './Event.module.css';
import EventCard from './EventCard';

function index({ MenuDetails, headLine, url ,setImageView}) {

    return (
        <div className={styles.CardContainer} id="events">
            <h2>{headLine}</h2>
            <div className={styles.Menucontent}>
                {EventData?.map((item) => {
                    return (
                        <>
                        <EventCard MenuDetails={item} url={url} setImageView={setImageView}/>
                        <h2 >No Data Found...</h2>
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default index


const EventData = [
    {
        id:0,
        name:'event Name1',
        club_name:'clubName',
        address:'Sector 63 Noida',
        price:200,
        seat_left:10,
        url:"/images/event1.svg",
        date:"today"
    },
    // {
    //     id:1,
    //     name:'event Name2',
    //     club_name:'clubName',
    //     address:'Sector 60 Noida',
    //     price:250,
    //     seat_left:15,
    //     url:"/images/event2.svg",
    //     date:"tomorrow"
    // }
]