import React, { useEffect, useState, } from "react";
import { useParams } from "react-router-dom";

import "./Carnival.css";

const CarnivalPassPayment = () => {
  const { order_id } = useParams();
  const [orderid, setOrderid] = useState(order_id);

  const handleSubmitLeadform = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("order_id", orderid);
    formData.append("payment_gateway", "phonepe");

    const form = e.target;

    fetch(form.action, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log('Payment Response: ', response);
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        const redirectUrl = data && data.redirectUrl; // Replace with the actual property name in your response
        console.log('Payment redirectUrl: ', redirectUrl);
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          // Handle the response data if no redirection URL is found
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const form = document.getElementById(order_id);
    if (form && form.submit) {
      console.log('Submit Form: ', form, form.submit);
      form.submit();
    }
  }, []);


  return (
    <div style={{ height: "100px", marginTop: "200px" }}>
      Submit Payment
      <form
        id={order_id}
        action="https://partywitty.com/master/APIs/Carnival/submitCarnivalPass"
        method="post"
        onsubmit="handleSubmitLeadform(event)"
        className="summery--page--pay--now"
      >
        <input type="hidden" name="order_id" value={orderid} />
        <input type="hidden" name="payment_gateway" value="phonepe" />
        <input
          className="summery--page--submit"
          type="submit"
          value="Placing Order..."
        />
      </form>
    </div>
  );
};

export default CarnivalPassPayment;
