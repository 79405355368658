import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Style.css';
import img1 from './image/partyimage1.jpg';
import WebApp1 from "../../Web--to--app/WebApp1";

const categoryStyles = {
    Vocalist: {
        background: 'linear-gradient(to bottom, rgba(215, 19, 98, 0.5), #d71362)'
    },
    DJ: {
        background: 'linear-gradient(to bottom, rgba(19, 98, 215, 0.5), blue)'
    },
    Musicians: {
        background: 'linear-gradient(to bottom, rgba(98, 215, 19, 0.5), #62d713)'
    },
    Band: {
        background: 'linear-gradient(to bottom, rgba(66, 119, 255, 0.5), #4277ff)'
    },
    default: {
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), #000)'
    }
};

export default function DJNight() {
    const [activePopup, setActivePopup] = useState({ categoryIndex: null, artistIndex: null });
    const [categories, setCategories] = useState([]);
    const [popupKey, setPopupKey] = useState(0); // Key to force re-render
    const URL = "https://partywitty.com/master/assets/uploads/";

    const fetchURL = async () => {
        try {
            const response = await axios.get(
                "https://partywitty.com/master/APIs/Artist/categoryWiseArtist"
            );
            setCategories(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchURL();
    }, []);

    const handleClickButton = (categoryIndex, artistIndex) => {
        setActivePopup({ categoryIndex, artistIndex });
        setPopupKey(prevKey => prevKey + 1); // Increment key to force re-render
    };

    const handleClosePopUp = () => {
        setActivePopup({ categoryIndex: null, artistIndex: null });
    };

    return (
        <div className='Artist-card-font'>
            {categories.map((category, categoryIndex) => (
                category.artist_list.length > 0 && (
                    <div key={category.id}>
                        <div className='Artist-geners-card'>
                            <img src={img1} alt='img' />
                            <div 
                                className='Artist-geners-card-overlay' 
                                style={categoryStyles[category.name] || categoryStyles.default}
                            >
                                <h4>{category.name} Genre</h4>
                            </div>
                        </div>
                        <div className='Artist-gener-card1'>
                            <div className='live-music-all-card'>
                                {category.artist_list.map((artist, artistIndex) => (
                                    <div 
                                        className={`Percussion-card-content2 ${activePopup.categoryIndex === categoryIndex && activePopup.artistIndex === artistIndex ? 'active' : ''}`} 
                                        key={artist.id}
                                    >
                                        <img src={URL + artist.profile_pic} alt='img' />
                                        <div className='live-music-card-content-1'>
                                            <p>{artist.name.substring(0, 15)}</p>
                                            <div>
                                                <button onClick={() => handleClickButton(categoryIndex, artistIndex)}>View Profile</button>
                                                {activePopup.categoryIndex === categoryIndex && activePopup.artistIndex === artistIndex && (
                                                    <WebApp1 key={popupKey} onClose={handleClosePopUp} />
                                                )}
                                            </div>
                                        </div>
                                        <div className='live-music-geners'>
                                            {artist.genre.split(",").map((genre, genreIndex) => (
                                                <button key={genreIndex}>{genre.trim()}</button>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
            ))}
        </div>
    );
}
