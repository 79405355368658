import "./Bookatable.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { BsFillStarFill } from "react-icons/bs";
import premium from "./image/premium.png";
import favlist from "../Assets/Fill Fovarates Icon.png";

const Favclub = () => {
  const [favList, setFavList] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
          fetchEvents(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

    const fetchEvents = async (latitude, longitude) => {

    const formData = new FormData();
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("user_id", userdata ? userdata.id : null);

    try {
      const response = await axios.post(
        "https://partywitty.com/master/APIs/Common/favList",
        formData
      );
      console.log("API Response:", response.data); // Log the entire response
      if (response.data && response.data.data) {
        setFavList(response.data.data);
      } else {
        console.error("Invalid response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };

  return (
    <div className="book--a--table mt-5">
      <p>Favorate List</p>
      <div className="book--a--table--right--content">
        <div className="bookatable-right">
          {favList.length > 0 ? (
            favList.map((clubitem) => (
              <LazyLoad height={200} offset={10} key={clubitem.id}>
                <Link
                  to={`/${clubitem.cityName.replace(
                    / /g,
                    "-"
                  )}/${clubitem.areaName.replace(/ /g, "-")}/${
                    clubitem.clubName
                      ? clubitem.clubName
                          .trim()
                          .split(/ +/)
                          .join("-")
                          .replace(/ /g, "-")
                      : "default-name"
                  }`}
                  key={clubitem.id}
                  className="bookatable-routes"
                >
                  <div className="bookatable-card">
                    <div className="bookatable-img">
                      <img
                        className="bookatable-lawn-img"
                        src={url + clubitem.clubLogo}
                        alt="img"
                      />
                      <div className="premiumtag">
                        <img src={premium} alt="Premium tag" />
                        <a>
                          <img src={favlist} alt="heart" />
                        </a>
                      </div>
                    </div>
                    <Link to="/" className="bookatable-routes">
                      <div className="bookatable-content--1">
                        <div className="bookatable-content">
                          <div>
                            <p className="bookatable-bold">
                              {clubitem.clubName
                                ? clubitem.clubName.substring(0, 16)
                                : "N/A"}
                            </p>
                            <p className="bookatable-white">
                              <HiOutlineLocationMarker />{" "}
                              {clubitem.address
                                ? clubitem.address.substring(0, 20)
                                : "N/A"}
                              ...
                            </p>
                          </div>
                          <div className="thumbnail--rating">
                            <div>
                              <div className="thumbnail--rating-1">
                                <div className="thumbnail--rating-1-1">
                                  <BsFillStarFill />
                                  <p>4.8</p>
                                </div>
                              </div>
                              <div className="thumbnail--rating-2">
                                <p>
                                  {clubitem.totalRating
                                    ? clubitem.totalRating
                                    : 0}{" "}
                                  reviews
                                </p>
                              </div>
                            </div>
                            <div className="thumbnail--rating-3">
                              <p className="offer"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Link>
              </LazyLoad>
            ))
          ) : (
            <p>No favorite clubs found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Favclub;
