import "./Favorate--hangout.css";
import banner from "./image/banner.png";
import { LuSearch } from "react-icons/lu";
import logo from "../Assets/logo.png";

export default function FavorateSearchBanner() {
  return (
    <div className="Favorate--Hangout--page">
      <div className="Favorate--Hangout--page--card">
        <div className="Favorate--Hangout--page--card-banner-img">
          <img src={banner}></img>
        </div>
        <div className="Favorate--Hangout--page--card-banner-Search8">
          <img src={logo}></img>
           <p className="Favorate--Hangout--page--p1">Explore top party packages, table bookings, and events in Delhi NCR</p>
           <div className="Favorate--Hangout--page--card-banner-Search--1">
          <input type="text" placeholder="Search Packages in Gurugram"></input>
          <span>  <LuSearch /></span>
          </div>
        </div>
      </div>
    </div>
  );
}
