import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Clubpage.css";
import "./Rating.css";
import ReactStars from "react-stars";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Loginpopup from "../Navbar/Loginmodal";

function Rating() {
  const { city, area, clubname } = useParams();
  const [show, setShow] = useState(false);
  const [bookdata, setBookData] = useState([]);
  var userdata = JSON.parse(localStorage.getItem("userData"));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [stars, setStars] = useState(0);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [data, setData] = useState([]);
  const [ratingdata, setRatingData] = useState([]);
  const [id, setId] = useState(null); 
  const toggleReviewForm = () => {
    setShowReviewForm(!showReviewForm);
  };
  
  var url = "https://partywitty.com/master/assets/uploads/";
  const [showResponse, setShowResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [parameter, setParameter] = useState([]);
  const [ambienceRating, setAmbienceRating] = useState(0);
  const [foodRating, setFoodRating] = useState(0);
  const [hygieneRating, setHygineneRating] = useState(0);
  const [musicRating, setMusicRating] = useState(0);
  const [pricingRating, setPricingRating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [formData, setFormData] = useState({
  

    msg: "",
    image: null,
  });
  

   
   const clubN = clubname;
 
   const newApi = new FormData();
   newApi.append("city", city);
   newApi.append("area", area);
   newApi.append("club", clubN);
 
   useEffect(() => {
     axios
       .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
       .then((response) => {
         setData(response.data.data);
         const id = response.data.data.id;
      
         setId(id); 
       })
       .catch((error) => {
         console.error(error);
       });
   }, []);

   useEffect(() => {
      const clubid = new FormData();
      clubid.append("club_id", id);
  
      axios
        .post("https://partywitty.com/master/Api/getDataPost/clubRatings", clubid)
        .then((response) => {
        
          if (response.data.msg === true) {
            setData([]);
            setRatingData({});
          } else {
            setData(response.data.data);
            setRatingData(response.data.ratingsDetails);
          }
        })
        .catch((error) => {
          console.error("Error fetching club ratings:", error);
        });
    
  }, [id]);
  

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/Parameter")
      .then((response) => {
        if (response.data.msg === true) {
         
          setParameter([]);
        } else {
         
          setParameter(response.data.data);
        }
      });
  }, []);

  const [ratings, setRatings] = useState([]);

  const handleRatingChange = (category, newRating) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: newRating,
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (userdata == null) {
      
        setShowLoginPopup(true);
       
      } else {
       
        const formDataToSend = new FormData();
        formDataToSend.append("club_id", id);
        formDataToSend.append("user_id", userdata.id);
        formDataToSend.append("parameter_id[]", 1);
        formDataToSend.append("parameter_id[]", 2);
        formDataToSend.append("parameter_id[]", 3);
        formDataToSend.append("parameter_id[]", 4);
        formDataToSend.append("parameter_id[]", 5);
        formDataToSend.append("parameter_id[]", 6);
        formDataToSend.append("stars[]", ambienceRating);
        formDataToSend.append("stars[]", foodRating);
        formDataToSend.append("stars[]", serviceRating);
        formDataToSend.append("stars[]", pricingRating);
        formDataToSend.append("stars[]", hygieneRating);
        formDataToSend.append("stars[]", musicRating);
       

        for (const field in formData) {
          if (field === "image") {
            
            formDataToSend.append(field, formData[field]);
          } else {
            formDataToSend.append(field, formData[field]);
          }
        }
        const response = await fetch(
          "https://partywitty.com/master/Api/sendData/submitReview",
          {
            method: "POST",
            body: formDataToSend,
          }
        );

        const responseData = await response.json();

        if (responseData.status === true) {
          setResponseMessage(responseData.msg);
          setBookData(responseData.data);
          window.location.reload();
          if (responseData.data) {
            localStorage.setItem("review", JSON.stringify(responseData.data));
         
          } else {
            setShowResponse(true);
          }
        } else {
          setResponseMessage("Error in processing the request");
        }
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "parameter_id") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleAmbienceRatingChange = (newRating) => {
    setAmbienceRating(newRating);
  };
  const handleFoodRatingChange = (newRating) => {
    setFoodRating(newRating);
  };
  const handleHygieneRatingChange = (newRating) => {
    setHygineneRating(newRating);
  };
  const handleMusicRatingChange = (newRating) => {
    setMusicRating(newRating);
  };
  const handlePriceRatingChange = (newRating) => {
    setPricingRating(newRating);
  };
  const handleServiceRatingChange = (newRating) => {
    setServiceRating(newRating);
  };
  if (!ratingdata) {
    return (
      <div className="club--rating--review--page">
        {showLoginPopup && <Loginpopup />}
        <div className="club--review--avg">
          <h5 className="m-0 p-0">Be the first one to Review</h5>
          <>
            <ReactStars
              count={5}
              name="rating"
              size={24}
              position="center"
              activeColor="#ffd700"
              value={0}
              isHalf={true}
              edit={false}
              style={{
                display: "inline-block", 
                fontSize: "30px", 
                color: "#ffd700", 
              }}
              starSpacing="15px"
            />
          </>
          <p>
            “I had my son’s 1st birthday celebration just this past weekend.
            Everyone was very friendly and helpful...”
          </p>

          <button onClick={handleShow}>Write a Review</button>
        </div>
        <div className="club--review--namewise--avg">
          <p>86 Rank amongst club in Delhi</p>
          <div className="club--review--names">
            <p className="m-0">Ambience</p>
            <>
              <ReactStars
                count={5}
                name="rating"
                size={30}
                position="center"
                activeColor="#ffd700"
                value={0}
                isHalf={true}
                edit={false}
                style={{
                  display: "inline-block", 
                  fontSize: "30px", 
                  color: "#ffd700", 
                }}
              />
            </>
            <p className="m-0">0</p>
          </div>
          <div className="club--review--names">
            <p className="m-0">Food</p>
            <>
              <ReactStars
                count={5}
                name="rating"
                size={30}
                position="center"
                activeColor="#ffd700"
                value={0}
                isHalf={true}
                edit={false}
                style={{
                  display: "inline-block", 
                  fontSize: "30px", 
                  color: "#ffd700", 
                }}
              />
            </>
            <p className="m-0">{0}</p>
          </div>
          <div className="club--review--names">
            <p className="m-0">Hygiene</p>
            <>
              <ReactStars
                count={5}
                name="rating"
                size={30}
                position="center"
                activeColor="#ffd700"
                value={0}
                isHalf={true}
                edit={false}
                style={{
                  display: "inline-block", 
                  fontSize: "30px", 
                  color: "#ffd700", 
                }}
              />
            </>
            <p className="m-0">{0}</p>
          </div>
          <div className="club--review--names">
            <p className="m-0">Music</p>
            <>
              <ReactStars
                count={5}
                name="rating"
                size={30}
                position="center"
                activeColor="#ffd700"
                value={0}
                isHalf={true}
                edit={false}
                style={{
                  display: "inline-block", 
                  fontSize: "30px", 
                  color: "#ffd700", 
                }}
              />
            </>

            <p className="m-0">{0}</p>
          </div>
          <div className="club--review--names">
            <p className="m-0">Pricing</p>
            <>
              <ReactStars
                count={5}
                name="rating"
                size={30}
                position="center"
                activeColor="#ffd700"
                value={0}
                isHalf={true}
                edit={false}
                style={{
                  display: "inline-block", 
                  fontSize: "30px", 
                  color: "#ffd700", 
                }}
              />
            </>
            <p className="m-0">{0}</p>
          </div>
          <div className="club--review--names">
            <p className="m-0">Service</p>
            <>
              <ReactStars
                count={5}
                name="rating"
                size={30}
                position="center"
                activeColor="#ffd700"
                value={0}
                isHalf={true}
                edit={false}
                style={{
                  display: "inline-block", 
                  fontSize: "30px", 
                  color: "#ffd700", 
                }}
              />
            </>
            <p className="m-0">{0}</p>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="custom-modal">
            <Modal.Title>Write a Review</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modal">
            <div className="club--review--namewise--avg1">
              
              <div className="club--review--names">
                <p className="m-0">Ambience</p>
                <ReactStars
                  count={5}
                  name="ambience-rating"
                  size={30}
                  activeColor="#ffd700"
                  value={ambienceRating}
                  isHalf={true}
                  edit={true}
                  onChange={handleAmbienceRatingChange}
                />
                <p className="m-0">{ambienceRating}</p>
              </div>
              <div className="club--review--names">
                <p className="m-0">Food</p>
                <ReactStars
                  count={5}
                  name="food-rating"
                  size={30}
                  activeColor="#ffd700"
                  value={foodRating}
                  isHalf={true}
                  edit={true}
                  onChange={handleFoodRatingChange}
                />
                <p className="m-0">{foodRating}</p>
              </div>
              <div className="club--review--names">
                <p className="m-0">Hygiene</p>
                <ReactStars
                  count={5}
                  name="hygiene-rating"
                  size={30}
                  activeColor="#ffd700"
                  value={hygieneRating}
                  isHalf={true}
                  edit={true}
                  onChange={handleHygieneRatingChange}
                />
                <p className="m-0">{hygieneRating}</p>
              </div>
              <div className="club--review--names">
                <p className="m-0">Music</p>
                <ReactStars
                  count={5}
                  name="music-rating"
                  size={30}
                  activeColor="#ffd700"
                  value={musicRating}
                  isHalf={true}
                  edit={true}
                  onChange={handleMusicRatingChange}
                />
                <p className="m-0">{musicRating}</p>
              </div>
              <div className="club--review--names">
                <p className="m-0">Pricing</p>
                <ReactStars
                  count={5}
                  name="pricing-rating"
                  size={30}
                  activeColor="#ffd700"
                  value={pricingRating}
                  isHalf={true}
                  edit={true}
                  onChange={handlePriceRatingChange}
                />
                <p className="m-0">{pricingRating}</p>
              </div>
              <div className="club--review--names">
                <p className="m-0">Service</p>
                <ReactStars
                  count={5}
                  name="service-rating"
                  size={30}
                  activeColor="#ffd700"
                  value={serviceRating}
                  isHalf={true}
                  edit={true}
                  onChange={handleServiceRatingChange}
                />
                <p className="m-0">{serviceRating}</p>
              </div>
              <p className="mt-3">Add A Headline</p>
              <input
                type="text"
                name="msg"
                placeholder="What's most importent to know"
                value={formData.msg}
                onChange={handleInputChange}
              />
              <p className="mt-3">What did you Like or Dislike</p>
              <input
                type="file" 
                name="image"
          
                onChange={handleImageChange}
              />
              <p className="mt-3">Add A Written Review</p>

              <div class="input-group">
                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  type="text"
                  name="msg"
                  placeholder="Message"
                  value={formData.msg}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="custom-modal">
          
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  return (
    <div className="club--rating--review--page">
      {showLoginPopup && <Loginpopup />}
      <div className="club--review--avg">
        <h1 className="m-0 p-0">{ratingdata.averageTotal}</h1>
        <>
          <ReactStars
            count={5}
            name="rating"
            size={24}
            position="center"
            activeColor="#ffd700"
            value={
              ratingdata && ratingdata.averageTotal
                ? parseFloat(ratingdata.averageTotal.toFixed(1))
                : 0
            }
            isHalf={true}
            edit={false}
            style={{
              display: "inline-block", 
              fontSize: "30px", 
              color: "#ffd700", 
            }}
            starSpacing="15px"
          />
        </>
        <p>
          “I had my son’s 1st birthday celebration just this past weekend.
          Everyone was very friendly and helpful...”
        </p>

        <button onClick={handleShow}>Write a Review</button>
      </div>
      <div className="club--review--namewise--avg">
        <p>86 Rank amongst club in Delhi</p>
        <div className="club--review--names">
          <p className="m-0">Ambience</p>
          <>
            <ReactStars
              count={5}
              size={30}
              activeColor="#ffd700"
              value={ratingdata.averageAmbience} 
              isHalf={true}
              edit={false} 
              style={{
                display: "inline-block",
                fontSize: "30px",
                color: "#ffd700",
              }}
            />
          </>
          <p className="m-0">
            {ratingdata && ratingdata.averageAmbience
              ? parseFloat(ratingdata.averageAmbience.toFixed(1))
              : 0}
          </p>
        </div>
        <div className="club--review--names">
          <p className="m-0">Food</p>
          <>
            <ReactStars
              count={5}
              name="rating"
              size={30}
              position="center"
              activeColor="#ffd700"
              value={ratingdata.averageFood}
              isHalf={true}
              edit={false}
              style={{
                display: "inline-block", 
                fontSize: "30px", 
                color: "#ffd700", 
              }}
            />
          </>
          <p className="m-0">
            {ratingdata && ratingdata.averageFood
              ? parseFloat(ratingdata.averageFood.toFixed(1))
              : 0}
          </p>
        </div>
        <div className="club--review--names">
          <p className="m-0">Hygiene</p>
          <>
            <ReactStars
              count={5}
              name="rating"
              size={30}
              position="center"
              activeColor="#ffd700"
              value={ratingdata.averageHygiene}
              isHalf={true}
              edit={false}
              style={{
                display: "inline-block", 
                fontSize: "30px", 
                color: "#ffd700", 
              }}
            />
          </>
          <p className="m-0">
            {" "}
            {ratingdata && ratingdata.averageHygiene
              ? parseFloat(ratingdata.averageHygiene.toFixed(1))
              : 0}
          </p>
        </div>
        <div className="club--review--names">
          <p className="m-0">Music</p>
          <>
            <ReactStars
              count={5}
              name="rating"
              size={30}
              position="center"
              activeColor="#ffd700"
              value={ratingdata.averageMusic || 0}
              isHalf={true}
              edit={false}
              style={{
                display: "inline-block", 
                fontSize: "30px", 
                color: "#ffd700", 
              }}
            />
          </>

          <p className="m-0">
            {" "}
            {ratingdata && ratingdata.averageMusic
              ? parseFloat(ratingdata.averageMusic.toFixed(1))
              : 0}{" "}
          </p>
        </div>
        <div className="club--review--names">
          <p className="m-0">Pricing</p>
          <>
            <ReactStars
              count={5}
              name="rating"
              size={30}
              position="center"
              activeColor="#ffd700"
              value={ratingdata.averagePricing || 0}
              isHalf={true}
              edit={false}
              style={{
                display: "inline-block", 
                fontSize: "30px", 
                color: "#ffd700", 
              }}
            />
          </>
          <p className="m-0">
            {ratingdata && ratingdata.averagePricing
              ? parseFloat(ratingdata.averagePricing.toFixed(1))
              : 0}
          </p>
        </div>
        <div className="club--review--names">
          <p className="m-0">Service</p>
          <>
            <ReactStars
              count={5}
              name="rating"
              size={30}
              position="center"
              activeColor="#ffd700"
              value={ratingdata.averageService}
              isHalf={true}
              edit={false}
              style={{
                display: "inline-block", 
                fontSize: "30px", 
                color: "#ffd700", 
              }}
            />
          </>
          <p className="m-0">
            {" "}
            {ratingdata && ratingdata.averageService
              ? parseFloat(ratingdata.averageService.toFixed(1))
              : 0}
          </p>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="custom-modal">
          <Modal.Title>Write a Review</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal">
          <div className="club--review--namewise--avg1">
           
            <div className="club--review--names">
              <p className="m-0">Ambience</p>
              <ReactStars
                count={5}
                name="ambience-rating"
                size={30}
                activeColor="#ffd700"
                value={ambienceRating}
                isHalf={true}
                edit={true}
                onChange={handleAmbienceRatingChange}
              />
              <p className="m-0">{ambienceRating}</p>
            </div>
            <div className="club--review--names">
              <p className="m-0">Food</p>
              <ReactStars
                count={5}
                name="food-rating"
                size={30}
                activeColor="#ffd700"
                value={foodRating}
                isHalf={true}
                edit={true}
                onChange={handleFoodRatingChange}
              />
              <p className="m-0">{foodRating}</p>
            </div>
            <div className="club--review--names">
              <p className="m-0">Hygiene</p>
              <ReactStars
                count={5}
                name="hygiene-rating"
                size={30}
                activeColor="#ffd700"
                value={hygieneRating}
                isHalf={true}
                edit={true}
                onChange={handleHygieneRatingChange}
              />
              <p className="m-0">{hygieneRating}</p>
            </div>
            <div className="club--review--names">
              <p className="m-0">Music</p>
              <ReactStars
                count={5}
                name="music-rating"
                size={30}
                activeColor="#ffd700"
                value={musicRating}
                isHalf={true}
                edit={true}
                onChange={handleMusicRatingChange}
              />
              <p className="m-0">{musicRating}</p>
            </div>
            <div className="club--review--names">
              <p className="m-0">Pricing</p>
              <ReactStars
                count={5}
                name="pricing-rating"
                size={30}
                activeColor="#ffd700"
                value={pricingRating}
                isHalf={true}
                edit={true}
                onChange={handlePriceRatingChange}
              />
              <p className="m-0">{pricingRating}</p>
            </div>
            <div className="club--review--names">
              <p className="m-0">Service</p>
              <ReactStars
                count={5}
                name="service-rating"
                size={30}
                activeColor="#ffd700"
                value={serviceRating}
                isHalf={true}
                edit={true}
                onChange={handleServiceRatingChange}
              />
              <p className="m-0">{serviceRating}</p>
            </div>
            <p className="mt-3">Add A Headline</p>
            <input
              type="text"
              name="msg"
              placeholder="What's most importent to know"
              value={formData.msg}
              onChange={handleInputChange}
            />
            <p className="mt-3">What did you Like or Dislike</p>
            <input
              type="file" 
              name="image"
              
              onChange={handleImageChange}
            />
            <p className="mt-3">Add A Written Review</p>

            <div class="input-group">
              <textarea
                class="form-control"
                aria-label="With textarea"
                type="text"
                name="msg"
                placeholder="Message"
                value={formData.msg}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="custom-modal">
         
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Rating;
