import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Favorate--hangout.css";
import Carousel from "react-bootstrap/Carousel";
import banner from "./image/banner.png";
import banner1 from "./image/banner1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import logo from "../Assets/logo.png";
import LazyLoad from "react-lazy-load";
export default function NewlyAddedClubViewAll() {
  
    const [newlyAddClub, setNewlyAddClub] = useState([]);
    const url = "https://partywitty.com/master/assets/uploads/";
    useEffect(() => {
        axios
          .get('https://partywitty.com/master/APIs/Common/newlyAddedClub')
          .then((response) => setNewlyAddClub(response.data.data))
          .catch((error) => console.log(error));
      }, []);
      

  

  
  
  return (
    <>
      <div className="Favorate--Hangout--page">
        <div className="Favorate--Hangout--page--card">
          <div className="Favorate--Hangout--page--card-banner-img">
            <img src={banner} alt="Main banner" />
          </div>
          <div className="Favorate--Hangout--page--card-banner-Search8">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo} alt="Logo" />
            </Link>
            <h1 className="Favorate--Hangout--page--p1">
              Newly Added club in Delhi, Ncr
            </h1>
          </div>
        </div>
      </div>
      <div className="Book--a--table--view--all--gap">
        <div>
          <Carousel interval={6000} indicators={false}>
            <Carousel.Item className="slider_img">
              <img src={banner1} alt="img" />
            </Carousel.Item>
          </Carousel>
        </div>

        <div className="Newly_Added_Club_card">
        {newlyAddClub.map((club) => (
        <LazyLoad key={club.id} offset={5}>
          <Link to={`/club/${club.id}`} className="bookatable-routes">
            <div className="Favorate--hangout--all--thumbnail--card1">
              <div className="Favorate--hangout--all--thumbnail--card--img">
                {club.clubLogo ? (
                  <img
                    className="Favorate--hangout--all--thumbnail--card--img--1--img"
                    src={url + club.clubLogo}
                    alt={club.name}
                  />
                ) : null}
                <div className="Favorate--hangout--all--thumbnail--card--img--1"></div>
                {(club.addon !== 0 || club.discount !== 0) && (
                  <div className="Favorate--hangout--all--thumbnail--card--2">
                    <div className="Favorate--hangout--all--thumbnail--card--2--1">
                      <div className="favorate--add--on">
                        <div className="favorate--add--on--card">
                          <p className="favorate--add--on--card--p">{club.addon}</p>
                          <p className="favorate--add--on--card--p--1">%</p>
                        </div>
                        <div className="favorate--add--on--p">
                          <p className="favorate--add--on--p--1">Add On</p>
                          <p className="favorate--add--on--p--2">EXTRA</p>
                        </div>
                      </div>
                      <div className="favorate--offered--card">
                        <p className="favorate--offered--card--1">Offered in</p>
                        <div className="favorate--offered--card--days">
                          <p>Last 30 Days</p>
                        </div>
                      </div>
                      <div className="favorate--add--on">
                        <div className="favorate--add--on--p">
                          <p className="favorate--add--on--p--1--1">Overall</p>
                          <p className="favorate--add--on--p--2--1">Discount</p>
                        </div>
                        <div className="favorate--add--on--card1">
                          <p className="favorate--add--on--card--p">{club.discount}</p>
                          <p className="favorate--add--on--card--p--1">%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="Favorate--hangout--card--content">
                <div className="Favorate--hangout--card--content--1">
                  <p className="Favorate--hangout--card--content--1--p1">
                    {club.name.substring(0, 15)}...
                  </p>
                  <p className="Favorate--hangout--card--content--1--p2">
                    {club.category}
                  </p>
                </div>
                <div className="favorate--hangout--address">
                  {club.totalRating !== 0 ? (
                    <div className="favorate--hangout--review--card1">
                      <div className="favorate--hangout--review--card">
                        <img src="/path/to/star/icon" alt="Star Icon" />
                        <p>{club.totalRating}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="favorate--hangout--review--card5"></div>
                  )}
                  <p className="favorate--hangout--address--p2">
                    {club.address.substring(0, 10)}...
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </LazyLoad>
      ))}
        </div>
      </div>
    </>
  );
}
