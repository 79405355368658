import './blog.css';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';


export default function Blog() {
        const [expandedAccordion, setExpandedAccordion] = useState(null);
        const [blogs, setBlogs] = useState([]);
        const [categories, setCategories] = useState([]);

        const apiUrl = "https://partywitty.com/master/APIs/Web/getBlogsList";
        const categoriesApiUrl = "https://partywitty.com/master/APIs/Web/getBlogCategroy";
        const imgUrlPrefix = "https://partywitty.com/master/assets/uploads/";
    
        useEffect(() => {
        
            axios.get(apiUrl)
                .then(response => {
                    if (response.data && response.data.status) {
                        setBlogs(response.data.data);
                    } else {
                        console.error("Failed to fetch blog data:", response.data.msg);
                    }
                })
                .catch(error => {
                    console.error("Error fetching blog data:", error);
                });
    

            axios.get(categoriesApiUrl)
                .then(response => {
                    if (response.data && response.data.status) {
                        setCategories(response.data.data);
                    } else {
                        console.error("Failed to fetch categories data:", response.data.msg);
                    }
                })
                .catch(error => {
                    console.error("Error fetching categories data:", error);
                });
        }, []);
    
        const handleAccordionChange = (panel) => (event, isExpanded) => {
            setExpandedAccordion(isExpanded ? panel : null);
        };
        function truncateText(text, maxLength) {
            const words = text.split(' ');
                if (words.length <= maxLength) {
                return text;
            }
        
           
            const truncatedText = words.slice(0, maxLength).join(' ') + '...';
            return truncatedText;
        }

    return (
        <div className='blog-page'>
            <div className='blog-banner'>
                <h2>Blog</h2>
            </div>
            <div className='aboutus'>
              <div className="blog-grid--box">
                            {blogs.map(blog => (
                                <Link to={`/blog/${blog.title.replace(/\s+/g, '-').toLowerCase()}`} key={blog.title} className="blog-item-link">

                                <div className="c--box" key={blog.title}>
                                    <div className='blog_img_card'>
                                    <img src={imgUrlPrefix + blog.img} alt={blog.title} style={{ width: '100%', height: '250px' }} />
                                    <div className='blog_img_card_content'>
            
                                        <p>{blog.category}</p>
                                    </div>
                                    </div>
                              
                                    <div className="content">
                                        <h3 className='blog-h3'>{blog.title}</h3>
                                        <p className='blog-pink'>{new Date(blog.date_added).toDateString()}</p>
                                        <div className="blog-p-white" dangerouslySetInnerHTML={{ __html: truncateText(blog.des, 70) }}></div>
                                        <p className='blog-pink_content'>Read more...</p>
                                    </div>
                                </div>
                             </Link>
                            ))}
            </div>
                  
               
            </div>
        </div>
    );
}





