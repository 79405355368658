import "./My--Booking.css";
import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "antd";
import Arrow from "./image/Arrow.svg";
import booking from "./image/booking.png";
import { MdStar } from "react-icons/md";
import heart from "./image/heart.svg";
import { useParams, useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import axios from "axios";
export default function MyFavorite() {
  const navigate = useNavigate();
  var url = "https://partywitty.com/master/assets/uploads/";
  const [bannerdata, setBannerdata] = useState([]);
  const getbannerapi =
    "https://partywitty.com/master/Api/getData/getFavorateHangoutBanner";

  useEffect(() => {
    fetch(getbannerapi)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "true") {
          setBannerdata([]);
        } else {
          setBannerdata(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);
  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const radius = 6371; 

    const toRadians = (degrees) => {
      return degrees * (Math.PI / 180);
    };

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    lat1 = toRadians(lat1);
    lat2 = toRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return radius * c;
  };
  const { params } = useParams();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dataid, setDataid] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [bookdata, setBookData] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
 
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const clubid = new FormData();
  clubid.append("user_id", userdata ? userdata.id : "");
  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    axios
      .post(
        "https://partywitty.com/master/Api/getDataPost/getUserFavouriteClub",
        clubid
      )
      .then((response) => {
        if (response.data.status === "true") {
       
          setData([]);
        } else {
          setData(response.data.data);
          setData1(response.data.data.map((item) => item.clubDetails));
          setDataid(response.data.data.map((item) => item.club_id));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterValue(value);
    applyFilter(value);
  };

  const applyFilter = (value) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };
 
  useEffect(() => {
   
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  if (data) {
    data.sort((a, b) => {
      const hasCoordinatesA = a.latitude && a.longitude;
      const hasCoordinatesB = b.latitude && b.longitude;

      if (hasCoordinatesA && hasCoordinatesB) {
        const distanceA = haversineDistance(
          latitude,
          longitude,
          a.latitude,
          a.longitude
        );
        const distanceB = haversineDistance(
          latitude,
          longitude,
          b.latitude,
          b.longitude
        );
        return distanceA - distanceB;
      } else if (hasCoordinatesA) {
        return -1;
      } else if (hasCoordinatesB) {
        return 1;
      }

      return 0;
    });
  }

  const addtofavclub = async (clubid) => {


    try {
      if (!userdata) {
      
        setResponseMessage("User data is missing");
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("club_id", clubid);
      formDataToSend.append("user_id", userdata.id);

      const response = await fetch(
        "https://partywitty.com/master/Api/sendData/addClubFavourite",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const responseData = await response.json();

      if (responseData.status === true) {
        setResponseMessage(responseData.msg);
        setBookData(responseData.data);

        if (responseData.data) {
         
        } else {
          setShowResponse(true);
        }
      } else {
        setResponseMessage("Error in processing the request");
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };

  return (
    <div className="My--Favorite--hamburger">
      <div className="mobile--view--hamburger--my--booking--1--fav">
        <img
          src={Arrow}
          className="left-aligned-img"
          alt="Arrow Icon"
          onClick={() => navigate(-1)}
        />
        <p className="centered-text">My Favorite</p>
      </div>
      <div className="mobile--view--hamburger--my--booking--search--fav">
        <input type="text" placeholder="Search..."></input>
      
      </div>
      <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>

      <div className="My--Favorite--hamburger--card">
        <p className="mobile--view--hamburger--my--booking--card1--content--p--1--2">
          Favorites
        </p>
        <div className="My--Favorite--hamburger--card--1">
          {data1.map((e) => (
            <Link to={`/club-aboutus/${e.id}`} className="bookatable-routes">
              <div className="My--Favorite--hamburger--card--12">
                <div className="My--Favorite--hamburger--card--12--1">
                  <img src={url + e.profile_pic}></img>
                </div>
                <div className="My--Favorite--hamburger--card--12--2">
                  <div className="My--Favorite--hamburger--card--12--2--1--1">
                    <p className="mobile--view--hamburger--my--booking--card1--content--p--1--2">
                      {e.name}
                    </p>
                    <div className="hamburger--review--card">
                      <span>
                        <MdStar />
                      </span>
                      <p>4.5</p>
                    </div>
                  </div>
                  <p className="mobile--view--hamburger--my--booking--card1--content--p1">
                    <span>Address:</span>
                    {e.address}
                  </p>
                </div>
                <div className="My--Favorite--hamburger--card--12--3">
                  <img src={heart}></img>
                </div>
              </div>
              <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
