import SearchComp from '../Components/SearchComp'
import SearchNavbar from '../Navbar/SearchNav'
const index = () => {
    return (
        <>
        <SearchNavbar/>
        <SearchComp />
        </>
    )
}

export default index