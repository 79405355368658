import React, { useEffect, useState } from "react";
import axios from "axios";
import "./multyitem.css";
import search from "./image/search1.png";
import { useLocation, useNavigate } from "react-router-dom";

function ArtistSearchbar() {
  const [data, setData] = useState([]);
  const [userInput, setUserInput] = useState("");
  
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  
  const navigate = useNavigate();
  const location = useLocation();

  const no_Rerender_URL_Name = ["/result"];
  const url = "https://partywitty.com/master/assets/uploads/";

  const searchBarStyle = {
    height: "50px",
    width: "550px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#2b2b36",
    borderRadius: "5px",
    padding: "10px 20px",
    color: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",
  };

  const inputStyle = {
    border: "none",
    outline: "none",
    fontSize: "16px",
    width: "100%",
    backgroundColor: "#2b2b36",
    color: "#fff",
    fontFamily: "Open Sans, sans-serif",
  };

  const searchBarStyleMobile = {
    height: "40px",
    width: "280px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#2b2b36",
    borderRadius: "5px",
    padding: "10px 10px",
    color: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease-in-out",
  };

  const inputStyleMobile = {
    border: "none",
    outline: "none",
    fontSize: "10px",
    width: "100%",
    backgroundColor: "#2b2b36",
    color: "#fff",
    fontFamily: "Open Sans, sans-serif",
  };

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      const ClubApi = new FormData();
      ClubApi.append("latitude", latitude);
      ClubApi.append("longitude", longitude);
  
      axios
        .post("https://partywitty.com/master/APIs/Common/recommendedClub", ClubApi)
        .then((response) => {
          const dataArray = Object.values(response.data.data);
          setData(dataArray);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [latitude, longitude]);
  
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setUserInput(inputValue);
    if (inputValue.trim() !== "") { 
      navigate(`/result?${inputValue}`); 
    }
  };

  const handlesearch = async (e) => {
    if (e.key === "Enter") {
      const RemoveSpace = userInput.replace(/\s/g, '');
      if (RemoveSpace === "") {
        return alert(`It looks like you didn't search.`)
      }
      navigate(`/result?${userInput}`)
    }
  }

  return (
    <>
      <div className="homepage--search--web--view--11">
        <div className="homepage--search--degign-1">
          <div className="homepage--search--degign">
            <div className="searchbar_field">
              {!no_Rerender_URL_Name.includes(location.pathname) && 
                <div style={searchBarStyle}>
                  <input
                  className="input-placeholder-white"
                    style={inputStyle}
                    type="search"
                    placeholder="Search for Clubs and Packages..."
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyDown={handlesearch}
                  />
                  <span>
                    <img src={search} alt="searchicon" />
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="homepage--search--mobile--view--11">
        <div className="homepage--search--degign-1">
          <div className="homepage--search--degign">
            {!no_Rerender_URL_Name.includes(location.pathname) && 
              <div className="searchbar_field">
                <div style={searchBarStyleMobile}>
                  <input
                  className="input-placeholder-white"
                    style={inputStyleMobile}
                    type="text"
                    placeholder="Search..."
                    value={userInput}
                    onChange={handleInputChange}
                    onKeyDown={handlesearch}
                  />
                  <span>
                    <img src={search} alt="searchicon" />
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtistSearchbar;
