import React from 'react';


export default function PromotionalLine(){
    return(
        <div className='club-detail-promotional-line'>
          <ul className='club-detail-promotional-line-content'>
            <li>Club has give maximum discount of 40% to Customer in last 30 days</li>
            <li>Club has give maximum discount of 40% to Customer in last 30 days</li>
            <li>Club has give maximum discount of 40% to Customer in last 30 days</li>
            <li>Club has give maximum discount of 40% to Customer in last 30 days</li>
          </ul>
        </div>
    );
}