import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Style.css';
import WebApp1 from "../../Web--to--app/WebApp1";

export default function SpecialNight() {
  const [data, setData] = useState([]);
  const [showPopUpIndex, setShowPopUpIndex] = useState(null);
  const [popupKey, setPopupKey] = useState(0); // Key to force re-render
  const URL = "https://partywitty.com/master/assets/uploads/";

  const fetchURL = async () => {
    try {
      const response = await axios.get(
        "https://partywitty.com/master/APIs/Artist/specialArtist"
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchURL();
  }, []);

  const handleClickButton = (index) => {
    setShowPopUpIndex(index);
    setPopupKey(prevKey => prevKey + 1); // Increment key to force re-render
  };

  const handleClosePopUp = () => {
    setShowPopUpIndex(null);
  };

  return (
    <div className='Artist-card-font'>
      <h4>Special Artist</h4>
      <div className='live-music-all-card'>
        {data.length > 0 ? (
          data.map((artist, index) => (
            <div 
              className={`special-night-card-content ${showPopUpIndex === index ? 'active' : ''}`} 
              key={artist.id}
            >
              <div className='special-card-left'>
                {artist.profile_pic ? (
                  <img 
                    src={URL + artist.profile_pic} 
                    alt={artist.name} 
                  />
                ) : artist.video ? (
                  <video controls loop>
                    <source src={URL + artist.video} type="video/mp4" />
                  </video>
                ) : (
                  <p>No Media Available</p>
                )}
              </div>
              <div className='special-night-card-content-1'>
                <p className='arstist-club-name-1'>{artist.name}</p>
                <div className='artist--name--percussion'>
                  <p>GENRE</p>
                  <div className="artist-content-para1">
                    {artist.genre.split(",").map((genre, genreIndex) => (
                      <button key={genreIndex}>{genre.trim()}</button>
                    ))}
                  </div>
                </div>
                <div className='artist-view-profile'>
                  <button onClick={() => handleClickButton(index)}>View Profile</button>
                  {showPopUpIndex === index && <WebApp1 key={popupKey} onClose={handleClosePopUp} />}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No special artists available.</p>
        )}
      </div>
    </div>
  );
}
