import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Style.css';
import WebApp1 from "../../Web--to--app/WebApp1";

export default function LiveMusic() {
  const [data, setData] = useState([]);
  const [showPopUpIndex, setShowPopUpIndex] = useState(null); 
  const [popupKey, setPopupKey] = useState(0); // Key to force re-render
  const URL = "https://partywitty.com/master/assets/uploads/";

  const fetchURL = async () => {
    try {
      const response = await axios.get(
        "https://partywitty.com/master/APIs/Artist/trendingArtist"
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchURL();
  }, []);

  const handleClickButton = (index) => {
    setShowPopUpIndex(index); 
    setPopupKey(prevKey => prevKey + 1); 
  };

  const handleClosePopUp = () => {
    setShowPopUpIndex(null); 
  };

  return (
    <div className='Artist-card-font-trending'>
      <h4>Trending Artists</h4>
      <div className='live-music-all-card'>
        {data.length > 0 ? (
          data.map((artist, index) => (
            <div
              className={`live-music-card ${showPopUpIndex === index ? 'active' : ''}`}
              key={artist.id}
            >
              <div className='live-music-card-content'>
                {artist.profile_pic ? (
                  <img 
                    src={URL + artist.profile_pic} 
                    alt='img' 
                  />
                ) : artist.video ? (
                  <video controls loop>
                    <source src={URL + artist.video} type="video/mp4" />
                  </video>
                ) : (
                  <p>No Media Available</p>
                )}
                <div className='live-music-card-content-1'>
                  <p>{artist.name}</p>
                  <div>
                    <button onClick={() => handleClickButton(index)}>View Profile</button>
                    {showPopUpIndex === index && (
                      <WebApp1 key={popupKey} onClose={handleClosePopUp} />
                    )}
                  </div>
                </div>
                <div className='live-music-geners'>
                  {artist.genre.split(",").map((genre, genreIndex) => (
                    <button key={genreIndex}>{genre.trim()}</button>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No artists available.</p>
        )}
      </div>
    </div>
  );
}
