import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import React, { useEffect } from "react";

class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://partywitty.com/thank-you",
      },
    });

    if (result) {
      console.log(result);
    }
  };

  render() {
    return (
      <div className="checkoutformpage  mb-5 mt-5">
        <form onSubmit={this.handleSubmit}>
          <PaymentElement />
          <button className="mt-3" disabled={!this.props.stripe}>
            Submit
          </button>
        </form>
      </div>
    );
  }
}
export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
