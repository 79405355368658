import { Toaster } from "react-hot-toast";
import "./App.css";
import Homepage from "./Homepage/Homepage";
import Multiitem from "./Homepage/Multyitem";
import LoginSignupPopUp from "./Navbar/Login--signup--popup";

function App() {
  return (
    <div className="home-page-design">
      <LoginSignupPopUp />
     
      <div className="home-page-design1">
        <Homepage />
      </div>

    </div>
  );
}

export default App;
