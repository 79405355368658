import React, { useEffect, useState } from "react";
import "./registration.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Box } from "@material-ui/core";
import Googleplay from "../Footer/image/Googleplay.png";
import Applestore from "../Footer/image/appdownloads.png";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


const RegistrationLoginForm = () => {
  const [mobile, setMobile] = useState('');
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [managerMobile, setManagerMobile] = useState('');
  const [isManagerMobileValid, setIsManagerMobileValid] = useState(false);
  const [managerOtp, setManagerOtp] = useState('');
  const [isManagerOtpSent, setIsManagerOtpSent] = useState(false);
  const [isManagerOtpVerified, setIsManagerOtpVerified] = useState(false);
  const [managerOtpError, setManagerOtpError] = useState('');
  const [managerName, setManagerName] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('Freemium');
  const [subscriptionDuration, setSubscriptionDuration] = useState('Annual');
  const [showPopup, setShowPopup] = useState(false);
  const [isMobileDisabled, setIsMobileDisabled] = useState(false);
  const [isManagerMobileDisabled, setIsManagerMobileDisabled] = useState(false);
  const [swalProps, setSwalProps] = useState({});

  const handleMobileNumberChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    if (numericValue.length <= 10) {
      setMobile(numericValue);
      setMobile(e.target.value);
      setIsMobileValid(/^\d{10}$/.test(numericValue));
    }
  };

  const handleManagerMobileNumberChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '');
    if (numericValue.length <= 10) {
      setManagerMobile(numericValue);
      setIsManagerMobileValid(/^\d{10}$/.test(numericValue));
    }
  };

  const handleVerifyMobile = async () => {
    if (!isMobileValid) return;

    try {
      const formData = new FormData();
      formData.append('mobile', mobile);
      formData.append('otpType', 'text');

      console.log('Sending mobile verification request with:', { mobile });

      const response = await axios.post(
        'https://partywitty.com/master/Api/getDataPost/verifyMobile',
        formData
      );

      console.log('Mobile verification response:', response.data);

      if (response.data.status) {
        setIsOtpSent(true);
        setOtpError('');
        setIsMobileDisabled(true);
        iziToast.success({
          title: 'Success',
          message: response.data.msg || 'OTP sent successfully!',
          position: 'topCenter',
        });
      } else {
        setOtpError('Failed to send OTP.');
        iziToast.error({
          title: 'Error',
          message: 'Failed to send OTP.',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setOtpError('Error occurred while sending OTP.');
      iziToast.error({
        title: 'Error',
        message: 'Error occurred while sending OTP.',
        position: 'topCenter',
      });
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.length !== 6) return;

    try {
      const formData = new FormData();
      formData.append('mobile', mobile);
      formData.append('otp', otp);

      console.log('Sending OTP verification request with:', { mobile, otp });

      const response = await axios.post(
        'https://partywitty.com/master/Api/getDataPost/verifyMobileOtp',
        formData
      );

      console.log('OTP verification response:', response.data);

      if (response.data.status) {
        setIsOtpVerified(true);
        setIsOtpSent(false);
        setOtpError('');
        iziToast.success({
          title: 'Success',
          message: 'Mobile number verified successfully!',
          position: 'topCenter',
        });
      } else {
        setOtpError('Incorrect OTP.');
        iziToast.error({
          title: 'Error',
          message: 'Incorrect OTP.',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setOtpError('Error occurred while verifying OTP.');
      iziToast.error({
        title: 'Error',
        message: 'Error occurred while verifying OTP.',
        position: 'topCenter',
      });
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setOtpError(''); // Clear any previous errors when OTP is being typed
  };
  
  const handleVerifyManagerMobile = async () => {
    if (!isManagerMobileValid) return;

    try {
      const formData = new FormData();
      formData.append('mobile', managerMobile);
      formData.append('otpType', 'text');

      const response = await axios.post(
        'https://partywitty.com/master/Api/getDataPost/verifyMobile',
        formData
      );

      if (response.data.status) {
        setIsManagerOtpSent(true);
        setManagerOtpError('');
        setIsManagerMobileDisabled(true);
        iziToast.success({
          title: 'Success',
          message: response.data.msg || 'OTP sent successfully!',
          position: 'topCenter', // Position at the top center
        });
      } else {
        setManagerOtpError('Failed to send OTP.');
        iziToast.error({
          title: 'Error',
          message: 'Failed to send OTP.',
          position: 'topCenter', // Position at the top center
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setManagerOtpError('Error occurred while sending OTP.');
      iziToast.error({
        title: 'Error',
        message: 'Error occurred while sending OTP.',
        position: 'topCenter', // Position at the top center
      });
    }
  };

  const handleVerifyManagerOtp = async () => {
    if (managerOtp.length !== 6) return;
    try {
      const formData1 = new FormData();
      formData1.append('mobile', managerMobile);
      formData1.append('otp', managerOtp);

      const response = await axios.post(
        'https://partywitty.com/master/Api/getDataPost/verifyMobileOtp',
        formData1
      );

      if (response.data.status) {
        setIsManagerOtpVerified(true);
        setIsManagerOtpSent(false);
        setManagerOtpError('');
        iziToast.success({
          title: 'Success',
          message: 'Mobile number verified successfully!',
          position: 'topCenter', // Position at the top center
        });
      } else {
        setManagerOtpError('Incorrect OTP.');
        iziToast.error({
          title: 'Error',
          message: 'Incorrect OTP.',
          position: 'topCenter', // Position at the top center
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setManagerOtpError('Error occurred while verifying OTP.');
      iziToast.error({
        title: 'Error',
        message: 'Error occurred while verifying OTP.',
        position: 'topCenter', // Position at the top center
      });
    }
  };
 
  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('business_name', businessName);
    formData.append('business_address', businessAddress);
    formData.append('business_type', selectedBusinessType);
    formData.append('subscription', subscriptionType);
    formData.append('plan_validity', subscriptionDuration);
    formData.append('owner_mobile', mobile);
    formData.append('owner_name', ownerName);
    formData.append('owner_email', ownerEmail);
    formData.append('manager_mobile', managerMobile);
    formData.append('manager_name', managerName);
    formData.append('manager_email', managerEmail);

    try {
      const response = await axios.post(
        'https://partywitty.com/master/Api/getDataPost/restaurantsEnquiry',
        formData
      );
      console.log(response.data);

      if (response.data.status === true) {
        alert("Enquiry submitted successfully!"); // Alert message
        setShowPopup(true); // Open the popup/modal
      } else {
        alert("Enquiry submission failed."); // Optional: Handle failed submission
      }
    } catch (error) {
      console.error('Error:', error);
      alert("Error submitting enquiry."); // Alert for error
    }
  };
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState("");

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/ClubPackage/getVenuType")
      .then((response) => {
        setBusinessTypes(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const isFormComplete = () => {
    return (
      businessName &&
      businessAddress &&
      selectedBusinessType &&
      subscriptionType &&
      subscriptionDuration &&
      mobile &&
      ownerName &&
      ownerEmail &&
      managerMobile &&
      managerName &&
      managerEmail &&
      isMobileValid &&
      isOtpVerified &&
      isManagerMobileValid &&
      isManagerOtpVerified
    );
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href = '/'; 
  };

  return (
    <div className="login-container">
      <section class="about--banner">
        <div class="main--box">
          <div class="title--name">
            <h2>Business Information</h2>
          </div>
        </div>
      </section>
      <section class="main--feacture">
      <form onSubmit={handleSubmit1}>
      <div className="main--box--1">
        <div className="border--box--1">
          <div className="row">
            <h3>Subscription Type</h3>

            <div className="col-2-3">
              <div className="form-group1">
                <select
                  name="subscriptionType"
                  className="businessType_data"
                  value={subscriptionType}
                  onChange={(e) => setSubscriptionType(e.target.value)}
                >
                  <option value="Freemium">Freemium</option>
                  <option value="Gold">Gold</option>
                  <option value="Platinum">Platinum</option>
                </select>
              </div>
              <div className="form-group1">
                <select
                  name="subscriptionDuration"
                  className="businessType_data"
                  value={subscriptionDuration}
                  onChange={(e) => setSubscriptionDuration(e.target.value)}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Annual">Annual</option>
                </select>
              </div>
            </div>

           
          </div>
        </div>
      </div>
      <div className="main--box--1">
        <div className="border--box--1">
          <div className="row_11">
            <h3>Restaurant details</h3>

            <div className="col--1_8">
              <div className="form-group_1">
                <input
                  type="text"
                  placeholder="Business Name"
                  name="name"
                  className="form-control"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>
              <div className="form-group_1">
                <input
                  type="text"
                  placeholder="Business Address"
                  name="subject"
                  className="form-control"
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                />
              </div>
            </div>
          
            <div className="col">
              <div className="form-group">
              <select
      name="businessType"
      id="businessType"
      className="businessType_data"
      value={selectedBusinessType}
      onChange={(e) => setSelectedBusinessType(e.target.value)}
    >
      <option value="">Business Type</option>
      {businessTypes.map((type, index) => (
        <option key={index} value={type.name}>
          {type.name}
        </option>
      ))}
    </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main--box--1">
      <div className="border--box--1">
        <div className="row">
          <h3>Owner Details</h3>
          <p>These will be used to share revenue related communications</p>
          <div className="col--1--2">
      <input
        className="form-control"
        placeholder="Business Owner Mobile Number"
        type="number"
        value={mobile}
        maxLength="10"
        onChange={handleMobileNumberChange}
        pattern="[0-9]*"
        title="Please enter only numbers"
        style={{ backgroundColor: isMobileValid ? 'white' : '' }}
        disabled={isMobileDisabled}
      />
      {!isOtpVerified ? (
        isOtpSent ? (
          <>
            <input
              className="form-control"
              placeholder="Enter OTP"
              type="number"
              value={otp}
              onChange={handleOtpChange}
              style={{ backgroundColor: otp.length === 6 ? 'white' : '' }}
            />
            <button
              type="button"
              onClick={handleVerifyOtp}
              style={{
                backgroundColor: otp.length === 6 ? '#d71362' : '',
                cursor: otp.length === 6 ? 'pointer' : 'not-allowed',
              }}
            >
              Verify OTP
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={handleVerifyMobile}
            style={{
              backgroundColor: isMobileValid ? '#d71362' : '',
              cursor: isMobileValid ? 'pointer' : 'not-allowed',
            }}
          >
            Verify
          </button>
        )
      ) : (
        <button
          type="button"
          disabled
          style={{
            backgroundColor: '#d71362',
          }}
        >
          Verified
        </button>
      )}
    </div>
          <div className="col--1--2-1">
            <input type="checkbox" />
            <p>
              Yes, I would like to receive important updates and notifications from PartyWitty on my WhatsApp
            </p>
          </div>
          <div className="col--1">
            <div className="form-group_1">
              <input
                placeholder="Business Owner Full Name"
                name="ownerName"
                className="form-control"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
              />
            </div>
            <div className="form-group_1">
              <input
                type="email"
                placeholder="Business Owner Email"
                name="email"
                className="form-control"
                value={ownerEmail}
                onChange={(e) => setOwnerEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="main--box--1">
        <div className="border--box--1">
          <div className="row">
            <h3>Manager details</h3>
            <p>These will be used to share revenue related communications</p>
            <div>
            <div className="col--1--2">
      <input
        type="tel"
        placeholder="Manager Mobile Number"
        name="mobileNumber"
        value={managerMobile}
        onChange={handleManagerMobileNumberChange}
        className="form-control"
        style={{ backgroundColor: isManagerMobileValid ? 'white' : '' }}
        required
        disabled={isManagerMobileDisabled}
      />
      {!isManagerOtpVerified ? (
        isManagerOtpSent ? (
          <>
            <input
              className="form-control"
              placeholder="Enter OTP"
              type="number"
              value={managerOtp}
              onChange={(e) => setManagerOtp(e.target.value)}
              style={{ backgroundColor: managerOtp.length === 6 ? 'white' : '' }}
            />
            <button
              type="button"
              onClick={handleVerifyManagerOtp}
              style={{
                backgroundColor: managerOtp.length === 6 ? '#d71362' : '',
                cursor: managerOtp.length === 6 ? 'pointer' : 'not-allowed'
              }}
            >
              Verify OTP
            </button>
          
          </>
        ) : (
          <button
            type="button"
            onClick={handleVerifyManagerMobile}
            style={{
              backgroundColor: isManagerMobileValid ? '#d71362' : '',
              cursor: isManagerMobileValid ? 'pointer' : 'not-allowed'
            }}
          >
            Verify
          </button>
        )
      ) : (
        <button
          type="button"
          disabled
          style={{
            backgroundColor: '#d71362'
          }}
        >
          Verified
        </button>
      )}
            </div>
            </div>
            <div className="col--1--2-1">
              <input type="checkbox"></input>
              <p>
                Yes, I would like to receive important updates and notifications from PartyWitty on my WhatsApp
              </p>
            </div>

            <div className="col--1">
              <div className="form-group_1">
                <input
                  placeholder="Manager Full Name"
                  name="managerName"
                  className="form-control"
                  value={managerName}
                  onChange={(e) => setManagerName(e.target.value)}
                ></input>
              </div>
              <div className="form-group_1">
                <input
                  type="email"
                  placeholder="Manager Email"
                  name="managerEmail"
                  className="form-control"
                  value={managerEmail}
                  onChange={(e) => setManagerEmail(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="col-md-12 text-right">
                <button
              type="submit"
              className="btn--theame--1"
              onClick={handleSubmit1}
              disabled={!isFormComplete()}
              style={{
                backgroundColor: isFormComplete() ? '#d71362' : '#cdcdcd',
                cursor: isFormComplete() ? 'pointer' : 'not-allowed'
              }}
            >
              Submit
            </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <Modal open={showPopup} onClose={handleClosePopup}>
              <div className="modal-container">
                <div className="modal-content">
                <button
                    className="modal-button"
                    onClick={() => setShowPopup(false)}
                  >
                  x
                  </button>
                  <dotlottie-player
                    src="https://lottie.host/6b9fc96a-5c59-464a-ad26-5782e3a54022/EFWiS28KVM.json"
                    background="transparent"
                    speed="1"
                    style={{ height: "300px" }}
                    loop
                    autoplay
                  ></dotlottie-player>
                  <p className="modal-message">Form submitted successfully!</p>
                  <div className="about-us-playstore-image">
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Googleplay} alt="playstore"/>
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Applestore} alt="appstore"/>
                </Link>
              </div>
                </div>
              </div>
     </Modal>
      </section>
    </div>
  );
};

export default RegistrationLoginForm;
