import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import {useState,useEffect} from 'react';
import star from "../../favorate--hangout--page/image/star.png";
import '../../Homepage/Homepage.css'
import { ShimmerThumbnail } from "react-shimmer-effects"

// import { Base64 } from "js-base64";


const Index = ({ PackageData, isLoading  }) => {
    
    // Destructure of PackageData
    const { id, packageThumbnail, clubLogo, title, address, clubName, totalRating, areaName, cityName,totalAverageRating, distance} = PackageData;
    // const TitleVal = title?.split('+')
    
    // const RedirectURL = cityName ? `/${cityName?.replace(/ /g, '-')}/${areaName?.replace(/ /g, '-')}/${clubName?.replace(/ /g, '-')}/${TitleVal[0].replace(/ /g, '-')}` + `${TitleVal[1] === undefined ? '/':TitleVal[1]}-${Base64.encode(id)}`   : '/'
    const VALID_URL = cityName ? `/${cityName?.replace(/ /g, '-')}/${areaName?.replace(/ /g, '-')}/${clubName?.trim().split(/ +/).join('-').replace(/ /g, '-')}/${title.split('+').map(part => part.trim().replace(/ /g, '-')).join('-').toLowerCase()}`: '/';
    // Baseurl of Image
    const url = "https://partywitty.com/master/assets/uploads/";
   
   
  
    return (
        <div key={id}>
           
            <LazyLoad offset={5} >
                <Link to={VALID_URL}>
              
                    <div className="Favorate--hangout--all--thumbnail--card1">
                        <div className="Favorate--hangout--all--thumbnail--card--img--2">
                         
                            { packageThumbnail || clubLogo ? (
                                <img
                                    className="Favorate--hangout--all--thumbnail--card--img--1--img"
                                    src={url + `${packageThumbnail !== "" ? packageThumbnail : clubLogo}`}
                                    alt={title}
                                />
                            ) : null}

                            <div className="Favorate--hangout--all--thumbnail--card--img--1">
                                
                                <div className="Favorate--hangout--all--thumbnail--card--club--logo">
                                    <img src={url + clubLogo} alt={title} />
                                </div>
                            </div>
                            <div className="group--booking--thumbnail--livemusic--card--2">
                                {/* <div className="group--booking--thumbnail--livemusic--card">
                  <div className="group--booking--thumbnail--livemusic--card1">
                    <img src={livemusic}></img>
                    <p>Live music</p>
                  </div>
                  <div className="group--booking--thumbnail--livemusic--card1">
                    <img src={alcoholic}></img>
                    <p>alcoholic</p>
                  </div>
                  <div className="group--booking--thumbnail--livemusic--card1">
                    <img src={tablebook}></img>
                    <p>OutDoor</p>
                  </div>
                </div> */}
                            </div>
                        </div>
                        <div className="Favorate--hangout--card--content">
                            <div className="Favorate--hangout--card--content--1">
                                <p className="Favorate--hangout--card--content--1--p1">
                                    {title.substring(0, 15)}...
                                </p>
                                <p className="Favorate--hangout--card--content--1--p2">
                                    {clubName.substring(0, 16)}
                                </p>
                                <p className="favorate--hangout--address--p8">
                                    {address.substring(0, 15)}...
                                </p>
                            </div>
                            <div className="favorate--hangout--address">
                                {totalRating !== 0 ? (
                                    <div className="favorate--hangout--review--card1">
                                        <div className="favorate--hangout--review--card">
                                            <img src={star} alt="Star Icon"></img>
                                            <p>{totalAverageRating}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="favorate--hangout--review--card1">
                                        <div className="favorate--hangout--review--card5"></div>
                                    </div>
                                )}

                                {/* <p className="favorate--hangout--address--p2">
                          Get The Price
                          </p> */}
                          <p className="favorate--hangout--address--p2">
                        {distance} km
                          </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </LazyLoad>
        </div>
    )
}

export default Index