import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import CarnivalPassBookingInput from "./CarnivalPassBookingInput";
import Commons from "./Commons";
import Group from "./image/Group.png";
import CarnivalPassDetails from "./CarnivalPassDetails";

export default function CarnivalPassCard(props) {
  const pass = props.pass;
  const PD = pass?.passDetails;
  if (PD) {
    pass.id = pass.id || PD.id;
    pass.icon = pass.icon || PD.icon;
    pass.entertainment = pass.entertainment || PD.entertainment;
    pass.name = pass.name || PD.name;
  }

  const [state1, setState1] = useState({ bottom: false });
  const toggleDrawer1 = (anchor, open) => (event) => {
    // console.log('Anchor: ', anchor, open);
    setState1({ ...state1, [anchor]: open });
  }

  const getLowestPrice = () => {
    let minPrice = 0;
    for (let passType of pass.pricing) {
      minPrice = Math.min(minPrice, passType.price);
    }
    return minPrice;
  }

  const getAvailableSeats = () => {
    let count = 0;
    for (let passType of pass.pricing) {
      count += Number.parseInt(passType.seat || '0');
    }
    return count;
  }

  const [detailState, setDetailState] = React.useState({
    bottom: false,
  });

  const toggleDrawer3 = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDetailState({ ...detailState, [anchor]: open });
  };

  const buildViewDetails = () => {
    return (
      <div style={{ margin: "auto", width: "100px" }}>
        {
          ["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <button
                onClick={toggleDrawer3(anchor, true)}
              >
                {" "}
                <p className="new--year--page--more--detail">
                  Details
                </p>
              </button>
              <Drawer
                anchor={anchor}
                open={detailState.bottom}
                onClose={toggleDrawer3(anchor, false)}
                PaperProps={{
                  sx: {
                    margin: "15px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  },
                }}
              >
                <CarnivalPassDetails pass={pass} />
              </Drawer>
            </React.Fragment>
          ))
        }
      </div>
    );
  }

  const buildBookNow = () => {
    return (
      <>
        {["bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div onClick={
              async (event) => {
                toggleDrawer1(anchor, true)(event);
              }
            }>
              {" "}
              <p
                className="new--year--page--more--detail"
                key={pass.id}
              >
                BOOK NOW
              </p>
            </div>
            <Drawer
              anchor={anchor}
              open={state1[anchor]}
              onClose={toggleDrawer1(anchor, false)}
              PaperProps={{
                sx: {
                  margin: "15px", // Adjust the margin values as needed
                  borderTopLeftRadius: "8px", // Set border-top-left-radius
                  borderTopRightRadius: "8px", // Set border-top-right-radius
                },
              }}
            >
              <CarnivalPassBookingInput pass={pass} />
            </Drawer>
          </React.Fragment>
        ))}
      </>
    );
  }

  const buildIcon = () => {
    return (
      <div className="New--year--page--design--All--passes1--card--img">
        <img src={Commons.getUploadPath(pass.icon)}></img>
        <div className="new--year--page--img--button--dj">
          <div className="dj--nights--img">
            <img src={Group}></img>
            <button className="dj--nights--img-btn1">
              {pass.entertainment}
            </button>
          </div>
          <div className="dj--nights--img">
            <button className="dj--nights--img-btn3">
              Pass
            </button>
          </div>
        </div>
      </div>
    );
  }

  const buildCardSummary = () => {
    return (
      <div className="New--year--page--design--All--passes1--card--content--1">
        <div className="New--year--page--all--passes1--card-p--9">
          <p className="New--year--page--design--All--passes1--card--content--1--p">
            {pass.name}
          </p>
          <div className="new--year--card--content2--1-2">
            <p className="New--year--page--design--All--passes1--card--content--p--blue">
              {pass.clubName}
            </p>
            <div className="new--year--card--content2--1">
              <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                {pass.start_time} - {pass.end_time}
              </p>
              <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                {pass.clubAddress.slice(0, 150)}
              </p>
            </div>
          </div>
        </div>
        <div className="New--year--page--design--All--passes1--card--content--1-12">
          <p className="New--year--page--design--All--passes1--card--content--1--p--price">
            <span> Start @</span> ₹{getLowestPrice()}
          </p>
          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
            Price
          </p>
          <p className="new--year--page--table6--package">
            {getAvailableSeats()} Seats Left
          </p>
          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
            Kids{" "}
            {pass.kids_friendly === "true" ? <></> : <>Not</>}{" "}
            Allowed
          </p>
        </div>
      </div>
    );
  }

  const buildLine = () => {
    return (<div className="new--year--page--border--line"></div>);
  }

  return (
    <>
      <div
        className="New--year--page--design--All--passes1--card1"
        key={pass.id}
      >
        {buildIcon()}

        {buildViewDetails()}

        <div className="New--year--page--design--All--passes1--card--content">
          {buildCardSummary()}
          {buildLine()}
          {buildBookNow()}
        </div>


      </div>

    </>
  );
}



/**
 <div className="New--year--page--design--All--passes1--card1">
                          <div className="New--year--page--design--All--passes1--card--img">
                            <img src={url + e.icon}></img>
                            <div className="new--year--page--img--button--dj">
                              <div className="dj--nights--img">
                                <img src={Group}></img>
                                <button className="dj--nights--img-btn1">
                                  {e.entertainment}
                                </button>
                              </div>
                              <div className="dj--nights--img">
                                <button className="dj--nights--img-btn3">
                                  Pass
                                </button>
                                </div>
                                </div>
                              </div>
    
                              <div className="New--year--page--design--All--passes1--card--content">
                                <div className="New--year--page--design--All--passes1--card--content--1">
                                  <div className="New--year--page--all--passes1--card-p--9">
                                    <p className="New--year--page--design--All--passes1--card--content--1--p">
                                      {e.name.slice(0, 150)}
                                    </p>
                                    <div className="new--year--card--content2--1-2">
                                      <p className="New--year--page--design--All--passes1--card--content--p--blue">
                                        {e.clubName}
                                      </p>
                                      <div className="new--year--card--content2--1">
                                        <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                          {e.start_time} - {e.end_time}
                                        </p>
                                        <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                          {e.clubAddress.slice(0, 150)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="New--year--page--design--All--passes1--card--content--1-12">
                                    <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                                      {e.pricing.map((e, index) => (
                                        <div key={index}>
                                          {e.ticket_type === "Couple" ? (
                                            <>
                                              <span className="new--year--stag-couple--icon">
                                                <img src={coupleicon}></img>
                                              </span>
                                            </>
                                          ) : e.ticket_type === "Male Stag" ? (
                                            // <div className="new--year--stag-male">
                                            <span className="new--year--stag-male--icon">
                                              <IoIosMale />
                                            </span>
                                          ) : // </div>
                                            e.ticket_type === "Female Stag" ? (
                                              <span className="new--year--stag-female--icon">
                                                <IoIosFemale />
                                              </span>
                                            ) : (
                                              ""
                                            )}{" "}
                                          ₹{e.price}
                                        </div>
                                      ))}
                                    </p>
                                    <p className="new--year--page--table6--package">
                                      {e.pricing[0].seat} Seats Left
                                    </p>
                                    <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                      Kids{" "}
                                      {e.kids_friendly === "true" ? (
                                        <>Yes</>
                                      ) : (
                                        <>Not</>
                                      )}{" "}
                                      Allowed
                                    </p>
                                  </div>
                                </div>
                                <div className="new--year--page--border--line"></div>
    
                                {["bottom"].map((anchor) => (
                                  <React.Fragment key={anchor}>
                                    <div onClick={toggleDrawer(anchor, true)}>
                                      {" "}
                                      <p
                                        className="new--year--page--more--detail"
                                        key={e.id}
                                        // onClick={() => handleCategoryClick(e.id)}
                                        onClick={() =>
                                          handleOpenPlanEvent4(e.id, () =>
                                            toggleDrawer(anchor, false)
                                          )
                                        }
                                      >
                                        BOOK NOW
                                      </p>
                                    </div>
                                    <Drawer
                                      anchor={anchor}
                                      open={state[anchor]}
                                      onClose={toggleDrawer(anchor, false)}
                                      PaperProps={{
                                        sx: {
                                          margin: "15px", // Adjust the margin values as needed
                                          borderTopLeftRadius: "8px", // Set border-top-left-radius
                                          borderTopRightRadius: "8px", // Set border-top-right-radius
                                        },
                                      }}
                                    >
                                      {listdata(anchor)}
                                    </Drawer>
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
 */