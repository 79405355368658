import "../App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import logo from "../Assets/logo.png";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import farmhouse from "../Navbar/image/farmhouse.png";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import { useModal } from "../context/context";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";

import ListItemIcon from "@mui/material/ListItemIcon";

import userprofile from "../Navbar/image/user.png";
import eventicon from "../Navbar/image/event (1).png";
import hearticon from "../Navbar/image/heart.png";
import transactionhistory from "../Navbar/image/History.png";

import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import MyFavorites from "../Navbar/image/MyFavorites.png";

import Notification from "../Navbar/image/Notification.png";
import History from "../Navbar/image/History.png";

import Signout from "../Navbar/image/Signout.png";

import WhatsAppicon from "../Navbar/image/WhatsAppicon.png.webp";
import Settings from "../Navbar/image/Settings.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import Loginpopup from "../Navbar/Loginmodal";
import SearchbarHomepage from "../Homepage/Search--Homepage";
import tablebookingbanner from './image/tablebookingbanner.png';
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 450,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(email);
}
export default function TableBookingBanner() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMyAccount = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMyAccount = () => {
    setAnchorEl(null);
  };

  const [timeInSeconds, setTimeInSeconds] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const { toggle } = useModal();

  const [response, setResponse] = useState(null);

  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("male");
  const [updatemobile, setUpdateMobile] = useState("");

  const [updateotp, setUpdateOtp] = useState("");
  const [updatename, setUpdateName] = useState("");
  const [updateemail, setUpdateEmail] = useState("");
  const [updatedob, setUpdateDob] = useState(null);
  const [updategender, setUpdateGender] = useState("");
  const [updateprofile, setUpdateProfile] = useState("");
  
  const [token, setToken] = useState("");

  const handleEmailChangevalue1 = (e) => {
    setUpdateEmail(e.target.value);
  };

  var url = "https://partywitty.com/master/assets/uploads/";
  useEffect(() => {
    if (toggle) {
      setOpenLogin(true);
    }
  }, [toggle]);

  const handleSubmitupdateprofile = async (e) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userdata.id);
      formData.append("mobile", updatemobile);
      formData.append("name", updatename);
      formData.append("email", updateemail);
      formData.append("dob", updatedob);
      formData.append("gender", updategender);
      if (!validateEmail(updateemail)) {
    
        return;
      }
      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/UpdateProfile",
        formData
      );
    
      setResponse(response.data);

      if (response.data.status === true) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));

        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {

    const updateTimer = () => {
      if (timeInSeconds > 0) {
        setTimeInSeconds((prevTime) => prevTime - 1);
      } else {
      
        setShowResend(true);
      }
    };
   
    const intervalId = setInterval(updateTimer, 1000);

  
    return () => clearInterval(intervalId);
  }, [timeInSeconds]);


  const formattedTime = `${Math.floor(timeInSeconds / 60)}:${(
    timeInSeconds % 60
  )
    .toString()
    .padStart(2, "0")}`;
 

  const [openLogin, setOpenLogin] = React.useState(false);
  const handleOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const [openOtp, setOpenOtp] = React.useState(false);
  const handleOpenOtp = () => {
    setOpenOtp(true);
  };
  const handleCloseOtp = () => {
    setOpenOtp(false);
  };
  const [openSignup, setOpenSignup] = React.useState(false);
  const handleOpenSignup = () => {
    setOpenSignup(true);
  };
  const handleCloseSignup = () => {
    setOpenSignup(false);
  };

 
  const [openupdateprofile, setOpenupdateprofile] = React.useState(false);
  const handleOpenupdateprofile = () => {
    setOpenupdateprofile(true);
  };
  const handleCloseupdateprofile = () => {
    setOpenupdateprofile(false);
  };
  const [openupdateprofileimage, setOpenupdateprofileimage] =
    React.useState(false);
  const handleOpenupdateprofileimage = () => {
    setOpenupdateprofileimage(true);
  };
  const handleCloseupdateprofileimage = () => {
    setOpenupdateprofileimage(false);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const [value, setValue] = useState("");

  const userdata = JSON.parse(localStorage.getItem("userData"));

  const [data, setData] = useState([]);
  const [areadata, setAreaData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [itemsToShow, setItemsToShow] = useState(8);

  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/allClub")
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterValue(value);
    applyFilter(value);
  };

  const applyFilter = (value) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };
  const options1 = data.map((item) => ({
    value: item.name.toLowerCase().replace(/\s+/g, "-"), // Example: 'event-1'
    label: item.name,
  }));

  
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [cartdata, setCartData] = useState([]);

  useEffect(() => {
    if (userdata) {
      const userid = new FormData();
      userid.append("user_id", userdata.id);

      axios
        .post(
          "https://partywitty.com/master/Api/getDataPost/getUserCart",
          userid
        )
        .then((response) => {
          if (response.data.status === "true") {
            setCartData([]);
          } else {
            setCartData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleSubmitupdateprofileimage = async (e) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userdata.id);
      formData.append("img", updateprofile);

      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/updateProfileImage",
        formData
      );

      setResponse(response.data);

      if (response.data.status === true) {
        localStorage.setItem("userData", JSON.stringify(response.data.data));

        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
   
    const loginData = localStorage.getItem("userData");
    const timestamp = localStorage.getItem("loginTimestamp");

    if (loginData && timestamp) {
      const loginTime = new Date(timestamp);
      const currentTime = new Date();

      const timeDifference = currentTime - loginTime;

      const timeLimit = 24 * 60 * 60 * 1000;

      if (timeDifference > timeLimit) {
        localStorage.removeItem("userData");
        localStorage.removeItem("loginTimestamp");
      }
    }
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getAreaLead")
      .then((response) => {
        setAreaData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleOpenPlanEvent4 = () => {
    if (userdata == null) {
      setShowLoginPopup(true);
    }
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        width: "200px",
        height: "100%",
        backgroundColor: "white",
        color: "black",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div className="menuu">
                    {userdata === null ? (
                      <button
                        className="signup-button2"
                        onClick={handleOpenPlanEvent4}
                      >
                        Login/SignUp
                      </button>
                    ) : (
                      <div className="my-account--profile-name">
                        <>
                          <div>
                            <img src={url + userdata.img} alt="user"></img>
                          </div>
                          <div>
                            <p className="my-account--profile-name--1">
                              {userdata.name}
                            </p>
                            <p className="my-account--profile-name--1--2">
                              User Profile
                            </p>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <Link
                    to={"/my--booking"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="ham--profile">
                      <img src={eventicon} alt="favorites"></img>
                      <p className="m-0">My Booking</p>
                    </div>
                  </Link>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <Link
                  to={"./booking-history"}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="ham--profile">
                    <img src={transactionhistory} alt="history"></img>
                    <p className="m-0">Transaction History</p>
                  </div>
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <Link
                    to={"/my-Favorite--ham"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="ham--profile">
                      <img src={hearticon} alt="favorites"></img>
                      <p className="m-0">My Favorites</p>
                    </div>
                  </Link>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <Link
                    to={"/my--profile"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="ham--profile">
                      <img src={userprofile} alt="favorites"></img>
                      <p className="m-0">My Profile</p>
                    </div>
                  </Link>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <Link
                    to={"/my--Notification"}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <div className="ham--profile">
                      <img src={Notification} alt="notification"></img>
                      <p className="m-0">Notification</p>
                    </div>
                  </Link>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div className="my--account--Setting">
                    <div
                      className="my--account--Setting--dropdown"
                      onClick={handleDropdownToggle}
                    >
                      <div className="my--account--Setting--dropdown1">
                        <img src={Settings} alt="Settings"></img>
                        <p>Setting</p>
                      </div>
                      <span>
                        <RiArrowDropDownLine />
                      </span>
                    </div>
                    {isDropdownOpen && (
                      <div className="my--account--Setting--dropdown--content">
                        <ul>
                          <li
                            onClick={() => handleItemClick("Edit Profile")}
                            style={{
                              color:
                                selectedItem === "Edit Profile"
                                  ? "#317BFF"
                                  : "",
                            }}
                          >
                            Edit Profile
                          </li>
                        </ul>
                        <div className="my--profile--setting--dropdown--borderline"></div>

                        <ul>
                          <li
                            onClick={() => handleItemClick("Payments")}
                            style={{
                              color:
                                selectedItem === "Payments" ? "#317BFF" : "",
                            }}
                          >
                            Payments
                          </li>
                        </ul>
                        <div className="my--profile--setting--dropdown--borderline"></div>

                        <ul>
                          <li
                            onClick={() => handleItemClick("Password")}
                            style={{
                              color:
                                selectedItem === "Password" ? "#317BFF" : "",
                            }}
                          >
                            Password
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div
                    className="ham--profile1"
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = "/";
                    }}
                  >
                    <img src={Signout} alt="signout"></img>
                    <p className="m-0">Sign Out</p>
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <div className="my--account--help">
                    <div className="my-account--profile-name1">
                      <div>
                        <img src={WhatsAppicon} alt="user"></img>
                      </div>
                      <div>
                        <p className="my-account--profile-name--1">
                          Help Center
                        </p>
                        <p className="my-account--profile-name--1--2">
                          Answer Here
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
  };
  const [isDropdownFocused, setDropdownFocused] = useState(false);
  const inputRef = useRef();

  const handleDropdownClick = () => {
    setDropdownFocused(!isDropdownFocused);
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const handleInputClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const [selectedOption, setSelectedOption] = useState("All");
  const [isDropdownOpen1, setDropdownOpen1] = useState(false);

  const options = ["All", "Events", "Package", "Party"];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };
  const [checkbox, setCheckbox] = useState("");

  const [currentlocationname, setCurrentlocationname] = useState("");

  const getEighteenYearsAgoDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };
  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    latitude: "",
    longitude: "",
  });

  const handleLocationChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedLocationData = areadata[selectedIndex - 1];

    setSelectedLocation({
      name: selectedLocationData.name,
      latitude: selectedLocationData.latitude,
      longitude: selectedLocationData.longitude,
    });

    localStorage.setItem("selectedLatitude", selectedLocationData.latitude);
    localStorage.setItem("selectedLongitude", selectedLocationData.longitude);
  };


  return (
    <>
      {showLoginPopup && <Loginpopup />}
      <div className="homepage--banner--search--web--view">
      <div className="homepage--search--caraousal-1">
        <div className="homepage--search--caraousal">
          <img src={tablebookingbanner}></img>
        </div>
        <div className="homepage--search--content">
          <div className="homepage--search--content--1">
          <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
            <p className="homepage--search--content--1--p1">GET THE APP</p>
            </Link>
            {userdata === null ? (
              <p
                className="homepage--search--content--1--p2"
                onClick={handleOpenPlanEvent4}
              >
                Log in / Sign Up
              </p>
            ) : (
              <div
                className="profile_icon"
                data-toggle="tooltip"
                title="My Account"
              >
                <p onClick={handleClick}>{userdata.name}</p>
                <img
                  onClick={handleClick}
                  src={url + userdata.img}
                  alt="image"
                ></img>
              </div>
            )}
          </div>

          <div className="homepage--search--all--logo--search">
            <img src={logo}></img>
            <p className="homepage--search--all--logo--search--p">Discover the best Table Booking Reservations In Delhi NCR</p>
            <SearchbarHomepage/>
          </div>
        </div>
      </div>
      </div>
   
      <div className="homepage--banner--search--mobile--view">
      <div className="homepage--search--caraousal-1">
      <div className="homepage--search--caraousal">
          <img src={tablebookingbanner}></img>
        </div>
        <div className="homepage--search--content">
          <div className="homepage--search--content--1">
          <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
            <p className="homepage--search--content--1--p1">GET THE APP</p>
            </Link>
         <div>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)}>
                {" "}
                <div className={`menu-toggle ${isOpen ? "open" : ""}`}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              </Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
       </div>

          <div className="homepage--search--all--logo--search">
            <img src={logo}></img>
            <p className="homepage--search--all--logo--search--p">Discover the best Table Booking Reservations In Delhi NCR</p>
            <SearchbarHomepage/>
          </div>
        </div>
        </div>
    
      </div>
      <div>
     
        <div>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMyAccount}
            onClose={handleCloseMyAccount}
          >
            <MenuItem onClick={handleCloseMyAccount} disableRipple>
              <div className="my-account--payment">
                <div className="my-account--payments">
                  <div className="my-account--profile-name">
                    {userdata === null ? null : (
                      <>
                        <div>
                          <img src={url + userdata.img}></img>
                        </div>
                        <div>
                          <p className="my-account--profile-name--1">
                            {userdata.name}
                          </p>
                          <p className="my-account--profile-name--1--2">
                            User Profile
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <ul class="no-bullets">
                    
                    <li>
                      <Link
                        to={"./booking-history"}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="my-account--payment--wallet">
                          <img src={History} />
                          <p>Transaction History</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"./favourite-club"}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="my-account--payment--wallet">
                          <img src={MyFavorites} />
                          <p>My Favorites</p>
                        </div>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <div className="my--account--Setting">
                <div
                  className="my--account--Setting--dropdown"
                  onClick={handleDropdownToggle}
                >
                  <div className="my--account--Setting--dropdown1">
                    <img src={Settings} alt="Settings"></img>
                    <p>Setting</p>
                  </div>
                  <span>
                    <RiArrowDropDownLine />
                  </span>
                </div>
                {isDropdownOpen && (
                  <div className="my--account--Setting--dropdown--content">
                    <ul>
                      <li
                        onClick={() => handleItemClick("Edit Profile")}
                        style={{
                          color:
                            selectedItem === "Edit Profile" ? "#317BFF" : "",
                        }}
                      >
                        Edit Profile
                      </li>
                    </ul>
                    <div className="my--profile--setting--dropdown--borderline"></div>

                    <ul>
                      <li
                        onClick={() => handleItemClick("Payments")}
                        style={{
                          color: selectedItem === "Payments" ? "#317BFF" : "",
                        }}
                      >
                        Payments
                      </li>
                    </ul>
                    <div className="my--profile--setting--dropdown--borderline"></div>

                    <ul>
                      <li
                        onClick={() => handleItemClick("Password")}
                        style={{
                          color: selectedItem === "Password" ? "#317BFF" : "",
                        }}
                      >
                        Password
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <div className="my--account--sign-out">
                <div className="my--account--sign-out1">
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                </div>
                <p
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = "/";
                  }}
                  className="my-account--payment--p"
                >
                  {" "}
                  Sign out
                </p>
              </div>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <div className="my-account--profile-name1">
                <div>
                  <img src={WhatsAppicon} alt="user"></img>
                </div>
                <div>
                  <p className="my-account--profile-name--1">Help Center</p>
                  <p className="my-account--profile-name--1--2">Answer Here</p>
                </div>
              </div>
            </MenuItem>
          </StyledMenu>
          <Modal
            open={openupdateprofile}
            onClose={handleCloseupdateprofile}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="responsive-modal">
              <div className="login-content1">
                <h3>Update Profile.</h3>
                <div className="login-content1--text-field">
                  <Box
                    sx={{
                      alignItems: "center",
                      "& > :not(style)": { m: 1 },
                    }}
                  >
                    <TextField
                      className="input-field"
                      required
                      helperText=""
                      id="demo-helper-text-aligned"
                      type="text"
                      label="Name"
                      value={updatename}
                      onChange={(e) => setUpdateName(e.target.value)}
                    />

                    <TextField
                      className="input-field"
                      helperText=""
                      type="email"
                      required
                      id="demo-helper-text-aligned"
                      label="Email"
                      value={updateemail}
                      onChange={handleEmailChangevalue1}
                    />

                    <div className="login-close-button">
                      <label htmlFor="terms" className="plan-yourevent-div">
                        <input
                          type="checkbox"
                          id="terms"
                          name="terms"
                          required
                        />
                        I agree to the terms and conditions
                      </label>
                    </div>
                    <div className="signup--submit--button">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmitupdateprofile();
                        }}
                      >
                        Update Profile
                      </button>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="login-otp-close-button">
                <Button onClick={handleCloseupdateprofile}>
                  <p>X</p>
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal
            open={openupdateprofileimage}
            onClose={handleCloseupdateprofileimage}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="responsive-modal">
              <div className="login-content1">
                <h3>Update Profile Images.</h3>
                <div className="login-content1--text-field">
                  <Box
                    sx={{
                      alignItems: "center",
                      "& > :not(style)": { m: 1 },
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setUpdateProfile(e.target.files[0])}
                    />

                    <div className="login-close-button">
                      <label htmlFor="terms" className="plan-yourevent-div">
                        <input
                          type="checkbox"
                          id="terms"
                          name="terms"
                          required
                        />
                        I agree to the terms and conditions
                      </label>
                    </div>
                    <div className="signup--submit--button">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmitupdateprofileimage();
                        }}
                      >
                        Update Profile
                      </button>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="login-otp-close-button">
                <Button onClick={handleCloseupdateprofileimage}>
                  <p>X</p>
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      
      </div>
    </>
  );
}
