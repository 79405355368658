import "./eventview.css";
import { Link } from "react-router-dom";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Carousel } from "antd";
import { Helmet } from "react-helmet-async";

export default function EventViewAll() {
  const url = "https://partywitty.com/master/assets/uploads/";
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);
  const categoriesApiUrl = "https://partywitty.com/master/Api/getData/eventCategory";
  const allEventsApiUrl = "https://partywitty.com/master/APIs/ClubEvent/allEvents";

  const categoryWiseEventsApiUrl = "https://partywitty.com/master/APIs/ClubEvent/categoryWiseAllEvents";

  useEffect(() => {
    fetch(categoriesApiUrl)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
          fetchAllEvents(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  const fetchAllEvents = (latitude, longitude) => {
    const formData = new FormData();
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    setIsLoading(true);
    fetch(allEventsApiUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.status === true) {
          setEvents(data.data);
        } else {
          setEvents([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching all events:", error);
      });
  };
  const fetchEventsByCategory = (latitude, longitude, categoryId) => {
    const formData = new FormData();
    formData.append("category_id", categoryId);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    setIsLoading(true);
    fetch(categoryWiseEventsApiUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.status === true) {
          setEvents(data.data);
        } else {
          setEvents([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching events by category:", error);
      });
  };
  const handleCategoryClick = (categoryId) => {
    if (selectedCategory !== categoryId) {
      setSelectedCategory(categoryId);
      fetchEventsByCategory(latitude, longitude, categoryId);
    }
  };
  const handleMouseEnter = (videoId) => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    videoId.play();
    videoRef.current = videoId;
  };
  const handleMouseLeave = (videoId) => {
    videoId.pause();
    videoRef.current = null;
  };
 const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };
 useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);
  const fetchURL = async () => {
    try {
      const response = await axios.get("https://partywitty.com/master/APIs/Common/getWebPromotionalBanner");
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchURL();
  }, []);
  const filteredData = data.filter((banner) => banner.page_name === "Event" && banner.position === "partywitty");
  const eventUrl = (e) => {
    return `/${(e.cityName?.replace(/ /g, "-") || "").toLowerCase()}/${(e.areaName?.replace(/ /g, "-") || "").toLowerCase()}/${(e.clubName?.trim().split(/ +/).join("-").replace(/ /g, "-") || "").toLowerCase()}/event/${(e.name?.trim().split(/ +/).join("-").replace(/ /g, "-") || "").toLowerCase()}`;
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.partywitty.com/party-package-delhi-ncr-view-all" />
        <title> Party Witty | Event </title>
        <meta name="description" content="Are You Looking  for the Best Party Packages in Delhi Ncr With Price? Party Witty Will Provide You With a Discover and Book Perfect Party Planner Venue Online" />
      </Helmet>
      <div className="event-view-all">
        <div className="Artist-viewall-banner">
          <Carousel autoplay autoplaySpeed={6000}>
            {filteredData.map((item) => (
              <div key={item.id} className="Artist-viewall-banner--img">
                <img src={url + item.default_img} alt="img" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="event-view-all-elements">
          <div className="event-view-tags-allcards">
            {categories.map((e) => (
              <div
                className={`event-view-tags ${selectedCategory === e.id ? "selected" : ""}`}
                key={e.id}
                onClick={() => handleCategoryClick(e.id)}
              >
                <div className="event-view-tags-card">
                  <img className="event-view-tags-card-event" src={url + e.img} alt="category list" />
                  <p>{e.title}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="event-view-allclubs">
            <div className="allevent-view-card">
              {isLoading ? (
                <span style={{ textAlign: "center", width: "100%", padding: "4rem", color: "#fff", textTransform: "uppercase" }}>
                  Loading...
                </span>
              ) : events.length === 0 ? (
                <span style={{ textAlign: "center", width: "100%", padding: "4rem", color: "#fff", textTransform: "uppercase" }}>
                  No Event Available
                </span>
              ) : (
                events.map((e, index) => (
                  <Link to={eventUrl(e)} key={e.id}>
                    <div className="event-view-card1">
                      {e.image ? (
                        <img src={url + e.image} alt={`Image ${index}`} />
                      ) : e.video ? (
                        <video
                          id={index}
                          controls
                          loop
                          onClick={toggleMute}
                          onMouseLeave={() => handleMouseLeave(document.getElementById(index))}
                        >
                          <source src={url + e.video} type="video/mp4" />
                        </video>
                      ) : (
                        <p>No content available</p>
                      )}
                      <div className="event-view-card1-content">
                        <div className="event-view-card1-content1">
                          <h4 className="p-0,m-0">{e.name}</h4>
                          <p className="event-view-card1-content1-p">{e.clubName}</p>
                          <p className="event-view-card1-content1-p1">{e.distance} km</p>
                        </div>
                        <div className="event-view-card1-content2">
                          <div className="date">
                            <span>
                              <AiTwotoneCalendar />
                            </span>{" "}
                            <p>{e.event_date}</p>
                          </div>
                          <div className="date">
                            <span>
                              <MdOutlineWatchLater />
                            </span>{" "}
                            <p>{e.event_time}</p>
                          </div>
                          <button className="event-view-all-button">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
