import "./Favorate--hangout.css";
import { useEffect, useState } from "react";
import axios from "axios";
import star from './image/star.png';
import banner from "./image/banner.png";
import location from "./image/location.png";
import "../Homepage/multyitem.css";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import toast, { Toaster } from "react-hot-toast";

export default function FavorateHangoutThumbnail() {
  const { pathname } = useLocation();
  const CITY_Name = pathname.split('/')[1].replace(/-/g, ' ');
  const [cityName, setCityName] = useState('');
  const [otherPackages1, setOtherPackages1] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [totalClub, setTotalClub] = useState();
  const [searchInput, setSearchInput] = useState('');
  const PackageMenu1 = new FormData();
  PackageMenu1.append("city_id", CITY_Name);
  PackageMenu1.append("platform", "web");
  PackageMenu1.append("keyword", searchInput);
  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.post(
          "https://partywitty.com/master/APIs/Common/cityWiseClub",
          PackageMenu1
        );
        const { msg, status } = response.data;
        if (status === false) {
          return toast.error(msg);
        }
        if (status === true) {
          setCityName(response.data.cityName);
          setOtherPackages1(response.data.data);
          setFilteredPackages(response.data.data);
          setTotalClub(response.data.totalClub);
        } else {
          console.log("No data found");
        }
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
    };
    fetchPackages();
  }, []);

  useEffect(() => {
    const filtered = otherPackages1.filter((pkg) =>
      pkg.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredPackages(filtered);
  }, [searchInput, otherPackages1]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>The 24 Best Table Booking Restaurants, bar, club, lounge in {cityName}</title>
        <meta name="description" content={`Book a Table Online in ${cityName} at Partywitty. Best Restaurant, bar, club, lounge Near You Quick, Easy and Free Table Reservation. Get Various Offers, Discounts, CashBack`} />
      </Helmet>
      <Toaster />
      <div className="favorate--hangout--city--wise--club--page">
        <div className="Favorate--Hangout--page">
          <div className="Favorate--Hangout--page--card">
            <div className="Favorate--Hangout--page--card-banner-img">
              <img src={banner} alt="liquor party packages online" />
            </div>
            <div className="Favorate--Hangout--page--card-banner-Search">
              <img src={location} />
              <p className="Favorate--Hangout--page--p">{cityName}</p>
              <p className="Favorate--Hangout--page--p2">{totalClub} Clubs</p>
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="Group--booking--view--all--card">
          <div className="Group--booking--view--all--left">
            <div className="Favorate--hangout--all--thumbnail">
              {filteredPackages.map((e) => (
                <Link
                  to={`/${CITY_Name.replace(/ /g, '-')}/${e.areaName.replace(/ /g, '-')}/${e.name.trim().split(/ +/).join('-').replace(/ /g, '-')}`}
                  key={e.id}
                  className="bookatable-routes"
                >
                  <div key={e.id} className="Favorate--hangout--all--thumbnail--card">
                    <div className="Favorate--hangout--all--thumbnail--card--img">
                      <img src={url + e.clubLogo} alt={cityName} />
                      <div className="Favorate--hangout--all--thumbnail--card--img--1"></div>
                      <div className="Favorate--hangout--all--thumbnail--card--2">
                        <div className="Favorate--hangout--all--thumbnail--card--2--1">
                          <div className="favorate--add--on">
                            <div className="favorate--add--on--card">
                              <p className="favorate--add--on--card--p">{e.addon}</p>
                              <p className="favorate--add--on--card--p--1">%</p>
                            </div>
                            <div className="favorate--add--on--p">
                              <p className="favorate--add--on--p--1">Add On</p>
                              <p className="favorate--add--on--p--2">EXTRA</p>
                            </div>
                          </div>
                          <div className="favorate--offered--card">
                            <p className="favorate--offered--card--1">Offered in</p>
                            <div className="favorate--offered--card--days">
                              <p>Last 30 Days</p>
                            </div>
                          </div>
                          <div className="favorate--add--on">
                            <div className="favorate--add--on--p">
                              <p className="favorate--add--on--p--1--1">Overall</p>
                              <p className="favorate--add--on--p--2--1">Discount</p>
                            </div>
                            <div className="favorate--add--on--card1">
                              <p className="favorate--add--on--card--p">{e.discount}</p>
                              <p className="favorate--add--on--card--p--1">%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Favorate--hangout--card--content">
                      <div className="Favorate--hangout--card--content--1">
                        <p className="Favorate--hangout--card--content--1--p1">{e.name.substring(0, 16)}...</p>
                        <p className="Favorate--hangout--card--content--1--p2">{e.category.substring(0, 16)}...</p>
                      </div>
                      <div className="favorate--hangout--address">
                        {e.totalRating !== 0 ? (
                          <div className="favorate--hangout--review--card1">
                            <div className="favorate--hangout--review--card">
                              <img src={star} alt="Star Icon" />
                              <p>{e.totalAverageRating}</p>
                            </div>
                          </div>
                        ) : (
                          <div className="favorate--hangout--review--card5"></div>
                        )}
                        {e.distance != 0 &&  <p className="favorate--hangout--address--p2">{e.distance} km</p> }
                        
                        <p className="favorate--hangout--address--p2">{e.address.substring(0, 16)}...</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
