import './Agreement.css';


export default function RefundPolicy() {
    return (
        <div class="clubagreement pt-5">
            <section class="main--feacture tc--section pt-5">
                <div class="main--box">
                    <h2> Cancellation & Return - Amirag Hospitality OPC Pvt Ltd - PartyWitty</h2>
                    <p>Legal Entity: Amirag Hospitality OPC Pvt Ltd</p>
                    <p>Service: PartyWitty</p>

                    <h2>Cancellation Policy</h2>
                    <p>Our SaaS cancellation policy outlines the terms and conditions for cancelling your subscription to PartyWitty:</p>
                    <ul>
                        <li>Cancellations must be requested within the specified cancellation period.</li>
                        <li>Refunds will be processed according to the refund policy.</li>
                        <li>For detailed cancellation procedures, please refer to our Terms of Service.</li>
                    </ul>

                    <h2>Return Policy</h2>
                    <p>Our SaaS return policy governs the return of any unused services or subscriptions:</p>
                    <ul>
                        <li>Returns are only accepted within a certain timeframe after purchase.</li>
                        <li>Returns must adhere to the guidelines set in our Terms of Service.</li>
                        <li>Refunds will be provided according to our refund policy.</li>
                    </ul>

                    <p>Please review our complete Terms of Service and policies for more information on SaaS cancellations and returns.</p>
                    {/* <h2>Return & Refund Policy of <span class="website_url">www.partywitty.com</span></h2>

                    <p>If you are not happy with your purchase, we will accept a return of a unused product within 14 days. Once we receive the returned item <span class="website_name">www.partywitty.com</span> will then give a full refund (excluding shipping as we are unable to refund the initial shipping cost of your order). Our Return & Refund Policy was created by <a href="https://www.returnrefundpolicytemplate.com">Return Refund Policy Template</a> for <span class="website_url">www.partywitty.com</span></p>

                    <p>Please allow 1-2 weeks for your return to be processed.</p>

                    <p>Discounted items are not eligible for a return.</p>

                    <p><span class="website_name">www.partywitty.com</span> will not issue refunds for products purchased through other entities, such as distributors or retail partners.</p>

                    <p>Returned items must be delivered to us unused, in original packaging and in the condition they were received or may not be eligible for refund or be subject to a restocking fee. We cannot be held responsible for items damaged or lost in return shipment, therefore we recommend an insured and trackable mail service.</p>

                    <p>We are unable to issue a refund without actual receipt of the item(s) or proof of received return delivery.</p>

                    <p>We aim to accept all returns. In the unlikely event that an item is returned to us in an unsuitable condition, we may have to send it back to you. All goods will be inspected on return.</p> */}
                </div>
            </section>


        </div>
    );
}