

import React, { createContext, useContext, useState } from "react";

const SelectedItemContext = createContext();


export const useSelectedItem = () => useContext(SelectedItemContext);


export function SelectedItemProvider({ children }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const toggleDetail = () => {
    setIsDetailVisible(!isDetailVisible);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  const [itemCount, setItemCount] = useState(0);


  const incrementCount = () => {
    setItemCount(itemCount + 1);
  };

  
  const decrementCount = () => {
    if (itemCount > 0) {
      setItemCount(itemCount - 1);
    }
  };

  return (
    <SelectedItemContext.Provider
      value={{
        selectedItem,
        toggleDetail,
        isDetailVisible,
        handleItemClick,
        itemCount,
        incrementCount,
        decrementCount,
      }}
    >
      {children}
    </SelectedItemContext.Provider>
  );
}
