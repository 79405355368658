import React from 'react';
import menu from '../AboutClub/image/menu.jpg';
import { IoIosHeart } from "react-icons/io";

const Event = ()=>{
    return(
    <div >
    <p className='club-menu-p'>Event</p>
    <div className='all-club-menu'>
        <div className='club-detail-event-card'>
        <div className='event-card-img'>
            <img src={menu} alt='img'></img>
            <div className='club-detail-favorite-recommened'>
            <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='event-card-content'>
          <div className='event-card-content-name'>
            <p className='event-card-name'>Atif Creative...</p>
            <p className='event-card-location'>Venue Location</p>
          </div>
          <div className='event-date-card'>
            <p className='event-date-card-p'>17</p>
            <p className='event-date-card-p1'>July</p>
          </div>
        </div>
        </div>
        <div className='club-detail-event-card'>
        <div className='event-card-img'>
            <img src={menu} alt='img'></img>
            <div className='club-detail-favorite-recommened'>
            <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='event-card-content'>
          <div className='event-card-content-name'>
            <p className='event-card-name'>Atif Creative...</p>
            <p className='event-card-location'>Venue Location</p>
          </div>
          <div className='event-date-card'>
            <p className='event-date-card-p'>17</p>
            <p className='event-date-card-p1'>July</p>
          </div>
        </div>
        </div>
        <div className='club-detail-event-card'>
        <div className='event-card-img'>
            <img src={menu} alt='img'></img>
            <div className='club-detail-favorite-recommened'>
            <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='event-card-content'>
          <div className='event-card-content-name'>
            <p className='event-card-name'>Atif Creative...</p>
            <p className='event-card-location'>Venue Location</p>
          </div>
          <div className='event-date-card'>
            <p className='event-date-card-p'>17</p>
            <p className='event-date-card-p1'>July</p>
          </div>
        </div>
        </div>
        <div className='club-detail-event-card'>
        <div className='event-card-img'>
            <img src={menu} alt='img'></img>
            <div className='club-detail-favorite-recommened'>
            <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='event-card-content'>
          <div className='event-card-content-name'>
            <p className='event-card-name'>Atif Creative...</p>
            <p className='event-card-location'>Venue Location</p>
          </div>
          <div className='event-date-card'>
            <p className='event-date-card-p'>17</p>
            <p className='event-date-card-p1'>July</p>
          </div>
        </div>
        </div>
        <div className='club-detail-event-card'>
        <div className='event-card-img'>
            <img src={menu} alt='img'></img>
            <div className='club-detail-favorite-recommened'>
            <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='event-card-content'>
          <div className='event-card-content-name'>
            <p className='event-card-name'>Atif Creative...</p>
            <p className='event-card-location'>Venue Location</p>
          </div>
          <div className='event-date-card'>
            <p className='event-date-card-p'>17</p>
            <p className='event-date-card-p1'>July</p>
          </div>
        </div>
        </div>
    </div>   
   </div>
    );
}
export default Event;