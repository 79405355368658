import styles from './Menu.module.css';
import Card from './card';

function index({ MenuDetails, headLine, url ,setImageView}) {

    return (
        <div className={styles.CardContainer} id="menu">
            <h2>{headLine}</h2>
            <div className={styles.Menucontent}>
                {MenuDetails?.map((item) => {
                    return (
                        <Card MenuDetails={item} headLine={headLine} url={url} setImageView={setImageView}/>
                    )
                })}
            </div>
        </div>
    )
}

export default index