import React from "react";
import "./Clubpage.css";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { BiRupee } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
function Dealsoffer() {
  return (
    <div class="deals-card">
      <div class="fst-part">
        <p class="p-fst-card">188 Bought</p>
        <h4 class="h4">4IMFL(30ml)/4 BEAR + 1 Starter/Pint</h4>

        <p class="green">Free Cancellation</p>
        <div class="deal-info">
          <div>
            <p>Valid on</p>
            <p>Timeing</p>
            <p>Valid</p>
          </div>
          <div>
            <p>All Days</p>
            <p>
              12:00 PM - 12:00 AM{" "}
              <span>
                <MdOutlineArrowDropDownCircle />
              </span>
            </p>
            <p>2 people</p>
          </div>
        </div>
        <div class="deals-and-offers-btn">
          <button class="deals-btn">Inclusion</button>
          <button class="deals-btn">Details</button>
        </div>
      </div>
      <div class="scond-part1">
        <div className="spart">
       
          <button class="scond-part-btn">
            <p>56% OFF</p>
          </button>
          <p class="scond-part-price">
            <BiRupee /> 4500/-
          </p>

          <p class="p-fst-card">Inc. of all taxes</p>
        </div>

  
        <div>
          <button class="add">ADD +</button>
        </div>
      </div>
    </div>
  );
}

export default Dealsoffer;
