import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import axios from "axios";


export default function EventCard() {
  const { city, area, clubname } = useParams();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clubName, setClubName] = useState('');
  const url = "https://partywitty.com/master/assets/uploads/";

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubname);

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        const eventData = response.data.data.events || [];
        const fetchedClubName = response.data.data.name;
        console.log(fetchedClubName);
        setClubName(fetchedClubName);
        setEvents(eventData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [city, area, clubname]);

  const toggleMute = (e) => {
    e.target.muted = !e.target.muted;
  };

  const handleMouseLeave = (videoElement) => {
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };
  const eventUrl = (e) => {
    return `/${(e.cityName?.replace(/ /g, "-") || "").toLowerCase()}/${(
      e.areaName?.replace(/ /g, "-") || ""
    ).toLowerCase()}/${(
      clubName?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}/event/${(
      e.name?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}`;
  };
  

  return (
    <div className="event-view-allclubs">
      <div className="allevent-view-card--8">
        {isLoading ? (
         <span style={{
            textAlign: 'center',
            width: '100%',
            padding: '4rem',
            color: '#fff',
            textTransform: 'uppercase'
          }}>
            Loading...
          </span>
        ) : events.length === 0 ? (
            <span style={{
                textAlign: 'center',
                width: '100%',
                padding: '4rem',
                color: '#fff',
                textTransform: 'uppercase'
              }}>
            No Event Available
          </span>
        ) : (
          events.map((e, index) => (
            <Link
              to={eventUrl(e)}
              style={{ textDecoration: "none" }}
              key={e.id}
            >
              <div className="event-view-card1">
                {e.image ? (
                  <img src={url + e.image} alt={`Image ${index}`} />
                ) : e.video ? (
                  <video
                    id={`video-${index}`}
                    width="100%"
                    height="260"
                    controls
                    loop
                    onClick={toggleMute}
                    onMouseLeave={() =>
                      handleMouseLeave(document.getElementById(`video-${index}`))
                    }
                  >
                    <source src={url + e.video} type="video/mp4" />
                  </video>
                ) : (
                  <p>No content available</p>
                )}
                <div className="event-view-card1-content">
                  <div className="event-view-card1-content1">
                    <h4>{e.name}</h4>
                    <p>{clubName}</p>
                  </div>
                  <div className="event-view-card1-content2">
                    <div className="date">
                      <span>
                        <AiTwotoneCalendar />
                      </span>{" "}
                      <p>{e.event_date}</p>
                    </div>
                    <div className="date">
                      <span>
                        <MdOutlineWatchLater />
                      </span>{" "}
                      <p>{e.event_time}</p>
                    </div>
                    <button className="event-view-all-button">
                      Book Now
                    </button>
                  </div>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}
