import React from 'react';
import club from '../AboutClub/image/club.jpg';
import { FaStar } from "react-icons/fa";
import { IoIosHeart } from "react-icons/io";

export default function RecommenedClub(){
    return(
        <div className="about-club-details">
        <div className="about-club-detail-collapse">
        <p>Recommened Clubs</p>
        </div>
        <div className='all-club-menu'>
        <div className='club-detail-recommened-card'>
        <div className='club-detail-recommened-card-img'>
        <img src={club} alt='img' ></img>
        <div className='club-detail-favorite-recommened'>
        <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='club-detail-recommened-card-content'>
             <div>
                <p className='recommmened-club-name'>Club Name</p>
                <p className='recommmened-club-category'>Restaurants & Bar</p>
                <p className='recommmened-club-address'>Address of Club</p>
             </div>
             <div className="club-rating-card-recommended">
           <p>4.8</p><span><FaStar /></span> 
             </div>
        </div>
        </div>
        <div className='club-detail-recommened-card'>
        <div className='club-detail-recommened-card-img'>
        <img src={club} alt='img' ></img>
        <div className='club-detail-favorite-recommened'>
        <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='club-detail-recommened-card-content'>
             <div>
                <p className='recommmened-club-name'>Club Name</p>
                <p className='recommmened-club-category'>Restaurants & Bar</p>
                <p className='recommmened-club-address'>Address of Club</p>
             </div>
             <div className="club-rating-card-recommended">
           <p>4.8</p><span><FaStar /></span> 
             </div>
        </div>
        </div>
        <div className='club-detail-recommened-card'>
        <div className='club-detail-recommened-card-img'>
        <img src={club} alt='img' ></img>
        <div className='club-detail-favorite-recommened'>
        <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='club-detail-recommened-card-content'>
             <div>
                <p className='recommmened-club-name'>Club Name</p>
                <p className='recommmened-club-category'>Restaurants & Bar</p>
                <p className='recommmened-club-address'>Address of Club</p>
             </div>
             <div className="club-rating-card-recommended">
           <p>4.8</p><span><FaStar /></span> 
             </div>
        </div>
        </div>
        <div className='club-detail-recommened-card'>
        <div className='club-detail-recommened-card-img'>
        <img src={club} alt='img' ></img>
        <div className='club-detail-favorite-recommened'>
        <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='club-detail-recommened-card-content'>
             <div>
                <p className='recommmened-club-name'>Club Name</p>
                <p className='recommmened-club-category'>Restaurants & Bar</p>
                <p className='recommmened-club-address'>Address of Club</p>
             </div>
             <div className="club-rating-card-recommended">
           <p>4.8</p><span><FaStar /></span> 
             </div>
        </div>
        </div>
        <div className='club-detail-recommened-card'>
        <div className='club-detail-recommened-card-img'>
        <img src={club} alt='img' ></img>
        <div className='club-detail-favorite-recommened'>
        <span><IoIosHeart /></span>
        </div>
        </div>
        <div className='club-detail-recommened-card-content'>
             <div>
                <p className='recommmened-club-name'>Club Name</p>
                <p className='recommmened-club-category'>Restaurants & Bar</p>
                <p className='recommmened-club-address'>Address of Club</p>
             </div>
             <div className="club-rating-card-recommended">
           <p>4.8</p><span><FaStar /></span> 
             </div>
        </div>
        </div>
        </div>
        </div>
    );
}