import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Favorate--hangout.css";
import filterimg from "./image/filterimg.png";
import star from "./image/star.png";
import Carousel from "react-bootstrap/Carousel";
import banner from "./image/banner.png";
import banner1 from "./image/banner1.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import logo from "../Assets/logo.png";
import { LuSearch } from "react-icons/lu";
import VisitingGroupBooking from "../Visiting--table-venue/Visiting--group--booking";
import SearchbarHomepage from "../Homepage/Search--Homepage";
import { Helmet } from "react-helmet-async";
import { Base64 } from "js-base64";
import LazyLoad from "react-lazy-load";

export default function BookATableViewAll() {
  const { id } = useParams();

  const [venueData, setVenueData] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState({
    2: "Restaurant & Bar",
  });
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [selectedMenuIds, setSelectedMenuIds] = useState([]);
  const [data, setData] = useState([]);
  const [isReadmore, setReadmore] = useState(false);
  const [limit, setLimit] = useState(18);
  const url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/ClubPackage/getVenuType")
      .then((response) => {
        setVenueData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async (lat, long) => {
      try {
        const formData = new FormData();
        formData.append("latitude", lat);
        formData.append("longitude", long);
        formData.append("limit", limit.toString());
        formData.append("venue_id", selectedMenuIds.join(","));

        const response = await axios.post(
          "https://partywitty.com/master/APIs/Common/allClubNew",
          formData
        );

        if (response.data.status === true) {
          setData(response.data.data);
         
        } else {
          setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude.toString());
            setLongitude(longitude.toString());
            fetchData(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not available in this browser.");
      }
    };
    getLocation();
  }, [limit, selectedMenuIds]);

  
  const Targetting_content = `Table booking is a handy service that allows customers to book a table at any venue in advance which is made more convenient when you book your table through Party Witty. As it offers the best venues placed all over Noida, Ghaziabad, Faridabad, Gurgaon, Delhi . Booking table across Delhi,Ncr through Party Witty gives its users multiple offers on each venue enlisted. The different venue categories for the table booking service are:
  Clubs- Party Witty has the most trendiest cafes located across Delhi,Ncr with more than 500+ clubs on board.These clubs are social hubs where people gather to socialize, network, and enjoy various activities. We offer many discounts here for table booking and give you the best. 
  Cafes- Cafes, which are pleasant retreats where people hang out to enjoy flavorful food and drinks in a laid-back setting, become even more comfortable when patrons utilize Party Witty to book a table and take advantage of extra offers, saving them time and effort in the process of searching. 
  Lounges- Lounges are sophisticated venues known for their upscale ambiance, comfortable seating, and premium offerings which when tie-up with Party Witty becomes more affordable and unique with their offers. 
  Restaurants and bar-
  Fine-dine- Fine-dines when clubbed with Party Witty get distinctive with its special discounts and offered a refined dining experience, often featuring gourmet dishes, curated wine lists, and meticulous attention to detail, making them ideal for special occasions or indulgent evenings out.
  BYOB- BYOB (Bring Your Own Bottle) refers to a dining or social setting where patrons are encouraged to bring their own alcoholic beverages. This concept allows guests to enjoy their favorite drinks while dining out, often saving on costs and ensuring a personalized experience and also when the users get extra offers on booking table through Party Witty the fun gets doubled. 
  Experience the ultimate convenience of table booking by booking your table through Party Witty. With just a few taps, you can secure your table at your favorite restaurants, ensuring a seamless and enjoyable dining experience. Don't wait in line or deal with last-minute reservations – take control of your dining plans and make every meal a delightful one with our hassle-free table booking service. Download our app today and elevate your table booking experience like never before!
`;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSeeMore = () => {
 
    setLimit(limit + 18);
  };

  const handleMenuClick = (venue) => {
    const updatedSelectedMenus = { [venue.id]: venue }; 
    setSelectedMenus(updatedSelectedMenus);

   
    setSelectedMenuIds([venue.id]);
  };

  

  return (
    <>
      <Helmet>
        <title>
          Party Witty: Table Booking in Delhi NCR – Book Your Table in
          Restaurants
        </title>
        <meta
          name="description"
          content="Party Witty is a Free Table Reservation & Booking Table Portal.  Best Deals & Offers Veg & Non Veg Food.  Book a Table Across Best Restaurants in Delhi Ncr."
        />
      </Helmet>
      <VisitingGroupBooking />
      <div className="Favorate--Hangout--page">
        <div className="Favorate--Hangout--page--card">
          <div className="Favorate--Hangout--page--card-banner-img">
            <img src={banner} alt="Main banner" />
          </div>
          <div className="Favorate--Hangout--page--card-banner-Search8">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo} />
            </Link>
            <h1 className="Favorate--Hangout--page--p1">
              Table booking at 500+ best club in Delhi, Ncr
            </h1>
           
          </div>
        </div>
      </div>
      <div className="Book--a--table--view--all--gap">
        <div>
          <Carousel interval={6000} indicators={false}>
            <Carousel.Item className="slider_img">
              <img src={banner1} alt="img" />
            </Carousel.Item>
          </Carousel>
        </div>

       

        <div className="favorate--club--category--10">
          <div className="Favorate--club--category--8">
            <Slider {...settings}>
              {venueData.map((venue, index) => (
                <div
                  key={venue.id}
                  className={`Favorate--club--category--card ${
                    selectedMenus[venue.id] ? "selected2" : ""
                  }`}
                  onClick={() => handleMenuClick(venue)}
                >
                  <img
                    src={url + venue.img}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <p>{venue.name}</p>
                </div>
              ))}
            </Slider>
          </div>
          <div className="Favorate--club--category--8--mobile">
            <div className="services1">
              {venueData.map((venue, index) => (
                <div
                  key={venue.id}
                  className={`Favorate--club--category--card ${
                    selectedMenus[venue.id] ? "selected2" : ""
                  }`}
                  onClick={() => handleMenuClick(venue)}
                >
                  <img src={url + venue.img} alt="" />
                  <p>{venue.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="Group--booking--view--all--left">
        {data.length === 0 ? (
    <div className="package-not-available">
      <p className="package-not-available-h1">Sorry, This Club Partner Isn't Available Right Now 
      </p>
      <p className="package-not-available-p">We regret to inform you that the club partner you're looking for is currently unavailable. But don't worry – we’ve got a variety of fantastic alternatives just around the corner!

      </p>
      <p className="package-not-available-h1">Explore Nearby Clubs with Similar Options
 
      </p>
      <p className="package-not-available-p">We have handpicked a selection of nearby clubs that offer similar amenities and experiences. Plus, you can take advantage of exclusive deals and discounts! 

      </p>
    </div>
  ) : (
          <LazyLoad>
            <div className="Favorate--hangout--all--thumbnail">
              {data.map((item, index) => (
                <Link
                  to={`/${item.cityName
                    .trim()
                    .replace(/ /g, "-")
                    .toLowerCase()}/${item.areaName
                    .trim()
                    .replace(/ /g, "-")
                    .toLowerCase()}/${item.name
                    .trim()
                    .split(/ +/)
                    .join("-")
                    .replace(/ /g, "-")
                    .toLowerCase()}`}
                  className="bookatable-routes"
                  key={item.id}
                >
                  <div
                    className="Favorate--hangout--all--thumbnail--card"
                    key={item.club_id}
                  >
                    <div className="Favorate--hangout--all--thumbnail--card--img">
                      {item.clubThumbnail && item.clubThumbnail.length > 0 ? (
                        <img
                          className="Favorate--hangout--all--thumbnail--card--img--1--img"
                          src={url + item.clubThumbnail[0].img}
                          alt={`${item.name}`}
                        />
                      ) : (
                        <img
                          className="Favorate--hangout--all--thumbnail--card--img--1--img"
                          src={url + item.profile_pic}
                          alt=""
                        />
                      )}
                      <div className="Favorate--hangout--all--thumbnail--card--img--1">
                        {/* <div className="Favorate--hangout--all--thumbnail--card--img--1-2">
                      <p>Sponsered</p>
                    </div> */}
                      </div>

                      <div className="Favorate--hangout--all--thumbnail--card--2">
                        <div className="Favorate--hangout--all--thumbnail--card--2--1">
                          <div className="favorate--add--on">
                            <div className="favorate--add--on--card">
                              <p className="favorate--add--on--card--p">
                                {item.addon}
                              </p>
                              <p className="favorate--add--on--card--p--1">%</p>
                            </div>
                            <div className="favorate--add--on--p">
                              <p className="favorate--add--on--p--1">Add On</p>
                              <p className="favorate--add--on--p--2">EXTRA</p>
                            </div>
                          </div>
                          <div className="favorate--offered--card">
                            <p className="favorate--offered--card--1">
                              Offered in
                            </p>
                            <div className="favorate--offered--card--days">
                              <p>Last 30 Days</p>
                            </div>
                          </div>
                          <div className="favorate--add--on">
                            <div className="favorate--add--on--p">
                              <p className="favorate--add--on--p--1--1">
                                Overall
                              </p>
                              <p className="favorate--add--on--p--2--1">
                                Discount
                              </p>
                            </div>
                            <div className="favorate--add--on--card1">
                              <p className="favorate--add--on--card--p">
                                {item.discount}
                              </p>
                              <p className="favorate--add--on--card--p--1">%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Favorate--hangout--card--content">
                      <div className="Favorate--hangout--card--content--1">
                        <p className="Favorate--hangout--card--content--1--p1">
                          {" "}
                          {item.name.substring(0, 16)}...
                        </p>
                        <p className="Favorate--hangout--card--content--1--p2">
                          {item.venueType}
                        </p>
                        {/* <p className="Favorate--hangout--card--content--1--direction">
                      <img src={direction} alt="direction" /> Get Direction
                    </p> */}
                      </div>
                      <div className="favorate--hangout--address">
                        {item.totalRating > 0 && (
                          <div className="favorate--hangout--review--card1">
                            <div className="favorate--hangout--review--card">
                              <img src={star} alt="star" />
                              <p>{item.totalAverageRating}</p>
                            </div>
                          </div>
                        )}
                        <p className="favorate--hangout--address--p2">{item.distance} km</p>
                        <p className="favorate--hangout--address--p2">
                          {item.address.substring(0, 16)}...
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </LazyLoad>
  )}
          {data.length > 0 && (
            <div>
              <p
                className="favorate--hangout--search--result-1--1"
                onClick={handleSeeMore}
                style={{ cursor: "pointer" }}
              >
                See More
              </p>
            </div>
          )}

        
          <p style={{ color: "#fff" }}>
            {!isReadmore
              ? `${Targetting_content.slice(0, 350)}...`
              : Targetting_content}
          </p>
          <p
            onClick={() => setReadmore(!isReadmore)}
            style={{ textAlign: "center", color: "#fd2f71", cursor: "pointer" }}
          >
            read more
          </p>
        </div>
      </div>
    </>
  );
}
