import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import successgif from "./image/success.gif"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "fitcontent",
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



export default function ChildModal({ heading, Description, Open, setOpen }) {


  const handlePopup = (isOpen) => {
    setOpen(isOpen);
   
  };

  
  return (
    <>
      <Modal
        open={Open}
        onClose={() => handlePopup(true)}
        aria-labelledby="popup"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 id="child-modal-title">{heading}</h2>
          
          <dotlottie-player src="https://lottie.host/6b9fc96a-5c59-464a-ad26-5782e3a54022/EFWiS28KVM.json" background="transparent" speed="1" style={{width: "300px", height: "300px"}} loop autoplay></dotlottie-player>
          <p id="child-modal-description" style={{textAlign:'center'}}>
            {Description}
          </p>
          <Button onClick={() => handlePopup()}>Close</Button>
        </Box>
      </Modal>
    </>
  );
}
