import React from "react";
import "../../Club About Us/css/Style2.css";
import style from "./Gallery.module.css";

function Index({ GalleryData, url, setImageView }) {
    const { category, images, id } = GalleryData;

    // Construct an array of image URLs with full paths
    const imageUrls = images.map(item => `${url}${item.img}`);

    return (
        <div className={style.container}>
            <div className={style.gallery_item}>
                <div
                    className={style.image_container}
                    key={id}
                    onClick={() => setImageView({ isActive: true, imageData: imageUrls })}
                >
                    {/* Render the first image */}
                    {images.length > 0 && (
                        <img src={`${url}${images[0].img}`} alt={category} />
                    )}
                    {/* Render the overlay text with the total image count */}
                    <p className={style.overlay_text}>{images.length}+</p>
                </div>
            </div>
            <div className={style.category_title}>{category}</div>
        </div>
    );
}

export default Index;
