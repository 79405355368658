import styles from "./Booking.css";
import moment from "moment";
import axios from "axios";
import { Modal, Box } from "@material-ui/core";
import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { CovertString_TO_DateFormat } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import Googleplay from "./image/Googleplay.png";
import Applestore from "./image/appdownloads.png";

const useStyles12 = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const inputStyle = {
  width: "110px",
  padding: "0.5em",
  border: "none",
  background: "#1e1434",
  borderRadius: "5px",
  boxSizing: "border-box",
  marginBottom: "10px",
};

const selectStyle = {
  width: "180px",
  padding: "0.9em",
  borderRadius: "5px",
  background: "#1e1434",
  color: "rgb(180, 180, 180)",
  fontSize: "12px",
};

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B40431",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B40431",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B40431",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
  },
});
const useStyles1 = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-outlined": {
      color: "white",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "white",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "white",
    },
  },
});

function Index({ clubId }) {
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [remainingDates, setRemainingDates] = useState([]);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [cityId, setCityId] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [bookdata, setBookData] = useState([]);
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes12 = useStyles12();
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [citydata, setCityData] = useState([]);
  const [selectedAreaid, setSelectedAreaid] = useState(null);
  const [location12, setLocation12] = useState(""); // Location state
  const [leadarea, setLeadarea] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isTextFieldOpen, setIsTextFieldOpen] = useState(false);
  const handleTextFieldClick = () => {
    setIsTextFieldOpen(true);
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedVenu, setSelectedVenu] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedPax, setSelectedPax] = useState(null);
  const [isReserved, setReserved] = useState(false);
  const [cancel, setCancel] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

  const url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if (status === "success" && !isReserved) {
      setReserved(true);
    }
  }, [status, isReserved]);

  useEffect(() => {
    if (status === "failed" && !cancel) {
      setCancel(true);
    }
  }, [status, cancel]);

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
  };

  const handleAreaChange = (e) => {
    const selectedAreaId = e.target.value;
    const areaData = leadarea.find((area) => area.id === selectedAreaId);

    setSelectedArea(areaData);
    setSelectedAreaid(areaData.id);
  };

  const venueTypeIds = selectedVenu.map((venue) => venue.id).join(",");

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      club_id: clubId,
      city_id: cityId,
      area_id: selectedArea ? selectedArea.id : null,
      party_date: formattedDate,
      party_time: selectedTime,
      user_id: userdata ? userdata.id : "",
      venue_type: venueTypeIds,
      whatsapp: userdata ? userdata.whatsapp : null,
    }));
  }, [clubId, cityId, selectedArea, selectedTime, selectedVenu, formattedDate]);

  const [formData, setFormData] = useState({
    club_id: clubId,
    whatsapp: userdata ? userdata.whatsapp : null,
    whatsapp_msg: "true",
    city_id: cityId,
    area_id: selectedArea ? selectedArea.id : "",
    male: "",
    female: "",
    kids: "",
    party_date: formattedDate,
    party_time: selectedTime,
    lead_source: "website",
    user_id: userdata ? userdata.id : "",
    venue_type: venueTypeIds,
  });

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    today.setHours(0, 0, 0, 0);
    const initialStartTime = moment(startTime, "h:mm A").toDate();
    const initialEndTime = moment(endTime, "h:mm A").toDate();
    initialStartTime.setMinutes(
      Math.ceil(initialStartTime.getMinutes() / 30) * 30
    ); 
    const intervals = [];
    const remainingDates = [];
    for (let i = 0; i < 3; i++) {
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const lastDayOfMonth = new Date(
        currentYear,
        currentMonth + 1,
        0
      ).getDate();
      for (let j = today.getDate(); j <= lastDayOfMonth; j++) {
        const date = new Date(currentYear, currentMonth, j);
        remainingDates.push(date);
      }
      today.setMonth(currentMonth + 1);
      today.setDate(1); 
    }
    while (initialStartTime <= initialEndTime) {
      const formattedTime = moment(initialStartTime).format("HH:mm");
      intervals.push(formattedTime);
      initialStartTime.setMinutes(initialStartTime.getMinutes() + 30); 
    }
    setTimeIntervals(intervals);
    setRemainingDates(remainingDates);
  }, [startTime, endTime]);

  useEffect(() => {}, [startTime, endTime]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataObj.append(key, value);
    });
    try {
      const response = await axios.post(
        "https://partywitty.com/master/APIs/ClubTable/submitEnquiry",
        formDataObj
      );
      if (response.data.status === true) {
        setFormData({
          club_id: clubId,
          whatsapp: userdata ? userdata.whatsapp : null,
          whatsapp_msg: "true",
          city_id: "",
          area_id: "",
          male: "",
          female: "",
          kids: "",
          party_date: null,
          party_time: null,
          lead_source: "website",
          user_id: userdata ? userdata.id : "",
          venue_type: venueTypeIds,
        });
        alert("Form submitted successfully");
        setShowPopup(true);
      } else {
        alert(response.data.msg);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlePopupClose = () => {
    setShowPopup(false);
  };
  const [clubcategorydata, setClubcategorydata] = useState([]);
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/ClubPackage/getVenuType")
      .then((response) => {
        const clubCategories = response.data.data;
        setClubcategorydata(clubCategories);
        setSelectedVenu(clubCategories);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const apiUrl = "https://partywitty.com/master/Api/getDataPost/AreaLead";
    if (cityId) {
      const formData = new FormData();
      formData.append("city_id", cityId);
      axios
        .post(apiUrl, formData)
        .then((response) => {
          setLeadarea(response.data.data);
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  }, [cityId]);

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getAreaLead")
      .then((response) => {
        setCityData(response.data.data);
      })
      .catch((error) => {
        console.log("Error fetching area data:", error);
      });
  }, []);
  useEffect(() => {
    const firstApiUrl =
      "https://partywitty.com/master/Api/getDataPost/countryWiseState";
    const formData = new FormData();
    formData.append("country_id", "101");
    const combinedData = [];
    axios
      .post(firstApiUrl, formData)
      .then((response) => {
        const stateIds = response.data.data
          .filter(
            (item) =>
              item.id === "4007" || item.id === "4021" || item.id === "4022"
          )
          .map((item) => item.id);
        const secondApiUrl =
          "https://partywitty.com/master/Api/getDataPost/stateWiseCity";
        const secondPostPromises = stateIds.map((stateId) => {
          const formData = new FormData();
          formData.append("state_id", stateId);
          return axios.post(secondApiUrl, formData);
        });
        return Promise.all(secondPostPromises);
      })
      .then((responses) => {
        responses.forEach((response) => {
          combinedData.push(...Array.from(response.data.data));
        });
        const filteredData = combinedData.filter((item) =>
          [
            "134404",
            "134007",
            "133234",
            "131821",
            "58171",
            "132032",
            "131853",
            "133230",
            "132005",
            "131953",
          ].includes(item.id)
        );
        setApiData(filteredData);
      })
      .catch((error) => {
        console.error("API error", error);
      });
  }, []);
  const handleVenueSelection = (venue) => {
    const isVenueSelected = selectedVenu.find((v) => v.id === venue.id);
    if (isVenueSelected) {
      setSelectedVenu((prevSelectedVenu) =>
        prevSelectedVenu.filter((v) => v.id !== venue.id)
      );
    } else {
      setSelectedVenu((prevSelectedVenu) => [...prevSelectedVenu, venue]);
    }
    console.log("Selected Venues:", selectedVenu);
  };
  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setLocation12(selectedLocation);
    const selectedCity = apiData.find((e) => e.name === selectedLocation);
    if (selectedCity) {
      setCityId(selectedCity.id);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [selectedVenues, setSelectedVenues] = useState([]);

  const handleDateSelection = (data, type) => {
    if (type === "date") {
      const isAlreadySelected = selectedVenues.some(
        (venue) => venue.id === data.id
      );
      if (isAlreadySelected) {
        setSelectedVenues((prevVenues) =>
          prevVenues.filter((venue) => venue.id !== data.id)
        );
      } else {
        setSelectedVenues((prevVenues) => [...prevVenues, data]);
      }
    } else {
    }
  };
  return (
    <div className={styles.Container}>
      <div className="sticky-container">
        <div className="reserve--table--booking--card">
          <>
            <form
              className="formdatainput formdatainput1"
              onSubmit={handleSubmit}
            >
              <p className="booktablecss m-0">Book a Table</p>
              <div className="choose--time--date">
                <p className="mb-2">Venu ?</p>
                <div className="main-component" style={{ gap: "30px" }}>
                  {clubcategorydata?.map((data) => (
                    <div
                      key={data.id}
                      className={`chip1 ${
                        selectedVenues.some((venue) => venue.id === data.id)
                          ? "selectedVenu"
                          : ""
                      }`}
                      onClick={() => handleDateSelection(data, "date")}
                    >
                      <p
                        className="date--data"
                        style={{
                          borderRadius: "10px",
                          padding: "25px",
                          border: selectedVenues.some(
                            (venue) => venue.id === data.id
                          )
                            ? "1px solid #1e1434"
                            : "1px solid #1e1434",
                          background: selectedVenues.some(
                            (venue) => venue.id === data.id
                          )
                            ? "#1e1434"
                            : "#d71362",
                        }}
                      >
                        {data.name}
                      </p>
                    </div>
                  ))}
                </div>
                <p className="mb-2">What Day ?</p>
                <div className="main-component" style={{ gap: "30px" }}>
                  {remainingDates?.map((data, index) => (
                    <div
                      key={index}
                      className={`chip1 ${
                        selectedDate === data ? "selecteddate" : ""
                      }`}
                      onClick={() => {
                        setSelectedDate(data);
                        handleDateSelection(data, "date");
                      }}
                    >
                      <p
                        className="date--data"
                        style={{
                          borderRadius: "10px",
                          padding: "25px",
                          border: selectedDate === data ? "1px solid #1e1434" : "1px solid #1e1434",
                          background:
                            selectedDate === data ? "#d71362" : "#1e1434",
                        }}
                      >
                        {index === 0
                          ? "Today"
                          : index === 1
                          ? "Tomorrow"
                          : moment(data).format("dddd, Do MMMM")}
                      </p>
                    </div>
                  ))}
                </div>
                <p className="mt-2 mb-1">What time ?</p>
                <div className="main-component-time" style={{ gap: "30px" }}>
                  {ClubTiming?.map((data) => (
                    <div
                      className={`chip1 ${
                        selectedTime === data ? "selecteddate" : ""
                      }`}
                      onClick={() => {
                        setSelectedTime(data);
                        handleDateSelection(data, "time");
                      }}
                    >
                      <p
                        style={{
                          borderRadius: "10px",
                          padding: "25px",
                          border:
                            selectedTime === data
                              ? "1px solid #1e1434"
                              : "1px solid #1e1434",
                          background:
                            selectedTime === data ? "#d71362" : "#1e1434",
                        }}
                      >
                        {data}
                      </p>
                    </div>
                  ))}
                </div>
                <p className="mt-2 mb-1">How many people?</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginLeft: "-50px",
                    marginBottom: "-10px",
                  }}
                >
                  <p
                    className="mt-2 mb-0"
                    style={{ marginLeft: "30px", fontSize: "14px" }}
                  >
                    Male
                  </p>
                  <p className="mt-2 mb-0" style={{ marginLeft: "20px" }}>
                    Female
                  </p>
                  <p className="mt-2 mb-0" style={{ marginRight: " 20px" }}>
                    Kids
                  </p>
                </div>
                <div className="main-component-time">
                  <div className="input-wrapper">
                    <input
                      type="number"
                      name="male"
                      placeholder="Enter here"
                      onChange={handleInputChange}
                      style={inputStyle}
                      min="0"
                    />
                  </div>
                  <div className="input-wrapper" style={{ margin: "10px" }}>
                    <input
                      type="number"
                      name="female"
                      placeholder="Enter here"
                      onChange={handleInputChange}
                      style={inputStyle}
                      min="0"
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="number"
                      name="kids"
                      placeholder="Enter here"
                      onChange={handleInputChange}
                      style={inputStyle}
                      min="0"
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", gap: "160px", marginLeft: "10px" }}
                >
                  <p className="mt-2 mb-1">City</p>
                  <p className="mt-2 mb-1">Area</p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <select
                    name="city"
                    value={formData.city}
                    onChange={handleLocationChange}
                    style={selectStyle}
                  >
                    <option value="">Choose</option>
                    {citydata.map((e) => (
                      <option key={e.id} value={e.name}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="area"
                    value={selectedArea ? selectedArea.id : ""}
                    onChange={handleAreaChange}
                    style={selectStyle}
                  >
                    <option value="">Choose</option>
                    {leadarea.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <button type="submit" style={{ cursor: "pointer" }}>
                Reserve Now
              </button>
            </form>
            <Modal open={showPopup} onClose={() => setShowPopup(false)}>
              <div className="modal-container">
                <div className="modal-content">
                  <button
                    className="modal-button"
                    onClick={() => setShowPopup(false)}
                  >
                    x
                  </button>
                  <dotlottie-player
                    src="https://lottie.host/6b9fc96a-5c59-464a-ad26-5782e3a54022/EFWiS28KVM.json"
                    background="transparent"
                    speed="1"
                    style={{ height: "300px" }}
                    loop
                    autoplay
                  ></dotlottie-player>

                  <p className="modal-message">Form submitted successfully!</p>
                  <div className="about-us-playstore-image">
                    <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                      <img src={Googleplay} alt="playstore" />
                    </Link>
                    <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                      <img src={Applestore} alt="appstore" />
                    </Link>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        </div>
      </div>
    </div>
  );
}

export default Index;

export const OfferDetailsData = [
  {
    id: 0,
    name: "product name",
    discount: "50%",
    offering_price: 14.25,
    originalPrice: 15.0,
    imgsrc: "/images/product1.svg",
  },
  {
    id: 1,
    name: "product name2",
    discount: "50%",
    offering_price: 16.25,
    originalPrice: 19.0,
    imgsrc: "/images/product2.svg",
  },
];

export const ClubTiming = [
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
];
