import React from "react";
import "../../Club About Us/css/Style2.css";
import style from "./Gallery.module.css";
import Card from "./card"; // Assuming Card component is defined in 'Card.js'

function Index({ GalleryData, url, setImageView }) {
  
  return (
    <div className={style.gallery_section} id="gallery">
      <h2>GALLERY</h2>
      <div className={style.gallery_row}>
        {GalleryData?.map((item) => (
          <Card key={item.id} GalleryData={item} url={url} setImageView={setImageView} />
        ))}
      </div>
    </div>
  );
}

export default Index;
