import './NewThankew--page.css';
import Thanks from '../Navbar/image/Thanks.png';
import user from './image/user.svg';

export default function NewThankewPage() {
    return (
        <div className='New--thankew--page--card--start'>
            <div className='New--thankew--page--card--start--1'>
                <div className='New--thankew--page--card--start--1--img'>
                    <img src={Thanks}></img>
                </div>
                <div>
                    <p className='New--thankew--page--card--start--order'>Order Number</p>
                    <p className='New--thankew--page--card--start--order1'>8746568464</p>
                </div>
                <div className='New--thankew--page--card--start--people'>
                    <p className='New--thankew--page--card--start--people--p'>Load of The Drinks</p>
                    <p className='New--thankew--page--card--start--people--p--user'><img src={user}></img> 1</p>
                </div>

                <div className='New--thankew--page--card--start--people'>
                    <div>
                        <p className='New--thankew--page--card--booking'>Booking Date</p>
                        <p className='New--thankew--page--card--booking--date'>28-10-2023</p>
                    </div>
                    <div>
                        <p className='New--thankew--page--card--booking'>Event Start Time</p>
                        <p  className='New--thankew--page--card--booking--date'>19:00 to 23:59</p>
                    </div>
                   
                </div>
                <div>
                        <p className='New--thankew--page--card--payment--upi'>
                        Payment: paid Through UPI 
                        </p>
                        <p className='New--thankew--page--card--payment--total'>Total Payment: 940.00</p>
                </div>
                
                  
            </div>

           
        </div>
    );
}