import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Style.css';
import WebApp1 from "../../Web--to--app/WebApp1";

export default function PercussionNight() {
  const [data, setData] = useState([]);
  const [showPopUpIndex, setShowPopUpIndex] = useState(null);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [popupKey, setPopupKey] = useState(0); // Key to force re-render
  const URL = "https://partywitty.com/master/assets/uploads/";
  const elementsRef = useRef([]);

  const fetchURL = async () => {
    try {
      const response = await axios.get(
        "https://partywitty.com/master/APIs/Artist/top10Artist"
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchURL();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = elementsRef.current.indexOf(entry.target);
              setVisibleIndex(index);
            }
          });
        },
        { threshold: 0.5 }
      );

      elementsRef.current.forEach((element) => {
        if (element) {
          observer.observe(element);
        }
      });

      return () => {
        elementsRef.current.forEach((element) => {
          if (element) {
            observer.unobserve(element);
          }
        });
      };
    }
  }, [data]);

  const handleClickButton = (index) => {
    setShowPopUpIndex(index);
    setPopupKey(prevKey => prevKey + 1); // Increment key to force re-render
  };

  const handleClosePopUp = () => {
    setShowPopUpIndex(null);
  };

  return (
    <div className='Artist-card-font'>
      <h4>Top 10 Artists</h4>
      <div className='live-music-all-card'>
        {data.length > 0 ? (
          data.map((artist, index) => (
            <div
              className='percussion-card'
              key={artist.id}
              ref={(el) => (elementsRef.current[index] = el)}
              style={{ borderColor: visibleIndex === index ? '#d71362' : '#141420' }}
            >
              <div
                className="num"
                style={{ color: visibleIndex === index ? '#d71362' : '#141420' }}
              >
                {index + 1}
              </div>
              <div className={`Percussion-card-content ${showPopUpIndex === index ? 'active' : ''}`}>
                <img src={URL + artist.profile_pic} alt='img' />
                <div className='live-music-card-content-1'>
                  <p>{artist.name.substring(0, 16)}</p>
                  <div>
                    <button onClick={() => handleClickButton(index)}>View Profile</button>
                    {showPopUpIndex === index && <WebApp1 key={popupKey} onClose={handleClosePopUp} />}
                  </div>
                </div>
                <div className='live-music-geners'>
                  {artist.genre.split(",").map((genre, genreIndex) => (
                    <button key={genreIndex}>{genre.trim()}</button>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No artists available.</p>
        )}
      </div>
    </div>
  );
}
