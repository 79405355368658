import "./My--Booking.css";
import Arrow from "./image/Arrow.svg";
import React, { useState, useEffect } from "react";
import booking from "./image/booking.png";

export default function MyNotification() {
  const [activePrice, setActivePrice] = useState("notification");
  const handleButtonPrice = (type) => {
    setActivePrice(type);
  };
  return (
    <div className="mobile-view-ham--notification">
      <div className="mobile--view--hamburger--my--booking--1">
        <img src={Arrow} className="left-aligned-img" alt="Arrow Icon" />
        <p className="centered-text">My Notification</p>
      </div>
      <div className="mobile--view--hamburger--my--booking--tab">
        <button
          onClick={() => handleButtonPrice("notification")}
          style={{
            color: activePrice === "notification" ? "#000" : "#66666E",
          }}
        >
          160 Notification
        </button>
        <button
          onClick={() => handleButtonPrice("active")}
          style={{
            color: activePrice === "active" ? "#000" : "#66666E",
          }}
        >
          Active
        </button>
        <button
          onClick={() => handleButtonPrice("expire")}
          style={{
            color: activePrice === "expire" ? "#000" : "#66666E",
          }}
        >
          Expire
        </button>
      </div>
      {activePrice === "notification" && (
        <div className="mobile--view--hamburger--my--notification--card">
          <div className="mobile--view--hamburger--my--notification--card1">
            <div className="web--view--Notification--bar--heading--and--cross--card">
              <div className="web--view--Notification--bar--heading--and--cross--card1">
                <img src={booking}></img>
                <div className="web--view--Notification--bar--heading--and--cross--card1--1">
                  <p className="web--view--notification--1--p">stewiedewie</p>
                  <p className="web--view--notification--1--p1">
                    New Party Notification
                  </p>
                  <p className="web--view--notification--1--p2">0 min</p>
                </div>
              </div>
              <div className="web--view--Notification--bar--heading--and--cross--card2">
                <p className="web--view--notification--1--p1">03:59:00</p>
              </div>
            </div>
          </div>
          <div className="mobile--view--hamburger--my--booking--border--line--fav1-2"></div>
          <div className="mobile--view--hamburger--my--notification--card1">
            <div className="web--view--Notification--bar--heading--and--cross--card">
              <div className="web--view--Notification--bar--heading--and--cross--card1">
                <img src={booking}></img>
                <div className="web--view--Notification--bar--heading--and--cross--card1--1">
                  <p className="web--view--notification--1--p">stewiedewie</p>
                  <p className="web--view--notification--1--p1">
                    New Party Notification
                  </p>
                  <p className="web--view--notification--1--p2">0 min</p>
                </div>
              </div>
              <div className="web--view--Notification--bar--heading--and--cross--card2">
                <p className="web--view--notification--1--p1">03:59:00</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
