
import FavorateSearchBanner from './Favorate--banner--search';
import FavorateClubCategory from './Favorate--club--category';
import FavorateHangoutThumbnail from './Favorate--hangout--thumbnail';
import './Favorate--hangout.css';
import FavorateHangoutSponserd from './Favorate--sponserd--banner';
import GroupBookingThumbnail from './GroupBookingThumbnail';
import FavoratePackageFilter from './favorate--hangout--package--filter';





export default function GroupBookingViewall()
{
    return(
        <div className='Favorate--Hangout--page'>
          <div >
            <GroupBookingThumbnail/>
            <FavorateHangoutSponserd/>
            </div>
        </div>
    );
}