import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import "./Clubpage.css";
import axios from "axios";
import { Base64 } from "js-base64";

function About() {


  const fullContent =
    "Discover new flavours and fusions in the eclectic menu of Italian, continental fast food and Indian cuisine in trendy Blooms .Delicious and healthy food is artistically presented with which you can pamper your palate. The experienced chef has carefully crafted the eclectic menu keeping in mind the food preferences of both foodies and the aware food lovers.  A Savour Bruschettas, risotto, shahi paneer rolls, sumptuous keema pao, Panner mirchi ka salan and Indian breads Mexican Chilly Cheese Toast. Relish excellently prepared dal makhni with garlic naan, biryani and green thai curry. Murg malai kebab chicken caeser salad is worth trying. Blooms club sandwich with crispy bacon,chicken fried egg, coleslaw and cheese is not to be missed. Gorge on to the sweet delicacies stylishly presented in the dessert counter. The quality of food is extraordinary and fresh herbs as well with exotic ingredients are used in all the preparations.The warm hospitality of the staff is overwhelming. Friendly and polite waiters serve Delicious Food. You can be there with your family, friends, and colleagues as well as with your date. It is a completely chilled out and relaxed place away from the hustle and bustle of the city amidst the elegant environs and fresh music. The urban-chic restaurant in Hotel Eros offers a panorama of delectable food with diverse flavours and textures. So sit in the restaurant, and surprise yourself with new concepts in food and presentations.";
  const [showFullContent, setShowFullContent] = useState(false);
  const contentToShow = showFullContent
    ? fullContent
    : fullContent.slice(0, 300);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  
  const [features, setFeatures] = useState([]);
  const [id, setId] = useState(null); 

  

  
  var url = "https://partywitty.com/master/assets/uploads/";
  var menuurl = "https://partywitty.com/master/assets/uploads/";

  const [data, setData] = useState([]);

  const { city, area, clubname } = useParams();
  const clubN = clubname;

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        setData(response.data.data);
        const id = response.data.data.id;
       
        setId(id);
      })
      .catch((error) => {
        console.error(error);
       
      });
  }, []);
  
  const clubid = new FormData();
  clubid.append("club_id", id);

  useEffect(() => {
    if (id) {
      axios
        .post("https://partywitty.com/master/Api/getDataPost/clubAmenities", clubid)
        .then((response) => {
      
          if (response.data.status === "true") {
            setFeatures([]);
          } else {
            setFeatures(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
      
        });
    }
  }, [id]);

  
  return (
    <div className="club-tab-about-card">
      <div className="details-page">
       
      </div>

     

      
     
     
     
      <div class="features">
        <div class="features-content1">
          {features &&
            features.map((e) => (
              <div class="content1">
                <img
                  class="about-img1"
                  src={url + e.amenity_icon}
                  alt="img"
                ></img>
                <p>{e.amenity_name}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default About;
