import "./Favorate--hangout.css";
import { useEffect, useState } from "react";
import PackageCard from '../Components/PackageCard'
import axios from "axios";
import thumbnail from './image/thumbnail.jpg';
import star from './image/star.png';
import direction from './image/direction.png';
import banner from "./image/banner.png";
import location from "./image/location.png";
import { LuSearch } from "react-icons/lu";
import Carousel from "react-bootstrap/Carousel";
import "../Homepage/multyitem.css";
import sponsed from './image/sponsed.png';
import { Link, useParams } from "react-router-dom";
import logo from "../Assets/logo.png";

import livemusic from "./image/livemusic.png";
import alcoholic from "./image/alchoholic.png";
import tablebook from "./image/tablebook.png";
import overall from "./image/overall.png";
import { Helmet } from "react-helmet-async";


export default function PopularPackageView() {
  const params = useParams(); 
  const { id } = params;
 

  const [otherPackages1, setOtherPackages1] = useState([]);

  const PackageMenu1 = new FormData();
  PackageMenu1.append("title", id);
  const url = "https://partywitty.com/master/assets/uploads/";
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.post(
          "https://partywitty.com/master/APIs/Web/allPackagesTitleWise",
          PackageMenu1
        );

        if (response.data.status === true) {

          setOtherPackages1(response.data.data);
        } else {
          console.log("No data found");
        }

      } catch (error) {
        console.log("Error fetching data: ", error);
      }
    };
    fetchPackages();
  }, []);


  return (
    <div>
      <Helmet>
        <link rel="canonical" href=" https://www.partywitty.com/party-package-delhi-ncr-view-all" />
        <title> Party Witty | Packages </title> 
        <meta name="description" content="Are You Looking  for the Best Party Packages in Delhi Ncr With Price? Party Witty Will Provide You With a Discover and Book Perfect Party Planner Venue Online"/>
      </Helmet>
    <div className="favorate--hangout--city--wise--club--page">
      <div className="Favorate--Hangout--page">
        <div className="Favorate--Hangout--page--card">
          <div className="Favorate--Hangout--page--card-banner-img">
            <img src={banner}></img>
          </div>
          <div className="Favorate--Hangout--page--card-banner-Search8">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo}></img>
            </Link>


            <p className="Favorate--Hangout--page--p1">Discover the best Party Packages In Delhi NCR</p>
            <p className="Favorate--Hangout--page--p2">{otherPackages1.length} Packages</p>
            
          </div>
        </div>
      </div>
     
      <div className="Group--booking--all-card--1">
        <div className="Group--booking--view--all--card">
          <div className="Group--booking--view--all--left">
            <div className="Favorate--hangout--all--thumbnail">
              {otherPackages1.map((data) => { return <PackageCard PackageData={data} /> })}
            </div>
         
          </div>
        
        </div>
      </div>
    </div>
    </div>
  );
}