import "../App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import logo from "../Assets/logo.png";
import Button from "@mui/material/Button";
import farmhouse from "./image/farmhouse.png";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/style.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useModal } from "../context/context";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import coupon from "./image/coupon.png";
import notification from "./image/notificaton.png";
import favorites from "./image/favorites.png";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import { LuTicket } from "react-icons/lu";
import { GoSignOut } from "react-icons/go";
import booking from "./image/booking.png";
import ListItemIcon from "@mui/material/ListItemIcon";
import Notiflix from "notiflix";
import Select from "react-select";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Searchbar from "../Homepage/Searchpage";
import { AiOutlineShoppingCart } from "react-icons/ai";
import Modal from "@mui/material/Modal";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 450,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 450,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 450,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const drawerWidth = 200;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(email);
}

export default function Loginpopup() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMyAccount = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMyAccount = () => {
    setAnchorEl(null);
  };
  const initialTimeInSeconds = 60;
  const [timeInSeconds, setTimeInSeconds] = useState(initialTimeInSeconds);
  const [showResend, setShowResend] = useState(false);
  const { toggle } = useModal();
  const [mobile, setMobile] = useState("");
  const [response, setResponse] = useState(null);
  const [otpType, setOtpType] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [updatemobile, setUpdateMobile] = useState("");
  const [updateotp, setUpdateOtp] = useState("");
  const [updatename, setUpdateName] = useState("");
  const [updateemail, setUpdateEmail] = useState("");
  const [updatedob, setUpdateDob] = useState(null);
  const [updategender, setUpdateGender] = useState("");
  const [updateprofile, setUpdateProfile] = useState("");
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleDobChange = (newDate) => {
    setDob(newDate);
  };
  const handleEmailChangevalue = (e) => {
    setEmail(e.target.value);
  };
  const handleEmailChangevalue1 = (e) => {
    setUpdateEmail(e.target.value);
  };
  const getEighteenYearsAgoDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };
  const [dob, setDob] = useState("");
  const handleDateChange = (event) => {
    setDob(event.target.value);
  };
  var url = "https://partywitty.com/master/assets/uploads/";
  useEffect(() => {
    if (toggle) {
      setOpenLogin(true);
    }
  }, [toggle]);
  useEffect(() => {

    const updateTimer = () => {
      if (timeInSeconds > 0) {
        setTimeInSeconds((prevTime) => prevTime - 1);
      } else {
        setShowResend(true);
      }
    };

    const intervalId = setInterval(updateTimer, 1000);


    return () => clearInterval(intervalId);
  }, [timeInSeconds]);
  const handleResendOTP = () => {
    setTimeInSeconds(initialTimeInSeconds);
    setShowResend(false);
  };
  const formattedTime = `${Math.floor(timeInSeconds / 60)}:${(
    timeInSeconds % 60
  )
    .toString()
    .padStart(2, "0")}`;
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleResendOTP();
    try {
      const formData = new FormData();
      formData.append("mobile", mobile);
      formData.append("otpType", "text");

      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/Login",
        formData
      );

      setResponse(response.data);
      handleOpenOtp();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSubmitotp = async (e) => {
    try {
      const formData = new FormData();
      formData.append("mobile", mobile);
      formData.append("otp", otp);

      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/verifyOtp",
        formData
      );

      if (response.data.status === true) {
        if (response.data.type === "old") {
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          window.location.reload();
        } else {
          handleOpenSignup();
        }
      } else {

        alert("Invalid OTP. Please try again.");

      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSubmitsignup = async (e) => {
    try {
      const formData = new FormData();
      formData.append("mobile", mobile);
      formData.append("whatsapp", mobile);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("dob", dob);
      formData.append("gender", gender);
      if (!validateEmail(email)) {
        alert("Please enter a valid email address.");
        return;
      }
      const response = await axios.post(
        "https://partywitty.com/master/Api/UserAuth/updateDetails",
        formData
      );

      setResponse(response.data);

      if (response.data.status === true) {
        localStorage.setItem("userData", JSON.stringify(response.data.data[0]));


        window.location.reload();
      } else {

      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = (e) => {
    e.preventDefault()
    setOpenLogin(false);
    window.location.reload();
  };
  const [openOtp, setOpenOtp] = React.useState(false);
  const handleOpenOtp = () => {
    setOpenOtp(true);
  };
  const handleCloseOtp = () => {
    setOpenOtp(false);
  };
  const [openSignup, setOpenSignup] = React.useState(false);
  const handleOpenSignup = () => {
    setOpenSignup(true);
  };
  const handleCloseSignup = () => {
    setOpenSignup(false);
  };
  const loginerror = () => { };
  const otperror = () => { };
  const [value, setValue] = useState("");
  const userdata = JSON.parse(localStorage.getItem("userData"));
  var url = "https://partywitty.com/master/assets/uploads/";
  useEffect(() => {
    setOpenLogin(true);
  }, []);

  return (
    <div>
      {openLogin && (
        <div className="blur-background">
          <Modal
            open={openLogin}
            onClose={handleCloseLogin}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box className="responsive-modal--log--in">
              <div className="login-content">
                <div className="Plan--your--event--popup--1--img">
                  <div className="login-close-button">
                    <Button onClick={handleCloseLogin}>
                      X
                    </Button>
                  </div>
                </div>
                <div className="login-content1 mt-2">
                  <h4>Login/Signup</h4>
                  <div className="login-phoneno">
                    <lable className="lable">WhatsApp Number</lable>
                    <input
                      className="phone-input"
                      placeholder="Enter"
                      type="number"
                      value={mobile}
                      maxLength="10"
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/\D/g, "");

                        if (numericValue.length <= 10) {
                          setMobile(numericValue);
                        }
                      }}
                      pattern="[0-9]*"
                      title="Please enter only numbers"
                    />

                  </div>
                  <div className="login-submit-button">
                    <button
                      onClick={mobile.length == 10 ? handleSubmit : loginerror}
                      className={
                        mobile.length === 10 ? "pink-button" : "gray-button"
                      }
                    >
                      Log in
                    </button>
                  </div>
                  <p className="mt-2 w-85 privacy-policy-aggreement ">
                    By proceeding, you agree to Party Witty's
                    <a href="./privacy-policy">
                      {" "}
                      &nbsp;Privacy Policy&nbsp;
                    </a>{" "}
                    <br />
                    and{" "}
                    <p href="#" data-toggle="modal" data-target="#exampleModal">
                      &nbsp;Terms & Condition&nbsp;
                    </p>
                    &nbsp;
                  </p>
                  <Modal
                    open={openOtp}
                    onClose={handleCloseOtp}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box className="responsive-modal--log--in">
                      <div className="login-content">
                        <div className="Plan--your--event--popup--1--img">
                        </div>
                        <div className="login-content1">
                          <h4 className="m-0">Confirm your number </h4>
                          <p className="mb-2 ">
                            {mobile}{" "}
                            <span
                              className="login-content11-otp"
                              onClick={handleCloseOtp}
                            >
                              Edit
                            </span>
                          </p>
                          <div className="login-content11">
                            <p>Enter the 6 digit OTP we've sent by WhatsApp</p>
                          </div>
                          <div className="login-otp-content">
                            <div className="login-otp-text">
                              <input
                                type="text"
                                value={otp}
                                maxlength="6"
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );

                                  setOtp(numericValue);
                                }}
                                pattern="[0-9]*"
                                title="Please enter only numbers"
                                size="small"
                              />
                            </div>

                            <button
                              onClick={
                                otp.length == 6 ? handleSubmitotp : otperror
                              }
                              className={
                                otp.length === 6 ? "pink-button" : "gray-button"
                              }
                              disabled={otp.length === 0}
                            >
                              Verify OTP
                            </button>
                            <div id="timer" className="login-otp-content-timer">
                              {showResend ? (
                                <p
                                  className="resend--otp--after--time"
                                  onClick={handleSubmit}
                                >
                                  Resend OTP
                                </p>
                              ) : (
                                `Resend OTP in ${formattedTime}`
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="login-otp-close-button">
                        <Button onClick={handleCloseOtp}>
                          <p>X</p>
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={openSignup}
            onClose={handleCloseSignup}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="responsive-modal111">
              <div className="login-content">
                <div className="Plan--your--event--popup--1--img"></div>
                <div className="login-content1">
                  <h3 className="mt-2">Sign Up</h3>
                  <h4>Enter your information to register</h4>
                  <div className="login-content1--text-field ">
                    <Box
                      sx={{
                        alignItems: "center",
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      <lable className="lable">Name*</lable>
                      <input
                        className="input-field signupinput--field mt-0"
                        required
                        placeholder="Enter Your Name"
                        helperText=""
                        id="demo-helper-text-aligned"
                        type="text"
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        size="small"
                      />

                      <div className="gender-group">
                        <fieldset>
                          <legend className="gender-heading m-0 gender--lable">
                            Gender
                          </legend>
                          <label className=" gender--lable11">
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              onChange={handleGenderChange}

                            />{" "}
                            Male
                          </label>
                          <label className="margin-left gender--lable11">
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              onChange={handleGenderChange}
                            />{" "}
                            Female
                          </label>
                        </fieldset>
                      </div>
                      <div className="gender-group--1">
                        <label className="lable">
                          Email*
                          <input
                            placeholder="Enter Your Email"
                            className="input-field signupinput--field1"
                            helperText=""
                            type="email"
                            required
                            id="demo-helper-text-aligned"
                            label="Email"
                            value={email}
                            onChange={handleEmailChangevalue}
                            size="small"
                          />
                        </label>

                        <label className="lable">
                          DOB*
                          <input
                            type="date"
                            className="input-field signupinput--field1"
                            placeholder="date"
                            label="DOB"

                            max={getEighteenYearsAgoDate()}
                            value={dob}
                            onChange={handleDateChange}

                          />
                        </label>
                      </div>
                      <div className="signup--submit--button ">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleSubmitsignup();
                          }}
                          className={
                            name && gender && email && dob
                              ? "pink-button"
                              : "gray-button"
                          }
                        >
                          Submit
                        </button>
                      </div>
                      <p className="mt-1 w-85 privacy-policy-aggreement1">
                        By proceeding, you agree to Party Witty's <br />
                        <a href="./privacy-policy">
                          {" "}
                          &nbsp;Privacy Policy&nbsp;
                        </a>{" "}
                        and <a href="#">&nbsp;Terms & Condition&nbsp;</a> &nbsp;
                      </p>
                    </Box>
                  </div>
                </div>
              </div>
              <div className="close--signup-button">
                <Button onClick={handleCloseSignup}>
                  <p>X</p>
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
