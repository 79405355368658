import React, { useEffect, useState } from "react";
import "./css/Style2.css";
import axios from "axios";
import "../favorate--hangout--page/Favorate--hangout.css";
import thumbnail from "../favorate--hangout--page/image/thumbnail.jpg";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import beer from "./assets/images/beer.svg";
import food from "./assets/images/food.svg";
import { Helmet } from "react-helmet-async";
import menu6 from "./assets/images/menu.png";
import { BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";
import { FaDirections } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import GroupBooking from "../Components/Table_booking/index1";

const url = "https://partywitty.com/master/assets/uploads/";

export default function PackageAboutUs() {
  const { packageName, area, city, club } = useParams();
  const AddTitile = `${packageName.split("-")[0]} ${
    packageName.split("-")[1]
  } + ${
    packageName.split("-")[2] === undefined ? "" : packageName.split("-")[2]
  }`;
  const currentURL = window.location.href;
  const shareText = "Check out this link: " + currentURL;
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 1,
  };
  function menuHover(c_name) {
    var divs = document.getElementsByClassName("menu_nav");
    for (let item of divs) {
      item.classList.remove("active");
    }
    var div = document.querySelector("." + c_name);
    div.classList.add("active");
  }
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => setOpen2(false);
  const [menu1, setMenu1] = useState([]);
  const [data, setData] = useState([]);
  const [PackageName, setPackageName] = useState();
  const [ClubName, setClubName] = useState();
  const [Address, setAddress] = useState();
  const [clubId, setClubId] = useState(null);
  const { id } = useParams();
  const [menu, setMenu] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [packageId, setPackageId] = useState(0);
  const [otherPackages, setOtherPackages] = useState([]);
  const [otherPackages1, setOtherPackages1] = useState([]);
  const [partyvideo, setPartyvideo] = useState([]);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("city", city);
    formdata.append("area", area);
    formdata.append("club", club);
    formdata.append("title", packageName);
    axios
      .post("https://partywitty.com/master/APIs/Web/packageDetails", formdata)
      .then((response) => {
        if (response.data.status === "true") {
          setData({});
        } else if (response.data.data && response.data.data.length > 0) {
          setData(response.data.data[0]);
          const imageData = response.data.data[0].img.split("systumm");
          setMenu1(imageData);
          const packageId = response.data.data[0].id;
          setPackageId(packageId);
          setClubId(response.data.data[0].club_id);
          const packageName = response.data.data[0].title;
          setPackageName(packageName);
          const clubName = response.data.data[0].clubName;
          setClubName(clubName);
          const Address = response.data.data[0].address;
          setAddress(Address);
        } else {
          console.error("No data found in response:", response.data);
        }
      })
      .catch((error) => {
        console.log("Error fetching package details:", error);
      });
  }, [city, area, club, packageName]);

  useEffect(() => {
    const PackageMenu = new FormData();
    PackageMenu.append("package_id", packageId);
    axios
      .post(
        "https://partywitty.com/master/APIs/ClubPackage/getPackageMenu1",
        PackageMenu
      )
      .then((response) => {
        if (response.data.status === "true") {
          setMenu([]);
          setLogoUrl("");
          setNoDataFound(true);
        } else {
          const firstItem = response.data.data[0];
          if (firstItem.clubLogo) {
            setLogoUrl(firstItem.clubLogo);
          }
          setMenu(firstItem.category);

          const hasData = Object.values(firstItem.category).some(
            (subCategory) => {
              return (
                (subCategory.veg_choice !== "0" &&
                (subCategory.veg_choice === "All" || Number(subCategory.veg_choice) > 0)) ||
              (subCategory.nonveg_choice !== "0" &&
                (subCategory.nonveg_choice === "All" || Number(subCategory.nonveg_choice) > 0))
              );
            }
          );
          setNoDataFound(!hasData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [packageId]);
  console.log("package_id", packageId);
  
  useEffect(() => {
    const PackageMenu1 = new FormData();
    PackageMenu1.append("package_id", packageId);
    PackageMenu1.append("club_id", clubId);

    axios
      .post(
        "https://partywitty.com/master/APIs/ClubPackage/packageOfSameClub",
        PackageMenu1
      )
      .then((response) => {
        if (response.data.status === true) {
          setOtherPackages(response.data.data);
        } else {
          console.log("No data found");
        }
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, [packageId, clubId]);

  useEffect(() => {
    const PackageMenu1 = new FormData();
    PackageMenu1.append("package_id", packageId);
    PackageMenu1.append("club_id", clubId);

    axios
      .post(
        "https://partywitty.com/master/APIs/ClubPackage/samePackageOfOtherClub",
        PackageMenu1
      )
      .then((response) => {
        if (response.data.status === true) {
          setOtherPackages1(response.data.data);
        } else {
          console.log("No data found");
        }
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, [packageId, clubId]);
  useEffect(() => {
    const PackageMenu2 = new FormData();
    PackageMenu2.append("club_id", clubId);

    axios
      .post("https://partywitty.com/master/APIs/Common/getTeaser", PackageMenu2)
      .then((response) => {
        if (response.data.status === true) {
          setPartyvideo(response.data.data);
        } else {
          PackageMenu2.set("club_id", "Admin");
          axios
                  .post(
              "https://partywitty.com/master/APIs/Common/getTeaser",
              PackageMenu2
            )
            .then((response) => {
              if (response.data.status === true) {
                setPartyvideo(response.data.data);
              } else {
                console.log("No data found for club_id Admin either");
              }
            })
            .catch((error) => {
              console.log("Error fetching data for Admin: ", error);
            });
        }
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, [clubId]);

  const handleShare = (platform) => {
    let shareURL = "";
    if (platform === "facebook") {
      shareURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}&quote=${shareText}`;
    } else if (platform === "whatsapp") {
      shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        shareText
      )}%20${currentURL}`;
    } else if (platform === "twitter") {
      shareURL = `https://twitter.com/intent/tweet?url=${currentURL}&text=${encodeURIComponent(
        shareText
      )}`;
    } else if (platform === "email") {
      shareURL = `mailto:?subject=${encodeURIComponent(
        shareText
      )}&body=${encodeURIComponent(`Check it out: ${currentURL}`)}`;
    }

    window.open(shareURL, "_blank");
  };

 const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = "https://partywitty.com/master/assets/uploads/";
  useEffect(() => {
    const PackageMenu2 = new FormData();
    PackageMenu2.append("club_id", clubId);

    axios
      .post("https://partywitty.com/master/APIs/Common/getTeaser", PackageMenu2)
      .then((response) => {
        if (response.data.status === true) {
          setPartyvideo(response.data.data);
        } else {
          PackageMenu2.set("club_id", "Admin");
          axios
            .post(
              "https://partywitty.com/master/APIs/Common/getTeaser",
              PackageMenu2
            )
            .then((response) => {
              if (response.data.status === true) {
                setPartyvideo(response.data.data);
              } else {
                console.log("No data found for club_id Admin either");
              }
            })
            .catch((error) => {
              console.log("Error fetching data for Admin: ", error);
            });
        }
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, [clubId]);

  useEffect(() => {
    const PackageMenu2 = new FormData();
    PackageMenu2.append("club_id", clubId);

    const fetchEvents = async () => {
      try {
        const response = await axios.post("https://partywitty.com/master/APIs/ClubEvent/clubWiseAllEvents", PackageMenu2
         );

        if (response.data.status && response.data.data) {
          setEvents(response.data.data);
        } else {
          setEvents([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setIsLoading(false);
        setEvents([]);
      }
    };

    fetchEvents();
  }, [clubId]);

  const toggleMute = (e) => {
    e.target.muted = !e.target.muted;
  };

  const handleMouseLeave = (videoElement) => {
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };

  const eventUrl = (e) => {
    return `/${(e.cityName?.replace(/ /g, "-") || "").toLowerCase()}/${(
      e.areaName?.replace(/ /g, "-") || ""
    ).toLowerCase()}/${(
      e.clubName?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}/event/${(
      e.name?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}`;
  };
  const handleOpen2 = () => setOpen2(true);
  
  return (
    <div className="All--page">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.partywitty.com/party-package-delhi-ncr-view-all"
        />
        <title>
          {`${PackageName}`} | {`${ClubName}`}
        </title>
        <meta
          name="description"
          content={`${packageName} | ${ClubName} | ${Address}}`}
        />
      </Helmet>
      <div className="banner_img_overlay">
        <div className="banner_img">
          {data.clubLogo && data.thumbnail ? (
            <img
              src={url + data.thumbnail}
              alt="#"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = url + data.clubLogo;
              }}
            />
          ) : (
            <img src={url + data.clubLogo} alt="#" />
          )}
                  <div class="SocialShareIcon">
                    <a
                      rel="noreferrer"
                      href={`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${data.latitude},${data.longitude}&travelmode=driving`}
                      target="_blank"
                    >
                      <button className="direction-icon">
                        <FaDirections />
                      </button>
                    </a>
                    <button className="direction-icon1" onClick={handleOpen2}>
                      <BsShareFill />
                    </button>
                  </div>
        </div>
        <div className="third3">
        <h2>{data.title}</h2>
        <div className="package-food--service-card">
          <div className="package-food--service-card1">
            <img src={beer}></img>
            <div className="package-food--service-card2">
              {data.foodserving && <h5>{data.foodserving}</h5>}
              <h6>Beverage Menu </h6>
            </div>
          </div>

          <div className="package-food--service-card1">
            <img src={food}></img>
            <div className="package-food--service-card2">
              {data.alcoholserving && <h5>{data.alcoholserving}</h5>}
              <h6>Food Menu</h6>
            </div>
          </div>
        </div>
       <div className="third3--1 ">
          <p>{data.clubName}</p>
          <h3>{data.address}</h3>
          <span className="Timing_container">
            <h3>
             ( Club Timing: {data.open_time} - {data.close_time})
            </h3>
          </span>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="share-menu">
                <p className="facebook" onClick={() => handleShare("facebook")}>
                  <BsFacebook />
                </p>
                <p className="whatsapp" onClick={() => handleShare("whatsapp")}>
                  <BsWhatsapp />
                </p>
                <p className="twitter" onClick={() => handleShare("twitter")}>
                <FaSquareXTwitter />
                </p>
                <p className="gmail" onClick={() => handleShare("email")}>
                  <AiOutlineMail />
                </p>
              </div>
            </Box>
          </Modal>
        </div>
      
        </div>
      </div>
      <div className="fourth_desktop">
        <div className="club--about--us--video--frames"></div>
      </div>
      {/* menu div */}
      <div className="package_card">
        <div className="pacakge_card_left">
      <div className="menu_div">
        <div
          className="menu_nav menu active"
          onClick={() => {
            menuHover("menu");
          }}
        >
          <a href="#Menu">Menu</a>{" "}
        </div>
        <div
          className="menu_nav teaser"
          onClick={() => {
            menuHover("teaser");
          }}
        >
          <a href="#Teaser">Teaser</a>{" "}
        </div>
        <div
          className="menu_nav event"
          onClick={() => {
            menuHover("event");
          }}
        >
          <a href="#Event">Event</a>{" "}
        </div>
      </div>
      <div className="club--about--tabs--left--right">
        <div className="container-fluid menu_div_2">
          <div className="club--about--us--tab--view" id="Offers">
            <div className="club--about--us--page--card--3">
              <div
                className="menu_images_div  offer--img--heading menu-padding"
                id="Menu"
              >
                <div>
                  <h2>MENU</h2>
                  <div className="live-music-all-card">
                    {noDataFound == true ? (
                      menu1.map((image, index) => (
                        <div
                          className="package--about--us--menu--card5"
                          key={index}
                        >
                          <a
                            href={url + image}
                            data-fancybox="gallery"
                            data-caption=""
                          >
                            <img src={url + image} />
                          </a>
                        </div>
                      ))
                    ) : (
                      <div className="img_title">
                      {Object.keys(menu).map((category, index) => {
                        const vegChoice = menu[category].veg_choice;
                        const nonVegChoice = menu[category].nonveg_choice;
                        const showCategory =
                          vegChoice === "All" || parseInt(vegChoice) > 0 ||
                          nonVegChoice === "All" || parseInt(nonVegChoice) > 0;
                    
                        const hasNonVeg = menu[category].nonveg && menu[category].nonveg.length > 0;
                    
                        return (
                          showCategory && (
                            <div key={index}>
                              <h3>{category}</h3>
                              <div
                                className="package--about--us--menu--card"
                                data-bs-toggle="modal"
                                data-bs-target={`#exampleModal${category}`}
                              >
                                <img src={menu6} alt="Foodback" />
                                <div className="package--about--us--menu--card-1"></div>
                                <div className="package--anout--us--card--content--1">
                                  <div className="package--anout--us--card--content">
                                    <div>
                                      <div className="package--anout--us--card--content--9 ">
                                        {logoUrl && (
                                          <img
                                            src={url + logoUrl}
                                            alt="Club Logo"
                                          />
                                        )}
                                      </div>
                                      <div className="package--veg--non-veg--row">
                                        <div className="package--veg--course">
                                          <p className="package--veg--course--p">
                                            {hasNonVeg ? "Veg" : "Item"}
                                          </p>
                                          <p className="package--choose--veg">
                                            (Choose {menu[category].veg_choice})
                                          </p>
                                          {menu[category].veg &&
                                            menu[category].veg.map((item, i) => (
                                              <ul className="para--dots" key={i}>
                                                <li className="package--veg--course--p1">
                                                  {item}
                                                </li>
                                              </ul>
                                            ))}
                                        </div>
                                        {hasNonVeg && (
                                          <>
                                            <div className="package--veg--course--border--line"></div>
                                            <div className="package--veg--course">
                                              <p className="package--veg--course--p">
                                                Non-Veg
                                              </p>
                                              <p className="package--choose--veg">
                                                (Choose {menu[category].nonveg_choice})
                                              </p>
                                              {menu[category].nonveg.map((item, i) => (
                                                <ul className="para--dots" key={i}>
                                                  <li className="package--veg--course--p1">
                                                    {item}
                                                  </li>
                                                </ul>
                                              ))}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="modal fade"
                                data-bs-backdrop="static"
                                id={`exampleModal${category}`}
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered modal-lg">
                                  <div className="modal-content_package modal-content">
                                    <div className="modal-header">
                                      <h1
                                        className="modal-title fs-5"
                                        id="exampleModalLabel"
                                      >
                                        {category}
                                      </h1>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="package--about--us--menu--card7">
                                        <img src={menu6} alt="Foodback" />
                                        <div className="package--anout--us--card--content--2">
                                          <div className="package--anout--us--card--content--6">
                                            <div className="package--anout--us--card--content--9-1-2">
                                              <div className="package--anout--us--card--content--9-1">
                                                {logoUrl && (
                                                  <img
                                                    src={url + logoUrl}
                                                    alt="Club Logo"
                                                  />
                                                )}
                                              </div>
                                              <div className="package--veg--non-veg--row">
                                                <div className="package--veg--course">
                                                  <p className="package--veg--course--p2">
                                                    {hasNonVeg ? "Veg" : "Item"}
                                                  </p>
                                                  <p className="package--choose--veg1">
                                                    (Choose {menu[category].veg_choice})
                                                  </p>
                                                  {menu[category].veg &&
                                                    menu[category].veg.map((item, i) => (
                                                      <ul className="para--dots" key={i}>
                                                  <li className="package--veg--course--p1">
                                                    {item}
                                                  </li>
                                                </ul>
                                                    ))}
                                                </div>
                                                {hasNonVeg && (
                                                  <>
                                                    <div className="package--veg--course--border--line"></div>
                                                    <div className="package--veg--course">
                                                      <p className="package--veg--course--p2">
                                                        Non-Veg
                                                      </p>
                                                      <p className="package--choose--veg1">
                                                        (Choose {menu[category].nonveg_choice})
                                                      </p>
                                                      {menu[category].nonveg.map((item, i) => (
                                                        <ul className="para--dots" key={i}>
                                                        <li className="package--veg--course--p1">
                                                          {item}
                                                        </li>
                                                      </ul>
                                                      ))}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                    
                    )}
                  </div>
                </div>
              </div>

              <div
                className="events_div similar_clubs_section offer--img--heading menu-padding"
                id="Teaser"
              >
                <div className=" div">
                  <h2>Teasers</h2>
                  <div className="inner_div_events">
                    <div className="wrapper_videos">
                      {partyvideo.length > 0 ? (
                        partyvideo.map((e, index) => (
                          <a
                            href={url + e.video}
                            data-fancybox="gallery"
                            data-caption=""
                          >
                            {" "}
                            <div
                              className="club--about--us--video--frames--card"
                              key={e.id}
                            >
                              <video
                                autoPlay
                                loop
                                muted
                                controls
                                width="auto"
                                height="230"
                              >
                                <source src={url + e.video} type="video/mp4" />
                              </video>
                              <p>{e.title}</p>
                            </div>
                          </a>
                        ))
                      ) : (
                        <p>No party videos available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </div>
      </div>
      <div className="events_div similar_clubs_section offer--img--heading menu_div_2" id="Event">
      <div className="div">
        <h2>Events</h2>
        <div className="inner_div_events">
          <div className="wrapper_videos">
            <div className="event-view-allclubs">
              <div className="allevent-view-card--8">
                {isLoading ? (
                  <span style={{
                    textAlign: 'center',
                    width: '100%',
                    padding: '4rem',
                    color: '#fff',
                    textTransform: 'uppercase'
                  }}>
                    Loading...
                  </span>
                ) : events.length === 0 ? (
                  <span style={{
                    textAlign: 'center',
                    width: '100%',
                    padding: '4rem',
                    color: '#fff',
                    textTransform: 'uppercase'
                  }}>
                    No Events Available
                  </span>
                ) : (
                  events.map((e) => (
                    <Link
                    to ={eventUrl(e)} 
                      style={{ textDecoration: "none" }}
                      key={e.id}
                    >
                      <div className="event-view-card1">
                        {e.image ? (
                          <img src={url + e.image} alt={`Image ${e.id}`} />
                        ) : e.video ? (
                          <video
                            width="100%"
                            height="260"
                            controls
                            loop
                            onClick={toggleMute}
                            onMouseLeave={() =>
                              handleMouseLeave(document.getElementById(`video-${e.id}`))
                            }
                          >
                            <source src={url + e.video} type="video/mp4" />
                          </video>
                        ) : (
                          <p>No content available</p>
                        )}
                        <div className="event-view-card1-content">
                          <div className="event-view-card1-content1">
                            <h4>{e.name}</h4>
                            <p>{e.categoryName}</p>
                          </div>
                          <div className="event-view-card1-content2">
                            <div className="date">
                              <span>
                                <AiTwotoneCalendar />
                              </span>{" "}
                              <p>{e.event_date}</p>
                            </div>
                            <div className="date">
                              <span>
                                <MdOutlineWatchLater />
                              </span>{" "}
                              <p>{e.event_time}</p>
                            </div>
                            <button className="event-view-all-button">
                              Book Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      <div className="package_card_right">
        <div className="package_card_right1">
        <GroupBooking clubId={clubId}  />
        </div>
      </div>
      </div>
      <div className="events_div similar_clubs_section offer--img--heading menu_div_2"  >
                {otherPackages.length > 0 && (
                  <div className="div">
                    <h2>Other Packages from this club</h2>
                    <div className="inner_div_events">
                      <div className="wrapper_videos">
                        {otherPackages.map((e, index) => (
                          <Link
                            to={`/${e.cityName
                              .trim()
                              .replace(/ /g, "-")
                              .toLowerCase()}/${e.areaName
                              .trim()
                              .replace(/ /g, "-")
                              .toLowerCase()}/${e.clubName
                              .trim()
                              .split(/ +/)
                              .join("-")
                              .replace(/ /g, "-")
                              .toLowerCase()}/${e.title
                              .trim()
                              .split(/ +/)
                              .join("-")
                              .replace(/ /g, "-")
                              .toLowerCase()}`}
                            className="bookatable-routes"
                          >
                            <div
                              key={e.id}
                              className="Favorate--hangout--all--thumbnail--card--package"
                            >
                              <div className="Favorate--hangout--all--thumbnail--card--img--2">
                                {e.thumbnail ? (
                                  <img
                                    className="Favorate--hangout--all--thumbnail--card--img--1--img"
                                    src={url + e.thumbnail}
                                    onError={(e) => (e.target.src = thumbnail)}
                                  />
                                ) : (
                                  <img
                                    className="Favorate--hangout--all--thumbnail--card--img--1--img"
                                    src={thumbnail}
                                  />
                                )}

                                <div className="Favorate--hangout--all--thumbnail--card--img--1"></div>
                              </div>
                              <div className="Favorate--hangout--card--content">
                                <div className="Favorate--hangout--card--content--1">
                                  <p className="Favorate--hangout--card--content--1--p1">
                                    {e.title.substring(0, 15)}...
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
      </div>
      <div className="events_div similar_clubs_section offer--img--heading  menu_div_2">
                {otherPackages1.length > 0 && (
                  <div className=" div">
                    <h2>Similar Packages from the other clubs</h2>
                    <div className="inner_div_events">
                      <div className="wrapper_videos">
                        <div className="img_title">
                          {otherPackages1.map((e, index) => (
                            <Link
                              to={`/${e.cityName
                                .trim()
                                .replace(/ /g, "-")
                                .toLowerCase()}/${e.areaName
                                .trim()
                                .replace(/ /g, "-")
                                .toLowerCase()}/${e.clubName
                                .trim()
                                .split(/ +/)
                                .join("-")
                                .replace(/ /g, "-")
                                .toLowerCase()}/${(
                                e.title.split("+")[0] + e.title.split("+")[1]
                              )
                                .trim()
                                .replace(/ /g, "-")
                                .toLowerCase()}`}
                            >
                              <div
                                key={e.id}
                                className="Favorate--hangout--all--thumbnail--card--package"
                              >
                                <div className="Favorate--hangout--all--thumbnail--card--img--2">
                                  <img
                                    className="Favorate--hangout--all--thumbnail--card--img--1--img"
                                    src={url + e.clubLogo}
                                  ></img>

                                  <div className="Favorate--hangout--all--thumbnail--card--img--1">
                                    <div className="Favorate--hangout--all--thumbnail--card--club--logo">
                                      <img src={url + e.clubLogo}></img>
                                    </div>
                                  </div>
                                  <div className="group--booking--thumbnail--livemusic--card--2"></div>
                                </div>
                                <div className="Favorate--hangout--card--content">
                                  <div className="Favorate--hangout--card--content--1">
                                    <p className="Favorate--hangout--card--content--1--p1">
                                      {e.title.substring(0, 23)}
                                    </p>
                                    {/* {console.log(e.clubName)} */}
                                    <p className="Favorate--hangout--card--content--1--p2">
                                      {e.clubName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
      </div>
    </div>
  );
}

