import React, { useState } from "react";
import "./Carnival.css";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import female from "./image/female.svg";
import Group from "./image/Group.png";

import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import carretimage from "../Assets/carret.png";
import group1 from "../Assets/Group 12053.svg";
import group from "../Assets/Group.svg";
import vector from "../Assets/Vector.svg";
import vector1 from "../Assets/Vector1.svg";
import Commons from "./Commons";
import arrow1 from "./image/arrow1.svg";
import coupon from "./image/coupon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 345,
  // height: 400,
  bgcolor: "#120829",
  // border: '2px solid #000',
  boxShadow: 24,
  //   p: 4,
};

export default function BookNowBookATable() {
  const params = useParams();
  const [data, setData] = useState([]);

  const [activeMenu, setActiveMenu] = useState("menu");
  const [activeDetail, setActiveDetail] = useState("menu");
  const malecount = JSON.parse(localStorage.getItem("count"));
  const femalecount = JSON.parse(localStorage.getItem("fcount"));
  const couplecount = JSON.parse(localStorage.getItem("ccount"));
  const maleamount = JSON.parse(localStorage.getItem("maleamount"));
  const femaleamount = JSON.parse(localStorage.getItem("Femaleamount"));
  const coupleamount = JSON.parse(localStorage.getItem("coupleamount"));
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [showResponse, setShowResponse] = useState(false);
  const [bookdata, setBookData] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [seatingcapecity, setSeatingcapecity] = useState([]);
  const [datalist, setDatalist] = useState([]);
  const totalinputfield = malecount + femalecount;
  const handleButtonClickMenu = (type) => {
    setActiveMenu(type);
  };
  const handleButtonClickDetail = (type) => {
    setActiveDetail(type);
  };

  const [menuState, setMenuState] = React.useState({
    bottom: false,
  });
  const [detailState, setDetailState] = React.useState({
    bottom: false,
  });
  const toggleDrawer2 = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuState({ ...menuState, [anchor]: open });
  };
  const toggleDrawer3 = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDetailState({ ...detailState, [anchor]: open });
  };

  const list3 = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        width: "100%",
        height: "500px",
        backgroundColor: "white",
        color: "black",
        padding: 0,
        margin: 0,
        overflowY: "auto",
      }}
      role="presentation"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <List>
        <ListItem
          disablePadding
          dense
          sx={{
            "&:hover": {
              backgroundColor: "inherit",
              padding: "0px",
            },
          }}
        >
          <ListItemButton
            onClick={(e) => e.stopPropagation()}
            disablePadding
            disableRipple
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <ListItemText
              sx={{ padding: 0 }}
              primary={
                <div className="new--year-menu--details--popup">
                  <div className="new--year-menu--details--popup--1-2">
                    <div className="new--year-menu--details--popup--1">
                      <button
                        onClick={() => handleButtonClickDetail("menu")}
                        style={{
                          backgroundColor:
                            activeDetail === "menu" ? "#1976d2" : "#fff",
                          color: activeDetail === "menu" ? "#fff" : "#808080",
                        }}
                      >
                        Menu
                      </button>
                      <button
                        onClick={() => handleButtonClickDetail("detail")}
                        style={{
                          backgroundColor:
                            activeDetail === "detail" ? "#1976d2" : "#fff",
                          color: activeDetail === "detail" ? "#fff" : "#808080",
                        }}
                      >
                        Details
                      </button>
                    </div>
                    <div className="new--year--border--line"></div>
                  </div>
                  {activeDetail === "menu" && (
                    <div className="new--year--menu--content">
                      <p className="new--year--menu--content--p">
                        ALCOHOLIC Drinks:
                      </p>
                      {datalist.whiskey === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Whiskey-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.whiskey}
                          </p>
                        </div>
                      )}
                      {datalist.vodka === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Vodka-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.vodka}
                          </p>
                        </div>
                      )}
                      {datalist.rum === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Rum-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.rum}
                          </p>
                        </div>
                      )}
                      {datalist.gin === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            {" "}
                            Gin
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.gin}
                          </p>
                        </div>
                      )}
                      {datalist.tequila === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Tequil
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.tequila}
                          </p>
                        </div>
                      )}
                      {datalist.beer === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Beer-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.beer}
                          </p>
                        </div>
                      )}
                      {datalist.red_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Red Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.red_wine}
                          </p>
                        </div>
                      )}
                      {datalist.white_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            White Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.white_wine}
                          </p>
                        </div>
                      )}
                      {datalist.rose_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Rose Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.rose_wine}
                          </p>
                        </div>
                      )}
                      {/* <div className="new--year--menu--content--p1">
                        <p className="new--year--menu--content--p--12">
                          9. Rose Wine
                        </p>
                        <p className="new--year--menu--content--p--13">
                          {datalist.whiskey}
                        </p>
                      </div> */}
                      {datalist.sparkling_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Sparkling Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.sparkling_wine}
                          </p>
                        </div>
                      )}

                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].food_starter,
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].non_alcoholic_beverages,
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].special_offering,
                        }}
                      ></p>
                    </div>
                  )}
                  {activeDetail === "detail" && (
                    <div className="new--year--menu--content1">
                      <div className="new--year--menu--content--1">
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Entertainment-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.entertainment}
                          </p>
                        </div>
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Start Time-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.start_time}
                          </p>
                        </div>
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            End Time-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.end_time}
                          </p>
                        </div>
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Kids-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.kids_friendly === "true" ? (
                              <>Yes</>
                            ) : (
                              <>No</>
                            )}
                          </p>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data[0].tc,
                          }}
                        ></p>

                        {/* <p className="new--year--menu--content--p">
                          Applicable on
                        </p>
                        <p className="new--year--menu--content--p--13">
                          Valid For: Dine-in
                        </p>
                        <p className="new--year--menu--content--p--13">
                          {" "}
                          Not Valid for: Takeaway, Home Delivery
                        </p> */}
                      </div>
                      {/* <div className="new--year--menu--content--1">
                        <p className="new--year--menu--content--p">
                          Use this within
                        </p>
                        <p className="new--year--menu--content--p--13">
                          30 days of purchase
                        </p>
                        <p className="new--year--menu--content--p">
                          {" "}
                          How to use offer
                        </p>
                        <div className="new--year--menu--content--p--marker">
                          <ul>
                            <li>
                              Carry your email voucher on phone or access it
                              under the 'purchases' section of the app
                            </li>
                            <li>
                              Make prior reservation before you visit the
                              merchant Merchant
                            </li>
                            <li>
                              Merchant verifies the voucher or you can redeem it
                              yourself using the app
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="new--year--menu--content--1">
                        <p className="new--year--menu--content--p">
                          Things to remember
                        </p>
                        <div className="new--year--menu--content--p--marker">
                          <ul>
                            <li>
                              Inclusive of all applicable taxes and service
                              charges.
                            </li>
                            <li>Prior reservations Mandatory.</li>
                            <li>Cannot be clubbed with any other offer.</li>
                            <li>Cannot be clubbed with any other offer.</li>
                            <li>
                              All local / national laws with respect to legal
                              drinking age apply on this voucher.
                            </li>
                            <li>
                              Not valid on Days of Special Events, please refer
                              to Black out Dates for this offer.
                            </li>
                            <li>
                              Maximum 10 vouchers of 1+1 offers can be used at
                              one time
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  )}
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const [count, setCount] = useState(6);
  const [inputFields, setInputFields] = useState([
    { id: 0, value: "", gender: "" },
  ]);
  const [inputFieldsfemale, setInputFieldsfemale] = useState([
    { id: 0, value: "", gender: "" },
  ]);
  const [inputFieldscouple, setInputFieldscouple] = useState([
    { id: 0, value: "", gender: "" },
  ]);
  const [inputFieldscouplemrs, setInputFieldscouplemrs] = useState([
    { id: 0, value: "", gender: "" },
  ]);
  const [nextId, setNextId] = useState(1);
  const [names, setNames] = useState([]);
  // Array to store names
  const [namesmalestag, setNamesmalestag] = useState([]);
  const [names1, setNames1] = useState([]);
  const [namesmrs, setNamesmrs] = useState([]);
  const [namesfemale, setNamesfemale] = useState([]);
  const [genders, setGenders] = useState(inputFields.map(() => "M")); // Array to store genders
  const [genders1, setGenders1] = useState([]);
  const [genders11, setGenders11] = useState(inputFields.map(() => "M"));
  const [mrsgender, setMrsgender] = useState([]);
  useEffect(() => {
    setInputFields(
      Array.from({ length: malecount }, (_, i) => ({
        id: i,
        value: "",
        gender: "",
      }))
    );
  }, [malecount]);
  useEffect(() => {
    setInputFieldsfemale(
      Array.from({ length: femalecount }, (_, i) => ({
        id: i,
        value: "",
        gender: "",
      }))
    );
  }, [femalecount]);

  useEffect(() => {
    setInputFieldscouplemrs(
      Array.from({ length: couplecount }, (_, i) => ({
        id: i,
        value: "",
        gender: "",
      }))
    );
  }, [couplecount]);
  useEffect(() => {
    setInputFieldscouple(
      Array.from({ length: couplecount }, (_, i) => ({
        id: i,
        value: "",
        gender: "",
      }))
    );
  }, [couplecount]);

  const handleInputChange = (id, event) => {
    const newInputFields = inputFieldsfemale.map((field) => {
      if (field.id === id) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setInputFieldsfemale(newInputFields);
    const newNames = newInputFields.map((field) => field.value);
    setNames(newNames);
  };
  const handleInputChangemalestag = (id, event) => {
    const newInputFields = inputFields.map((field) => {
      if (field.id === id) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setInputFields(newInputFields);
    const newNames = newInputFields.map((field) => field.value);
    setNamesmalestag(newNames);
  };
  const handleInputChange1 = (id, event) => {
    const newInputFields = inputFieldscouple.map((field) => {
      if (field.id === id) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setInputFieldscouple(newInputFields);
    const newNames = newInputFields.map((field) => field.value);
    setNames1(newNames);
  };
  const handleInputChangemrs1 = (id, event) => {
    const newInputFields = inputFieldscouplemrs.map((field) => {
      if (field.id === id) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setInputFieldscouplemrs(newInputFields);
    const newNames = newInputFields.map((field) => field.value);
    setNamesmrs(newNames);
  };
  // test
  const handleInputChangemrs = (index, event) => {
    const newInputFields = [...inputFieldscouplemrs];
    newInputFields[index].value = event.target.value;
    setInputFieldscouplemrs(newInputFields);

    const newGenders = [...mrsgender];
    newGenders[index] = "F"; // Set gender as "M" for "Mr."
    setMrsgender(newGenders);
    handleInputChangemrs1();
  };
  // test
  const handleInputChangefemale = (id, event) => {
    const newInputFields = inputFieldscouple.map((field) => {
      if (field.id === id) {
        return { ...field, value: event.target.value };
      }
      return field;
    });
    setInputFieldscouple(newInputFields);
    const newNames = newInputFields.map((field) => field.value);
    setNamesfemale(newNames);
  };
  const handleCheckboxChange = (id, option) => {
    const newInputFields = inputFields.map((field) => {
      if (field.id === id) {
        return { ...field, gender: option };
      }
      return field;
    });
    setInputFields(newInputFields);
    const newGenders = newInputFields.map((field) => field.gender);
    setGenders(newGenders);
  };
  const handleCheckboxChange1 = (id, option) => {
    const newInputFields = inputFields.map((field) => {
      if (field.id === id) {
        return { ...field, gender: option };
      }
      return field;
    });
    setInputFields(newInputFields);
    const newGenders = newInputFields.map((field) => field.gender);
    setGenders11(newGenders);
  };

  const handleInputChange11 = (index, event) => {
    const newInputFields = [...inputFieldscouple];
    newInputFields[index].value = event.target.value;
    setInputFieldscouple(newInputFields);

    const newGenders = [...genders1];
    newGenders[index] = "M"; // Set gender as "M" for "Mr."
    setGenders1(newGenders);
    handleInputChange1();
  };

  // For handling "Mrs." inputs
  const handleInputChangefemale1 = (index, event) => {
    const newInputFields = [...inputFieldscouple];
    newInputFields[index].value = event.target.value;
    setInputFieldscouple(newInputFields);

    const newGenders = [...genders1];
    newGenders[index] = "F"; // Set gender as "F" for "Mrs."
    setGenders1(newGenders);
    handleInputChangefemale();
  };

  const totalguestlistdata = names.concat(names1);

  const totalguest = namesmalestag.concat(totalguestlistdata);
  const custmerlist = totalguest.concat(namesmrs);
  const totalguestlistgender = genders.concat(genders1);
  const totalgender = totalguestlistgender.concat(genders11);
  const totalgenderlist = mrsgender.concat(totalgender);
  const [couponsdata, setCouponsdata] = useState([]);
  const clubid = new FormData();
  clubid.append("pass_id", params.id);
  clubid.append("user_id", userdata ? userdata.id : null);
  clubid.append("couple_qty", couplecount || 0);
  clubid.append("male_qty", malecount || 0);
  clubid.append("female_qty", femalecount || 0);
  var url = "https://partywitty.com/master/assets/uploads/";

  const inputBorderStyle = {
    borderColor: "red",
  };

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Carnival/PassDetails", clubid)
      .then((response) => {
        setData(response.data.data);

        setDatalist(response.data.data[0]);
        setCouponsdata(response.data.data[0].AvailableCoupons);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [isChecked, setIsChecked] = useState(null);

  // Function to handle checkbox click
  const handleCheckboxClick = () => {
    setIsChecked((prev) => {
      if (prev === null || prev === false) {
        return true; // If null or false, set it to true
      } else {
        return null; // If true, set it to null
      }
    });
  };
  const [nextpagedata, setNextpagedata] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]); // State to store the selected option
  const list2 = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        width: "100%",
        height: "500px",
        backgroundColor: "white",
        color: "black",
        padding: 0,
        margin: 0,
        overflowY: "auto",
      }}
      role="presentation"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <List>
        <ListItem
          disablePadding
          dense
          sx={{
            "&:hover": {
              backgroundColor: "inherit",
              padding: "0px",
            },
          }}
        >
          <ListItemButton
            onClick={(e) => e.stopPropagation()}
            disablePadding
            disableRipple
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <ListItemText
              sx={{ padding: 0 }}
              primary={
                <div className="new--year-menu--details--popup">
                  <div className="new--year-menu--details--popup--1-2">
                    <div className="new--year-menu--details--popup--1">
                      <button
                        onClick={() => handleButtonClickDetail("menu")}
                        style={{
                          backgroundColor:
                            activeDetail === "menu" ? "#1976d2" : "#fff",
                          color: activeDetail === "menu" ? "#fff" : "#808080",
                        }}
                      >
                        Menu
                      </button>
                      <button
                        onClick={() => handleButtonClickDetail("detail")}
                        style={{
                          backgroundColor:
                            activeDetail === "detail" ? "#1976d2" : "#fff",
                          color: activeDetail === "detail" ? "#fff" : "#808080",
                        }}
                      >
                        Details
                      </button>
                    </div>
                    <div className="new--year--border--line"></div>
                  </div>
                  {activeDetail === "menu" && (
                    <div className="new--year--menu--content">
                      <p className="new--year--menu--content--p">
                        ALCOHOLIC Drinks:
                      </p>
                      {datalist.whiskey === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Whiskey-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.whiskey}
                          </p>
                        </div>
                      )}
                      {datalist.vodka === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Vodka-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.vodka}
                          </p>
                        </div>
                      )}
                      {datalist.rum === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Rum-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.rum}
                          </p>
                        </div>
                      )}
                      {datalist.gin === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            {" "}
                            Gin
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.gin}
                          </p>
                        </div>
                      )}
                      {datalist.tequila === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Tequil
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.tequila}
                          </p>
                        </div>
                      )}
                      {datalist.beer === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Beer-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.beer}
                          </p>
                        </div>
                      )}
                      {datalist.red_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Red Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.red_wine}
                          </p>
                        </div>
                      )}
                      {datalist.white_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            White Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.white_wine}
                          </p>
                        </div>
                      )}
                      {datalist.rose_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Rose Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.rose_wine}
                          </p>
                        </div>
                      )}
                      {/* <div className="new--year--menu--content--p1">
                        <p className="new--year--menu--content--p--12">
                          9. Rose Wine
                        </p>
                        <p className="new--year--menu--content--p--13">
                          {datalist.whiskey}
                        </p>
                      </div> */}
                      {datalist.sparkling_wine === "" ? null : (
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Sparkling Wine
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.sparkling_wine}
                          </p>
                        </div>
                      )}

                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].food_starter,
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].non_alcoholic_beverages,
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].special_offering,
                        }}
                      ></p>
                    </div>
                  )}
                  {activeDetail === "detail" && (
                    <div className="new--year--menu--content1">
                      <div className="new--year--menu--content--1">
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Entertainment-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.entertainment}
                          </p>
                        </div>
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Start Time-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.start_time}
                          </p>
                        </div>
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            End Time-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.end_time}
                          </p>
                        </div>
                        <div className="new--year--menu--content--p1">
                          <p className="new--year--menu--content--p--12">
                            Kids-
                          </p>
                          <p className="new--year--menu--content--p--13">
                            {datalist.kids_friendly === "true" ? (
                              <>Yes</>
                            ) : (
                              <>No</>
                            )}
                          </p>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data[0].tc,
                          }}
                        ></p>
                      </div>
                    </div>
                  )}
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const formdata = new FormData();
  formdata.append("user_id", userdata ? userdata.id : null);
  formdata.append("bid_id", "0");
  formdata.append("coupon_code", selectedCoupon || "");
  formdata.append("selected_pass_id", params.id);
  formdata.append("name[]", custmerlist);
  formdata.append("gender[]", totalgenderlist);
  formdata.append("couple_qty", couplecount || 0);
  formdata.append("male_qty", malecount || 0);
  formdata.append("female_qty", femalecount || 0);
  formdata.append("carrot_use", isChecked);
  formdata.append("pay_percent", 100);

  const handleSubmitLeadform = (e) => {
    e.preventDefault();
    console.log('Placing Order: ', Commons.formToJson(formdata));

    // Your API endpoint URL
    const apiUrl = "https://partywitty.com/master/APIs/Carnival/PlaceOrder";

    // Making a POST request using fetch API
    fetch(apiUrl, {
      method: "POST",

      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("summerydata", JSON.stringify(data.data));
        localStorage.setItem("pay_percent", JSON.stringify(data.pay_percent));
        console.log("Summary:", data.data);
        setNextpagedata(data.data);
        datasendnextpage(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const datasendnextpage = (nextPageData) => {
    // window.location.href = `/nextPage?nextPageData=${JSON.stringify(
    //   nextPageData
    // )}`;
    window.location.href = "/nextPage";
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openedCoupon, setOpenedCoupon] = useState(null);
  const [imageSrcs, setImageSrcs] = useState({ vector });
  const paybleamountdata = maleamount + femaleamount + coupleamount + 5.9;
  const handleSubmit1 = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_id", userdata.id);
      formDataToSend.append("payableAmount", paybleamountdata);

      // Include the selected coupon code in the form data
      if (selectedCoupon) {
        formDataToSend.append("coupon_code", selectedCoupon);
      }

      const response = await fetch(
        "https://partywitty.com/master/Api/getDataPost/applyCoupon",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const responseData = await response.json();

      if (responseData.status === false) {
        setResponseMessage(responseData.msg);
        localStorage.setItem("discount", JSON.stringify(responseData.result));
        setBookData(responseData.result);

        if (responseData.result) {
        } else {
          setShowResponse(true);
        }
      } else {
        setResponseMessage("Error in processing the request");
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };
  const handleToggleDescription = (couponCode) => {
    setOpenedCoupon(openedCoupon === couponCode ? null : couponCode);
    // setImageSrc(openedCoupon === couponCode ? vector : vector1);
    if (!imageSrcs[couponCode]) {
      // Set the image source for the clicked coupon code
      setImageSrcs({
        ...imageSrcs,
        [couponCode]: vector1, // Replace 'new_image_url' with your desired image URL
      });
    } else {
      // Remove the image source for the clicked coupon code
      const updatedImageSrcs = { ...imageSrcs };
      delete updatedImageSrcs[couponCode];
      setImageSrcs(updatedImageSrcs);
    }
  };
  const [activeTab, setActiveTab] = useState("coupons");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const carretussesprice = parseInt(datalist.usableCarrot);
  return (
    <div className="new--year--book--a--table--book--now--1 mt-3">
      <div className="new--year--book--a--table--book--now">
        <div className="new--year--order--cart">
          <div className="new--year--your--order">
            {/**
            <Link to={"/new--year"}>
              <img src={arrow}></img>
            </Link>
             */}
            <p className="New--year--page--design--All--passes1--card--content--p--blue">
              Your Order
            </p>
          </div>
        </div>

        <div className="new--year--your--order--book-a-table--card--1">
          {malecount === 0 ? null : (
            <>
              {" "}
              {data.map((e) => (
                <div className="new--year--your--order--book-a-table--card">
                  <p className="new--year--cart--stag--couple">
                    Male Stag Passes
                  </p>
                  <div className="New--year--page--design--All--passes1--card1">
                    <div className="New--year--page--design--All--passes1--card--img">
                      <img src={url + e.icon}></img>
                      <div className="new--year--page--img--button--dj">
                        <div className="dj--nights--img">
                          <img src={Group}></img>
                          <button className="dj--nights--img-btn1">
                            With DJ Nights
                          </button>
                        </div>
                        <div className="dj--nights--img">
                          <button className="dj--nights--img-btn3">Pass</button>
                        </div>
                      </div>
                    </div>

                    <div className="New--year--page--design--All--passes1--card--content">
                      <div className="New--year--page--design--All--passes1--card--content--1">
                        <div className="New--year--page--all--passes1--card-p--9">
                          <p className="New--year--page--design--All--passes1--card--content--1--p">
                            {e.name.slice(0, 20)}
                          </p>
                          <div className="new--year--card--content2 ">
                            <div className="new--year--card--content2--1">
                              <p className="New--year--page--design--All--passes1--card--content--1-12--p1 pt-3">
                                {e.start_time} - {e.end_time}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1-12--p1 ">
                                {e.clubAddress}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="New--year--page--design--All--passes1--card--content--1-12">
                          <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                            ₹{maleamount / malecount}
                          </p>
                          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                            minimum Spend
                          </p>

                          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                            Kids Allowed
                          </p>
                        </div>
                      </div>

                      <div className="new--year--page--border--line"></div>
                      <div className="new--year--menu--details">
                        {["bottom"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <button onClick={toggleDrawer2(anchor, true)}>
                              {" "}
                              <p className="new--year--page--more--detail">
                                Menu
                              </p>
                            </button>
                            <Drawer
                              anchor={anchor}
                              open={menuState.bottom}
                              onClose={toggleDrawer2(anchor, false)}
                              PaperProps={{
                                sx: {
                                  margin: "15px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                },
                              }}
                            >
                              {list2(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}

                        {["bottom"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <button onClick={toggleDrawer3(anchor, true)}>
                              {" "}
                              <p className="new--year--page--more--detail">
                                Details
                              </p>
                            </button>
                            <Drawer
                              anchor={anchor}
                              open={detailState.bottom}
                              onClose={toggleDrawer3(anchor, false)}
                              PaperProps={{
                                sx: {
                                  margin: "15px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                },
                              }}
                            >
                              {list3(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="new--year--name--1--1--2">
                        {inputFields.map((field, index) => (
                          <div className="new--year--name" key={field.id}>
                            <div className="new--year--name1-2">
                              <label>Full Name</label>
                              <input
                                type="text"
                                placeholder="Enter"
                                value={field.value}
                                onChange={(e) =>
                                  handleInputChangemalestag(index, e)
                                }
                              ></input>
                            </div>
                            <div className="new--year--name--1">
                              <p>M</p>
                              <input
                                type="checkbox"
                                checked={genders11[index] === "M"}
                                onChange={() =>
                                  handleCheckboxChange1(index, "M")
                                }
                              ></input>

                              <p>F</p>
                              <input
                                type="checkbox"
                                checked={genders11[index] === "F"}
                                onChange={() =>
                                  handleCheckboxChange1(index, "F")
                                }
                              ></input>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {femalecount === 0 ? null : (
            <>
              {" "}
              {data.map((e) => (
                <div className="new--year--your--order--book-a-table--card">
                  <p className="new--year--cart--stag--couple">
                    {" "}
                    Female Stag Passes
                  </p>
                  <div className="New--year--page--design--All--passes1--card1">
                    <div className="New--year--page--design--All--passes1--card--img">
                      <img src={url + e.icon}></img>
                      <div className="new--year--page--img--button--dj">
                        <div className="dj--nights--img">
                          <img src={Group}></img>
                          <button className="dj--nights--img-btn1">
                            {e.entertainment}
                          </button>
                        </div>
                        <div className="dj--nights--img">
                          <button className="dj--nights--img-btn3">Pass</button>
                        </div>
                      </div>
                    </div>

                    <div className="New--year--page--design--All--passes1--card--content">
                      <div className="New--year--page--design--All--passes1--card--content--1">
                        <div className="New--year--page--all--passes1--card-p--9">
                          <p className="New--year--page--design--All--passes1--card--content--1--p">
                            {e.name.slice(0, 20)}
                          </p>
                          <div className="new--year--card--content2--1-2">
                            <p className="New--year--page--design--All--passes1--card--content--p--blue">
                              {e.clubName}
                            </p>
                            <div className="new--year--card--content2--1">
                              <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                {e.start_time} - {e.end_time}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                {e.clubAddress}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="New--year--page--design--All--passes1--card--content--1-12">
                          <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                            ₹{femaleamount / femalecount}
                          </p>
                          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                            Price
                          </p>
                          <p className="new--year--page--table6--package">
                            10 Seats Left
                          </p>
                          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                            Kids not Allowed
                          </p>
                        </div>
                      </div>

                      <div className="new--year--page--border--line"></div>
                      <div className="new--year--menu--details">
                        {["bottom"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <button onClick={toggleDrawer2(anchor, true)}>
                              {" "}
                              <p className="new--year--page--more--detail">
                                Menu
                              </p>
                            </button>
                            <Drawer
                              anchor={anchor}
                              open={menuState.bottom}
                              onClose={toggleDrawer2(anchor, false)}
                              PaperProps={{
                                sx: {
                                  margin: "15px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                },
                              }}
                            >
                              {list2(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}

                        {["bottom"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <button onClick={toggleDrawer3(anchor, true)}>
                              {" "}
                              <p className="new--year--page--more--detail">
                                Details
                              </p>
                            </button>
                            <Drawer
                              anchor={anchor}
                              open={detailState.bottom}
                              onClose={toggleDrawer3(anchor, false)}
                              PaperProps={{
                                sx: {
                                  margin: "15px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                },
                              }}
                            >
                              {list3(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    <div className="new--year--name--1--1--2">
                      {inputFieldsfemale.map((field, index) => (
                        <div className="new--year--name" key={field.id}>
                          <div className="new--year--name1-2">
                            <label>Full Name</label>
                            <input
                              type="text"
                              placeholder="Enter"
                              value={field.value}
                              onChange={(e) => handleInputChange(index, e)}
                            ></input>
                          </div>
                          <div className="new--year--name--1">
                            <p>M</p>
                            <input
                              type="checkbox"
                              checked={genders[index] === "M"}
                              onChange={() => handleCheckboxChange(index, "M")}
                            ></input>

                            <p>F</p>
                            <input
                              type="checkbox"
                              checked={genders[index] === "F"}
                              onChange={() => handleCheckboxChange(index, "F")}
                            ></input>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {couplecount === 0 ? null : (
            <>
              {data.map((e) => (
                <div className="new--year--your--order--book-a-table--card">
                  <p className="new--year--cart--stag--couple">Couple Passes</p>
                  <div className="New--year--page--design--All--passes1--card1">
                    <div className="New--year--page--design--All--passes1--card--img">
                      <img src={url + e.icon}></img>
                      <div className="new--year--page--img--button--dj">
                        <div className="dj--nights--img">
                          <img src={Group}></img>
                          <button className="dj--nights--img-btn1">
                            {e.entertainment}
                          </button>
                        </div>
                        <div className="dj--nights--img">
                          <button className="dj--nights--img-btn3">Pass</button>
                        </div>
                      </div>
                    </div>

                    <div className="New--year--page--design--All--passes1--card--content">
                      <div className="New--year--page--design--All--passes1--card--content--1">
                        <div className="New--year--page--all--passes1--card-p--9">
                          <p className="New--year--page--design--All--passes1--card--content--1--p">
                            {e.name.slice(0, 20)}
                          </p>
                          <div className="new--year--card--content2--1-2">
                            <p className="New--year--page--design--All--passes1--card--content--p--blue">
                              {e.clubName}
                            </p>
                            <div className="new--year--card--content2--1">
                              <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                {e.start_time} - {e.end_time}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                {e.clubAddress}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="New--year--page--design--All--passes1--card--content--1-12">
                          <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                            ₹{coupleamount / couplecount}
                          </p>
                          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                            Price
                          </p>
                          <p className="new--year--page--table6--package">
                            10 Seats Left
                          </p>
                          <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                            Kids not Allowed
                          </p>
                          <div className="new--year--stag-female">
                            <img src={female}></img>
                            <p>F</p>
                          </div>
                        </div>
                      </div>

                      <div className="new--year--page--border--line"></div>
                      <div className="new--year--menu--details">
                        {["bottom"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <button onClick={toggleDrawer2(anchor, true)}>
                              {" "}
                              <p className="new--year--page--more--detail">
                                Menu
                              </p>
                            </button>
                            <Drawer
                              anchor={anchor}
                              open={menuState.bottom}
                              onClose={toggleDrawer2(anchor, false)}
                              PaperProps={{
                                sx: {
                                  margin: "15px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                },
                              }}
                            >
                              {list2(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}

                        {["bottom"].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <button onClick={toggleDrawer3(anchor, true)}>
                              {" "}
                              <p className="new--year--page--more--detail">
                                Details
                              </p>
                            </button>
                            <Drawer
                              anchor={anchor}
                              open={detailState.bottom}
                              onClose={toggleDrawer3(anchor, false)}
                              PaperProps={{
                                sx: {
                                  margin: "15px",
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                },
                              }}
                            >
                              {list3(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <div className="couple--list-name">
                      <div className="couple--male--list">
                        {" "}
                        {inputFieldscouple.map((field, index) => (
                          <div
                            className="new--year--name--couple"
                            key={field.id}
                          >
                            <div className="new--year--couple1">
                              <label>Mr.</label>
                              <input
                                type="text"
                                placeholder="Enter"
                                value={field.value}
                                onChange={(e) => handleInputChange11(index, e)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="couple--male--list">
                        {inputFieldscouplemrs.map((field, index) => (
                          <div
                            className="new--year--name--couple"
                            key={field.id}
                          >
                            <div className="new--year--couple1">
                              <label>Mrs.</label>
                              <input
                                type="text"
                                placeholder="Enter"
                                value={field.value}
                                onChange={(e) => handleInputChangemrs(index, e)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="new--year--page--border--line1-1"></div>
        <div className="carret--use">
          <div className="carret--checkbox">
            <input
              type="checkbox"
              checked={isChecked === true}
              onChange={handleCheckboxClick}
            />
            <label className="m-0" htmlFor="checkbox">
              Select For Use Carrot Balance
            </label>
          </div>
          <p className="m-0">
            Available Carrot Balance:{" "}
            <span>
              <img src={carretimage}></img>{" "}
              {isChecked === true ? (
                <>{userdata.virtual_wallet - carretussesprice}</>
              ) : (
                <>{userdata.virtual_wallet}</>
              )}
            </span>{" "}
          </p>
        </div>
        <div className="new--year--page--border--line1-1"></div>
        <div className="new--year--order--cart">
          <div className="new--year--your--order--1">
            <img src={coupon}></img>
            <p className="New--year--page--add--more">Use Coupon</p>
          </div>
          <div className="new--year--your--order" onClick={handleOpen}>
            <img src={arrow1}></img>
          </div>
        </div>
        <div className="p--button">
          <div className="new--year--order--cart1">
            <p className="New--year--page--add--more">Item Total</p>
            <p className="New--year--page--add--more">
              ₹{(maleamount + femaleamount + coupleamount).toFixed(2)}
            </p>
          </div>
          {isChecked === true ? (
            <div className="new--year--order--cart1">
              <p className="New--year--page--add--more">Carret Uses</p>
              <p className="New--year--page--add--more">
                <span>- ₹{datalist.usableCarrot}</span>
              </p>
            </div>
          ) : null}

          <div className="new--year--order--cart1">
            <p className="New--year--page--add--more">
              Platform & Other Charges:
            </p>
            <p className="New--year--page--add--more">₹5.90</p>
          </div>
          <div className="new--year--page--border--line1-1"></div>
          <div className="new--year--your--order--1--2">
            <p className="New--year--page--add--more">Grand Total </p>
            <p className="New--year--page--add--more">
              ₹{" "}
              {bookdata.length === 0 ? (
                <>
                  {isChecked === true ? (
                    <>
                      {maleamount +
                        femaleamount +
                        coupleamount +
                        5.9 -
                        carretussesprice}
                    </>
                  ) : (
                    <>{maleamount + femaleamount + coupleamount + 5.9}</>
                  )}{" "}
                </>
              ) : (
                bookdata.newPayableAmount - carretussesprice
              )}
            </p>
          </div>
          <div className="new--year--page--border--line1-1"></div>
          <button
            className="summery--page--submit--pay--now"
            onClick={handleSubmitLeadform}
          >
            Pay Now
          </button>
        </div>
      </div>
      <Modal
        className="mobile--view--coupons"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit1}>
            <div className="coupons--header">
              <p className="m-0">Coupons</p>
              <p className=" m-0" onClick={handleClose}>
                <img src={group1}></img>
              </p>
            </div>

            <div className="form-group">
              <div className="coupons--list p-2">
                <div className="coupons--tabs--card">
                  <p
                    // className="partywity--coupons"
                    className={`partywity--coupons ${activeTab === "coupons" ? "active-tab" : ""
                      }`}
                    onClick={() => handleTabClick("coupons")}
                  >
                    PW Coupons
                  </p>
                  <p
                    // className="partywity--coupons "
                    className={`partywity--coupons ${activeTab === "voucher" ? "active-tab" : ""
                      }`}
                    onClick={() => handleTabClick("voucher")}
                  >
                    My Vouchers
                  </p>
                </div>
                <div className="coupons--apply--text d-flex justify-content-between mt-2">
                  <p className="type--coupons--text m-0">
                    Type coupon code here
                  </p>
                </div>

                <div className="coupons--serch">
                  <input
                    type="text"
                    placeholder="What’s most important to Know"
                    style={{
                      color: "white",
                      padding: "10px",
                    }}
                  ></input>
                </div>
                {activeTab === "coupons" && (
                  <>
                    {couponsdata.map((coupon) => (
                      <div className="coupons--code--design">
                        <div
                          className={`coupons--card mb-2 ${selectedCoupon === coupon.code
                            ? "selected-button"
                            : ""
                            }`}
                          key={coupon.id}
                          value={coupon.code}
                        >
                          <div className="coupons--headericon">
                            <div className="coupon--header--icon">
                              <img src={group}></img>
                            </div>
                            <div className="dis--card--coupons">
                              <div className="dis--card--coupons--voucher"></div>
                              <div className="off--discount--text">
                                {coupon.discount_type === "amount" ? (
                                  <h5 className="m-0">
                                    UP TO {coupon.discount} OFF
                                  </h5>
                                ) : (
                                  <h5 className="m-0">
                                    {coupon.discount}% OFF UP TO{" "}
                                    {coupon.capping}
                                  </h5>
                                )}

                                <p className="m-0">
                                  Save {coupon.capping} with this code
                                </p>
                                <div className="c--code">
                                  <h3>{coupon.code}</h3>
                                </div>
                              </div>
                              <div className="dropdown--terms">
                                <div
                                  className="terms--condition"
                                  onClick={() =>
                                    handleToggleDescription(coupon.code)
                                  }
                                >
                                  View Details &nbsp;
                                  <img
                                    src={imageSrcs[coupon.code] || vector}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          {openedCoupon === coupon.code && (
                            <div className="term--discription">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: coupon.terms_conditions,
                                }}
                              ></p>
                            </div>
                          )}

                          {/* {data.totalAmount >= coupon.min_spent ? ( */}
                          <button
                            id={`select-coupon-${coupon.id}`}
                            onClick={() => {
                              if (selectedCoupon === coupon.code) {
                                setSelectedCoupon(null); // Deselect if already selected
                              } else {
                                setSelectedCoupon(coupon.code); // Select if not selected
                              }
                            }}
                            className={`coupon-button ${selectedCoupon === coupon.code
                              ? "selected-button"
                              : ""
                              }`}
                          >
                            Tab To Apply
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
