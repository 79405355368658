import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineWatchLater } from "react-icons/md";
import { Link } from "react-router-dom";
import "./Homepage.css";
import { ShimmerThumbnail } from "react-shimmer-effects";


export default function UpcommingEvents() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const handleMouseLeave = (videoId) => {
    videoId.pause();
    setActiveVideo(null);
  };
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };

  const url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude.toString());
            setLongitude(longitude.toString());
            fetchData(latitude, longitude);
          } else {
            console.error(
              "Error: Unable to get coordinates from the position object."
            );
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const fetchData = (latitude, longitude) => {
    const formData = new FormData();
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    axios
      .post(
        "https://partywitty.com/master/APIs/ClubEvent/upcomingEvents",
        formData
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.status === true) {
          setUpcomingEvents(response.data.data);
        } else {
          setUpcomingEvents([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const eventUrl = (e) => {
    return `/${(e.cityName?.replace(/ /g, "-") || "").toLowerCase()}/${(
      e.areaName?.replace(/ /g, "-") || ""
    ).toLowerCase()}/${(
      e.clubName?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}/event/${(
      e.name?.trim().split(/ +/).join("-").replace(/ /g, "-") || ""
    ).toLowerCase()}`;
  };
  const dummyArray = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  return (
    <>
    {isLoading &&  dummyArray.map((item) => (
        <ShimmerThumbnail key={item.id} height={250} width={310} rounded />
      )) }
    {
      upcomingEvents.length === 0 ? null :  (
        <>
      <div className="partygoer1">
      <p className="partygoer--p">Upcomming Events</p>
    </div>
      <div className="club_Card_Container">
        {isLoading ? (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              padding: "4rem",
              color: "#fff",
              textTransform: "uppercase",
            }}
          >
            Loading...
          </span>
        ) : upcomingEvents.length === 0 ? (
          <span
            style={{
              textAlign: "center",
              width: "100%",
              padding: "4rem",
              color: "#fff",
              textTransform: "uppercase",
            }}
          >
            No Event Available
          </span>
        ) : (
          upcomingEvents.map((e, index) => (
            <Link to={eventUrl(e)} key={index} className="event-link">
              <div className="event-view-card1">
                {e?.image ? (
                  <img src={url + e.image} alt="img" />
                ) : e?.video ? (
                  <video
                    id={index}
                    controls
                    loop
                    onClick={toggleMute}
                    onMouseLeave={() =>
                      handleMouseLeave(document.getElementById(index))
                    }
                  >
                    <source src={url + e.video} type="video/mp4" />
                  </video>
                ) : (
                  <p>No content available</p>
                )}
                <div className="event-view-card1-content">
                  <div className="event-view-card1-content1">
                    <h4 className="p-0,m-0">{e?.name}</h4>
                    <p className="event-view-card1-content1-p">{e?.clubName}</p>
                    <p className="event-view-card1-content1-p1">{e?.distance} km</p>
                  </div>
                  <div className="event-view-card1-content2">
                    <div className="date">
                      <span>
                        <AiTwotoneCalendar />
                      </span>{" "}
                      <p>{e?.event_date}</p>
                    </div>
                    <div className="date">
                      <span>
                        <MdOutlineWatchLater />
                      </span>{" "}
                      <p>{e?.event_time}</p>
                    </div>
  
                    <button className="event-view-all-button">Book Now</button>
                  </div>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
      </>)
    }
   
    </>
  );
}
