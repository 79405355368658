import React from "react";
import { IoIosArrowUp } from "react-icons/io";
import ReactStars from "react-stars";
import review from "../AboutClub/image/review.jpg";
import { MdOutlineStar } from "react-icons/md";

export default function Review() {
  return (
    <div className="about-club-details">
      <div className="about-club-detail-collapse">
        <p>Review</p>
        <span>
          <IoIosArrowUp />
        </span>
      </div>
      <div className="club-review-card">
        <div className="club-review-card-number">
          <div className="club-review-card-number1">
            <div className="club-review-card-number2">
              <h3>4.7</h3>
              <p>Over All Review</p>
            </div>
            <div className="club-review-card-number3">
              <>
                <ReactStars
                  count={5}
                  name="rating"
                  size={24}
                  position="center"
                  activeColor="#ffd700"
                  isHalf={true}
                  edit={false}
                  style={{
                    display: "inline-block",
                    fontSize: "30px",
                    color: "#ffd700",
                  }}
                  starSpacing="25px"
                />
              </>
              <p>From 3.5K reviews</p>
            </div>
          </div>
          <div className="club-rating-parameter">
            <div className="club-rating-parameter-card">
              <div className="club-rating-parameter-card-name">
                <p>Services</p>
              </div>
              <div className="rating-parameter-white">
                <div className="rating-parameter-pink"></div>
              </div>
              <div className="club-rating-parameter-card-no">
                <p>3.8k</p>
              </div>
            </div>
            <div className="club-rating-parameter-card">
              <div className="club-rating-parameter-card-name">
                <p>Pricing</p>
              </div>
              <div className="rating-parameter-white">
                <div className="rating-parameter-pink"></div>
              </div>
              <div className="club-rating-parameter-card-no">
                <p>3.8k</p>
              </div>
            </div>
            <div className="club-rating-parameter-card">
              <div className="club-rating-parameter-card-name">
                <p>Music</p>
              </div>
              <div className="rating-parameter-white">
                <div className="rating-parameter-pink"></div>
              </div>
              <div className="club-rating-parameter-card-no">
                <p>3.8k</p>
              </div>
            </div>
            <div className="club-rating-parameter-card">
              <div className="club-rating-parameter-card-name">
                <p>Hygiene</p>
              </div>
              <div className="rating-parameter-white">
                <div className="rating-parameter-pink"></div>
              </div>
              <div className="club-rating-parameter-card-no">
                <p>3.8k</p>
              </div>
            </div>
            <div className="club-rating-parameter-card">
              <div className="club-rating-parameter-card-name">
                <p>Food</p>
              </div>
              <div className="rating-parameter-white">
                <div className="rating-parameter-pink"></div>
              </div>
              <div className="club-rating-parameter-card-no">
                <p>3.8k</p>
              </div>
            </div>
            <div className="club-rating-parameter-card">
              <div className="club-rating-parameter-card-name">
                <p>Ambience</p>
              </div>
              <div className="rating-parameter-white">
                <div className="rating-parameter-pink"></div>
              </div>
              <div className="club-rating-parameter-card-no">
                <p>3.8k</p>
              </div>
            </div>
          </div>
        </div>
        <div className="all-write-review-card">
          <div className="club-write-review-card">
            <div className="club-write-review-card1">
              <div className="club-write-review-img">
                <img src={review}></img>
              </div>
              <div className="club-write-review-name">
                <p>Srishti Pal</p>
                <span>
                  {" "}
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                </span>
              </div>
            </div>
            <div className="club-write-review-card2">
                <div>
              <p className="club-write-review-card2-p">Amazing Food</p>
              <p className="club-write-review-card2-p1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
              </div>
              <div className="club-review-multi-image">
                <img src={review}></img>
                <img src={review}></img>
              </div>
            </div>
          </div>
          <div className="club-write-review-card">
            <div className="club-write-review-card1">
              <div className="club-write-review-img">
                <img src={review}></img>
              </div>
              <div className="club-write-review-name">
                <p>Srishti Pal</p>
                <span>
                  {" "}
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                </span>
              </div>
            </div>
            <div className="club-write-review-card2">
                <div>
              <p className="club-write-review-card2-p">Amazing Food</p>
              <p className="club-write-review-card2-p1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
              </div>
              <div className="club-review-multi-image">
                <img src={review}></img>
                <img src={review}></img>
              </div>
            </div>
          </div>
          <div className="club-write-review-card">
            <div className="club-write-review-card1">
              <div className="club-write-review-img">
                <img src={review}></img>
              </div>
              <div className="club-write-review-name">
                <p>Srishti Pal</p>
                <span>
                  {" "}
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                </span>
              </div>
            </div>
            <div className="club-write-review-card2">
                <div>
              <p className="club-write-review-card2-p">Amazing Food</p>
              <p className="club-write-review-card2-p1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
              </div>
              <div className="club-review-multi-image">
                <img src={review}></img>
                <img src={review}></img>
              </div>
            </div>
          </div>
          <div className="club-write-review-card">
            <div className="club-write-review-card1">
              <div className="club-write-review-img">
                <img src={review}></img>
              </div>
              <div className="club-write-review-name">
                <p>Srishti Pal</p>
                <span>
                  {" "}
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                  <MdOutlineStar />
                </span>
              </div>
            </div>
            <div className="club-write-review-card2">
                <div>
              <p className="club-write-review-card2-p">Amazing Food</p>
              <p className="club-write-review-card2-p1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
              </div>
              <div className="club-review-multi-image">
                <img src={review}></img>
                <img src={review}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
