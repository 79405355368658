import './Happening-music-view.css';
import party from "./image/party.jpg"
import clubimgg from "./image/clubimgg.png"
import part1 from "./image/part1.avif"
import { HiOutlineLocationMarker } from 'react-icons/hi';



import livemusic from "./image/livemusic.avif"

import dancefloor from "./image/dancefloor.avif"



const MusicViewButton = () => {


    return (

        <div class="deals">
            <div className='image-slider'>
                <div id="carouselExampleIndicators" class="carousel slide">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={party} class="d-block w-100" alt="..."></img>
                        </div>
                        <div class="carousel-item">
                            <img src={party} class="d-block w-100" alt="..."></img>
                        </div>
                        <div class="carousel-item">
                            <img src={party} class="d-block w-100" alt="..."></img>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div class="happeningParty-tags-allcards">

                <div class="happeningParty-tags">
                    <div class="happeningParty-tags-card">
                        <img class="happeningParty-tags-card-live-music" src={livemusic}></img>
                        <p>Live Music</p>
                    </div>
                </div>
                <div class="happeningParty-tags">
                    <div class="happeningParty-tags-card">
                        <img class="happeningParty-tags-card-live-music" src={livemusic}></img>
                        <p>Special Night</p>
                    </div>
                </div>
                <div class="happeningParty-tags">
                    <div class="happeningParty-tags-card">
                        <img class="happeningParty-tags-card-dancefloor" src={dancefloor}></img>
                        <p>Celeb Night</p>
                    </div>
                </div>
                <div class="happeningParty-tags">
                    <div class="happeningParty-tags-card">
                        <img class="happeningParty-tags-card-dancefloor" src={dancefloor}></img>
                        <p>Dance Floor</p>
                    </div>
                </div>
                <div class="happeningParty-tags">
                    <div class="happeningParty-tags-card">
                        <img class="happeningParty-tags-card-live-music" src={livemusic}></img>
                        <p>Band Night</p>
                    </div>
                </div>
            </div>
            {/* left part start */}
            <div class="deals-and-offer">
                <div class="second">
                    <div class="1st-part">
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button deals-acc" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        <p class="bold">Music Joner</p>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                                    <div class="accordion-body">
                                        <p class="white"><span><input type="checkbox"></input> </span> Sufi Sitdown</p>
                                        <p class="white"><span><input type="checkbox"></input> </span>Western Music</p>
                                        <p class="white"><span><input type="checkbox"></input> </span>RockNight</p>
                                        <p class="white"><span><input type="checkbox"></input> </span>Bollywood</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed deals-acc" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        <p class="bold">Cover Charges</p>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                                    <div class="accordion-body">


                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed deals-acc" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        <p class="bold">Occupancy</p>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
                                    <div class="accordion-body">

                                        <div class="checkbox-with-content">
                                            <p class="white"><span><input type="checkbox"></input></span>Male</p>
                                            <p class="white"><span><input type="checkbox"></input></span>Female</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="third">
                    <div class="deal-third">
                        <div class="deal-card">
                            <div class="fst-partt">
                                <img src={part1} alt='img'></img>
                            </div>
                            <div class="scond-partt">
                                <div class="scond-part-first">
                                    <img src={clubimgg} alt='img'></img>
                                    <button>Reach The Party</button>
                                </div>
                            </div>
                            <div class="third-partt">
                                <div className='third-partt-first'>
                                    <h3>Location</h3>
                                    <p><span><HiOutlineLocationMarker /></span> Habitat Center,Ahinsa Khand 1,Indirapuram, Ghaziabad, UP 201010</p>
                                </div> </div>
                        </div>
                        <div class="deal-card">
                            <div class="fst-partt">
                                <img src={part1} alt='img'></img>
                            </div>
                            <div class="scond-partt">
                                <div class="scond-part-first">
                                    <img src={clubimgg} alt='img'></img>
                                    <button>Reach The Party</button>
                                </div>
                            </div>
                            <div class="third-partt">
                                <div className='third-partt-first'>
                                    <h3>Location</h3>
                                    <p><span><HiOutlineLocationMarker /></span> Habitat Center,Ahinsa Khand 1,Indirapuram, Ghaziabad, UP 201010</p>
                                </div> </div>
                        </div>
                        <div class="deal-card">
                            <div class="fst-partt">
                                <img src={part1} alt='img'></img>
                            </div>
                            <div class="scond-partt">
                                <div class="scond-part-first">
                                    <img src={clubimgg} alt='img'></img>
                                    <button>Reach The Party</button>
                                </div>
                            </div>
                            <div class="third-partt">
                                <div className='third-partt-first'>
                                    <h3>Location</h3>
                                    <p><span><HiOutlineLocationMarker /></span> Habitat Center,Ahinsa Khand 1,Indirapuram, Ghaziabad, UP 201010</p>
                                </div> </div>
                        </div>
                        <div class="deal-card">
                            <div class="fst-partt">
                                <img src={part1} alt='img'></img>
                            </div>
                            <div class="scond-partt">
                                <div class="scond-part-first">
                                    <img src={clubimgg} alt='img'></img>
                                    <button>Reach The Party</button>
                                </div>
                            </div>
                            <div class="third-partt">
                                <div className='third-partt-first'>
                                    <h3>Location</h3>
                                    <p><span><HiOutlineLocationMarker /></span> Habitat Center,Ahinsa Khand 1,Indirapuram, Ghaziabad, UP 201010</p>
                                </div> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default MusicViewButton;