import PartnerwithUsHeader from "../Navbar/Partnerwithus-Header";
import "./Services.css";
import c1 from "./image/c1.png";
import c2 from "./image/c2.png";
import c3 from "./image/c3.png";
import c4 from "./image/c4.png";
import c5 from "./image/c5.png";
import c6 from "./image/c6.png";
import { Link } from "react-router-dom";

export default function PartnerwithUs() {
  return (
    <>
      <div className="aboutusPage">
        <PartnerwithUsHeader />
        <section className="about--banner club_bg">
          <div className="main--box">
            <div className="title--name">
              <h3>Partner with us</h3>
            </div>
          </div>
        </section>
        <div className="aboutus">
          <section className="main--feacture career">
            <div className="main--box">
              <div className="title">
                <h2>Why should clubs be with Party Witty? </h2>
                <p>
                  We at <a href="/">party witty</a> are Primary focused and
                  driven towards an objective to enhance the experience for an
                  Artist. Problem solving motive towards the points that comes
                  across as challenges for the artist on regular basis, we have
                  come here as a solution to all those highlighted problems.
                </p>
              </div>
              <div className="grid--box book">
                <div className="c--box">
                  <img src={c1} alt=""></img>
                  <div className="content">
                    <h3>Get a Distinguished Digital Presence</h3>
                    <p>
                      Joining hands with Party Witty gets your club a
                      distinguished digital presence. We do that with attractive
                      graphics and banners of trending deals being offered and
                      parties on your calendar. As our platform keeps party
                      goers updated about your latest offer for them, we
                      essentially become a platform of Digital Advertising for
                      you.
                    </p>
                  </div>
                </div>
                <div className="c--box">
                  <img src={c2} alt=""></img>
                  <div className="content">
                    <h3>Get a step closer to party lovers</h3>
                    <p>
                      Party Witty has been working closely with 5000+ artists
                      which bring their own fan club along with them. Pools of
                      party lovers on our platform are regular club visitors.
                      Don’t miss this chance of targeting the right people for
                      the best deals you have to offer to them.
                    </p>
                  </div>
                </div>
                <div className="c--box">
                  <img src={c3} alt=""></img>
                  <div className="content">
                    <h3>Reap on the benefits of surge</h3>
                    <p>
                      Occasions like New Year and Christmas essentially call for
                      a party for almost everyone. This is the time when a
                      regular club visitor will be accompanied by not so regular
                      visitors. This is also the time of surge with some
                      existing and new guests around. A surge in guests gives
                      you a chance to play on deal prices and number of offers
                      you can put to plate for the target audience.
                    </p>
                  </div>
                </div>
                <div className="c--box">
                  <img src={c4} alt=""></img>
                  <div className="content">
                    <h3>Set your own dynamic Deal price</h3>
                    <p>
                      At Party Witty, we always value clubs associated with us.
                      Thus, we let them be the price maker of the deals they
                      have to offer. Plan and play on the deal prices based on
                      the number of guests seeking deals and the number of
                      passes left to be floated free.{" "}
                    </p>
                  </div>
                </div>
                <div className="c--box">
                  <img src={c5} alt=""></img>
                  <div className="content">
                    <h3>Enjoy the share in artist’s fan base</h3>
                    <p>
                      All the artists at Party Witty are professional &
                      verified. They have worked well for years before they
                      could be on board with us. So, they would carry for you an
                      established fan base. The more artists you book with us,
                      the more groups of fan clubs are attracted to your club
                      availing the services and deals offered by you.
                    </p>
                  </div>
                </div>
                <div className="c--box">
                  <img src={c6} alt=""></img>
                  <div className="content">
                    <h3>Come closer to latest party trends</h3>
                    <p>
                      As Party Witty works on live updates on deals, guests,
                      artist booking etc., we bring to you the exact statistics
                      and numbers on guests and their interests. This will give
                      you an opportunity to plan your vouchers, parties, deals &
                      coupons better. Transparency in these statistics broadens
                      your vision as a club and lets you strategically plan the
                      club's revenue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center sticky--btn--center">
                <Link to="/registration-login">
                  {" "}
                  <button type="button" className="btn--theame--2">
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
