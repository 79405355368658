import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";


export default function CarnivalPassDetails(props) {
    const pass = props.pass;

    return (
        <>
            {pass?.id}
            {pass?.name}
        </>
    );
}