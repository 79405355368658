// import { Base64 } from "js-base64";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import star from "../../favorate--hangout--page/image/star.png";
import "../../Homepage/Homepage.css";

const index = ({ ClubData, isFulldetails ,length }) => {

  
  // Image BaseURL
  const url = "https://partywitty.com/master/assets/uploads/";

  // Drestrucure of ClubData
  const {
    id,
    cityName,
    areaName,
    addon,
    discount,
    name,
    clubThumbnail,
    profile_pic,
    venueType,
    totalRating,
    address,
    totalAverageRating,
   
    distance,
  } = ClubData;

  // ValidURL ACC to isFulldetails
  const RedirectURL = isFulldetails
  ? cityName
  ? `/${cityName.trim().replace(/ /g, '-').toLowerCase()}/${areaName.trim().replace(/ /g, '-').toLowerCase()}/club/${name.trim().split(/ +/).join('-').replace(/ /g, '-').toLowerCase()}`
  : "/"
: cityName
  ? `/${cityName.trim().replace(/ /g, '-').toLowerCase()}/${areaName.trim().replace(/ /g, '-').toLowerCase()}/${name.trim().split(/ +/).join('-').replace(/ /g, '-').toLowerCase()}`
  : "/";
    
  return (
    <div key={id}>
      <LazyLoad offset={5}>
        <Link to={RedirectURL} className="bookatable-routes">
          <div className="Favorate--hangout--all--thumbnail--card1">
            <div className="Favorate--hangout--all--thumbnail--card--img">
              {profile_pic ||clubThumbnail ? (
                <img
                  className="Favorate--hangout--all--thumbnail--card--img--1--img"
                  src={
                    url +
                    `${
                      clubThumbnail && clubThumbnail.length > 0
                        ? clubThumbnail[0].img
                        : profile_pic
                    }`
                  }
                  alt={name}
                />
              ) : null}
              <div className="Favorate--hangout--all--thumbnail--card--img--1"></div>
              <div className="Favorate--hangout--all--thumbnail--card--2">
                      <div className="Favorate--hangout--all--thumbnail--card--2--1">
                      <div className="favorate--add--on">
                        <div className="favorate--add--on--card">
                          <p className="favorate--add--on--card--p">{addon}</p>
                          <p className="favorate--add--on--card--p--1">%</p>
                        </div>
                        <div className="favorate--add--on--p">
                          <p className="favorate--add--on--p--1">Add On</p>
                          <p className="favorate--add--on--p--2">EXTRA</p>
                        </div>
                      </div>
                      <div className="favorate--offered--card">
                        <p className="favorate--offered--card--1">Offered in</p>
                        <div className="favorate--offered--card--days">
                        
                          <p>Last 30 Days</p>
                      
                        </div>
                      </div>
                      <div className="favorate--add--on">
                        <div className="favorate--add--on--p">
                          <p className="favorate--add--on--p--1--1">Overall</p>
                          <p className="favorate--add--on--p--2--1">Discount</p>
                        </div>
                        <div className="favorate--add--on--card1">
                          <p className="favorate--add--on--card--p">{discount}</p>
                          <p className="favorate--add--on--card--p--1">%</p>
                        </div>
                      </div>
                    </div>
                    </div>
            </div>
            <div className="Favorate--hangout--card--content">
              <div className="Favorate--hangout--card--content--1">
                <p className="Favorate--hangout--card--content--1--p1">
                  {name.substring(0, 15)}...
                </p>
                <p className="Favorate--hangout--card--content--1--p2">
                  {venueType}
                </p>
              </div>
              <div className="favorate--hangout--address">
                {totalRating !== 0 ? (
                  <div className="favorate--hangout--review--card1">
                    <div className="favorate--hangout--review--card">
                      <img src={star} alt="Star Icon"></img>
                      <p>{totalAverageRating}</p>
                    </div>
                  </div>
                ) : (
                  <div className="favorate--hangout--review--card5"></div>
                )}
                <p className="favorate--hangout--address--p2">{distance} km</p>
                <p className="favorate--hangout--address--p2">
                  {address.substring(0, 10)}...
                </p>
              </div>
            </div>
          </div>
        </Link>
      </LazyLoad>
     
    </div>
  );
};

export default index;
