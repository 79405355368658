import "./PackagePrice.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineCheck } from "react-icons/ai";
import Label from "./image/Label.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Thanks from "./image/Thanks.png";
import Group1 from "./image/Group1.png";
import Group2 from "./image/Group2.png";
import Group3 from "./image/Group3.png";
import Group4 from "./image/Group4.png";
import Group5 from "./image/Group5.png";
import Group6 from "./image/Group6.png";
import Group7 from "./image/Group7.png";
import { Link } from "react-router-dom";

export default function PackagePrice() {
  const [activeButton, setActiveButton] = useState("monthly");
  // const [active1Button, setActive1Button] = useState("gold");
  const [openPlanEvent, setOpenPlanEvent] = useState(false);
  const [openPlanEvent4, setOpenPlanEvent4] = useState(false);
  
  const handleOpenPlanEvent = () => {
    setOpenPlanEvent(true);
  };
  const handleClosePlanEvent = () => {
    setOpenPlanEvent(false);
  };
  const handleOpenPlanEvent4 = () => {
    setOpenPlanEvent4(true);
  };
  const handleClosePlanEvent4 = () => {
    setOpenPlanEvent4(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 const [data, setData] = useState([]);
  const [plans, setPlans] = useState({
    monthly: [],
    quarterly: [],
    yearly: [],
  });
  const fetchURL = async () => {
    try {
      const response = await axios.get("https://partywitty.com/master/APIs/Common/getSubscriptionPlan");
      console.log('API Response:', response.data); // Log the response data
  
      // Ensure the API response structure is correctly accessed
      if (response.data && response.data.data && response.data.data.length > 0) {
        const fetchedData = response.data.data[0]; // Get the first object from the data array
  
        // Set state with the fetched data
        setPlans({
          monthly: fetchedData.monthly || [],
          quarterly: fetchedData.quarterly || [],
          yearly: fetchedData.yearly || [],
        });
      } else {
        console.log('No data available.');
        setPlans({
          monthly: [],
          quarterly: [],
          yearly: [],
        });
      }
    } catch (error) {
      console.log('API Error:', error);
      setPlans({
        monthly: [],
        quarterly: [],
        yearly: [],
      });
    }
  };
  useEffect(() => {
    fetchURL();
  }, []);
  const handleButtonClick = (frequency) => {
    setActiveButton(frequency);
  };

  return (
    <div className="Package--Price">
       <Link to="https://partywitty.com/master/Club/Login">
      <div className="package-price-buisness-login">
        <button>Buisness Login</button>
      </div>
      </Link>
      <div className="Package--Price--card">
        <div className="Package--Price--content--information">
          <h3>Maximize your bookings and pay only for conversions. Get Leads for FREE!</h3>
          <p className="Package--Price--content--information--p">
             Start with ₹0 as Your Setup Fees
          </p>
          <div className="Package--Price--card--button">
  <button
    className={activeButton === "monthly" ? "active" : ""}
    onClick={() => handleButtonClick("monthly")}
  >
    Monthly
  </button>
  <button
    className={activeButton === "quarterly" ? "active" : ""}
    onClick={() => handleButtonClick("quarterly")}
  >
    Quarterly
  </button>
  <button
    className={activeButton === "yearly" ? "active" : ""}
    onClick={() => handleButtonClick("yearly")}
  >
    Annual
  </button>
</div>
        

          <div className="Package--Price--all--cards1">
  {plans[activeButton] && plans[activeButton].length > 0 ? (
    plans[activeButton].map((plan) => (
      <div key={plan.id} className={`Package--Price--all--cards--${plan.name.toLowerCase()}`}>
          {plan.name === 'Gold' && (
          <img className='Package--Price--all--cards--gold-img' src={Label} alt='img' />
        )}
        <h2>{plan.name}</h2>
        <div className="Package--Price--all--cards--price">
          <p>₹{plan.price} <span>(Taxes Extra)</span></p>
        </div>
        <div className="package--price--border--line"></div>
        <p className="Package--Price--all--cards--features-title">Key Features</p>
        <p className="Package--Price--all--cards--description">
          {plan.des ? <div dangerouslySetInnerHTML={{ __html: plan.des }} /> : "Description not available"}
        </p>
       
      
       
      </div>
    ))
  ) : (
    <p>No plans available for this frequency.</p>
  )}
         </div>
        </div>
        {/* mobile--view--content */}
        <div className="pacakage--price1--question--buisness--h2">
          <h2>How PartyWitty Help Your business to grow</h2>
        </div>
        <div className="pacakage--price1--question--buisness1">
          <div className="pacakage--price1--question--buisness">
            <h2>7 x Boost in sales</h2>
            <p>
              Access to a wider customer base through PartyWitty's platform can
              lead to increased bookings for events and parties, translating to
              higher revenue streams for the club
            </p>
          </div>
          <div className="pacakage--price1--question--buisness">
            <h2>Expanded Customer Base</h2>
            <p>
              By joining PartyWitty, clubs and bars can tap into a new customer
              base, attracting individuals and event planners who use the
              platform to discover and book venues for various social
              gatherings.
            </p>
          </div>
          <div className="pacakage--price1--question--buisness">
            <h2>Targeted Marketing and Exposure</h2>
            <p>
              Benefit from PartyWitty's marketing efforts and exposure to a
              specifically targeted audience interested in event hosting. This
              can enhance the club's visibility and attract party planners
              looking for suitable venues.
            </p>
          </div>
          <div className="pacakage--price1--question--buisness">
            <h2>Streamlined Booking Process</h2>
            <p>
              PartyWitty provides a user-friendly platform for customers to
              easily browse and book Group Booking, Table Booking & events. This
              streamlined process can attract more customers and simplify the
              reservation process for the club.
            </p>
          </div>
          <div className="pacakage--price1--question--buisness">
            <h2>Networking Opportunities</h2>
            <p>
              Being part of PartyWitty provides networking opportunities with
              corporate customer base,, businesses, and individuals looking for
              venues. This can lead to long-term partnerships and repeat
              business.
            </p>
          </div>
        </div>
        <div className="pacakage--price1--question--leads">
          <div className="pacakage--price1--question--leads--1">
            <h2>Pay Per Conversions, Not Per Leads</h2>
            <p>
              Boost your club's success with conversion-based charges through
              WhatsApp interactions. Our commissions are adaptable and
              customised per conversion, ensuring a flexible solution for
              turning party seekers into your loyal customers.
            </p>
          
          </div>
        </div>
        {/* <div className="Package--Price--all--cards1-commision">
          <div className="Package--Price--all--cards--silver_commision">
                  <p className="Package--Price--all--cards--silver--p">
                    Commission 10% (Taxes Extra)
                  </p>
          </div>
          <div className="Package--Price--all--cards--gold_commision">
                  <p className="Package--Price--all--cards--silver--p">
                    Commission 8% (Taxes Extra)
                  </p>
              
          </div>
          <div className="Package--Price--all--cards--silver_commision">
                  <p className="Package--Price--all--cards--silver--p">
                    Commission 12% (Taxes Extra)
                  </p>
               
          </div>
        </div> */}
        {/* mobile content */}
        <div className="pacakage--price1--question--buisness--h2">
          <h2>Who can benefit from Partywitty?</h2>
        </div>
        <div className="partywiity--benifit">
          <p>Party Enthusiasts</p>
          <p>Corporate Event Planners</p>
          <p>Restaurants and Cafes</p>
          <p>Venue Owners and Managers</p>
          <p>Clubs and Nightlife Venues</p>
        </div>
        <div className="pacakage--price1--question--leads--all-pricing">
          <h2>All Pricing Plans Include:</h2>
          <div className="pacakage--price1--question--leads--all-pricing--1">
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Verified Customers</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>SAAS Model easy lead access</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Party Photograph Share</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Menu Display on the Dashboard</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Digital Restaurant/Club Profile</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Tailor-made Bidding Mechanism</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Real-time notifications</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>New Bid Notification through Partner App</p>
            </div>
            <div className="Package--Price--all--cards--silver--tick--points1">
              <span>
                <AiOutlineCheck />
              </span>
              <p>Easy collect through Partywitty Pay</p>
            </div>
          </div>
        </div>
        <div className="pacakage--price1--question--leads--all-pricing--mobile">
          <h2>All Pricing Plans Include</h2>
          <div className="pacakage--price1--question--leads--all-pricing--1">
            <img src={Group1}></img>
            <img src={Group2}></img>
            <img src={Group3}></img>
            <img src={Group4}></img>
            <img src={Group5}></img>
            <img src={Group6}></img>
            <img src={Group7}></img>
          </div>
        </div>
        <div className="pacakage--price1--question--frequently--asked">
          <h2>FREQUENTLY ASKED QUESTION.</h2>
          <div className="pacakage--price1--question--frequently--asked--1">
            <div>
              <div className="pacakage--price1--question--frequently--asked--1-1">
                <button>1</button>
                <p>
                  <b>
                    How does your platform source and verify the authenticity of
                    the leads?
                  </b>
                </p>
              </div>
              <p className="pacakage--price1--question--frequently--asked--1-1-1">
                We guarantee lead authenticity through chat and over-call
                verification. Chat interactions gather essential data, while
                personal calls confirm interest, ensuring that leads delivered
                to you are genuine and genuinely interested.
              </p>
            </div>
            <div className="pacakage--price1--question--frequently--asked--border-line"></div>
            <div>
              <div className="pacakage--price1--question--frequently--asked--1-1">
                <button>2</button>
                <p>
                  <b>
                    What kind of data analytics and insights will I have access
                    to on your platform?
                  </b>
                </p>
              </div>
              <p className="pacakage--price1--question--frequently--asked--1-1-1">
                On our platform, you'll gain access to valuable data analytics
                and insights, including the total number of bids, engagement
                metrics such as the number of profile views your club receives,
                and detailed information on your menu and packages. These
                insights empower you to make informed decisions and optimize
                your club's offerings effectively.
              </p>
            </div>
            <div className="pacakage--price1--question--frequently--asked--border-line"></div>
            <div>
              <div className="pacakage--price1--question--frequently--asked--1-1">
                <button>3</button>
                <p>
                  <b>
                    What kind of support and assistance will you offer during
                    the onboarding & integration process?
                  </b>
                </p>
              </div>
              <p className="pacakage--price1--question--frequently--asked--1-1-1">
                You'll have a dedicated dashboard for convenience, and we grant
                access to make changes to the front end. Plus, our 24/7 support
                is available over call and chat to ensure a smooth and
                successful integration.
              </p>
            </div>
            <div className="pacakage--price1--question--frequently--asked--border-line"></div>
            <div>
              <div className="pacakage--price1--question--frequently--asked--1-1">
                <button>4</button>
                <p>
                  <b>
                    How do you ensure that my club's bids for potential
                    customers are competitive and likely to be accepted over
                    other clubs on your dashboard?
                  </b>
                </p>
              </div>
              <p className="pacakage--price1--question--frequently--asked--1-1-1">
                Our platform ensures the competitiveness of your club's bids
                through a two-fold approach. We use chat verification and
                over-call verification to confirm the authenticity and interest
                of potential customers. This process maximizes the likelihood of
                your bids being accepted.
              </p>
            </div>
            <div className="pacakage--price1--question--frequently--asked--border-line"></div>
            <div>
              <div className="pacakage--price1--question--frequently--asked--1-1">
                <button>5</button>
                <p>
                  <b>
                    How do you measure the success of your service, and what key
                    performance indicators (KPIs) do you track?
                  </b>
                </p>
              </div>
              <p className="pacakage--price1--question--frequently--asked--1-1-1">
                We measure the success of our service through several key
                performance indicators (KPIs) that include lead conversion
                rates, engagement metrics such as profile views, bid acceptance
                rates, and overall customer satisfaction.
              </p>
            </div>
            <div className="pacakage--price1--question--frequently--asked--border-line"></div>
            <div>
              <div className="pacakage--price1--question--frequently--asked--1-1">
                <button>6</button>
                <p>
                  <b>
                    Do you offer any guarantees or refunds if the leads
                    generated do not meet the promised criteria?
                  </b>
                </p>
              </div>
              <p className="pacakage--price1--question--frequently--asked--1-1-1">
                We have a unique approach to guaranteeing results. You only pay
                our commission once a lead converts into a patron for your club.
                This way, you can be confident that you're investing in leads
                that genuinely impact your business, ensuring a win-win scenario
                for both parties.
              </p>
            </div>

            <div className="pacakage--price1--question--frequently--asked--border-line"></div>
          </div>
        </div>
      </div>
      {/* pop--up start */}
      <Modal
        open={openPlanEvent}
        onClose={handleClosePlanEvent}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className="responsive-modal-1-1">
          <div className="Plan--your--event--popup">
            <div className="Plan--your--event--popup--1--1">
              <div className="package--deals--talk--to--expert">
                <h2>Talk To An Expert</h2>
                <div className="cls--btn" onClick={handleClosePlanEvent}>
                  X
                </div>
              </div>
              <div className="package--deals--talk--to--expert--content">
                <h2>Business Details</h2>
                <div className="package--deals--talk--to--expert--content--1">
                  <div className="package--deals--talk--to--expert--content--1--2">
                    <label>Name of Business</label>
                    <input type="number" placeholder="Enter" />
                  </div>
                  <div className="package--deals--talk--to--expert--content--1--2">
                    <label>Type of Business</label>
                    <input type="text" placeholder="Enter" />
                  </div>
                </div>
                <div className="package--deals--talk--to--expert--content--1">
                  <div className="package--deals--talk--to--expert--content--1--21">
                    <label>Address</label>
                    <input type="text" placeholder="Enter" />
                  </div>
                </div>
                <div className="package--deals--talk--to--expert--content--1">
                  <div className="package--deals--talk--to--expert--content--1--2">
                    <label>Business Number</label>
                    <input type="text" placeholder="Enter" />
                  </div>
                  <div className="package--deals--talk--to--expert--content--1--2">
                    <label>Whatsapp Number</label>
                    <input type="text" placeholder="Enter" />
                  </div>
                </div>
              </div>
              <div className="package--deals--talk--to--expert--content1">
                <h2>Owner Details</h2>
                <div className="package--deals--talk--to--expert--content--1">
                  <div className="package--deals--talk--to--expert--content--1--2">
                    <label>Owner Name</label>
                    <input type="number" placeholder="Enter" />
                  </div>
                  <div className="package--deals--talk--to--expert--content--1--2">
                    <label>Owner Number</label>
                    <input type="number" placeholder="Enter" />
                  </div>
                </div>
              </div>
              <button onClick={handleOpenPlanEvent4}>Continue</button>
            </div>

            <Modal
              open={openPlanEvent4}
              onClose={handleClosePlanEvent4}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box className="responsive-modal-1">
                <div className="lead--genrate--thankew-page">
                  <div className="Plan--your--event--popup-page2">
                    <p className="popup--thankew1">
                      Your Query is important to us. We'll be reverting soon.
                    </p>
                    <img src={Thanks}></img>
                    <p className="popup--thankew2">Thanks for your patience!</p>
                  </div>
                </div>
                <div className="login-otp-close-button-1">
                  <button onClick={handleClosePlanEvent4}>
                    <p>X</p>
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </Box>
      </Modal>
      {/* pop--up end */}
      <div className="package_price_floating_button">
        <Link to="/business-registration">
            <button>Select The Plan</button>
        </Link>
      </div>
    </div>
  );
}
