import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner from "../Club-aboutus/image/celeb.jpg";
import { FaDirections } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";
import { MdFavoriteBorder } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

export default function Banner() {
  return (
    <div className="club-detail-banner">
      <Carousel controls={false} interval={6000} indicators={false}>
        <Carousel.Item>
          <img src={banner} alt="img" />
        </Carousel.Item>
      </Carousel>
      <div className="club-detail-bg-tansparent"></div>
      <div className="club-detail-direction">
        <button><span><FaDirections /></span>Direction</button>
        <button><span><IoShareSocial /></span>Share</button>
        <button><span><MdFavoriteBorder /></span>Favourite</button>
      </div>
      <div className="club-details">
        <div className="club-details-field">
          <div className="club-rating-card">
           <p>4.8</p><span><FaStar /></span> 
          </div>
          <h1 className="club-name">Big Boyz Lounge</h1>
          <p className="club-address">Rajouri Garden, West delhi, delhi</p>
          <p className="club-time"><span>Closes in 1 Minute</span> - 12 Pm - 10 Pm (Today)</p>
        </div>
        <div className="club-details-popup">
          <button className="club-book-a-table">Book A Table <span><FaRegArrowAltCircleRight /></span></button>
          <button className="club-party-package">Group Booking <span><FaRegArrowAltCircleRight /></span></button>
        </div>
      </div>
    </div>
  );
}
