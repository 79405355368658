import React, { useEffect, useState } from "react";
import axios from "axios";
import "./multyitem.css";

import { LuSearch } from "react-icons/lu";

function SearchbarMobile() {
  const [data, setData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  var url = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      const ClubApi = new FormData();
      ClubApi.append("latitude", latitude);
      ClubApi.append("longitude", longitude);
  
      axios
        .post("https://partywitty.com/master/APIs/Common/recommendedClub", ClubApi)
        .then((response) => {
          const dataArray = Object.values(response.data.data);
          // console.log("new", dataArray); // This will log the converted array to the console
          setData(dataArray);
          // setStartTime(dataArray[0].open_time);
          // setEndTime(dataArray[0].close_time);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [latitude, longitude]);

  
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterValue(value);
    applyFilter(value);
  };

  const applyFilter = (value) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };
  const [userInput, setUserInput] = useState("");
  const [options, setOptions] = useState([]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserInput(inputValue);
  
    const newOptions = getOptions(inputValue);
    setOptions(newOptions);
  };

  const getOptions = (inputValue) => {
  

    return data.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };


  const renderOptions = () => {
    if (userInput === "" || options.length === 0) {
      return null; 
    }

    return (
      <div className="clubnameby_filter">
        {options.map((option, index) => (
          <a href={`/clubpage/${option.id}`} style={{ textDecoration: "none" }}>
            <div className="clubname_images">
              <img src={url + option.profile_pic}></img>

              <p key={index}>
                {option.name} ({option.clubCategoryName})
              </p>
            </div>
          </a>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="searchbar_field--mobile">
        <div style={searchBarStyleMobile}>
          <input
            style={inputStyle}
            type="text"
            placeholder="Search..."
            value={userInput}
            onChange={handleInputChange}
          />
          <span>
            <LuSearch />
          </span>
        </div>
        {renderOptions()}
      </div>
    </>
  );
}

const searchBarStyleMobile = {
  height: "40px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#120829",
  borderRadius: "20px",
  padding: "10px 10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.3s ease-in-out",
  color: "white",
  border: "1px solid grey",
};

const inputStyle = {
  border: "none",
  outline: "none",
  fontSize: "12px",
  width: "100%",
  backgroundColor: "#120829",
  color: "white",
};

export default SearchbarMobile;
