import './Happeningparty.css';
import happeningpartyimage from "./images/happeningpartyimage.avif"
import livemusic from "./images/livemusic.avif"
import event from "./images/event.avif"
import dancefloor from "./images/dancefloor.avif"
import clubimg from "./images/clubimg.jpg"
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BsSearch } from 'react-icons/bs';



export default function Happeningparty() {
    return (
        <div class="happeningParty">
            <img class="happeningParty-img" src={happeningpartyimage}></img>
            <div class="happeningParty-all-elements">
                <div class="happeningParty-search">
                    <form>
                        <input type="search" id="site-search" class="happeningParty-searchtag" placeholder="Search..."></input>
                        <button class="happeningParty-searchbutton"><span>< BsSearch/></span></button>
                    </form>
                </div>
                <div class="happeningParty-tags-allcards">
                    <div class="happeningParty-tags">
                        <div class="happeningParty-tags-card">
                            <img class="happeningParty-tags-card-event" src={event}></img>
                            <p>All Events</p>
                        </div>
                    </div>
                    <div class="happeningParty-tags">
                        <div class="happeningParty-tags-card">
                            <img class="happeningParty-tags-card-live-music" src={livemusic}></img>
                            <p>Live Music</p>
                        </div>
                    </div>
                    <div class="happeningParty-tags">
                        <div class="happeningParty-tags-card">
                            <img class="happeningParty-tags-card-live-music" src={livemusic}></img>
                            <p>Special Night</p>
                        </div>
                    </div>
                    <div class="happeningParty-tags">
                        <div class="happeningParty-tags-card">
                            <img class="happeningParty-tags-card-dancefloor" src={dancefloor}></img>
                            <p>Dance Floor</p>
                        </div>
                    </div>
                    <div class="happeningParty-tags">
                        <div class="happeningParty-tags-card">
                        <img class="happeningParty-tags-card-dancefloor" src={dancefloor}></img>
                            <p>Dance Floor</p>
                        </div>
                    </div>
                    <div class="happeningParty-tags">
                        <div class="happeningParty-tags-card">
                            <img class="happeningParty-tags-card-live-music" src={livemusic}></img>
                            <p>Band Night</p>
                        </div>
                    </div>
                </div>
                <div class="happeningParty-allclubs">
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                    <div class="happeningParty-allclubs-card">
                        <div class="img">
                            <img class="lawn-img" src={clubimg} alt="img"></img>
                        </div>
                        <div class="content">
                            <p class="bold">Castle Barbeque</p>
                            <p class="white"> <HiOutlineLocationMarker /> Delhi Aerocity,Aerocity</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}