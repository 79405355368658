import AboutClubDetails from './AboutClub';
import Banner from './Banner';
import Index from './Gallery';
import ClubOther from './Other/Index';
import PromotionalLine from './PromotionalLine';
import RecommenedClub from './RecommenedClub';
import Review from './Review/Review';
import './Style.css';
import TopBanner from './TopBanner';

const ClubDetail = () =>{
    
    return(
        <div className='club-detail-package'>
        <TopBanner/>
        <Banner/>
        <PromotionalLine/>
        <div className='about-club-detail'>
        <AboutClubDetails />
        <Index/>
        <Review/>
        <ClubOther/>
        <RecommenedClub/>
        </div>
        </div>
    );
}
export default ClubDetail;