import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <ModalContext.Provider value={{ toggle, setToggle }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
