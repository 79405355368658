import React, { useEffect, useState } from "react";
import "./Clubpage.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";

import moment from "moment";
import Loginpopup from "../Navbar/Loginmodal";
import { Base64 } from "js-base64";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 1,
};

function PackageOffers() {
 

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [bookdata, setBookData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [id, setId] = useState(null); // Declare id state variable

  const { city, area, clubname } = useParams();
  const clubN = clubname;

  const clubid = new FormData();
  clubid.append("club_id", id);
  var url = "https://partywitty.com/master/assets/uploads/";
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [responseFromPostApi, setResponseFromPostApi] = useState([]);

 
  const [data, setData] = useState([]);

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        setData(response.data.data);
        setCategories(response.data.data.party_packages);
        const id = response.data.data.id;
       
        setId(id); 
      })
      .catch((error) => {
        console.error(error);
    
      });
  }, []);
  

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);

    console.log(categoryId);
  
    const formData = new FormData();
    formData.append("package_id", categoryId);

    
    fetch("https://partywitty.com/master/Api/getDataPost/clubPackageDetails", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          setResponseFromPostApi([]);
        } else {
          setResponseFromPostApi(data.data);
          setMenu(
            data.data[0].img
              .split("systumm")
              .filter((fileName) => fileName.trim() !== "")
          );
        }

       
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const rotateAnimationHandler = (props, state) => {
    const transitionTime = props.transitionTime + "ms";
    const transitionTimingFunction = "ease-in-out";
    let slideStyle = {
      display: "block",
      maxHeight: "630px",

      transitionTimingFunction: transitionTimingFunction,
      msTransitionTimingFunction: transitionTimingFunction,
      MozTransitionTimingFunction: transitionTimingFunction,
      WebkitTransitionTimingFunction: transitionTimingFunction,
      OTransitionTimingFunction: transitionTimingFunction,
      transform: `rotate(0)`,
      position:
        state.previousItem === state.selectedItem ? "relative" : "absolute",
      inset: "0 0 0 0",
      zIndex: state.previousItem === state.selectedItem ? "1" : "-2",
      opacity: state.previousItem === state.selectedItem ? "1" : "0",
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
    return {
      slideStyle,
      selectedStyle: {
        ...slideStyle,
        opacity: 1,
        position: "relative",
        zIndex: 2,
        filter: `blur(0)`,
      },
      prevStyle: {
        ...slideStyle,
        transformOrigin: " 0 100%",
        transform: `rotate(${
          state.previousItem > state.selectedItem ? "-45deg" : "45deg"
        })`,
        opacity: "0",
        filter: `blur( ${
          state.previousItem === state.selectedItem ? "0px" : "5px"
        })`,
      },
    };
  };

  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [remainingDates, setRemainingDates] = useState([]);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isTextFieldOpen1, setIsTextFieldOpen1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedPax, setSelectedPax] = useState(null);
  const [selectedkids, setSelectedKids] = useState(null);
  const handleTextFieldClick1 = () => {
    setIsTextFieldOpen1(true);
  };

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    today.setHours(0, 0, 0, 0); 

    const initialStartTime = moment(startTime, "h:mm A").toDate();
    const initialEndTime = moment(endTime, "h:mm A").toDate();

    
    initialStartTime.setMinutes(
      Math.ceil(initialStartTime.getMinutes() / 60) * 60
    );

    const intervals = [];
    const remainingDates = [];

    
    for (let i = 0; i < 3; i++) {
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const lastDayOfMonth = new Date(
        currentYear,
        currentMonth + 1,
        0
      ).getDate();

      for (let j = today.getDate(); j <= lastDayOfMonth; j++) {
        const date = new Date(currentYear, currentMonth, j);
        remainingDates.push(date);
      }

    
      today.setMonth(currentMonth + 1);
      today.setDate(1); 
    }

    while (initialStartTime <= initialEndTime) {
      const hours = initialStartTime.getHours();
      const minutes = initialStartTime.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; 
      const formattedTime = `${formattedHours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${ampm}`;
      intervals.push(formattedTime);

    
      initialStartTime.setTime(initialStartTime.getTime() + 30 * 60 * 1000);
    }

    setTimeIntervals(intervals);
    setRemainingDates(remainingDates);
  }, [startTime, endTime]);

  useEffect(() => {
   
  }, [startTime, endTime]);
  const [showCard1, setShowCard1] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCard1(!showCard1);
    }, 2000);

    return () => clearInterval(interval);
  }, [showCard1]);
  const numbersArray = [];

  for (let i = 1; i <= 100; i++) {
    numbersArray.push(i);
  }
  const [active2, setActive2] = useState(false);
  const handleClick2 = () => {
    setActive2(!active2);
  };
  const [selectedOption, setSelectedOption] = useState("yes"); // Default selected option

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [showNoOfKids, setShowNoOfKids] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);
  const [showBookingContent, setShowBookingContent] = useState(false);
  const [formData, setFormData] = useState({
    club_id: id,
    user_id: userdata ? userdata.id : "",
    guest: "",
    kids: "",
    no_of_kids: "",
    booking_date: "",
    booking_time: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateSelection = (data, type) => {
    setFormData((prevData) => ({
      ...prevData,
      [type === "date" ? "booking_date" : "booking_time"]: data,
    }));
  };
  
  const handleDrop1Click = () => {
    
    setShowBookingContent(!showBookingContent);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (userdata == null) {
      
        setShowLoginPopup(true);
       
      } else {
        const formDataToSend = new FormData();

        for (const field in formData) {
          formDataToSend.append(field, formData[field]);
        }

        const response = await fetch(
          "https://partywitty.com/master/Api/sendData/submitBookTable",
          {
            method: "POST",
            body: formDataToSend,
          }
        );

        const responseData = await response.json();

        if (responseData.status === true) {
          setResponseMessage(responseData.msg);
          setBookData(responseData.data);
          localStorage.setItem(
            "paymentData",
            JSON.stringify(responseData.clientSecret)
          );

          window.location.href = "/payment"; 

          if (responseData.data) {
           
          } else {
            setShowResponse(true);
          }
        } else {
        
          setResponseMessage("Error in processing the request");
        }
      }
    } catch (error) {
      setResponseMessage("An error occurred");
    }
  };

  return (
    <>
      <div className="package_images_card ">
        {showLoginPopup && <Loginpopup />}

        <div className="package--web--view">
          {categories && categories.length > 0 ? (
            categories.map((e, index) => (
              <a
                href={`/${e.cityName?.replace(/ /g, "-")}/${e.areaName?.replace(
                  / /g,
                  "-"
                )}/${e.clubName
                  ?.trim()
                  .split(/ +/)
                  .join("-")
                  .replace(/ /g, "-")}/${e.title
                  .split("+")
                  .map((part) => part.trim().replace(/ /g, "-"))
                  .join("-")
                  .toLowerCase()}`} 
                className="pkg_img_name_card"
                key={e.id} 
              >
                <img src={url + e.packageThumbnail} alt={e.title} />
                <p className="mt-3">
                  {e.title} <br /> <span></span>
                </p>
              </a>
            ))
          ) : (
            <div className="nodata--found">No data found</div>
          )}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
           
            overflow="hidden"
            sx={{
              ...style1,
              width: 365,
              height: 560,
            }}
          >
            <Carousel
              showIndicators
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  hasNext && (
                    <button
                      className="nav_btn nav_btn_right"
                      onClick={clickHandler}
                    >
                      <MdNavigateNext />
                     
                    </button>
                  )
                );
              }}
              renderArrowPrev={(clickHandler, hasNext) => {
                return (
                  hasNext && (
                    <button
                      onClick={clickHandler}
                      className="nav_btn nav_btn_left"
                    >
                      <GrFormPrevious />
                    </button>
                  )
                );
              }}
              renderIndicator={(clickHandler, isSelected, index) => {
                return (
                  <li
                    onClick={clickHandler}
                    className={`ind ${isSelected ? "active" : ""}`}
                    key={index}
                    role="button"
                  />
                );
              }}
             
              transitionTime={310}
              animationHandler={rotateAnimationHandler}
              swipeable={false}
            >
              {menu.map((URL, index) => (
                <div className="slide">
                  <img alt="sample_file" src={url + URL} key={index} />
                </div>
              ))}
            </Carousel>
          </Box>
        </Modal>
      
      </div>
      {/* <div className="book--a--table--menu--page--menu--card">
        <div className="package_images ">
          {categories && categories.length > 0 ? (
            categories.map((e) => (
              <div
                onClick={() => handleCategoryClick(e.id)}
                className="pkg_img_name "
              >
                <div className="menu--mobile--select--1">
                  <input type="radio"></input>
                  <p key={e.id} onClick={handleOpen}>
                    {e.title} <br></br> <span></span>
                  </p>
                </div>
                <img
                  onClick={handleOpen}
                  src={url + e.packageThumbnail}
                  alt={e.title}
                />
              </div>
            ))
          ) : (
            <div className="nodata--found">No data found</div>
          )}
        </div>

       
      </div> */}
    </>
  );
}
export default PackageOffers;
