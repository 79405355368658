import './Services.css';

import directbooking from "./image/directbooking.png"
import icon2 from "./image/icon2.png"
import icon3 from "./image/icon3.png"
import icon4 from "./image/icon4.png"
import icon5 from "./image/icon5.png"
import icon6 from "./image/icon6.png"


export default function Asanartist() {
    return (
        <div className="aboutusPage">

            <section className="about-banner artist">
                <div className="main--box">
                    <div className="title-name">
                        <h3>As an Artist</h3>
                    </div>
                </div>
            </section>
            <div className="aboutus">
                <section className="main--feacture artist">
                    <div className="main--box row">
                        <div className='artist-description'>
                            <div className="description-box">
                                <h2>Our Mission</h2>
                                <p>To increase the reach of an artist's profile and optimising their earnings with respect.</p>
                                <div className="border--line"></div>
                            </div>
                            <div className="description-box">
                                <h2>Our Vission</h2>
                                <p>To bring the best value proposition for an artist by channelizing the idea of newtworking.</p>
                                <div className="border--line"></div>
                            </div>
                        </div>
                        <div className="description-box">
                            <h2>Benefits for an Artist</h2>
                            <p>We at <a href="/">Party Witty</a> are Primary focused and driven towards an objective to
                                enhance the experience for an Artist.
                                <br>
                                </br>
                                Problem solving motive towards the points that comes across as challenges for the artist on regular
                                basis, we have come here as a solution to all those highlighted problems.
                            </p>



                            <div className="grid--box">
                                <div className="benefit-box">
                                    <img src={directbooking} alt=""></img>
                                    <h5>DIRECT BOOKING</h5>
                                    <p>All the shows and gigs will be booked real time along with the features of calendarization to have a
                                        organised work flow for an Artist at <a href="/">Party witty</a></p>
                                </div>
                                <div className="benefit-box">
                                    <img src={icon2} alt=""></img>
                                    <h5>UNLIMITED GIGS</h5>
                                    <p>Artist is not limited to countable gigs a months, it can be optimised by the number of days he has
                                        his calendar available with <a href="/">Party witty</a></p>
                                </div>
                                <div className="benefit-box">
                                    <img src={icon3} alt=""></img>
                                    <h5>REFERRAL EARNING</h5>
                                    <p>Growth is not just limited to the number of shows done by the artist booked through <a
                                        href="/">Party witty</a>
                                        An additional benefit for the fellow artist can be capitalized.
                                    </p>
                                </div>
                                <div className="benefit-box">
                                    <img src={icon4} alt=""></img>
                                    <h5>PROFILE SHOWCASE</h5>
                                    <p>Achievement and awards will be highlighted and showcase at the elevated platform through <a
                                        href="/">Party witty</a> application </p>
                                </div>
                                <div className="benefit-box">
                                    <img src={icon5} alt=""></img>
                                    <h5>TIMELY PAYOUT</h5>
                                    <p>Challenge of payout being delayed and risk of not getting the payment at all will be 100%
                                        eliminated.. at <a href="/">Party witty</a>
                                        All the payment will be made digitally and with seamless flow.
                                    </p>
                                </div>
                                <div className="benefit-box">
                                    <img src={icon6} alt=""></img>
                                    <h5>AUTOMATIC RATES ENHANCEMENT</h5>
                                    <p>Growth of an artist is directly proportional to the performance and its review. We shall promote the
                                        artist with the precise algorithm designed at the back to end to digitally enhance the price for the
                                        artist at the dynamic platform -<a href="/">Party witty</a> </p>
                                </div>
                            </div>
                            <div className="description-box">
                                <h3>Join with us today</h3>
                                <p>Our primary goal is developing a secure and customizable theme framework that meets the needs of the end web. Therefore, our customers are able to create websites using our templates as easy as 1-2-3! This process requires minimum knowledge of WordPress and coding, and extended documentation and our Support Team is always at your service. However, we ask you to keep in mind that sometimes issues occur not because of templates malfunction. There might be situations when it doesn’t depend on us and the framework.</p>
                                <div className="row">
                                    <div className="col-md-12 text-center sticky--btn--center">
                                        <button type="button" className="btn--theame--2">Join Now</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    );
}