import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedCheckoutForm from "./PaymentCheckoutform";

const stripePromise = loadStripe(
  "pk_live_51NmXWZSC4sP2pXi7rmsw54jdRSupeQKOU8M3dSVlLKxqtdUBcBmZN6cAFhZKBFFmjpH8sMdnHlj9ljSIabL6Ju2700y1gF21KX"
);

export default function PaymentPage() {
  var paymentData = JSON.parse(localStorage.getItem("paymentData"));
  const options = {
    // passing the client secret obtained from the server
    clientSecret: paymentData,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <InjectedCheckoutForm />
    </Elements>
  );
}
