import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Commons from "./Commons";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import carrotIcon from "../Assets/carret.png";
import couponIcon from "./image/coupon.png";
import arrowIcon from "./image/arrow1.svg";

const PassType = {
    Couple: 'Couple',
    Male: 'Male Stag',
    Female: 'Female Stag',
};

const user = Commons.getUserData();
const CDC = Commons.Format.Currency.decimalCount;

function CarnivaPassGuestList(props) {

    return (
        <>
        </>
    );
}

const pay_form_id = 'pay_form_id' + Math.random();

function CarnivaPassOrderSummary(props) {
    const pass = props.pass;
    const passID = pass?.passDetails?.id || pass?.id;
    const passStat = props.passStat;
    const totalPassCount = props.totalPassCount;
    const totalPassAmount = props.totalPassAmount;
    const platformFee = 5.9;
    // console.log('Pass: ', passID, totalPassCount, totalPassAmount, passStat, pass,);

    const [userPassDetails, setUserPassDetails] = useState({});
    const loadUserPassDetails = () => {
        const formData = new FormData();
        formData.append("pass_id", passID);
        formData.append("user_id", user?.id || null);
        formData.append("couple_qty", passStat[PassType.Couple]?.count || 0);
        formData.append("male_qty", passStat[PassType.Male]?.count || 0);
        formData.append("female_qty", passStat[PassType.Female]?.count || 0);

        Commons.doFetch('APIs/Carnival/PassDetails', formData).then(
            passData => {
                // console.log('Carol Usage Details: ', passID, passData, user, pass);

                if (passData?.data) {
                    const data = passData.data[0];
                    setUserPassDetails(data);
                }
            }
        );


    }

    useEffect(loadUserPassDetails, []);

    const usableCarrot = parseInt(userPassDetails?.usableCarrot || 0);
    // console.log('Carrot Usage: ', userPassDetails, user);

    const [usingCarot, setUsingCarot] = useState(true);
    const grandTotal = totalPassAmount + platformFee - (usingCarot ? usableCarrot : 0);

    const [selectedCoupon, setSelectedCoupon] = useState(null);
    let orderID = null;

    const customerList = [];
    const totalGenderList = [];

    const placeOrder = async (e) => {
        const orderForm = new FormData();
        orderForm.append("user_id", user?.id);
        orderForm.append("bid_id", "0");
        orderForm.append("coupon_code", selectedCoupon || "");
        orderForm.append("selected_pass_id", passID);
        orderForm.append("name[]", customerList);
        orderForm.append("gender[]", totalGenderList);
        orderForm.append("couple_qty", passStat[PassType.Couple]?.count || 0);
        orderForm.append("male_qty", passStat[PassType.Male]?.count || 0);
        orderForm.append("female_qty", passStat[PassType.Female]?.count || 0);
        orderForm.append("carrot_use", usingCarot);
        orderForm.append("pay_percent", 100);

        // console.log('Placing Order: ', Commons.formToJson(orderForm));

        const orderResult = await Commons.doFetch('APIs/Carnival/PlaceOrder', orderForm);
        // console.log('Order Result: ', orderResult);

        if (orderResult?.data && orderResult.data.length > 0) {
            const order = orderResult.data[0];
            console.log('Order Placed: ', order);
            orderID = order.order_id;
            window.location.href = `/payCarnivalPass/${orderID}`;
            // makePayment(order.order_id);
            // trigger payment
            // initPayment();
        }
    }

    const initPayment = () => {
        const form = document.getElementById(pay_form_id);
        if (form && form.submit && orderID) {
            console.log('Submit Payment Form: ', form, form.submit);
            form.submit();
        }
    }

    const makePayment = () => {
        const action = 'https://partywitty.com/master/APIs/Carnival/submitCarnivalPass';

        const formData = new FormData();
        formData.append("order_id", orderID);
        formData.append("payment_gateway", "phonepe");


        fetch(action, {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                console.log('Payment Response: ', response);
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Network response was not ok.");
                }
            })
            .then((data) => {
                const redirectUrl = data && data.redirectUrl; // Replace with the actual property name in your response
                console.log('Payment redirectUrl: ', redirectUrl);
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    // Handle the response data if no redirection URL is found
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const buildPaymentForm = () => {
        return (
            <>
                <div style={{ display: 'none' }}>
                    <form
                        id={pay_form_id}
                        action="https://partywitty.com/master/APIs/Carnival/submitCarnivalPass"
                        method="post"
                        onSubmit={(event) => {
                            event.preventDefault();
                            makePayment();
                        }}
                        className="summery--page--pay--now"
                    >
                        <input type="hidden" name="order_id" value={orderID} />
                        <input type="hidden" name="payment_gateway" value="phonepe" />
                        <input
                            className="summery--page--submit"
                            type="submit"
                            value="Placing Order..."
                        />
                    </form>
                </div>
            </>
        )
    }

    return (
        <div
            // className="new--year--book--a--table--book--now"
            style={{ backgroundColor: 'black', width: '100%', padding: '10px' }}
        >
            <div className="carret--use">
                <div className="carret--checkbox">
                    <input
                        type="checkbox"
                        checked={usingCarot === true}
                        onChange={(evt) => {
                            setUsingCarot(evt.target.checked);
                        }}
                    />
                    <label className="m-0" htmlFor="checkbox">
                        Use Carrot Balance
                    </label>
                </div>
                <p className="m-0">
                    Available Carrot Balance:{" "}
                    <span>
                        <img src={carrotIcon} />{" "}
                        {usingCarot === true ? (
                            <>{user.virtual_wallet - usableCarrot}</>
                        ) : (
                            <>{user.virtual_wallet}</>
                        )}
                    </span>{" "}
                </p>
            </div>

            <div className="new--year--order--cart">
                <div className="new--year--your--order--1">
                    <img src={couponIcon}></img>
                    <p className="New--year--page--add--more">Use Coupon</p>
                </div>
                <div
                    className="new--year--your--order"
                // onClick={handleOpen}
                >
                    <img src={arrowIcon}></img>
                </div>
            </div>
            <div className="p--button">
                <div className="new--year--order--cart1">
                    <p className="New--year--page--add--more">No of Passes</p>
                    <p className="New--year--page--add--more">
                        {totalPassCount}
                    </p>
                </div>
                <div className="new--year--order--cart1">
                    <p className="New--year--page--add--more">Gross Pass Value</p>
                    <p className="New--year--page--add--more">
                        ₹{totalPassAmount.toFixed(CDC)}
                    </p>
                </div>
                <div className="new--year--order--cart1">
                    <p className="New--year--page--add--more">Carrots Used</p>
                    <p className="New--year--page--add--more">
                        <span>- ₹{usingCarot ? userPassDetails.usableCarrot : 0}</span>
                    </p>
                </div>

                <div className="new--year--order--cart1">
                    <p className="New--year--page--add--more">
                        Platform & Other Charges:
                    </p>
                    <p className="New--year--page--add--more">₹{platformFee.toFixed(CDC)}</p>
                </div>
                <div className="new--year--page--border--line1-1"></div>
                <div className="new--year--your--order--1--2">
                    <p className="New--year--page--add--more">Grand Total </p>
                    <p className="New--year--page--add--more">
                        ₹{" "}{grandTotal.toFixed(CDC)}
                    </p>
                </div>
                <div className="new--year--page--border--line1-1"></div>
                <button
                    className="summery--page--submit--pay--now"
                    onClick={placeOrder}
                >
                    Place Order
                </button>
                {buildPaymentForm()}
            </div>
        </div>
    )
}

export default function CarnivalPassBookingInput(props) {
    const pass = props.pass;
    // console.log('Pass: ', pass);

    const pricing = pass?.pricing;

    const [passStat, setPassStat] = useState({});
    const [totalPassCount, setTotalPassCount] = useState(0);
    const [totalPassAmount, setTotalPassAmount] = useState(0);

    const updatePassStat = (category, count, action = 1 /* 1:increase, 0: set, -1:decrease */) => {
        const type = category.ticket_type;
        const typeStat = passStat[type] || { count: 0, amount: 0 };

        switch (action) {
            case 1:
                typeStat.count += count;
                break;
            case -1:
                typeStat.count -= count;
                break;
            case 0:
            default:
                typeStat.count = count;
        }

        // check for limits
        const limit = Number.parseInt(category.seat || 0);
        if (typeStat.count < 0) {
            typeStat.count = 0;
        } else if (typeStat.count > limit) {
            typeStat.count = limit;
        }

        // set amount
        typeStat.amount = category.price * typeStat.count;

        passStat[type] = typeStat;
        setPassStat(passStat);

        let totalCount = 0;
        let totalAmount = 0;
        Object.values(passStat || {}).forEach(ps => {
            totalCount += ps.count;
            totalAmount += ps.amount;
        });

        setTotalPassAmount(totalAmount);
        setTotalPassCount(totalCount);
    }

    const getPassStat = (category) => {
        const type = category.ticket_type;
        const typeStat = passStat[type] || { count: 0, amount: 0 };
        return typeStat;
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: "white",
                    color: "black",
                    "& *": {
                        padding: "0 !important",
                    },
                    zIndex: 100,
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <List>
                    <ListItem
                        disablePadding // This ensures there's no default padding
                        dense
                        sx={{
                            "&:hover": {
                                backgroundColor: "inherit",
                            },
                        }}
                    >
                        <ListItemButton
                            onClick={(e) => e.stopPropagation()}
                            disableRipple
                            sx={{
                                "&:hover": {
                                    backgroundColor: "inherit", // Set the background color on hover if needed
                                },
                            }}
                        >
                            <ListItemText
                                primary={
                                    <div className="pricing--strip">
                                        <div className="mb-5">
                                            <div className="club--detail--and--pass--details">
                                                <h4>{pass?.name}</h4>
                                                <h5>{pass?.clubName}</h5>
                                            </div>

                                            {
                                                pricing.map(passCat => (
                                                    <div className="ticket--type--data--set">
                                                        <p className="m-0 mt-2">
                                                            {passCat.ticket_type} @{passCat.price}
                                                        </p>
                                                        <div className="new--year--add--number--page1">
                                                            <div>
                                                                <p
                                                                    className="new--year--add--number--page1--p1 mt-3"
                                                                    onClick={() => {
                                                                        updatePassStat(passCat, -1);
                                                                    }}
                                                                >
                                                                    -
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="new--year--add--number--page1--p2 mt-3">
                                                                    <input
                                                                        key={`${passCat.ticket_type}${Math.random() * Math.random()}`}
                                                                        defaultValue={getPassStat(passCat).count}
                                                                        onBlur={(evt) => {
                                                                            let val = Number.parseInt(evt.target.value || '0');
                                                                            updatePassStat(passCat, val, 0);
                                                                        }}
                                                                        size='1'
                                                                        style={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    className="new--year--add--number--page1--p1 mt-3"
                                                                    onClick={() => {
                                                                        updatePassStat(passCat, 1);
                                                                    }}
                                                                >
                                                                    +
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className="ticket--type--data--set">
                                                <CarnivaPassGuestList
                                                    key={`GuestList${Math.random()}`}
                                                    passStat={passStat}
                                                    totalPassAmount={totalPassAmount}
                                                    totalPassCount={totalPassCount}
                                                    pass={pass}
                                                />
                                            </div>
                                            <div className="ticket--type--data--set">
                                                <CarnivaPassOrderSummary
                                                    key={`DueSummary${Math.random()}`}
                                                    passStat={passStat}
                                                    totalPassAmount={totalPassAmount}
                                                    totalPassCount={totalPassCount}
                                                    pass={pass}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </>);
}
