import BookingTabs from './BasicTabs';
import './MyBooking.css';

export default function MyBookingHistory(){
    return(
        <div className='my-booking-card'>
            <h1>My Booking</h1>
            <div>
                <BookingTabs/>
            </div>
        
        </div>
    );
}