import { useEffect, useState } from 'react';
import './Agreement.css';
import axios from 'axios';


export default function Termscondition() {
    const [TermsCondition, setTermsCondition] = useState()
    const [isloading, setisLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setisLoading(true)
                const res = await axios.get(`https://partywitty.com/master/Api/getData/getWebsiteSettings`)
                const { status, data } = res.data;
                status === true ? setTermsCondition(data[0].termsConditions) : setTermsCondition(null)
                setisLoading(false)
            } catch (error) {
                setisLoading(false)
                console.log(error)
            }
        }
        fetchData()
    }, [])

    return (
        <div class="clubagreement pt-5" dangerouslySetInnerHTML={{ __html: isloading ?`<p style="color:#fff;font-size:25px;text-align:center"> Loading...</p>` : TermsCondition }} />
    );
}