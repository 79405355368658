import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import "../../Homepage/Homepage.css";
import axios from "axios";
import { ShimmerThumbnail } from "react-shimmer-effects"

const url = "https://partywitty.com/master/assets/uploads/";

const Index = () => {
  const [allTeaser, setAllTeaser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const category = new FormData();
  category.append("category", "Ambience");

  useEffect(() => {
    const fetchTeaser = async () => {
      try {
        const response = await axios.post(
          "https://partywitty.com/master/APIs/Common/getTeaserCategoryWise",
          category
        );
        if (response.data.status === true) {
          console.log("video", response.data);
          setAllTeaser(response.data.data);
          setIsLoading(false);
          
        } else {
          console.log("No data found");
        }
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
    };
    fetchTeaser();
  }, []); 

  const dummyArray = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }];

  return (
    <div>
      <LazyLoad>
        <div className="club--about--us--video--frames">
          {isLoading &&  dummyArray.map((item) => (
            <ShimmerThumbnail key={item.id} height={250} width={230} rounded />
          ))}
          {allTeaser.map((e, index) => (
              <div className="club--about--us--video--frames--card">
                <video
                  
                  loop
                  muted
                  controls
                 
                 >
                  <source src={url + e.video} type="video/mp4" />
                </video>
                <p className="Homepage_Teaser_p mb-2">{e.clubName}</p>
              </div>
          ))}
        </div>
      </LazyLoad>
    </div>
  );
};

export default Index;
