import "./Favorate--hangout.css";
import filterimg from './image/filterimg.png';
import filterimg2 from './image/filterimg2.png';
import filterimg3 from './image/filterimg3.png';
import filterimg4 from './image/filterimg4.png';
export default function FavorateClubCategory()
{
    return(
        <div className="Favorate--club--category">
           <div className="Favorate--club--category--card">
            <img src={filterimg} alt="Fine Dine" />
            <p>Fine Dine</p>
           </div>
           <div className="Favorate--club--category--card">
            <img src={filterimg2} alt="Restaurant & Bar"/>
            <p>Restaurant & Bar</p>
           </div>
           <div className="Favorate--club--category--card">
            <img src={filterimg3} alt="Clubs & Lounge"/>
            <p>Clubs & Lounge</p>
           </div>
           <div className="Favorate--club--category--card">
            <img src={filterimg4} alt="Nightclub"/>
            <p>Nightclub</p>
           </div>
           <div className="Favorate--club--category--card">
            <img src={filterimg2} alt="BYOB"/>
            <p>BYOB</p>
           </div>
           <div className="Favorate--club--category--card">
            <img src={filterimg3} alt="Cafe"/>
            <p>Cafe</p>
           </div>
           <div className="Favorate--club--category--card">
            <img src={filterimg4} alt="Gaming Zone"/>
            <p>Gaming Zone</p>
           </div>
        </div>
    );
}