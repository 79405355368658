import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Homepage.css";
import star from "../favorate--hangout--page/image/star.png";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function NewlyAddedClub() {
  
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [newlyAddClub, setNewlyAddClub] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const url = "https://partywitty.com/master/assets/uploads/";
  const dummyArray = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }];

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
     
      axios
        .get("https://partywitty.com/master/APIs/Common/newlyAddedClub", {
          params: {
            latitude: latitude,
            longitude: longitude,
          },
        })
        .then((response) => {
          setNewlyAddClub(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [latitude, longitude]);

  return (
    <div className="club_Card_Container">
    {isLoading &&  dummyArray.map((item) => (
        <ShimmerThumbnail key={item.id} height={250} width={310} rounded />
      )) }
    {
      newlyAddClub.map((club) => (
        <Link
          to={`/${club.cityName.replace(/ /g, "-")}/${club.areaName.replace(/ /g, "-")}/${club.name.trim().split(/ +/).join("-").replace(/ /g, "-")}`}
          key={club.id}
          className="bookatable-routes"
        >
          <div className="Favorate--hangout--all--thumbnail--card1">
            <div className="Favorate--hangout--all--thumbnail--card--img">
              {club.clubLogo ? (
                <img
                  className="Favorate--hangout--all--thumbnail--card--img--1--img"
                  src={url + club.clubLogo}
                  alt={club.name}
                />
              ) : null}
              <div className="Favorate--hangout--all--thumbnail--card--img--1"></div>
              {(club.addon !== 0 || club.discount !== 0) && (
                <div className="Favorate--hangout--all--thumbnail--card--2">
                  <div className="Favorate--hangout--all--thumbnail--card--2--1">
                    <div className="favorate--add--on">
                      <div className="favorate--add--on--card">
                        <p className="favorate--add--on--card--p">{club.addon}</p>
                        <p className="favorate--add--on--card--p--1">%</p>
                      </div>
                      <div className="favorate--add--on--p">
                        <p className="favorate--add--on--p--1">Add On</p>
                        <p className="favorate--add--on--p--2">EXTRA</p>
                      </div>
                    </div>
                    <div className="favorate--offered--card">
                      <p className="favorate--offered--card--1">Offered in</p>
                      <div className="favorate--offered--card--days">
                        <p>Last 30 Days</p>
                      </div>
                    </div>
                    <div className="favorate--add--on">
                      <div className="favorate--add--on--p">
                        <p className="favorate--add--on--p--1--1">Overall</p>
                        <p className="favorate--add--on--p--2--1">Discount</p>
                      </div>
                      <div className="favorate--add--on--card1">
                        <p className="favorate--add--on--card--p">{club.discount}</p>
                        <p className="favorate--add--on--card--p--1">%</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="Favorate--hangout--card--content">
              <div className="Favorate--hangout--card--content--1">
                <p className="Favorate--hangout--card--content--1--p1">{club.name.substring(0, 15)}...</p>
                <p className="Favorate--hangout--card--content--1--p2">{club.category}</p>
              </div>
              <div className="favorate--hangout--address">
                {club.totalRating !== 0 ? (
                  <div className="favorate--hangout--review--card1">
                    <div className="favorate--hangout--review--card">
                      <img src={star} alt="img" />
                      <p>{club.totalAverageRating}</p>
                    </div>
                  </div>
                ) : (
                  <div className="favorate--hangout--review--card5"></div>
                )}
                {club.distance !== 0 && (
                  <p className="favorate--hangout--address--p2">{club.distance} km</p>
                )}
                <p className="favorate--hangout--address--p2">{club.address.substring(0, 10)}...</p>
              </div>
            </div>
          </div>
        </Link>
      ))
    }
  </div>
  );
}
