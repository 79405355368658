import './WebApp.css';
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Googleplay from './googleStore1.png';
import Applestore from './appstore.png';
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import webtoapp from './Artistweb.png';
import appmobile from './Artistmob.png';

export default function WebApp1() {
    const [openPlanEvent, setOpenPlanEvent] = useState(true);

    const handleClosePlanEvent = () => {
        setOpenPlanEvent(false);
    };

    return (
        <div className='Web--app--popup'>
            <div>
                {openPlanEvent && (
                    <div className="blur-background">
                        <Modal
                            open={openPlanEvent}
                            onClose={handleClosePlanEvent}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                        >
                            <Box className="responsive-modal-1--popup--webApp">
                                <div className="Plan--your--event--popup--webapp">
                                <button
                    className="modal-button_plan"
                    onClick={() => setOpenPlanEvent(false)}
                  >
                 <RxCross2 />
                  </button>
                                    <div className="Plan--your--event--popup--webapp--img">
                                        <img src={webtoapp}></img>
                                    </div>
                                    <div className="Plan--your--event--popup--webapp--img--6">
                                        <img src={appmobile}></img>
                                    </div>
                                    <div className='plan--yor--event--mobile--view'>
                                        <div className='app-popup-row'>
                                        <p className='Plan--your--event--popup--webapp--p1'>Unlock their Complete Profile with Party Witty App!
                                        </p>
                                        <p className='Plan--your--event--popup--webapp--p2'>Dive into the world of our amazing artist! View their complete profile, stunning musical journey, and exclusive content. Don't miss out – get Party Witty app!
                                        </p>
                                        </div>
                                        <div className='app-popup-row'>
                                        <p className='app-popup-row-p'>Explore Now</p>
                                        <div className='app-popup-row1'>
                                        <p className='app-popup-row-p1'>Explore Now</p>
                                        </div>
                                        <div className='Plan--your--event--popup--webapp--download--img'>
                                            <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                                                <img src={Googleplay} />
                                            </Link>
                                            <Link to="https://apps.apple.com/in/app/partywitty/id6478442502 ">
                  <img src={Applestore} alt="appstore"/>
                </Link></div>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );
}
