import styles from './Event.module.css'

function index({ MenuDetails }) {
    const { url, name, id, address, seat_left, price, club_name, date } = MenuDetails;
    // const currentDate = new Date().toLocaleString();
    // const EventOn = date === currentDate ? "today" :currentDate < date ? "tomorrow" : currentDate.toLocaleString()
    return (
        <div className={styles.MenuContainer} key={id}>
            <div className={styles.imageContainer}>
                <span className={styles.MaskTag}> <img src={`/images/mask1.svg`} alt={name} />{date} </span>
                <img src={`${url}`} alt={name} className={styles.bannerThumb} />
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.leftContent}>
                    <h2>{name}</h2>
                    <p id={styles.clubName}>{club_name}</p>
                    <p style={{opacity:'50%'}}>{address}</p>
                </div>
                <div className={styles.rightContent}>
                    <h2> Entry {price ? price : 'Free'}</h2>
                    <p id={styles.seatLeft}>{seat_left} Seats Left</p>
                    <button> view details</button>
                </div>
            </div>
        </div>
    )
}

export default index