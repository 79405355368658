import React from 'react';
import teaser from  './image/teaser.png';

export default function Teaser(){
    return(
        <div>
        <p className='club-menu-p'>Teaser</p>
        <div className='all-club-menu'>
         <div className='club-teaser'>
            <img src={teaser}></img>
            <p>Ambience</p>
         </div>
         <div className='club-teaser'>
            <img src={teaser}></img>
            <p>Ambience</p>
         </div>
         <div className='club-teaser'>
            <img src={teaser}></img>
            <p>Ambience</p>
         </div>
         <div className='club-teaser'>
            <img src={teaser}></img>
            <p>Ambience</p>
         </div>
         <div className='club-teaser'>
            <img src={teaser}></img>
            <p>Ambience</p>
         </div>
        </div>
   
    </div>
    );
}