const Commons = {
    Format: {
        Currency: { decimalCount: 2 }
    },
    formToJson: (formData) => {
        var object = {};
        formData.forEach(function (value, key) {
            object[key] = value;
        });
        return object;
    },
    URL: {
        Master: 'https://partywitty.com/master/',
        Upload: 'https://partywitty.com/master/assets/uploads/',
    },
    getUserData: () => {
        const ud = JSON.parse(localStorage.getItem("userData"));
        return ud;
    },
    getUploadPath: (filePath) => {
        return `${Commons.URL.Upload}${filePath}`;
    },

    doFetch: async (api, formData, method = 'GET', headers = {}) => {
        if (!api.startsWith('http')) {
            api = `${Commons.URL.Master}${api}`;
        }
        const init = {
            method: formData ? 'POST' : 'GET',
            // withCredentials: true,
            // crossorigin: true,
            // mode: 'no-cors',
            ...headers,
            body: formData,
        };

        try {
            const response = await fetch(api, init);

            // console.log('Fetch: ', api, init, response, response?.json);

            if (response?.json) {
                const data = await response.json();
                return data;
            }
        } catch (e) {
            console.log('Error Fetch: ', e);
        }
        
        return undefined;
    }
}

export default Commons;