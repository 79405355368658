import React, { useEffect, useState } from "react";
import "./Clubpage.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";



const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: 1,
};

function Menu() {
  
  const { city, area, clubname } = useParams();
  const clubN = clubname;
  const [partyvideo, setPartyvideo] = useState([]);
  const [clubId, setClubId] = useState(null)
  const [data, setData] = useState([]);
  const newApi = new FormData();

  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);

  const url = "https://partywitty.com/master/assets/uploads/";
   useEffect(() => {
   axios
     .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
     .then((response) => {
       console.log("API Response:", response.data); 
       setData(response.data.data);
      
       const clubid = response.data.data.id; 
       setClubId(clubid);
      
      
     })
     .catch((error) => {
       console.error(error);
     });
 }, []);

  const Teaser = new FormData();
  useEffect(() => {
    Teaser.append("club_id", clubId);
    console.log("club_id", clubId);
    axios
      .post(
        "https://partywitty.com/master/APIs/Common/getTeaser",
        Teaser
      )
      .then((response) => {
       
        if (response.data.status === true) {
          setPartyvideo(response.data.data);
          console.log(response.data.data);
        } else {
          console.log("No data found");
        }
      })
      .catch((error) => {
        console.log("Error fetching data: ", error);
      });
  }, []);
  
     return (
      <>
         <div className="club--about--us--video--frames">
                {partyvideo.map((e) => (
                  <div
                    className="club--about--us--video--frames--card"
                    key={e.id}
                  >
                    <video loop muted width="100%" height="230" controls>
                      <source src={url + e.video} type="video/mp4" />
                    </video>
                  </div>
                ))}
              </div>
      </>
    );
  }
  export default Menu;
