import React from "react";
import "../App.css";
import "./Clubpage.css";
import BasicTabs from "./Club-tabs";

function Clubpage() {
  return (
    <>
      <div className="club-page">
        <div className="club-card">
          <div className="club-left-page">
           
            <div className="club-name">
              <h1 className="m-2">Lord of the Drinks Chamber </h1>
              <p className="m-0">
                670.5 km away, Rajouri Garden Main Market, Rajouri Garden{" "}
              </p>
              <p className="m-0">Get Location</p>
            </div>

            <BasicTabs />
          </div>
          <div className="club-right-page">
            <div className="select-offer  ">
              <div className="offer-text border-bottom border-white color-white">
                <p className="">Select an Offer or Deal</p>
              </div>
              <div className="time">
                <p className="">Time</p>
                <p>Choose an available time slot</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clubpage;
