import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Carnival.css";

import Box from "@mui/material/Box";
import Group from "./image/Group.png";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";
import { GrCaretNext } from "react-icons/gr";
import GPIcon from "./image/GPIcon.png";
import cross1 from "./image/cross1.svg";
import search from "./image/search.svg";

import { Carousel } from "antd";
import axios from "axios";
import { LuSearch } from "react-icons/lu";
import { Link } from "react-router-dom";
import Loginpopup from "../Navbar/Loginmodal";

import CarnivalPassCard from "./CarnivalPassCard";
import CarnivalSpecialPassCard from "./CarnivalPassCard";

import Commons from "./Commons";

const searchBarStyleMobile = {
  height: "40px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#120829",
  // borderRadius: "20px",
  padding: "10px 10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.3s ease-in-out",
  color: "white",
  border: "1px solid grey",
};

const inputStyle = {
  border: "none",
  outline: "none",
  fontSize: "12px",
  width: "100%",
  backgroundColor: "#120829",
  color: "white",
};

export default function Carnival() {
  const { offer_type } = useParams();
  // console.log('Offer Type', offer_type);

  const [activeButton, setActiveButton] = useState("newYear");
  const [activeButton1, setActiveButton1] = useState("package");
  const [activeButton2, setActiveButton2] = useState("stag");
  const [activeButton3, setActiveButton3] = useState("stag");
  const [activePrice, setActivePrice] = useState("price");
  const [activePrice1, setActivePrice1] = useState("price1");
  const [activeMenu, setActiveMenu] = useState("menu");
  const [activeDetail, setActiveDetail] = useState("detail");
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [datalist, setDatalist] = useState([]);

  const [openPlanEvent, setOpenPlanEvent] = useState(false);

  const [newyeardata, setNewyearData] = useState([]);
  const [tablebookingnewyear, setTablebookingnewyear] = useState([]);
  const [tablebookingcristmas, setTablebookingcristmas] = useState([]);
  const [passcristmas, setPasscristmas] = useState([]);
  const [specialoffer, setSpecialoffer] = useState([]);
  const [specialoffercristmas, setSpecialoffercristmas] = useState([]);

  const specialOffers = "https://partywitty.com/master/APIs/Carnival/CarnivalPassSpecialOffer";
  const specialOffersApiCristmas =
    " https://partywitty.com/master/APIs/Carnival/CarnivalPassSpecialOfferChristmas";
  const specialOffersApiNewYear1 =
    " https://partywitty.com/master/APIs/Carnival/CarnivalPassSpecialOfferNewYear1";
  const getApiUrl =
    "https://partywitty.com/master/Api/getData/allCarnivalPassNewYear?type=";
  const getApiUrltable =
    "https://partywitty.com/master/Api/getData/allCarnivalTableNewYear";
  const getApiUrlcristmastable =
    "https://partywitty.com/master/Api/getData/allCarnivalTableChristmas";
  const getApiUrlcristmasdata =
    "https://partywitty.com/master/Api/getData/allCarnivalPassChristmas";

  useEffect(() => {
    const type = offer_type || 'Christmas';
    const body = new FormData();
    body.append('type', type);

    // Fetch categories on component mount
    // fetch(specialOffersApiCristmas)
    fetch(specialOffers, { method: 'POST', body: body, })
      .then((response) => response.json())
      .then((response) => {
        console.log(type, ' Special Offers:', response);
        if (response.status === "true") {
          setSpecialoffercristmas([]);
        } else {
          setSpecialoffercristmas(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    const type = offer_type || 'Diwali';
    const body = new FormData();
    body.append('type', type);

    // Fetch categories on component mount
    // fetch(specialOffersApiCristmas)
    fetch(specialOffers, { method: 'POST', body: body, })
      // Fetch categories on component mount
      // fetch(specialOffersApiNewYear1,)
      .then((response) => response.json())
      .then((response) => {
        console.log(type, ' - Special Offers:', response);
        if (response.status === "true") {
          setSpecialoffer([]);
        } else {
          setSpecialoffer(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch categories on component mount
    fetch(getApiUrl + offer_type)
      .then((response) => response.json())
      .then((response) => {
        console.log('All Carnival: ', response);

        if (response.status === "true") {
          setNewyearData([]);
        } else {
          setNewyearData(response.data || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const coupleData = { eventInfo: [], pricing: [] };
  const maleStagData = { eventInfo: [], pricing: [] };
  const femaleStagData = { eventInfo: [], pricing: [] };

  if (newyeardata && newyeardata.length > 0) {
    newyeardata.forEach((event) => {
      const { pricing, ...eventInfoWithoutPricing } = event;

      pricing.forEach((price) => {
        const { ticket_type } = price;

        // Categorize event information and pricing by ticket type
        if (ticket_type === "Couple") {
          coupleData.eventInfo.push(eventInfoWithoutPricing);
          coupleData.pricing.push(price);
        } else if (ticket_type === "Male Stag") {
          maleStagData.eventInfo.push(eventInfoWithoutPricing);
          maleStagData.pricing.push(price);
        } else if (ticket_type === "Female Stag") {
          femaleStagData.eventInfo.push(eventInfoWithoutPricing);
          femaleStagData.pricing.push(price);
        }
        // Add more conditions for other ticket types if needed
      });
    });
  }

  const mergedCoupleData = [
    coupleData.eventInfo.flatMap((info, index) => ({
      ...info,
      pricing: coupleData.pricing[index],
    })),
  ];

  const mergedStageData = [
    maleStagData.eventInfo.flatMap((info, index) => ({
      ...info,
      pricing: maleStagData.pricing[index],
    })),
  ];

  const mergedfemaleData = [
    femaleStagData.eventInfo.flatMap((info, index) => ({
      ...info,
      pricing: femaleStagData.pricing[index],
    })),
  ];

  const dataAtIndexZero = mergedStageData[0];
  const dataAtIndexOne = mergedfemaleData[0];

  const mergedDataArray = [dataAtIndexZero.concat(dataAtIndexOne)];
  const stagesortdata = mergedDataArray[0].sort((a, b) => a.id - b.id);

  useEffect(() => {
    // Fetch categories on component mount
    fetch(getApiUrlcristmasdata)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "true") {
          setPasscristmas([]);
        } else {
          setPasscristmas(response.data);
        }
        // setPasscristmas(data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const coupleData1 = { eventInfo: [], pricing: [] };
  const maleStagData1 = { eventInfo: [], pricing: [] };
  const femaleStagData1 = { eventInfo: [], pricing: [] };

  if (passcristmas && passcristmas.length > 0) {
    passcristmas.forEach((event) => {
      const { pricing, ...eventInfoWithoutPricing } = event;

      pricing.forEach((price) => {
        const { ticket_type } = price;

        // Categorize event information and pricing by ticket type
        if (ticket_type === "Couple") {
          coupleData1.eventInfo.push(eventInfoWithoutPricing);
          coupleData1.pricing.push(price);
        } else if (ticket_type === "Male Stag") {
          maleStagData1.eventInfo.push(eventInfoWithoutPricing);
          maleStagData1.pricing.push(price);
        } else if (ticket_type === "Female Stag") {
          femaleStagData1.eventInfo.push(eventInfoWithoutPricing);
          femaleStagData1.pricing.push(price);
        }
        // You can add more conditions for other ticket types if needed
      });
    });
  }

  const mergedCoupleData1 = [
    coupleData1.eventInfo.flatMap((info, index) => ({
      ...info,
      pricing: coupleData1.pricing[index],
    })),
  ];

  const mergedStageData1 = [
    maleStagData1.eventInfo.flatMap((info, index) => ({
      ...info,
      pricing: maleStagData1.pricing[index],
    })),
  ];

  const mergedfemaleData1 = [
    femaleStagData1.eventInfo.flatMap((info, index) => ({
      ...info,
      pricing: femaleStagData1.pricing[index],
    })),
  ];

  const dataAtIndexZero1 = mergedStageData[0];
  const dataAtIndexOne1 = mergedfemaleData[0];

  const mergedDataArray1 = [dataAtIndexZero1.concat(dataAtIndexOne1)];
  const stagesortdata1 = mergedDataArray1[0].sort((a, b) => a.id - b.id);

  useEffect(() => {
    // Fetch categories on component mount
    fetch(getApiUrltable)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "true") {
          setTablebookingnewyear([]);
        } else {
          setTablebookingnewyear(response.data);
        }
      })

      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch categories on component mount
    fetch(getApiUrlcristmastable)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "true") {
          setTablebookingcristmas([]);
        } else {
          setTablebookingcristmas(response.data);
        }
      })

      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const [count, setCount] = useState(0);
  const increment = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    setCount((prevCount) => {
      // Check if the count is greater than 0 before decrementing
      if (prevCount > 0) {
        return prevCount - 1;
      } else {
        // If count is already 0, return 0 (prevent negative value)
        return 0;
      }
    });
    // setCount((prevCount) => prevCount - 1);
  };

  const [femalecount, setFemaleCount] = useState(0);
  const femaleincrement = () => {
    setFemaleCount((prevCount) => prevCount + 1);
  };

  const femaledecrement = () => {
    setFemaleCount((prevCount) => {
      // Check if the count is greater than 0 before decrementing
      if (prevCount > 0) {
        return prevCount - 1;
      } else {
        // If count is already 0, return 0 (prevent negative value)
        return 0;
      }
    });
  };

  const [couplecount, setCoupleCount] = useState(0);
  const coupleincrement = () => {
    setCoupleCount((prevCount) => prevCount + 1);
  };

  const coupledecrement = () => {
    setCoupleCount((prevCount) => {
      if (prevCount > 0) {
        return prevCount - 1;
      } else {
        return 0;
      }
    });
  };

  const handleOpenPlanEvent = () => {
    setOpenPlanEvent(true);
  };

  const handleClosePlanEvent = () => {
    setOpenPlanEvent(false);
  };

  const [openPlanEvent1, setOpenPlanEvent1] = useState(false);
  const handleOpenPlanEvent1 = () => {
    setOpenPlanEvent(true);
  };

  const handleClosePlanEvent1 = () => {
    setOpenPlanEvent(false);
  };

  const handleButtonClick = (type) => {
    setActiveButton(type);
  };

  const handleButtonPrice = (type) => {
    setActivePrice(type);
  };

  const handleButtonPrice1 = (type) => {
    setActivePrice1(type);
  };

  const handleButtonClick1 = (type) => {
    setActiveButton1(type);
  };

  const handleButtonClick2 = (type) => {
    setActiveButton2(type);
  };

  const handleButtonClick3 = (type) => {
    setActiveButton3(type);
  };

  const handleButtonClickMenu = (type) => {
    setActiveMenu(type);
  };

  const handleButtonClickDetail = (type) => {
    setActiveDetail(type);
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const minDistance = 10;
  const [value1, setValue1] = React.useState([20, 37]);
  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const [value2, setValue2] = React.useState([20, 37]);
  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }
  };

  const [data, setData] = useState([]);
  var url = Commons.URL.Upload;

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getCarnivalBanner")
      .then((response) => setData(response.data.data))
      .catch((error) => console.log(error));
  }, []);
  const [detailsdata, setdetailsdata] = useState([]);
  const [passid, setPassid] = useState("");

  const handleCategoryClick = (categoryId) => {
    setPassid(categoryId);
    // Create form data
    const formData = new FormData();
    formData.append("pass_id", categoryId);
    formData.append("user_id", userdata ? userdata.id : null);
    formData.append("couple_qty", "0");
    formData.append("male_qty", "0");
    formData.append("female_qty", "0");
    // Make POST request with form data
    fetch("https://partywitty.com/master/APIs/Carnival/PassDetails", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Pass Details: ', categoryId, data);
        if (data.status === "true") {
          setdetailsdata([]);
        } else {
          setdetailsdata(data.data[0].pricing);
          setDatalist(data.data[0]);
        }
        // setResponseFromPostApi(data.data);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  const [menudetailsdata, setMenudetailsdata] = useState([]);
  const handleClickmenudetails = (categoryId) => {
    const formData = new FormData();
    formData.append("pass_id", categoryId);

    fetch("https://partywitty.com/master/APIs/Carnival/getPassMenu", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          setMenudetailsdata([]);
        } else {
          // setMenudetailsdata(data.data[0].pricing);
          setMenudetailsdata(data.data[0]);
        }
        // setResponseFromPostApi(data.data);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const [state, setState] = useState({ bottom: false });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const [state1, setState1] = useState({ bottom: false });

  const toggleDrawer1 = (anchor, open) => (event) => {
    setState1({ ...state1, [anchor]: open });
  };

  const [menudata, setMenudata] = React.useState({
    bottom: false,
  });
  const [statecristmas, setStatecristmas] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawercristmas = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStatecristmas({ ...statecristmas, [anchor]: open });
  };

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  const toggleDrawermenudata = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenudata({ ...menudata, [anchor]: open });
  };

  const maleamount = JSON.parse(localStorage.getItem("maleamount"));
  const femaleamount = JSON.parse(localStorage.getItem("Femaleamount"));
  const coupleamount = JSON.parse(localStorage.getItem("coupleamount"));
  const listdata = (anchor) => (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "white",
        color: "black",
        "& *": {
          padding: "0 !important",
        },
        zIndex: 100,
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <List>
        <ListItem
          disablePadding // This ensures there's no default padding
          dense
          sx={{
            "&:hover": {
              backgroundColor: "inherit",
            },
          }}
        >
          <ListItemButton
            onClick={(e) => e.stopPropagation()}
            disableRipple
            sx={{
              "&:hover": {
                backgroundColor: "inherit", // Set the background color on hover if needed
              },
            }}
          >
            <ListItemText
              primary={
                <div className="pricing--strip">
                  <div className="mb-5">
                    <div className="club--detail--and--pass--details">
                      <h4>{datalist.name}</h4>
                      <h5>{datalist.clubName}</h5>
                    </div>
                    <div className="new--year-filter-clear">
                      <p className="new--year-filter-clear1">Passes Type</p>
                      <p className="new--year-filter-clear2">clear All</p>
                    </div>

                    {detailsdata.map((e) => (
                      <div className="ticket--type--data--set">
                        <p className="m-0 mt-2">
                          {e.ticket_type} @{e.price}
                        </p>
                        {/* test */}
                        {e.ticket_type === "Couple" ||
                          e.ticket_type === "Male Stag" ||
                          e.ticket_type === "Female Stag" ? (
                          e.ticket_type === "Male Stag" ? (
                            <div className="new--year--add--number--page1">
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={decrement}
                                >
                                  -
                                </p>
                              </div>
                              <div>
                                <p className="new--year--add--number--page1--p2 mt-3">
                                  {count}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={increment}
                                >
                                  +
                                </p>
                              </div>
                            </div>
                          ) : e.ticket_type === "Couple" ? (
                            <div className="new--year--add--number--page1">
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={coupledecrement}
                                >
                                  -
                                </p>
                              </div>
                              <div>
                                <p className="new--year--add--number--page1--p2 mt-3">
                                  {couplecount}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={coupleincrement}
                                >
                                  +
                                </p>
                              </div>
                            </div>
                          ) : e.ticket_type === "Female Stag" ? (
                            <div className="new--year--add--number--page1">
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={femaledecrement}
                                >
                                  -
                                </p>
                              </div>
                              <div>
                                <p className="new--year--add--number--page1--p2 mt-3">
                                  {femalecount}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={femaleincrement}
                                >
                                  +
                                </p>
                              </div>
                            </div>
                          ) : null
                        ) : null}

                        {/* test */}
                      </div>
                    ))}
                    {detailsdata.map((e) => (
                      <div className="ticket--type--data--set">
                        {e.ticket_type === "Couple" ||
                          e.ticket_type === "Male Stag" ||
                          e.ticket_type === "Female Stag" ? (
                          e.ticket_type === "Male Stag" ? (
                            <>
                              {
                                (localStorage.setItem(
                                  "maleamount",
                                  JSON.stringify(e.price * count)
                                ),
                                  localStorage.setItem(
                                    "count",
                                    JSON.stringify(count)
                                  ))
                              }{" "}
                            </>
                          ) : e.ticket_type === "Couple" ? (
                            <>
                              {
                                (localStorage.setItem(
                                  "coupleamount",
                                  JSON.stringify(e.price * couplecount)
                                ),
                                  localStorage.setItem(
                                    "ccount",
                                    JSON.stringify(couplecount)
                                  ))
                              }{" "}
                            </>
                          ) : e.ticket_type === "Female Stag" ? (
                            <>
                              {
                                (localStorage.setItem(
                                  "Femaleamount",
                                  JSON.stringify(e.price * femalecount)
                                ),
                                  localStorage.setItem(
                                    "fcount",
                                    JSON.stringify(femalecount)
                                  ))
                              }{" "}
                            </>
                          ) : null
                        ) : null}

                        {/* test */}
                      </div>
                    ))}
                  </div>
                  <div className="price--total--card">
                    <div className="passes--and--price">
                      <p className="m-0">
                        {count + femalecount + couplecount} Passes &nbsp;
                        {maleamount + femaleamount + coupleamount}.00
                      </p>
                      <Link
                        to={`/booknow--table--cart/${passid}`}
                        className="bookatable-routes"
                      >
                        <p className="new--year--view--cart--p">
                          View Cart &nbsp;
                          <GrCaretNext />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const listdata1 = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        width: "100%",
        height: "auto",
        backgroundColor: "white",
        color: "black",
        "& *": {
          padding: "0 !important",
        },
        zIndex: 100,
      }}
      role="presentation"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <List>
        <ListItem
          disablePadding // This ensures there's no default padding
          dense
          sx={{
            "&:hover": {
              backgroundColor: "inherit",
            },
          }}
        >
          <ListItemButton
            onClick={(e) => e.stopPropagation()}
            disableRipple
            sx={{
              "&:hover": {
                backgroundColor: "inherit", // Set the background color on hover if needed
              },
            }}
          >
            <ListItemText
              primary={
                <div className="pricing--strip">
                  <div className="mb-5">
                    <div className="club--detail--and--pass--details">
                      <h4>{datalist.name}</h4>
                      <h5>{datalist.clubName}</h5>
                    </div>
                    <div className="new--year-filter-clear">
                      <p className="new--year-filter-clear1">Passes Type</p>
                      <p className="new--year-filter-clear2">clear All</p>
                    </div>

                    {detailsdata.map((e) => (
                      <div className="ticket--type--data--set">
                        <p className="m-0 mt-2">
                          {e.ticket_type} @{e.price}
                        </p>
                        {/* test */}
                        {e.ticket_type === "Couple" ||
                          e.ticket_type === "Male Stag" ||
                          e.ticket_type === "Female Stag" ? (
                          e.ticket_type === "Male Stag" ? (
                            <div className="new--year--add--number--page1">
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={decrement}
                                >
                                  -
                                </p>
                              </div>
                              <div>
                                <p className="new--year--add--number--page1--p2 mt-3">
                                  {count}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={increment}
                                >
                                  +
                                </p>
                              </div>
                            </div>
                          ) : e.ticket_type === "Couple" ? (
                            <div className="new--year--add--number--page1">
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={coupledecrement}
                                >
                                  -
                                </p>
                              </div>
                              <div>
                                <p className="new--year--add--number--page1--p2 mt-3">
                                  {couplecount}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={coupleincrement}
                                >
                                  +
                                </p>
                              </div>
                            </div>
                          ) : e.ticket_type === "Female Stag" ? (
                            <div className="new--year--add--number--page1">
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={femaledecrement}
                                >
                                  -
                                </p>
                              </div>
                              <div>
                                <p className="new--year--add--number--page1--p2 mt-3">
                                  {femalecount}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="new--year--add--number--page1--p1 mt-3"
                                  onClick={femaleincrement}
                                >
                                  +
                                </p>
                              </div>
                            </div>
                          ) : null
                        ) : null}

                        {/* test */}
                      </div>
                    ))}
                    {detailsdata.map((e) => (
                      <div className="ticket--type--data--set">
                        {e.ticket_type === "Couple" ||
                          e.ticket_type === "Male Stag" ||
                          e.ticket_type === "Female Stag" ? (
                          e.ticket_type === "Male Stag" ? (
                            <>
                              {
                                (localStorage.setItem(
                                  "maleamount",
                                  JSON.stringify(e.price * count)
                                ),
                                  localStorage.setItem(
                                    "count",
                                    JSON.stringify(count)
                                  ))
                              }{" "}
                            </>
                          ) : e.ticket_type === "Couple" ? (
                            <>
                              {
                                (localStorage.setItem(
                                  "coupleamount",
                                  JSON.stringify(e.price * couplecount)
                                ),
                                  localStorage.setItem(
                                    "ccount",
                                    JSON.stringify(couplecount)
                                  ))
                              }{" "}
                            </>
                          ) : e.ticket_type === "Female Stag" ? (
                            <>
                              {
                                (localStorage.setItem(
                                  "Femaleamount",
                                  JSON.stringify(e.price * femalecount)
                                ),
                                  localStorage.setItem(
                                    "fcount",
                                    JSON.stringify(femalecount)
                                  ))
                              }{" "}
                            </>
                          ) : null
                        ) : null}

                        {/* test */}
                      </div>
                    ))}
                  </div>
                  <div className="price--total--card">
                    <div className="passes--and--price">
                      <p className="m-0">
                        {count + femalecount + couplecount} Passes &nbsp;
                        {maleamount + femaleamount + coupleamount}.00
                      </p>
                      <Link
                        to={`/booknow--table--cart/${passid}`}
                        className="bookatable-routes"
                      >
                        <p className="new--year--view--cart--p">
                          View Cart &nbsp;
                          <GrCaretNext />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  // const listdatacristmas = (anchor) => (
  //   <Box
  //     sx={{
  //       width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
  //       width: "100%",
  //       height: "350px",
  //       backgroundColor: "white",
  //       color: "black",
  //       "& *": {
  //         padding: "0 !important",
  //       },
  //     }}
  //     role="presentation"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //     }}
  //     onKeyDown={(e) => {
  //       e.stopPropagation();
  //     }}
  //   >
  //     <List>
  //       <ListItem
  //         disablePadding // This ensures there's no default padding
  //         dense
  //         sx={{
  //           "&:hover": {
  //             backgroundColor: "inherit",
  //           },
  //         }}
  //       >
  //         <ListItemButton
  //           onClick={(e) => e.stopPropagation()}
  //           disableRipple
  //           sx={{
  //             "&:hover": {
  //               backgroundColor: "inherit", // Set the background color on hover if needed
  //             },
  //           }}
  //         >
  //           <ListItemText
  //             primary={
  //               <div>
  //                 <div className="new--year-filter-clear">
  //                   <p className="new--year-filter-clear1">Passes Type</p>
  //                   <p className="new--year-filter-clear2">clear All</p>
  //                 </div>

  //                 {detailsdata.map((e) => (
  //                   <div className="ticket--type--data--set">
  //                     <p className="m-0 mt-2">
  //                       {e.ticket_type} @{e.price}
  //                     </p>
  //                     {/* test */}
  //                     {e.ticket_type === "Couple" ||
  //                     e.ticket_type === "Male Stag" ||
  //                     e.ticket_type === "Female Stag" ? (
  //                       e.ticket_type === "Male Stag" ? (
  //                         <div className="new--year--add--number--page1">
  //                           <div>
  //                             <p
  //                               className="new--year--add--number--page1--p1 mt-3"
  //                               onClick={decrement}
  //                             >
  //                               -
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p className="new--year--add--number--page1--p2 mt-3">
  //                               {count}
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p
  //                               className="new--year--add--number--page1--p1 mt-3"
  //                               onClick={increment}
  //                             >
  //                               +
  //                             </p>
  //                           </div>
  //                         </div>
  //                       ) : e.ticket_type === "Couple" ? (
  //                         <div className="new--year--add--number--page1">
  //                           <div>
  //                             <p
  //                               className="new--year--add--number--page1--p1 mt-3"
  //                               onClick={coupledecrement}
  //                             >
  //                               -
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p className="new--year--add--number--page1--p2 mt-3">
  //                               {couplecount}
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p
  //                               className="new--year--add--number--page1--p1 mt-3"
  //                               onClick={coupleincrement}
  //                             >
  //                               +
  //                             </p>
  //                           </div>
  //                         </div>
  //                       ) : e.ticket_type === "Female Stag" ? (
  //                         <div className="new--year--add--number--page1">
  //                           <div>
  //                             <p
  //                               className="new--year--add--number--page1--p1 mt-3"
  //                               onClick={femaledecrement}
  //                             >
  //                               -
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p className="new--year--add--number--page1--p2 mt-3">
  //                               {femalecount}
  //                             </p>
  //                           </div>
  //                           <div>
  //                             <p
  //                               className="new--year--add--number--page1--p1 mt-3"
  //                               onClick={femaleincrement}
  //                             >
  //                               +
  //                             </p>
  //                           </div>
  //                         </div>
  //                       ) : null
  //                     ) : null}

  //                     {/* test */}
  //                   </div>
  //                 ))}
  //                 {detailsdata.map((e) => (
  //                   <div className="ticket--type--data--set">
  //                     {e.ticket_type === "Couple" ||
  //                     e.ticket_type === "Male Stag" ||
  //                     e.ticket_type === "Female Stag" ? (
  //                       e.ticket_type === "Male Stag" ? (
  //                         <>
  //                           {
  //                             (localStorage.setItem(
  //                               "maleamount",
  //                               JSON.stringify(e.price * count)
  //                             ),
  //                             localStorage.setItem(
  //                               "count",
  //                               JSON.stringify(count)
  //                             ))
  //                           }{" "}
  //                         </>
  //                       ) : e.ticket_type === "Couple" ? (
  //                         <>
  //                           {
  //                             (localStorage.setItem(
  //                               "coupleamount",
  //                               JSON.stringify(e.price * couplecount)
  //                             ),
  //                             localStorage.setItem(
  //                               "ccount",
  //                               JSON.stringify(couplecount)
  //                             ))
  //                           }{" "}
  //                         </>
  //                       ) : e.ticket_type === "Female Stag" ? (
  //                         <>
  //                           {
  //                             (localStorage.setItem(
  //                               "Femaleamount",
  //                               JSON.stringify(e.price * femalecount)
  //                             ),
  //                             localStorage.setItem(
  //                               "fcount",
  //                               JSON.stringify(femalecount)
  //                             ))
  //                           }{" "}
  //                         </>
  //                       ) : null
  //                     ) : null}

  //                     {/* test */}
  //                   </div>
  //                 ))}
  //                 {/* <p>{maleamount + femaleamount + coupleamount}</p> */}
  //                 <div className="price--total--card">
  //                   <div className="passes--and--price">
  //                     <p className="m-0">
  //                       {count + femalecount + couplecount} Passes &nbsp;
  //                       {maleamount + femaleamount + coupleamount}.00
  //                     </p>
  //                     <Link
  //                       to={`/booknow--table--cart/${passid}`}
  //                       className="bookatable-routes"
  //                     >
  //                       <p className="m-0">
  //                         View Cart &nbsp;
  //                         <GrCaretNext />
  //                       </p>
  //                     </Link>
  //                   </div>
  //                 </div>
  //               </div>
  //             }
  //           />
  //         </ListItemButton>
  //       </ListItem>
  //     </List>
  //   </Box>
  // );

  const [openWidgetNewYear, setOpenWidgetNewYear] = useState(false);

  useEffect(() => {
    const widgetOpenedBefore = localStorage.getItem("widgetOpened");
    if (!widgetOpenedBefore) {
      setOpenWidgetNewYear(true); // Open the Widgetnewyear component if it hasn't been opened before
      localStorage.setItem("widgetOpened", "true"); // Set in localStorage that the component has been opened
    }
  }, []);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleOpenPlanEvent4 = (id, closeDrawer) => {
    console.log('User Data: ', userdata);
    if (userdata === null) {
      setShowLoginPopup(true);
      // closeDrawer();
    } else {
      handleCategoryClick(id);
      closeDrawer();
    }
  };

  // const handleOpenPlanEventdata = (id, closeDrawer) => {
  //   if (userdata === null) {
  //     setShowLoginPopup(true);
  //     closeDrawer();
  //   } else {
  //     handleClickmenudetails(id);
  //     closeDrawer();
  //   }
  // };
  // filterdata
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const applyFilter = (value) => {
    const filtered = newyeardata.filter(
      (item) =>
        item.clubName.toLowerCase().startsWith(value.toLowerCase()) ||
        item.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const [userInput, setUserInput] = useState("");
  const [options, setOptions] = useState([]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserInput(inputValue);

    // Fetch options based on the inputValue
    const newOptions = getOptions(inputValue);
    setOptions(newOptions);

    // Update filteredItems based on inputValue
    applyFilter(inputValue);
  };

  const getOptions = (inputValue) => {
    if (!inputValue.trim()) {
      return []; // Return empty array if input is empty or whitespace
    }

    const filteredData = newyeardata.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredData.length > 0 ? filteredData : []; // Return filteredData if it has items else return empty array
  };

  // const list3 = (anchor) => (
  //   <Box
  //     sx={{
  //       width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
  //       width: "100%",
  //       height: "500px",
  //       backgroundColor: "white",
  //       color: "black",
  //       padding: 0,
  //       margin: 0,
  //       overflowY: "auto",
  //     }}
  //     role="presentation"
  //     onClick={(e) => {
  //       e.stopPropagation();
  //     }}
  //     onKeyDown={(e) => {
  //       e.stopPropagation();
  //     }}
  //   >
  //     <List>
  //       <ListItem
  //         disablePadding
  //         dense
  //         sx={{
  //           "&:hover": {
  //             backgroundColor: "inherit",
  //             padding: "0px",
  //           },
  //         }}
  //       >
  //         <ListItemButton
  //           onClick={(e) => e.stopPropagation()}
  //           disablePadding
  //           disableRipple
  //           sx={{
  //             "&:hover": {
  //               backgroundColor: "inherit",
  //             },
  //           }}
  //         >
  //           <ListItemText
  //             sx={{ padding: 0 }}
  //             primary={
  //               <div className="new--year-menu--details--popup">
  //                 <div className="new--year-menu--details--popup--1-2">
  //                   <div className="new--year-menu--details--popup--1">
  //                     <button
  //                       onClick={() => handleButtonClickDetail("menu")}
  //                       style={{
  //                         backgroundColor:
  //                           activeDetail === "menu" ? "#1976d2" : "#fff",
  //                         color: activeDetail === "menu" ? "#fff" : "#808080",
  //                       }}
  //                     >
  //                       Menu
  //                     </button>
  //                     <button
  //                       onClick={() => handleButtonClickDetail("detail")}
  //                       style={{
  //                         backgroundColor:
  //                           activeDetail === "detail" ? "#1976d2" : "#fff",
  //                         color: activeDetail === "detail" ? "#fff" : "#808080",
  //                       }}
  //                     >
  //                       Details
  //                     </button>
  //                   </div>
  //                   <div className="new--year--border--line"></div>
  //                 </div>
  //                 {activeDetail === "menu" && (
  //                   <div className="new--year--menu--content">
  //                     <p className="new--year--menu--content--p">
  //                       ALCOHOLIC Drinks:
  //                     </p>
  //                     {menudetailsdata.whiskey === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Whiskey-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.whiskey}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.vodka === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Vodka-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.vodka}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.rum === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Rum-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.rum}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.gin === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           {" "}
  //                           Gin
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.gin}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.tequila === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Tequil
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.tequila}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.beer === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Beer-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.beer}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.red_wine === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Red Wine
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.red_wine}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.white_wine === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           White Wine
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.white_wine}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {menudetailsdata.rose_wine === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Rose Wine
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.rose_wine}
  //                         </p>
  //                       </div>
  //                     )}
  //                     {/* <div className="new--year--menu--content--p1">
  //                       <p className="new--year--menu--content--p--12">
  //                         9. Rose Wine
  //                       </p>
  //                       <p className="new--year--menu--content--p--13">
  //                         {datalist.whiskey}
  //                       </p>
  //                     </div> */}
  //                     {menudetailsdata.sparkling_wine === "" ? null : (
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Sparkling Wine
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.sparkling_wine}
  //                         </p>
  //                       </div>
  //                     )}

  //                     <p
  //                       dangerouslySetInnerHTML={{
  //                         __html: menudetailsdata.food_starter,
  //                       }}
  //                     ></p>
  //                     <p
  //                       dangerouslySetInnerHTML={{
  //                         __html: menudetailsdata.non_alcoholic_beverages,
  //                       }}
  //                     ></p>
  //                     <p
  //                       dangerouslySetInnerHTML={{
  //                         __html: menudetailsdata.special_offering,
  //                       }}
  //                     ></p>
  //                   </div>
  //                 )}
  //                 {activeDetail === "detail" && (
  //                   <div className="new--year--menu--content1">
  //                     <div className="new--year--menu--content--1">
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Entertainment-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.entertainment}
  //                         </p>
  //                       </div>
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Start Time-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.start_time}
  //                         </p>
  //                       </div>
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           End Time-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.end_time}
  //                         </p>
  //                       </div>
  //                       <div className="new--year--menu--content--p1">
  //                         <p className="new--year--menu--content--p--12">
  //                           Kids-
  //                         </p>
  //                         <p className="new--year--menu--content--p--13">
  //                           {menudetailsdata.kids_friendly === "true" ? (
  //                             <>Yes</>
  //                           ) : (
  //                             <>No</>
  //                           )}
  //                         </p>
  //                       </div>
  //                       <p
  //                         dangerouslySetInnerHTML={{
  //                           __html: menudetailsdata.tc,
  //                         }}
  //                       ></p>
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             }
  //           />
  //         </ListItemButton>
  //       </ListItem>
  //     </List>
  //   </Box>
  // );

  return (
    <div className="New--year--page--design--1">
      {showLoginPopup && <Loginpopup />}

      <div>
        <div>
          <Modal
            open={openPlanEvent}
            onClose={handleClosePlanEvent}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box className="new--year--filter--popup">
              <div className="Plan--your--event--popup">
                <div className="Plan--your--event--popup--1">
                  <div className="cls--btn" onClick={handleClosePlanEvent}>
                    X
                  </div>
                  <div>
                    <div className="new--year-filter-clear">
                      <p className="new--year-filter-clear1">Filter</p>
                      <img src={cross1}></img>
                    </div>
                    <div className="new--year--filter--border--line"></div>
                    <div className="new--year-filter-clear--content">
                      <div className="new--year-filter-clear--content1">
                        <button
                          onClick={() => handleButtonPrice("price")}
                          style={{
                            backgroundColor:
                              activePrice === "price" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "price" ? "#000000" : "#666a80",
                          }}
                        >
                          Price
                        </button>
                        <button
                          onClick={() => handleButtonPrice("gender")}
                          style={{
                            backgroundColor:
                              activePrice === "gender" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "gender" ? "#000000" : "#666a80",
                          }}
                        >
                          Gender
                        </button>
                        <button
                          onClick={() => handleButtonPrice("nearBy")}
                          style={{
                            backgroundColor:
                              activePrice === "nearBy" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "nearBy" ? "#000000" : "#666a80",
                          }}
                        >
                          Near By
                        </button>
                        <button
                          onClick={() => handleButtonPrice("amenities")}
                          style={{
                            backgroundColor:
                              activePrice === "amenities"
                                ? "#ffffff"
                                : "#F1F1F1",
                            color:
                              activePrice === "amenities"
                                ? "#000000"
                                : "#666a80",
                          }}
                        >
                          Amenities
                        </button>
                        <button
                          onClick={() => handleButtonPrice("type")}
                          style={{
                            backgroundColor:
                              activePrice === "type" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "type" ? "#000000" : "#666a80",
                          }}
                        >
                          Type
                        </button>
                      </div>
                      {activePrice === "price" && (
                        <div className="new--year-filter-clear--content2">
                          <div className="new--year-filter-clear--content2--price">
                            <div>
                              <input type="number" placeholder="0"></input>
                              <div className="new--year--input--content--border--line"></div>
                            </div>
                            <div>
                              <input type="number" placeholder="10000"></input>
                              <div className="new--year--input--content--border--line"></div>
                            </div>
                          </div>
                          <div className="new--year-filter-clear--content2--slider">
                            <Box sx={{ width: 230 }}>
                              <Slider
                                getAriaLabel={() => "Minimum distance"}
                                value={value1}
                                onChange={handleChange1}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                disableSwap
                              />
                            </Box>
                          </div>
                        </div>
                      )}
                      {activePrice === "gender" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Male Stag</p>
                            <div>
                              <div>
                                <input
                                  type="text"
                                  placeholder="No. of Male"
                                  className="number--male--input"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                ></input>
                                <div className="new--year--input--content--border--line"></div>
                              </div>
                              <div className="new--year--input--content--border--line"></div>
                            </div>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Female Stag</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Couple</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>With Kids</p>
                          </div>
                        </div>
                      )}
                      {activePrice === "nearBy" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year--amenties--search">
                            <input
                              type="text"
                              placeholder="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                              }}
                            ></input>
                            <img src={search}></img>
                          </div>
                          <div>
                            <div className="new--year--near--by">
                              <img src={GPIcon} alt="GPS Icon" />
                              <p>Detect Current Location</p>
                            </div>
                            <p className="location--gps--p-near-by">
                              Using GPS
                            </p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Location 1</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Location 2</p>
                          </div>

                          <div>
                            <Box sx={{ width: 210 }}>
                              <Slider
                                size="small"
                                defaultValue={70}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                              />
                            </Box>
                          </div>
                        </div>
                      )}
                      {activePrice === "amenities" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year--amenties--search">
                            <input
                              type="text"
                              placeholder="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                              }}
                            ></input>
                            <img src={search}></img>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 1</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 2</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 3</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 4</p>
                          </div>
                        </div>
                      )}
                      {activePrice === "type" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 1</p>
                            <input
                              type="text"
                              placeholder="Number of Male"
                              className="number--male--input"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                              }}
                            ></input>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 1</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 1</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 1</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="new--year--filter--border--line"></div>
                    <div className="new--year-filter-clear--1">
                      <button className="new--year-filter-clear--btn1">
                        Apply Filter
                      </button>
                      <button className="new--year-filter-clear--btn2">
                        Clear All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>

        <div>
          <Modal
            open={openPlanEvent1}
            onClose={handleClosePlanEvent1}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box className="new--year--filter--popup">
              <div className="Plan--your--event--popup">
                <div className="Plan--your--event--popup--1">
                  <div>
                    <div className="new--year-filter-clear">
                      <p className="new--year-filter-clear1">Filter</p>
                      <p className="new--year-filter-clear2">clear All</p>
                    </div>
                    <div className="new--year-filter-clear--content">
                      <div className="new--year-filter-clear--content1">
                        <button
                          onClick={() => handleButtonPrice1("price1")}
                          style={{
                            backgroundColor:
                              activePrice1 === "price1" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "price1" ? "#000000" : "#666a80",
                          }}
                        >
                          Price
                        </button>
                        <button
                          onClick={() => handleButtonPrice1("gender")}
                          style={{
                            backgroundColor:
                              activePrice1 === "gender" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "gender" ? "#000000" : "#666a80",
                          }}
                        >
                          Pax
                        </button>
                        <button
                          onClick={() => handleButtonPrice1("nearBy")}
                          style={{
                            backgroundColor:
                              activePrice1 === "nearBy" ? "#ffffff" : "#F1F1F1",
                            color:
                              activePrice === "nearBy" ? "#000000" : "#666a80",
                          }}
                        >
                          Near By
                        </button>
                        <button
                          onClick={() => handleButtonPrice1("amenities")}
                          style={{
                            backgroundColor:
                              activePrice1 === "amenities"
                                ? "#ffffff"
                                : "#F1F1F1",
                            color:
                              activePrice === "amenities"
                                ? "#000000"
                                : "#666a80",
                          }}
                        >
                          Amenities
                        </button>
                      </div>
                      {activePrice1 === "price" && (
                        <div className="new--year-filter-clear--content2">
                          <div className="new--year-filter-clear--content2--price">
                            <div>
                              <input type="number" placeholder="0"></input>
                              <div className="new--year--input--content--border--line"></div>
                            </div>
                            <div>
                              <input type="number" placeholder="10000"></input>
                              <div className="new--year--input--content--border--line"></div>
                            </div>
                          </div>
                          <div className="new--year-filter-clear--content2--slider">
                            <Box sx={{ width: 230 }}>
                              <Slider
                                getAriaLabel={() => "Minimum distance"}
                                value={value1}
                                onChange={handleChange1}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                disableSwap
                              />
                            </Box>
                          </div>
                        </div>
                      )}
                      {activePrice1 === "gender" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Male Stag</p>
                            <div>
                              <input
                                type="text"
                                placeholder="No. of Male"
                                className="number--male--input"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                }}
                              ></input>
                              <div className="new--year--input--content--border--line"></div>
                            </div>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Female Stag</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Couple</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>With Kids</p>
                          </div>
                        </div>
                      )}
                      {activePrice1 === "nearBy" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year--amenties--search">
                            <input
                              type="text"
                              placeholder="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                                // Add your logic for handling the click event on the search input
                              }}
                              onFocus={(e) => {
                                e.stopPropagation();
                                // Add your logic for handling the focus event on the search input
                              }}
                            />

                            <img src={search}></img>
                          </div>
                          <div>
                            <div className="new--year--near--by">
                              <img src={GPIcon} alt="GPS Icon" />
                              <p>Detect Current Location</p>
                            </div>
                            <p className="location--gps--p-near-by">
                              Using GPS
                            </p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Location 1</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Location 2</p>
                          </div>

                          <div>
                            <Box sx={{ width: 210 }}>
                              <Slider
                                size="small"
                                defaultValue={70}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                              />
                            </Box>
                          </div>
                        </div>
                      )}
                      {activePrice1 === "amenities" && (
                        <div className="new--year-filter-clear--content2-gender">
                          <div className="new--year--amenties--search">
                            <input
                              type="text"
                              placeholder="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              onFocus={(e) => {
                                e.stopPropagation();
                              }}
                            ></input>
                            <img src={search}></img>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 1</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 2</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 3</p>
                          </div>
                          <div className="new--year-filter-clear--content2-gender1">
                            <input
                              type="checkbox"
                              className="number--male--checkbox"
                            />
                            <p>Amenities 4</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="new--year-filter-clear--1">
                      <button className="new--year-filter-clear--btn1">
                        Apply Filter
                      </button>
                      <button className="new--year-filter-clear--btn2">
                        Clear All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>

      <div className="new--year--mobile--view--1">
        <div className="new--year--banner--5">
          <div className="Artist-viewall-banner">
            <Carousel autoplay>
              {data.map((e) => (
                <div className="Artist-viewall-banner--img">
                  <img src={url + e.img} alt="img" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="new--year--mobile--view--1-1--card">
          <div className="new--year--mobile--view--1-1">
            <button
              className="new--year--mobile--view--1-1--button"
              onClick={() => handleButtonClick("newYear")}
              style={{
                backgroundColor:
                  activeButton === "newYear" ? "#1976D2" : "#22222E",
              }}
            >
              {offer_type || 'New Year'}
            </button>
          </div>
        </div>
        {activeButton === "newYear" && (
          <div>
            <p className="new--year--mobile--view--1--p">
              Popular Places For {offer_type || 'New Year'} Parties In Delhi NCR
            </p>
            <h1 className="new--year--mobile--view--1--h1">Special Offers</h1>

            <div className="new--year--special--package">
              {specialoffer &&
                specialoffer.map((e) => (// Carnival Special Pass Card
                  <CarnivalPassCard pass={e} />
                ))}
            </div>

            <div className="new--year--mobile--view--1-1--card">
              <div className="new--year--mobile--view--1-1">
                <button
                  className="new--year--mobile--view--1-1--button"
                  onClick={() => handleButtonClick1("package")}
                  style={{
                    backgroundColor:
                      activeButton1 === "package" ? "#1976D2" : "#22222E",
                  }}
                >
                  Pass
                </button>
                <button
                  className="new--year--mobile--view--1-1--button"
                  onClick={() => handleButtonClick1("tableBooking")}
                  style={{
                    backgroundColor:
                      activeButton1 === "tableBooking" ? "#1976D2" : "#22222E",
                  }}
                >
                  Table Booking
                </button>
              </div>
            </div>

            {activeButton1 === "package" && (
              <div className="new--year--package--tab">
                {activeButton2 === "stag" && (
                  <div className="new--year--special--package1-couple">
                    <div style={searchBarStyleMobile}>
                      <input
                        style={inputStyle}
                        type="text"
                        placeholder="Search..."
                        value={userInput}
                        onChange={handleInputChange}
                      />
                      <span>
                        <LuSearch />
                      </span>
                      {/* </div> */}
                    </div>
                    {userInput.trim() === "" ? (
                      newyeardata.map((e) => ( // Carnival Pass Card
                        <CarnivalPassCard pass={e} />
                      ))
                    ) : filteredItems.length > 0 ? (
                      filteredItems.map((e) => (// Carnival Pass Card
                        <CarnivalPassCard pass={e} />
                      ))
                    ) : (
                      <p className="new--year--special--package1--stag--pass">
                        No Pass Added By Club
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            {activeButton1 === "tableBooking" && (
              <div className="new--year--table--booking--tab">
                <div className="new--year--couple--stag">
                  <div className="new--year--mobile--view--1-1-stag-couple--1">
                    <p className="new--year--special--package1--stag--pass">
                      Table Booking
                    </p>
                  </div>
                </div>
                <div className="new--year--special--package1">
                  {tablebookingnewyear &&
                    tablebookingnewyear.map((e) => (
                      <div className="New--year--page--design--All--passes1--card1">
                        <div className="New--year--page--design--All--passes1--card--img">
                          <img src={url + e.icon}></img>
                          <div className="new--year--page--img--button--dj">
                            <div className="dj--nights--img">
                              <img src={Group}></img>
                              <button className="dj--nights--img-btn1">
                                {e.entertainment}
                              </button>
                            </div>
                            <div className="dj--nights--img">
                              <button className="dj--nights--img-btn3">
                                Book a Table
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="New--year--page--design--All--passes1--card--content">
                          <div className="New--year--page--design--All--passes1--card--content--1">
                            <div className="New--year--page--all--passes1--card-p--9">
                              <p className="New--year--page--design--All--passes1--card--content--1--p">
                                {e.name}
                              </p>
                              <div className="new--year--card--content2">
                                <p className="New--year--page--design--All--passes1--card--content--p--blue">
                                  {e.clubName}
                                </p>
                                <div className="new--year--card--content2--1">
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.start_time} - {e.end_time}
                                  </p>
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.clubAddress.slice(0, 150)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="New--year--page--design--All--passes1--card--content--1-12">
                              <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                                Start @ ₹{e.min_spent}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                minimum Spend
                              </p>
                              <p className="new--year--page--table6">
                                Table For {e.seat}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                {e.kids_friendly === "true" ? (
                                  <>Kids Allowed</>
                                ) : (
                                  <>Kids Not Allowed</>
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="new--year--menu--details--9">
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Menu
                              </p>
                            </button>
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Details
                              </p>
                            </button>
                          </div>
                          <div className="new--year--page--border--line"></div>
                          <Link
                            to={`/tablebooking--pass/${e.id}`}
                            className="bookatable-routes"
                          >
                            <p className="new--year--page--more--detail">
                              Book Now
                            </p>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
        {/* {activeButton === "christmas" && (
          <div>
            <p className="new--year--mobile--view--1--p">
              Popular Places For Christmas Parties In Delhi NCR
            </p>
            <h1 className="new--year--mobile--view--1--h1">Special Offers</h1>

            <div className="new--year--special--package">
              {specialoffercristmas &&
                specialoffercristmas.map((e) => (
                  <div className="New--year--page--design--All--passes1--card1">
                    <div className="New--year--page--design--All--passes1--card--img">
                      <img src={url + e.icon}></img>
                      <div className="new--year--page--img--button--dj">
                        <div className="dj--nights--img">
                          <img src={Group}></img>
                          <button className="dj--nights--img-btn1">
                            {e.entertainment}
                          </button>
                        </div>
                        <div className="dj--nights--img">
                          <button className="dj--nights--img-btn3">
                            {e.min_spent in e && e[e.min_spent] === null
                              ? " Book a Table"
                              : "Pass"}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="New--year--page--design--All--passes1--card--content">
                      <div className="New--year--page--design--All--passes1--card--content--1">
                        <p className="New--year--page--design--All--passes1--card--content--1--p--6">
                          {e.name}
                        </p>

                        <p className="New--year--page--design--All--passes1--card--content--1--p--price--12 text-end">
                          Start @{e.pricing[0].price}
                        </p>
                      </div>
                      <div className="new--year--menu--details--9">
                        <button>
                          <p className="new--year--page--more--detail--9">
                            Menu
                          </p>
                        </button>
                        <button>
                          <p className="new--year--page--more--detail--9">
                            Details
                          </p>
                        </button>
                      </div>
                      <div className="new--year--page--border--line"></div>
                      {["bottom"].map((anchor) => (
                        <React.Fragment key={anchor}>
                          <div onClick={toggleDrawercristmas(anchor, true)}>
                            {" "}
                            <p
                              className="new--year--page--more--detail"
                              key={e.id}
                              // onClick={() => handleCategoryClick(e.id)}
                              onClick={() =>
                                handleOpenPlanEvent4(e.id, () =>
                                  toggleDrawer(anchor, false)
                                )
                              }
                            >
                              Book Now
                            </p>
                          </div>
                          <Drawer
                            anchor={anchor}
                            open={statecristmas[anchor]}
                            onClose={toggleDrawercristmas(anchor, false)}
                            PaperProps={{
                              sx: {
                                margin: "15px", // Adjust the margin values as needed
                                borderTopLeftRadius: "8px", // Set border-top-left-radius
                                borderTopRightRadius: "8px", // Set border-top-right-radius
                              },
                            }}
                          >
                            {listdatacristmas(anchor)}
                          </Drawer>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          

            <div className="new--year--mobile--view--1-1--card">
              <div className="new--year--mobile--view--1-1">
                <button
                  className="new--year--mobile--view--1-1--button"
                  onClick={() => handleButtonClick1("package")}
                  style={{
                    backgroundColor:
                      activeButton1 === "package" ? "#1976D2" : "#22222E",
                  }}
                >
                  Pass
                </button>
                <button
                  className="new--year--mobile--view--1-1--button1"
                  onClick={() => handleButtonClick1("tableBooking")}
                  style={{
                    backgroundColor:
                      activeButton1 === "tableBooking" ? "#1976D2" : "#22222E",
                  }}
                >
                  Table Booking
                </button>
              </div>
            </div>
            {activeButton1 === "package" && (
              <div className="new--year--package--tab">
                <div className="new--year--couple--stag">
                  <div className="new--year--mobile--view--1-1-stag-couple">
                    <button
                      onClick={() => handleButtonClick2("stag")}
                      style={{
                        backgroundColor:
                          activeButton2 === "stag" ? "#1976D2" : "#22222E",
                      }}
                    >
                      Stag
                    </button>
                    <button
                      onClick={() => handleButtonClick2("couple")}
                      style={{
                        backgroundColor:
                          activeButton2 === "couple" ? "#1976D2" : "#22222E",
                      }}
                    >
                      Couple
                    </button>
                  </div>
                </div>
                {activeButton2 === "stag" && (
                  <div className="new--year--special--package1-couple">
                    <p className="new--year--special--package1--stag--pass">
                      Stag Passes
                    </p>
                    {stagesortdata1.map((e) => (
                      <div className="New--year--page--design--All--passes1--card1">
                        <div className="New--year--page--design--All--passes1--card--img">
                          <img src={url + e.icon}></img>
                          <div className="new--year--page--img--button--dj">
                            <div className="dj--nights--img">
                              <img src={Group}></img>
                              <button className="dj--nights--img-btn1">
                                {e.entertainment}
                              </button>
                            </div>
                            <div className="dj--nights--img">
                              <button className="dj--nights--img-btn3">
                                Pass
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="New--year--page--design--All--passes1--card--content">
                          <div className="New--year--page--design--All--passes1--card--content--1">
                            <div className="New--year--page--all--passes1--card-p--9">
                              <p className="New--year--page--design--All--passes1--card--content--1--p">
                                {e.name.slice(0, 150)}
                              </p>
                              <div className="new--year--card--content2--1-2">
                                <p className="New--year--page--design--All--passes1--card--content--p--blue">
                                  {e.clubName}
                                </p>
                                <div className="new--year--card--content2--1">
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.start_time} - {e.end_time}
                                  </p>
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.clubAddress.slice(0, 150)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="New--year--page--design--All--passes1--card--content--1-12">
                              <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                                Start @ ₹{e.pricing.price}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                Price
                              </p>
                              <p className="new--year--page--table6--package">
                                {e.pricing.seat} Seats Left
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                Kids{" "}
                                {e.kids_friendly === "true" ? (
                                  <>Yes</>
                                ) : (
                                  <>Not</>
                                )}{" "}
                                Allowed
                              </p>
                              <div className="new--year--stag-female">
                                {e.pricing.ticket_type === "Male Stag" ? (
                                  <div className="new--year--stag-male">
                                    <img src={male}></img>
                                    <p>M</p>
                                  </div>
                                ) : (
                                  <div className="new--year--stag-female">
                                    <img src={female}></img>
                                    <p>F</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="new--year--menu--details--9">
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Menu
                              </p>
                            </button>
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Details
                              </p>
                            </button>
                          </div>

                          <div className="new--year--page--border--line"></div>
                          {["bottom"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <div onClick={toggleDrawercristmas(anchor, true)}>
                                {" "}
                                <p
                                  className="new--year--page--more--detail"
                                  key={e.id}
                                  // onClick={() => handleCategoryClick(e.id)}
                                  onClick={() =>
                                    handleOpenPlanEvent4(e.id, () =>
                                      toggleDrawer(anchor, false)
                                    )
                                  }
                                >
                                  Book Now
                                </p>
                              </div>
                              <Drawer
                                anchor={anchor}
                                open={statecristmas[anchor]}
                                onClose={toggleDrawercristmas(anchor, false)}
                                PaperProps={{
                                  sx: {
                                    margin: "15px", // Adjust the margin values as needed
                                    borderTopLeftRadius: "8px", // Set border-top-left-radius
                                    borderTopRightRadius: "8px", // Set border-top-right-radius
                                  },
                                }}
                              >
                                {listdatacristmas(anchor)}
                              </Drawer>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {activeButton2 === "couple" && (
                  <div className="new--year--special--package1-couple">
                    <p className="new--year--special--package1--stag--pass">
                      Couple Passes
                    </p>
                    {mergedCoupleData1[0].map((e) => (
                      <div className="New--year--page--design--All--passes1--card1">
                        <div className="New--year--page--design--All--passes1--card--img">
                          <img src={url + e.icon}></img>
                          <div className="new--year--page--img--button--dj">
                            <div className="dj--nights--img">
                              <img src={Group}></img>
                              <button className="dj--nights--img-btn1">
                                {e.entertainment}
                              </button>
                            </div>
                            <div className="dj--nights--img">
                              <button className="dj--nights--img-btn3">
                                Package
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="New--year--page--design--All--passes1--card--content">
                          <div className="New--year--page--design--All--passes1--card--content--1">
                            <div className="New--year--page--all--passes1--card-p--9">
                              <p className="New--year--page--design--All--passes1--card--content--1--p">
                                {e.name}
                              </p>
                              <div className="new--year--card--content2--1-2">
                                <p className="New--year--page--design--All--passes1--card--content--p--blue">
                                  {e.clubName}
                                </p>
                                <div className="new--year--card--content2--1">
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.start_time} - {e.end_time}
                                  </p>
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.clubAddress.slice(0, 150)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="New--year--page--design--All--passes1--card--content--1-12">
                              <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                                Start @ ₹{e.pricing.price}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                Price
                              </p>
                              <p className="new--year--page--table6--package">
                                {e.pricing.seat} Seats Left
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                Kids{" "}
                                {e.kids_friendly === "true" ? (
                                  <>Yes</>
                                ) : (
                                  <>Not</>
                                )}{" "}
                                Allowed
                              </p>
                              <div className="new--year--couple">
                                <div className="new--year--stag-female">
                                  <img src={female}></img>
                                  <p>F</p>
                                </div>
                                <div className="new--year--stag-male">
                                  <img src={male}></img>
                                  <p>M</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="new--year--menu--details--9">
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Menu
                              </p>
                            </button>
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Details
                              </p>
                            </button>
                          </div>
                          <div className="new--year--page--border--line"></div>
                          {["bottom"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <div onClick={toggleDrawercristmas(anchor, true)}>
                                {" "}
                                <p
                                  className="new--year--page--more--detail"
                                  key={e.id}
                                  // onClick={() => handleCategoryClick(e.id)}
                                  onClick={() =>
                                    handleOpenPlanEvent4(e.id, () =>
                                      toggleDrawer(anchor, false)
                                    )
                                  }
                                >
                                  Book Now
                                </p>
                              </div>
                              <Drawer
                                anchor={anchor}
                                open={statecristmas[anchor]}
                                onClose={toggleDrawercristmas(anchor, false)}
                                PaperProps={{
                                  sx: {
                                    margin: "15px", // Adjust the margin values as needed
                                    borderTopLeftRadius: "8px", // Set border-top-left-radius
                                    borderTopRightRadius: "8px", // Set border-top-right-radius
                                  },
                                }}
                              >
                                {listdatacristmas(anchor)}
                              </Drawer>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {activeButton1 === "tableBooking" && (
              <div className="new--year--table--booking--tab">
                <div className="new--year--couple--stag">
                  <div className="new--year--mobile--view--1-1-stag-couple--1">
                    <p className="new--year--special--package1--stag--pass">
                      Table Booking
                    </p>
                  </div>
                </div>
                <div className="new--year--special--package1">
                  {tablebookingcristmas &&
                    tablebookingcristmas.map((e) => (
                      <div className="New--year--page--design--All--passes1--card1">
                        <div className="New--year--page--design--All--passes1--card--img">
                          <img src={url + e.icon}></img>
                          <div className="new--year--page--img--button--dj">
                            <div className="dj--nights--img">
                              <img src={Group}></img>
                              <button className="dj--nights--img-btn1">
                                {e.entertainment}
                              </button>
                            </div>
                            <div className="dj--nights--img">
                              <button className="dj--nights--img-btn3">
                                Book a Table
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="New--year--page--design--All--passes1--card--content">
                          <div className="New--year--page--design--All--passes1--card--content--1">
                            <div className="New--year--page--all--passes1--card-p--9">
                              <p className="New--year--page--design--All--passes1--card--content--1--p">
                                {e.name}
                              </p>
                              <div className="new--year--card--content2">
                                <p className="New--year--page--design--All--passes1--card--content--p--blue">
                                  {e.clubName}
                                </p>
                                <div className="new--year--card--content2--1">
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.start_time} - {e.end_time}
                                  </p>
                                  <p className="New--year--page--design--All--passes1--card--content--1-12--p1">
                                    {e.clubAddress.slice(0, 150)}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="New--year--page--design--All--passes1--card--content--1-12">
                              <p className="New--year--page--design--All--passes1--card--content--1--p--price">
                                ₹{e.min_spent}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                minimum Spend
                              </p>
                              <p className="new--year--page--table6">
                                Table For {e.max_sitting_capacity}
                              </p>
                              <p className="New--year--page--design--All--passes1--card--content--1--p--1-minimum">
                                {e.kids_friendly === "true" ? (
                                  <>Kids Allowed</>
                                ) : (
                                  <>Kids Not Allowed</>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="new--year--menu--details--9">
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Menu
                              </p>
                            </button>
                            <button>
                              <p className="new--year--page--more--detail--9">
                                Details
                              </p>
                            </button>
                          </div>
                          <div className="new--year--page--border--line"></div>
                          <Link
                            to={`/tablebooking--pass/${e.id}`}
                            className="bookatable-routes"
                          >
                            <p className="new--year--page--more--detail">
                              Book Now
                            </p>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )} */}

        <div className="Artist-viewall-banner--11">
          <Carousel autoplay className="footer--banner">
            {data.map((e) => (
              <div className="Artist-viewall-banner--img--1-1">
                <img src={url + e.footer_img}></img>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
