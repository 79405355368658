import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Caraousal.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import WebApp1 from "../../Web--to--app/WebApp1";

export default function UseindHomepageCarousal() {
  const [counter, setCounter] = useState(0);
  const [data, setData] = useState([]);
  const [showPopUpIndex, setShowPopUpIndex] = useState(null);
  const [popupKey, setPopupKey] = useState(0); // Key to force re-render
  const URL = "https://partywitty.com/master/assets/uploads/";

  const fetchURL = async () => {
    try {
      const response = await axios.get(
        "https://partywitty.com/master/APIs/Artist/specialArtist"
      );
      setData(response.data.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchURL();
  }, []);

  const handleClickButton = (index) => {
    setShowPopUpIndex(index);
    setPopupKey(prevKey => prevKey + 1); // Increment key to force re-render
  };

  const handleClosePopUp = () => {
    setShowPopUpIndex(null);
  };

  return (
    <>
      <div className="useind--homepage--caraousal--card">
        <Carousel
          selectedItem={counter}
          onChange={(index) => setCounter(index)}
        >
          {data.length > 0 ? (
            data.map((artist, index) => (
              <div
                className={`useind--homepage--caraousal--card1 ${
                  index === counter ? "active" : ""
                }`}
                key={artist.id}
              >
                <div className="Artist-caraousal-left">
                  {artist.video ? (
                    <video controls autoPlay muted >
                    <source src={URL + artist.video} type="video/mp4" />
                  </video>
                  
                  ) : artist.cover_pic ? (
                   
                     <img src={URL + artist.cover_pic} alt="img" />
                  ) : (
                    <p>No Media Available</p>
                  )}
                </div>
                <div className="useind--homepage--caraousal--card--p">
                  <div className="useind--homepage--caraousal--card--p1">
                    <div className="useind--homepage--caraousal--card--p--2">
                      <p>{artist.name}</p>
                    </div>
                    <div className="artist-content-para">
                      {artist.genre.split(",").map((genre, genreIndex) => (
                        <button key={genreIndex}>{genre.trim()}</button>
                      ))}
                    </div>
                  </div>
                  <div className="Artist-book-now">
                    <button onClick={() => handleClickButton(index)}>View Profile</button>
                    {showPopUpIndex === index && <WebApp1 key={popupKey} onClose={handleClosePopUp} />}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </Carousel>
      </div>
    </>
  );
}
