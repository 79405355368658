import './Services.css';


import livemusicc from "./image/livemusicc.avif"
import djlamp from "./image/djlamp.avif"
import drink from "./image/drink.png"
import privateparty from "./image/privateparty.avif"
import partyimages from "./image/partyimages.jpg"
import party from "./image/party.avif"


export default function AboutusPage() {
    return (
        <div className="aboutusPage">
          
            <section className="about--banner">
                <div className="main--box">
                    <div className="title--name">
                        <h3>About us</h3>
                    </div>
                </div>
            </section>
            <div className="aboutus">
                <section className="main--feacture">
                    <div className="main--box">
                        <div className="title">
                            <h5>WHO WE ARE</h5>
                            <h2>Main Features</h2>
                        </div>
                        <div className="grid-box">
                            <div className="box--a">
                                <img src={drink} alt="" className="icon--img"></img>
                                <p>Special Events and Drink Promos</p>
                            </div>
                            <div className="box--a">
                                <img src={livemusicc} alt="" className="icon--img"></img>
                                <p>Live Music Update,  Live Band Performance </p>
                            </div>
                            <div className="box--a">
                                <img src={djlamp} alt="" className="icon--img"></img>
                                <p>Real-time status of Happening Party in Town</p>
                            </div>
                            <div className="box--a">
                                <img src={privateparty} alt="" className="icon--img"></img>
                                <p>Private Parties From 20 to 150 guests</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about--section">
                    <div className="main--box">
                        <div className="wrapper--box">
                            <div className="left--box">
                                <h3>Create Perfect Party Plots</h3>
                                <p>Welcome to <a href="/">Party Witty</a> and get ready to TIP TAP TOE as a happening party is on your cards today. Our already busy lives and hectic schedules make us hate choosing a hangout place. All the while when you are sticking your eyes to laptop screens, you feel no more calling places for reservations or deals.</p>
                                <p>Whoa! Party Witty got your back there….We curate experience and we do it with FINESSE!</p>
                                <p>We promise your celebration this evening will be fun with no browsing sessions. Party Witty is a platform for you if you are an instinctive party-goer. If your feet do not tap on Bollywood, we got Sufi for you. If the DJ sounds not so happening to you, we have got something called ‘Live Music’. If the crowd is what you think much of, we have the most peaceful place with fewer people and the most happening place with people dancing wild. Yes! If you call this “You name it and get it” thing, IT TRULY IS!</p>
                                <div>
                                    <button className="btn--theame--2">
                                        TRENDING DEALS
                                    </button>
                                </div>
                            </div>
                            <div className="right--box">
                                <img src={partyimages} alt=""></img>
                            </div>
                        </div>
                        <div className="wrapper--box">
                            <div className="right--box">
                                <img src={party} alt=""></img>
                            </div>
                            <div className="left--box">
                                <h3>It’s raining rewards & opportunities</h3>
                                <p>It is not just this to the celebration! We know you want something more to make parties joyous for you. Who doesn’t love freebies & maddening deals? A drink for free, a 1+1 pass, couple entry discount followed by raising unlimited toast to the celebration called ‘Life’. Party Witty will ask you to say ‘Cheeeeeeseeee’ as we would be just there to capture these moments.</p>
                                <p>Neither have we ever seen a genie nor can we be one to bring you some gold reserve. We,at Party Witty understand what defines ‘FUN’ for you. This has made us the country’s first platform with real time reporting on deals, guests, availability and atmos of the party places around you. We list down a plethora of celebration options so that you have a credible reason for the choices made. We give clubs an opportunity to connect with existing and prospective guests at the same time. We also build a strong network for artists to enhance their career graph.</p>
                                <p><b><i>If you are an artist or a restaurant owner,</i></b> we have got room for your growth as well.
                                    To know more, click here

                                    <a href="/">artist</a> ; 
                                </p>
                                <div>
                                    <button className="btn--theame--2">Contact Us</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
              
                       
                            
                     

             
             
            </div>
        </div>
    );
}