import React, { useEffect, useState } from "react";
import "./Clubpage.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";


function Menu() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const [menu, setMenu] = useState([]);
  const params = useParams();
  const [id, setId] = useState(null);
  const clubid = new FormData();
  clubid.append("club_id", id);
  var url = "https://partywitty.com/master/assets/uploads/";
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [categories, setCategories] = useState([]);
  const [categories1, setCategories1] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [responseFromPostApi, setResponseFromPostApi] = useState([]);
  const [data, setData] = useState([]);
  const [titleName, setTitleName] = useState(null);
  const { city, area, clubname } = useParams();
  const clubN = clubname;
  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);
  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        setData(response.data.data);
        const id = response.data.data.id;
        setId(id);
       
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  const clubid1 = new FormData();
  clubid1.append("club_id", id);
  useEffect(() => {
    if (id) {
      axios
        .post("https://partywitty.com/master/Api/getDataPost/clubMenu", clubid1)
        .then((response) => {
          if (response.data.status === "true") {
            setTitleName(response.data.data.title_name);
            setCategories([]);
           
            console.log('Title Name:', response.data.data.title_name);
          } else {
            setCategories(response.data.data);
            setCategories1(response.data.data.img);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);


  return (
    <>
      <div className="book--a--table--menu--page--web--view">
      <div className="package_images">
        {categories && categories.length > 0 ? (
          categories.map((category) => (
            <div className="pkg_img_name" key={category.id}>
              {category.images && typeof category.images === "object" ? (
                <a
                  href={url + category.img.img}
                  data-fancybox={`gallery-${category.id}`}
                  data-caption={category.title_name}
                >
                  <img src={url + category.img.img} alt={category.title} />
                </a>
              ) : (
                <a
                  href={url + category.img}
                  data-fancybox={`gallery-${category.id}`}
                  data-caption={category.title_name}
                >
                  <img src={url + category.img} alt={category.title} />
                </a>
              )}
              <p className="mt-3">
                {category.title_name} <br />
                <span></span>
              </p>
              {category.images && Array.isArray(category.images) && category.images.map((img, imgIndex) => (
                <a
                  key={imgIndex}
                  href={url + img.img}
                  data-fancybox={`gallery-${category.id}`}
                  data-caption={img.title || category.title_name}
                  style={{ display: 'none' }}
                >
                  <img src={url + img.img} alt={img.title || category.title_name} />
                </a>
              ))}
            </div>
          ))
        ) : (
          <div className="nodata--found">No data found</div>
        )}
      </div>
      </div>
    </>
  );
}
export default Menu;

