import './Agreement.css';

export default function ArtistAgreement(){
    return(
        <div class="clubagreement pt-5">
         <section class="main--feacture tc--section pt-5">
    <div class="main--box">
        <h1 class="tc--title">Artist Agreement</h1>
        <p class="tc--para">These Terms of Service (the "Terms") are intended to make Artist (“You") aware of your legal rights and responsibilities with respect to your engagement with Amirag Hospitality OPC Private Limited (the “Company”) as an Artist. </p>
        <p class="tc--para">Please read these Terms carefully. When you agree to these Terms and conditions it concludes a legally binding contract with the Company and/or its affiliates. You shall not collaborate with the Company as an Artist if you do not accept the Terms or are unable to be bound by the Terms. In order to engage with the Company, you must first agree to the Terms. You can accept the Terms by Clicking to accept or agree to the Terms.</p>
        <h4 class="tc--subtitle">SCOPE OF AGENCY</h4>
        <p class="tc--para">You hereby engages the Company, and the Company accepts engagement, as Your sole agent within the territory of India (the “Territory”).</p>
        <ul>
            <li>
                <dl>
                    <dt>The Company agrees to act in its best capacity</dt>
                    <dd>To secure assignments for you as per your field of work.</dd>
                    <dd>To negotiate the terms of the assignment considering the best interests of yoursthrough its application and website named  You, iffinds the terms unacceptable, shall have the right to reject the given assignment. </dd>
                    <dd>To accept payment from third parties and enter the records accordingly. The Company may record videos of Your’s and promote the same on different platforms at the sole expense of yours. To the avoidance of any doubt the intellectual property rights in such promotional videos always remains with the Company only, youcan use these video only for self-promotion by giving credit to the Company.</dd>
                </dl>
            </li>
        </ul>
        <h4 class="tc--subtitle">TERM</h4>
        <p class="tc--para">The arrangement between the parties shall commence from the Commencement Date and shall be valid and binding on the parties, unless terminated in accordance with these Terms.</p>
        <p class="tc--para">Either Party may terminate the Form and the Terms by issuing a thirty (30) days prior written notice of termination to the other Party.</p>
       
        <h5 class="tc--subtitle">ENROLLMENT</h5>
        <p class="tc--para">You may enroll as an Artist through a referral code only, any existing Artist on the Platform shall be deemed as Qualified Referral; only a new user registering on the Platform using your Referral Code shall be considered as your Qualified Referral.</p>
       
        <h4 class="tc--subtitle">CONSIDERATION </h4>
        
        <p class="tc--para">Company shall be entitled to the 25% of commissions on assignments secured by the Company during the term of the Agreement. It is agreed by the parties that no commission shall be paid on assignments for which the You fails to receive payment and for the assignments rejected by You, regardless of the reasons. Further, on the expenses incurred by You in performing the assignment, no amount shall be payable, irrespective of such expenses are reimbursed by the Client.</p>
        <p class="tc--para">Parties are agreed that they will share booking fees in equal proportions if you receive or charge over and above the base price of your show. To the avoidance of any doubt, the base price will be decided by the parties mutually.</p>
        <p class="tc--para">The consideration shall be paid on weekly basis on an invoice generated by the Company timely.</p>
        
        <h4 class="tc--subtitle">PENALTIES FOR BREACH OF CONTRACT</h4>
        <p class="tc--para">Without prejudice to other remedies or powers that the Contract or the Applicable Laws and Provisions may establish in favour of the Company if you by reason of your own neglect or default, fails to appear and perform at the Club for which you have accepted the booking, You have to pay a penalty equal to 4Xtimes of booking amount. </p>
        <h4 class="tc--subtitle">PROMOTION </h4>
        <p class="tc--para">You, at the Your’s expense, shall provide the Company with such samples of work as are from time to time necessary for the purpose of securing assignments. These samples shall remain the property of  yourand digital copies sample work can be used without by you without claiming any Intellectual property in such work even after the termination of this agreement.  The Company shall take reasonable efforts to protect the work from loss or damage, but not to be liable for such loss or damage even caused by the it's negligence. Promotional expenses, including but not limited to promotional mailings and paid advertising, shall be paid by You.  </p>
        

        <h4 class="tc--subtitle">ASSIGNMENT </h4>
        <p class="tc--para">This Agreement shall not be assigned by either of the parties hereto. It shall be binding on and inure to the benefit of the successors, administrators, executors, or heirs of the Company and You.</p>
        <h4 class="tc--subtitle">INSPECTION OF THE BOOKS AND RECORDS</h4>
        <p class="tc--para">The party responsible for the billing shall keep the books and records with respect to commissions due at his or her place of business and permit the other party to inspect these books and records during normal business hours on the giving of reasonable notice.</p>


        <h4 class="tc--subtitle">MODIFICATIONS </h4>
        <p  class="tc--para">All modification to the Agreement shall be made with the consent of both parties and must be inwriting.</p>
        <h4 class="tc--subtitle">FORCE MAJEURE </h4>
        <p class="tc--para"> Either Party shall be excused from any delay or failure in performance required hereunder if caused by reason of any occurrence or contingency beyond its reasonable control, including, but not limited to, acts of God, acts of war, fire, insurrection, strikes, lock-outs or other serious labour disputes, riots, earthquakes, floods, explosions or other acts of nature. </p>
       <h4 class="tc--subtitle">GOVERNING LAW AND DISPUTE RESOLUTION</h4>
       <p class="tc--para">Any and all disputes, controversies, and conflicts ("Disputes") arising out of this Agreement between the Parties or arising out of or relating to or in connection with this Agreement and the performance or non-performance of the rights and obligations set forth herein or the breach, termination or invalidity thereof shall be subject to the jurisdiction of Courts in Delhi Only. Prior to submitting the Disputes to Courts the parties shall mutually resolve to settle the Disputes through mutual negotiation and discussions. In the event that the said Disputes are not settled within 15 days of the arising of the Disputes, the same shall finally be subjected to and adjudicated by Arbitration mechanism as per the provisions of the Arbitration and Conciliation Act, 1996 as amended till date. Both the parties shall jointly select the sole Arbitrator and if sole Arbitrator cannot be appointed then each party shall select its choice of Arbitrator and the two Arbitrators appointed shall jointly select the Third Arbitrator who shall act as Presiding Officer. The Arbitration proceeding shall be in English and venue of Arbitration shall be at Delhi. However, the Courts in Delhi under the Laws of India shall have exclusive jurisdiction to any other court. </p>
       <p class="tc--para">By checking here, you are consenting to use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record.</p>
       
    </div>
</section>
        </div>
    );
}