import React from 'react';
import { IoIosArrowUp } from "react-icons/io";
import Gallery from '../AboutClub/image/gallery.png';

export default function Index(){
    return(
        <div className='about-club-details'>
         <div className='about-club-detail-collapse'>
         <p>Gallery</p>
         <span><IoIosArrowUp /></span>
        </div>
        <div className='about-club-Gallery'>
            <div className='about-club-Gallery1'>
              <div className='about-club-Gallery-button'>
                <button>All</button>
                <button>Ambience</button>
                <button>Food</button>
                <button>Beverages</button>
              </div>
              <div className='about-club-Gallery-view'>
              <button>View All</button>
              </div>
            </div>
            <div className='club-gallery-img'>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>

             <div className='club-gallery-img1'>
                <img src={Gallery}></img>
             </div>
            </div>
        </div>
        </div>
    );
}