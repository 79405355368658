import "./My--Booking.css";
import Arrow from "./image/Arrow.svg";

import flag from "./image/flag.png";
import { BsCalendar2Date } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";

export default function MyProfile() {
  const getEighteenYearsAgoDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };
  const userdata = JSON.parse(localStorage.getItem("userData"));

  return (
    <div className="hamburger--my--profile">
      <div className="mobile--view--hamburger--my--booking--1">
        <img src={Arrow} className="left-aligned-img" alt="Arrow Icon" />
        <p className="centered-text">My Profile</p>
      </div>

      <div className="hamburger--my--profile--content">
        <div className="hamburger--my--profile--content--12">
          
          <CgProfile />
          <div className="hamburger--my--profile--content--1">
            <p className="hamburger--my--profile--content--1--p">
              {userdata.name}
            </p>
            <p className="hamburger--my--profile--content--1--p1">
              {userdata.email}
            </p>
          </div>
        </div>
        <div className="hamburger--my--profile--content--12--1">
          <input
            type="text"
            placeholder="Full Name"
            value={userdata.name}
          ></input>
          <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>
          <input type="text" placeholder="Email" value={userdata.email}></input>
          <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>
          <div className="mobile--view--profile--number">
            <img src={flag}></img>
            <div className="mobile--view--profile--number--border--line"></div>
            <input
              type="number"
              placeholder="Mobile Number"
              value={userdata.mobile}
            ></input>
          </div>
          <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>
          <select>
            <option className="gender--profile">Gender</option>
            <option className="gender--profile1">Male</option>
            <option className="gender--profile1">Female</option>
          </select>
          <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>
          <div className="hamburger--calander--mobile">
            <input
              type="date"
              placeholder="Date"
              max={getEighteenYearsAgoDate()} 
              defaultValue={userdata.dob} 
            />

            <span>
              <BsCalendar2Date />
            </span>
          </div>
          <div className="mobile--view--hamburger--my--booking--border--line--fav"></div>
        </div>
        <div className="hamburger--my--profile--content--12">
          <button>Update Profile</button>
        </div>
      </div>
    </div>
  );
}
