import React, { useEffect, useState } from "react";
import "./Gallery.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Base64 } from "js-base64";

function Gallerysection() {

  const [packagedata, setPackagedata] = useState([]);
  const [separatedWords, setSeparatedWords] = useState([]);
  const [id, setId] = useState(null); // Declare id state variable

  const clubid = new FormData();
  clubid.append("club_id", id);
  var url = "https://partywitty.com/master/assets/uploads/";

  const titleImageGroups = {};


  separatedWords.forEach((item) => {
    const { title_name, img } = item;
    if (!titleImageGroups[title_name]) {
      titleImageGroups[title_name] = [];
    }
    titleImageGroups[title_name].push(img);
  });


  const [activeTab, setActiveTab] = useState([]); 
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [galleryimage, setGalleryimage] = useState(null);
  const [responseFromPostApi, setResponseFromPostApi] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

 
  const [data, setData] = useState([]);

  const { city, area, clubname } = useParams();
  const clubN = clubname;

  const newApi = new FormData();
  newApi.append("city", city);
  newApi.append("area", area);
  newApi.append("club", clubN);

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
      .then((response) => {
        setData(response.data.data);
        const id = response.data.data.id;
       
        setId(id); 
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  
  

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("club_id", id);
    axios
      .post("https://partywitty.com/master/Api/getDataPost/clubGallery", formdata)
      .then((response) => {
      
        if (response.data.status === "true") {
          setCategories([]);
        } else {
          setCategories(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const uniqueCategories = {};

  
  const filteredData =
    categories &&
    categories.filter((item) => {
      if (!uniqueCategories[item.category_id]) {
        uniqueCategories[item.category_id] = item;
        return true;
      }
      return false; 
    });

  const [active, setActive] = useState(false);
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    setActive(!active);
  
    const formData = new FormData();
    formData.append("club_id", id);
    formData.append("category_id", categoryId);

    fetch(
      "https://partywitty.com/master/Api/getDataPost/clubGalleryCategoryWise",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          setResponseFromPostApi([]);
        } else {
          setResponseFromPostApi(data.data);
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };


  const handleShowAllImages = () => {
   
    const formData = new FormData();
    formData.append("club_id", id);

    fetch("https://partywitty.com/master/Api/getDataPost/clubGallery", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          setResponseFromPostApi([]);
        } else {
          setResponseFromPostApi(data.data);
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  return (
    <>
      <div className="package_images1">
        <div className="club--about--us--Gallery--section">
          <div className="club--about--us--Gallery--section--buttons">
            <button onClick={handleShowAllImages}>All</button>
            {filteredData &&
              filteredData.map((e) => (
                <button onClick={() => handleCategoryClick(e.category_id)}>
                  {e.category_name}
                </button>
              ))}
          </div>

          <div className="club--about--us--Gallery--section1--all--img">
            {responseFromPostApi.length > 0
              ? responseFromPostApi.map((e) => (
                  <div
                    className="club--about--us--Gallery--section1--img1"
                    
                    key={e.img}
                  >
                     <a
                            href={url + e.img}
                            data-fancybox="gallery"
                            data-caption=""
                          >
                            <img src={url + e.img} />
                          </a>
                  
                  </div>
                ))
              : categories &&
                categories.map((e) => (
                  <div
                    className="club--about--us--Gallery--section1--img1"
                   
                    key={e.img}
                  >
                 <a
                            href={url + e.img}
                            data-fancybox="gallery"
                            data-caption=""
                          >
                            <img src={url + e.img} />
                          </a>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default Gallerysection;
