  import styles from './Product.module.css'

  function Index({ OfferDetails, heading }) {

      return (
          <div className={styles.Container} >
            <div style={{
              marginTop: '40px',
              
            position: "relative",
            overflow: "hidden",
            display: "flex",
            height: "auto",
            flexDirection: "row",
            // overflowX: "scroll",
            columnGap: "25px",
            // width: "60%",
            // padding: '0.5rem 0 0 3rem',
            marginLeft: "10px",
            // overflowY: 'scroll', // Add a comma after marginLeft
            scrollbarWidth: "thin",
            scrollbarColor: "#D9D9D9 #f1f1f1" /* For Firefox */,
            // WebkitOverflowScrolling: 'touch', /* For iOS momentum scrolling */
            

          }}>
              {OfferDetailsData.map((item) => {
                  return (
                      <div key={item.id} className={styles.content_card} >
                          <div className={styles.ImageContainer}>
                              <img src={item.imgsrc} alt={item.name} />
                              <span className={styles.discount_ELE}>-{item.discount}</span>
                          </div>
                          <h6 className={styles.Prd_name}>{item.name}</h6>
                          <p className={styles.Offer_price}>{item.offering_price}<span className={styles.originalPrice}>{item.originalPrice}</span></p>
                      </div>
                  )
              })}

              
              </div>
              
          </div>
      )
  }

  export default Index

  export const OfferDetailsData = [
      {
          id: 0,
          name: 'product name',
          discount: '50%',
          offering_price: 14.25,
          originalPrice: 15.00,
          imgsrc: '/images/product1.svg'
      },
      {
          id: 1,
          name: 'product name2',
          discount: '50%',
          offering_price: 16.25,
          originalPrice: 19.00,
          imgsrc: '/images/product2.svg'
      }
  ]


  export const ClubTiming = [
      '3:00PM',
      '4:00PM',
      '5:00PM',
      '6:00PM',
      '7:00PM',
      '8:00PM',
      '9:00PM',
      '10:00PM',
      '11:00PM',
      '12:00PM',
      '1:00PM',
      '2:00PM',
      '3:00PM'
    ]