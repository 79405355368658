import React, {useEffect,useState} from 'react';
import axios from 'axios';
import { ShimmerThumbnail } from "react-shimmer-effects";



export default function ArtistHomePage(){
    const URL = "https://partywitty.com/master/assets/uploads/";
 const [artistData, setArtistData] = useState([]);
 const [isLoading, setIsLoading] = useState(true);

 const fetchData = async () => {
    try{
        const res = await axios.get("https://partywitty.com/master/APIs/Artist/allArtist?limit=10&offset=0");
        setArtistData(res.data.data);
        setIsLoading(false);
    }
    catch(error){
        console.log(error);
    }
};
useEffect(()=>{
    fetchData();
},[])
const dummyArray = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }];
    return(
        <>
        <div className='club_Card_Container'>
        {isLoading &&  dummyArray.map((item) => (
        <ShimmerThumbnail key={item.id} height={350} width={250} rounded />
      )) }
            {artistData.map((artist)=>(
                <div 
                                        className="Percussion-card-content2"
                                    >
                                        <img src={URL + artist.profile_pic} alt='img' />
                                        <div className='live-music-card-content-h'>
                                            <p>{artist.name.substring(0, 15)}</p>
                                            
                                        </div>
                                        <div className='live-music-geners'>
                                            {artist.genre.split(",").map((genre, genreIndex) => (
                                                <button key={genreIndex}>{genre.trim()}</button>
                                            ))}
                                        </div>
                                    </div>
                ))
            }
        </div>
        </>
    );
}