import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import star from "../../favorate--hangout--page/image/star.png";
import '../../Homepage/Homepage.css'
import axios from "axios";

const Index = () => {
    const [data, setData] = useState([]);

    const { city, area, clubname } = useParams();
    const clubN = clubname;

    const newApi = new FormData();
    newApi.append("city", city);
    newApi.append("area", area);
    newApi.append("club", clubN);

    useEffect(() => {
        axios
            .post("https://partywitty.com/master/APIs/Web/getClubDetails", newApi)
            .then((response) => {
                console.log("API Response package:", response.data); // Log entire response
                setData(response.data.data.party_packages); // Set only the party_packages data to state
            })
            .catch((error) => {
                console.error(error);
            });
    }, []); // Add setData as a dependency

    console.log(data, "data");

    const url = "https://partywitty.com/master/assets/uploads/";

    return (
        <div style={{display: 'flex'}} id="Package">
            {data.map((packageData, index) => (
                <LazyLoad offset={5} >
                <Link to={`/${packageData.cityName?.replace(/ /g, '-')}/${packageData.areaName?.replace(/ /g, '-')}/${packageData.clubName?.trim().split(/ +/).join('-').replace(/ /g, '-')}/${packageData.title.split('+').map(part => part.trim().replace(/ /g, '-')).join('-').toLowerCase()}`}>
                    <div className="Favorate--hangout--all--thumbnail--card1" >
                        <div className="Favorate--hangout--all--thumbnail--card--img--2">
                            {packageData.packageThumbnail || packageData.clubLogo ? (
                                <img
                                    className="Favorate--hangout--all--thumbnail--card--img--1--img"
                                    src={url + `${packageData.packageThumbnail !== "" ? packageData.packageThumbnail : packageData.clubLogo}`}
                                    alt={packageData.title}
                                />
                            ) : null}

                            <div className="Favorate--hangout--all--thumbnail--card--img--1">
                                <div className="Favorate--hangout--all--thumbnail--card--club--logo">
                                    <img src={url + packageData.clubLogo} alt={packageData.title} />
                                </div>
                            </div>
                            <div className="group--booking--thumbnail--livemusic--card--2">
                                {/* <div className="group--booking--thumbnail--livemusic--card">
                  <div className="group--booking--thumbnail--livemusic--card1">
                    <img src={livemusic}></img>
                    <p>Live music</p>
                  </div>
                  <div className="group--booking--thumbnail--livemusic--card1">
                    <img src={alcoholic}></img>
                    <p>alcoholic</p>
                  </div>
                  <div className="group--booking--thumbnail--livemusic--card1">
                    <img src={tablebook}></img>
                    <p>OutDoor</p>
                  </div>
                </div> */}
                            </div>
                        </div>
                        <div className="Favorate--hangout--card--content">
                            <div className="Favorate--hangout--card--content--1">
                                <p className="Favorate--hangout--card--content--1--p1">
                                    {packageData.title.substring(0, 15)}...
                                </p>
                                <p className="Favorate--hangout--card--content--1--p2">
                                    {packageData.clubName.substring(0, 16)}
                                </p>
                                <p className="favorate--hangout--address--p8">
                                    {packageData.address.substring(0, 15)}...
                                </p>
                            </div>
                            <div className="favorate--hangout--address">
                                {/* {packageData.totalRating !== 0 ? (
                                    <div className="favorate--hangout--review--card1">
                                        <div className="favorate--hangout--review--card">
                                            <img src={packageData.star} alt="Star Icon"></img>
                                            <p>{packageData.totalRating}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="favorate--hangout--review--card1">
                                        <div className="favorate--hangout--review--card5"></div>
                                    </div>
                                )} */}

                                {/* <p className="favorate--hangout--address--p2">
                          Get The Price
                          </p>
                          <p className="favorate--hangout--address--p2">
                          256 m
                          </p> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </LazyLoad>
            ))}
        </div>
    );
};

export default Index;
