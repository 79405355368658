import React from 'react';
import { IoIosArrowUp } from "react-icons/io";
import kids from '../AboutClub/image/friend.png';

export default function ClubOther(){
    return(
        <div className="about-club-details">
        <div className="about-club-detail-collapse">
        <p>Other</p>
        <span>
          <IoIosArrowUp />
        </span>
        </div>
        <div className='all-other-amenties'>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
            <div className='other-amenties-card'>
                <img src={kids} ></img>
                <p>Kids Friendly</p>
            </div>
        </div>
        </div>
    );
}