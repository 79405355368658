import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import menu from '../AboutClub/image/menu.jpg';
import { HiOutlineArrowLeft } from "react-icons/hi";
import { HiOutlineArrowRight } from "react-icons/hi";

export default function Package() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div
        style={{
          marginTop: '-25px',
          fontWeight: '300',
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 10, 
          display: 'flex',
          backgroundColor: 'transparent', 
          padding: '5px',
          borderRadius: '5px',
        }}
      >
        <button
          aria-label="Previous"
          onClick={previous}
          style={{
            marginRight: 10,
            fontSize: '25px',
            color: 'white',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
      <HiOutlineArrowLeft />
        </button>
        <button
          aria-label="Next"
          onClick={next}
          style={{
            backgroundColor: 'transparent',
            fontSize: '25px',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
        >
       <HiOutlineArrowRight />
        </button>
      </div>
    );
  };

  return (
    <div className="club-detail-package-card">
      <div className="club-detail-package-card-name">
        <p>P</p>
        <p>A</p>
        <p>C</p>
        <p>K</p>
        <p>A</p>
        <p>G</p>
        <p>E</p>
      </div>
      <div className="club-detail-package-caraousal">
        <Carousel
          responsive={responsive}
          customButtonGroup={<CustomButtonGroup />}
          arrows={false}
        >
            <div className="club-detail-package-caraousal-1">
            <img src={menu}></img>
            <p>Veg & Non Veg Food Only</p>
             </div>
             <div className="club-detail-package-caraousal-1">
            <img src={menu}></img>
            <p>Veg & Non Veg Food Only</p>
             </div>
             <div className="club-detail-package-caraousal-1">
            <img src={menu}></img>
            <p>Veg & Non Veg Food Only</p>
             </div>
             <div className="club-detail-package-caraousal-1">
            <img src={menu}></img>
            <p>Veg & Non Veg Food Only</p>
             </div>
             <div className="club-detail-package-caraousal-1">
            <img src={menu}></img>
            <p>Veg & Non Veg Food Only</p>
             </div>
             <div className="club-detail-package-caraousal-1">
            <img src={menu}></img>
            <p>Veg & Non Veg Food Only</p>
             </div>
        </Carousel>
      </div>
    </div>
  );
}
