import "./Services.css";
import {React, useState, useEffect} from 'react';
import axios from "axios";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

export default function ContactUs() {

  const [username, setUsername] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [subject,setSubject] = useState('');
  const [message,setMessage] = useState('');
  

  const handleSubmitEnquiry = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
      formdata.append('name',username);
      formdata.append('mobile',number);
      formdata.append('email',email);
      formdata.append('subject',subject);
      formdata.append('msg',message);
    try { 
      const response = await axios.post('https://partywitty.com/master/APIs/Common/submitContact',formdata);
      console.log(response.data);

      if(response.data.status === true){
        iziToast.success({
          title: 'Success',
          message: response.data.msg || 'Enquiry Submitted successfully!',
          position: 'topCenter',
        });
        setUsername('');
        setNumber('');
        setEmail('');
        setSubject('');
        setMessage('');
      }
      else{
        iziToast.error({
          title: 'Error',
          message: 'Enquiry submission failed.',
          position: 'topCenter',
        });
      }
    }
    catch(error){
    
      iziToast.error({
        title: 'Error',
        message: 'Error Submitting enquiry.',
        position: 'topCenter',
      });
    }
  };
  


  return (
    <div className="aboutusPage">
      <section class="about--banner">
        <div class="main--box">
          <div class="title--name">
            <h3>Contact us</h3>
          </div>
        </div>
      </section>

      <section class="main--feacture">
        <div class="main--box">
       
          <div class="border--box">
            <div class="contact--box">
              <div class="form--box">
                <form onSubmit={handleSubmitEnquiry}>
                
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <input
                          type="text"
                          placeholder="Enter Name"
                          class="form-control"
                          value={username}
                          onChange={(e)=>setUsername(e.target.value)}
                          required
                        ></input>
                      </div>
                    </div>
                    <div class="col--1">
                      <div class="form-group1">
                        <input
                          type="number"
                          placeholder="Enter Mobile Number"
                          
                          class="form-control"
                          value={number}
                          onChange={(e)=>setNumber(e.target.value)}
                          required
                        ></input>
                      </div>
                      <div class="form-group1">
                        <input
                          type="email"
                          placeholder="Enter Email"
                        
                          class="form-control"
                          value={email}
                          onChange={(e)=> setEmail(e.target.value)}
                          required
                        ></input>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                          type="text"
                          placeholder="Enter Subject"
                        
                          class="form-control"
                          value={subject}
                          onChange={(e)=> setSubject(e.target.value)}
                        ></input>
                      </div>

                      <div class="form-group">
                        <textarea
                          type="text"
                          placeholder=" Help us know what went wrong?"
                          class="form-control1"
                          
                          value={message}
                          onChange={(e)=> setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <button type="submit" class="btn--theame--1">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
