import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";

export default function PartnerwithUsHeader({ onRegularLinkClick }) {
  return (
    <div className="partner--with--us--header">
      <div className="partner--with--us--header1  ">
        <div className="partner--with--us--header1--logo">
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            onClick={onRegularLinkClick}
          >
            <img src={logo}></img>
          </Link>
        </div>
        <div>
          <a
            href="https://partywitty.com/master/Club/Login?email=test9289480700@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Business Login</button>
          </a>
        </div>
      </div>
    </div>
  );
}
