import './NewThankew--page.css';
import Thanks from '../Navbar/image/Thanks.png';
import user from './image/user.svg';

export default function () {
    return (
        <div className='New--thankew--page--card--start'>
            <div className='New--thankew--page--card--start--1'>
                <div className='New--thankew--page--card--start--1--img'>
                    <img src={Thanks}></img>
                </div>
               <h2>Payment Failed</h2>
                
                  
            </div>

           
        </div>
    );
}