import React, { createContext, useContext } from "react";

export const LoginContext = createContext();

export function useLoginContext() {
  return useContext(LoginContext);
}

export function LoginProvider({ children }) {
  const [openLogin, setOpenLogin] = React.useState(false);

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  return (
    <LoginContext.Provider
      value={{ openLogin, handleOpenLogin, handleCloseLogin }}
    >
      {children}
    </LoginContext.Provider>
  );
}
