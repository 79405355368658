import React, { useEffect, useState } from "react";
import "./Thankew-page.css";
import checked from "./image/checked.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

export default function ThankewPage() {
  const [data, setData] = useState([]);
  const [customerdata, setCustomerdata] = useState([]);
  const [loading, setLoading] = useState(true); // Loading indicator
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const bidData = JSON.parse(localStorage.getItem("biddetails"));

  // Get the query string from the URL
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const paymentIntentValue = params.get("payment_intent");
    setPaymentIntent(paymentIntentValue);
  }, []);

  useEffect(() => {
    if (!paymentIntent) {
      return; // Don't make the API request if paymentIntent is not set
    }

    const clubid = new FormData();
    clubid.append("paymentId", paymentIntent);

    axios
      .post(
        "https://partywitty.com/master/Api/sendData/verifyBidPayment",
        clubid
      )
      .then((response) => {
        setData(response.data.res);
        setCustomerdata(response.data.bookingDetails);
        setLoading(false); // Turn off loading indicator when data is received
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Turn off loading indicator in case of an error
      });
  }, [paymentIntent]);

  const [clubdata, setClubData] = useState([]);
  const clubid = new FormData();
  clubid.append("club_id", bidData.club_id);
  var url = "https://partywitty.com/master/assets/uploads/";
  var menuurl = "https://partywitty.com/master/assets/uploads/";
  const inputBorderStyle = {
    borderColor: "red",
  };

  useEffect(() => {
    axios
      .post("https://partywitty.com/master/Api/getDataPost/clubDetails", clubid)
      .then((response) => {
        setClubData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    // <div className="Payment--Thankew--Page">
    //   <div className="Payment--Thankew--Page1">
    //     <div className="Payment--Thankew--Page1--thanks--content">
    //       <img src={checked}></img>
    //       <p>Payment Status : {data.status}</p>
    //       <p>Hi {userdata.name} </p>
    //       <h1>Thanks For Your Order!</h1>
    //     </div>
    //     <div className="Payment--Thankew--Page1--orderid--button">
    //       <p>Order Id:</p>
    //       <p>#{data.id}</p>
    //     </div>
    //     <div className="Payment--Thankew--Page1--all--content">
    //       <div className="Payment--Thankew--Page1--thanks--Billing--content">
    //         <div className="Payment--Thankew--Page1--thanks--Billing--content-date">
    //           <p className="payment--thankew--heading">Date</p>
    //           <p className="payment--thankew--content">{data.date}</p>
    //         </div>
    //         <div className="Payment--Thankew--Page1--thanks--Billing--content-Bill">
    //           <p className="payment--thankew--heading">Billing Address</p>
    //           <p className="payment--thankew--content">{data.address}</p>
    //         </div>
    //         <div className="Payment--Thankew--Page1--thanks--Billing--content-Payment">
    //           <p className="payment--thankew--heading">Payment Method</p>
    //           <p className="payment--thankew--content">card</p>
    //         </div>
    //       </div>
    //       <div className="Payment--Thankew--order--summary">
    //         <h3>Order Summary</h3>
    //         <div className="Payment--Thankew--order--summary1">
    //           <div className="Payment--Thankew--order--summary1-product">
    //             <p className="payment--thankew--heading-1">Product</p>
    //           </div>
    //           <div className="Payment--Thankew--order--summary1-quantity">
    //             <p className="payment--thankew--heading-1">Quantity</p>
    //           </div>
    //           <div className="Payment--Thankew--order--summary1-quantity">
    //             <p className="payment--thankew--heading-1">Price</p>
    //           </div>
    //         </div>
    //         <div className="Payment--Thankew--order--summary1--content">
    //           <div className="Payment--Thankew--order--summary1-product">
    //             <p>{data.description}</p>
    //           </div>
    //           <div className="Payment--Thankew--order--summary1-quantity">
    //             <p>x 1</p>
    //           </div>
    //           <div className="Payment--Thankew--order--summary1-quantity">
    //             <p>
    //               {data.amount_received / 100}
    //               {data.currency}
    //             </p>
    //           </div>
    //         </div>
    //         <div className="discount--calculator--page1--border1--2"></div>
    //         <div className="Payment--Thankew--order--summary1--total">
    //           <p>SubTotal :</p>
    //           <p>
    //             {data.amount_received / 100}
    //             {data.currency}
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="Booking--coupon--code mt-5">
      <div className="Booking--coupon--code--1">
        <div className="Booking--coupon--code1 confirmation--page">
          <div className="Booking--coupon--code11">
            <h2>Your Booking is Confirmed!</h2>
            <p>
              Hi, <strong>{bidData.leadDetails[0].name}!</strong> Your Booking
              ID is <strong>PWTY01251</strong>. your booking.
            </p>
            <div className="Booking--coupon--code11--1">
              <p> Your table has been booked at-</p>
              {/* <br></br> */}
              <h2>{customerdata.clubName}</h2>
              <p>{customerdata.clubAddress}</p>
            </div>
            <div className="Booking--coupon--code11--2">
              <h3>Number of Diners : {customerdata.pax} people</h3>
              <h3>
                Requirement : {customerdata.customerRequirement} (
                {customerdata.customerWant})
              </h3>
              <h3>Mobile : {bidData.leadDetails[0].mobile}</h3>
              {/* <h3>Email : {userdata.email}</h3> */}
            </div>
            <div className="Booking--coupon--code2--11 congratulation-tab">
              Congratulations
            </div>
          </div>
          <div className="Booking--coupon--code12">
            <div className="Booking--coupon--code12--1">
              <div>
                <h2>{format(new Date(bidData.leadDetails[0].date), "dd")}</h2>
                <p>{format(new Date(bidData.leadDetails[0].date), "MMMM")}</p>
                <p>Breakfast</p>
              </div>
              <div>
                <h4>Reference no.</h4>
                <h3>DRW20216</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="Booking--coupon--code2 backgroundcolor--for--confirm">
          <div className="Booking--coupon--code2--1">
            {/* <div>
              <h1>Careem</h1>
              <h3>40AED OFF</h3>
              <p>Your Ride with Careem</p>
            </div> */}
            {/* <div class="icon-container"></div> */}
            <div className="Booking--coupon--code2--1--12">
              <p>
                BOOKED WITH US ONCE ALREADY?<br></br> WE GOT YOU BOO ❣️
              </p>
              <p>GET 10% Discount on Second time bookings! </p>
            </div>
            <div className="Booking--coupon--code2--1--12">
              <p>Your Code</p>
              <div className="Booking--coupon--code2--12">
                <p>DW5R66889</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
