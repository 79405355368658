import './Agreement.css';


export default function Cancellationpolicy() {
    return (
        <div class="clubagreement pt-5">
            <section class="main--feacture tc--section pt-5">
    <div class="main--box">
          
    </div>
</section>

    
        </div>
    );
}