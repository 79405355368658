import styles from './videocard.module.css';

const VideoCard = ({ videoInfo }) => {
    // Check if videoInfo is undefined or not an array
    if (!videoInfo || !Array.isArray(videoInfo)) {
        return null; // Return null or any other fallback if videoInfo is undefined or not an array
    }

    const url = "https://partywitty.com/master/assets/uploads/";

    return (
        <div className={styles.Container} id="Teaser">
            {videoInfo.map((videoData, index) => (
                <div key={index}>
                    {/* Conditional rendering of video tag */}
                    {videoData.video && (
                        <video src={url + videoData.video} className={styles.videotag} controls controlsList="nodownload" autoPlay muted loop />
                    )}
                    {/* Uncomment below if you want to display title */}
                    {/* {videoData.title && <p>{videoData.title}</p>} */}
                </div>
            ))}
        </div>
    );
}

export default VideoCard;
