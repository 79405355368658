import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./multyitem.css";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Link } from "react-router-dom";

function Sponsored() {
  const [data3, setData3] = useState([]);
  const URL = "https://partywitty.com/master/assets/uploads/";
  const [isLoading, setIsLoading] = useState(true);
  
  const fetchURL = async () => {
    try {
      const response = await axios.get(
        "https://partywitty.com/master/APIs/Common/getWebPromotionalBanner"
      );
      setData3(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchURL();
  }, []);


  const filteredData = data3.filter(
    (banner) => banner.page_name === "Home" && banner.position === "sponsored"
  );

  const generateURL = (landingPage, item) => {
    switch (landingPage) {
      case "":
        return "";
      case "party_packages":
        return "https://www.partywitty.com/party-packages-delhi-ncr-view-all";
      case "table_booking":
        return "https://www.partywitty.com/book-a-table-delhi-ncr-view-all";
      case "club_about":
        return `/${item.cityName.trim().replace(/ /g, '-').toLowerCase()}/${item.areaName.trim().replace(/ /g, '-').toLowerCase()}/${item.clubName.trim().split(/ +/).join('-').replace(/ /g, '-').toLowerCase()}`;
      default:
        return "";
    }
  };
  const dummyArray = [{ id: 1 }]

  return (
    <div>
        {isLoading &&  dummyArray.map((item) => (
            <ShimmerThumbnail key={item.id} height={160}  rounded />
          ))}
      {filteredData.length > 0 && (
        <Carousel interval={6000} indicators={false}>
          {filteredData.map((item) => (
            <Carousel.Item key={item.id} className="slider_img">
              {generateURL(item.landing_page, item) ? (
                <Link to={generateURL(item.landing_page, item)}>
                  <img src={URL + item.default_img} alt="img" />
                </Link>
              ) : (
                <img src={URL + item.default_img} alt="img" />
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default Sponsored;
