import './Happening-music-view.css';
import commingsoon from "./image/commingsoon.jpg"


export default function CommingSoon(){
    return(
        <div class="comming-soon">
           


    
      <div className="under-construction">
        <h1 className='h1-comming-soon'>Website is</h1>
        <h2 className='h2-comming-soon'>Under Construction</h2>
         <p>Coming Soon...</p>
      </div>
    </div>
  );
}


