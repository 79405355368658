import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "fitcontent",
  bgcolor: "background.paper",
  border: "0.5px solid #fff",
  borderRadius: "20px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function CancelPopup({ heading, Description, Open, setOpen }) {


  const CancelPopup = (isOpen) => {
    setOpen(isOpen);
    // window.location.reload()
  };

  return (
    <>
      <Modal
        open={Open}
        onClose={() => CancelPopup(true)}
        aria-labelledby="popup"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 id="child-modal-title">{heading}</h2>
          
          <dotlottie-player src="https://lottie.host/61bc0753-e093-46dd-a290-0cd6440986ba/W4jaUdDBYa.json" background="transparent" speed="1" style={{width: "300px", height: "300px"}} loop autoplay></dotlottie-player>
          <p id="child-modal-description" style={{textAlign:'center'}}>
            {Description}
          </p>
          <Button onClick={() => CancelPopup(false)}>Close</Button>
        </Box>
      </Modal>
    </>
  );
}
