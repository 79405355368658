import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Visiting--table.css";
import axios from "axios";
import Loginpopup from "../Navbar/Loginmodal";
import { GoDotFill } from "react-icons/go";
import Thanks from "../Navbar/image/Thanks.png";
import Notiflix from "notiflix";
import Alert from '@mui/material/Alert';
import Googleplay from '../Footer/image/Googleplay.png';
import  Applestore from '../Footer/image/appdownloads.png';
import { Link, useLocation } from "react-router-dom";

export default function VisitingGroupBooking() {
  
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const [cityId, setCityId] = useState("");
  const [numberPax, setnumberPax] = useState("");
  const [location, setLocation] = useState("");
  const [kids, setKids] = useState("no");
  const [eventDate, setEventDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [open, setOpen] = React.useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const [numberMale, setNumberMale] = useState('');
  const [numberFemale, setNumberFemale] = useState('');
  const [no_of_kids, setNoOfKids] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState('');
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(''); 
  const [cityAreas, setCityAreas] = useState([]);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState(''); 
  const [apiData, setApiData] = useState(null);
  const [leadarea, setLeadarea] = useState([]);
  const [location12, setLocation12] = useState(""); 
  const [citydata, setCityData] = useState([]);
  const [selectedAreaid, setSelectedAreaid] = useState(null)
  const [showAlert, setShowAlert] = useState(false);
  const [openPlanEvent, setOpenPlanEvent] = useState(true);
  const [thankyou, setThankyou] = useState(false);

  const handleOpenthankyou = () => {
    setThankyou(true);
  };
  const handleClosethankyou = () => {
    setThankyou(false);
  };
 
  const handleClosePlanEvent = () => {
    setOpenPlanEvent(false);
  };
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handleStepChange = (step) => {
    setCurrentStep(step);
    
  };
  const handleToggleItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  const handleAreaChange = (e) => {
    const selectedAreaId = e.target.value; 
    const areaData = leadarea.find((area) => area.id === selectedAreaId); 
    setSelectedArea(areaData); 
    setSelectedAreaid(areaData.id);
  };
  const [amenitiesdata, setAmentiesdata] = useState(null);
  const handleamenitiesChange = (e) => {
    const selectedAreaId = e.target.value; 
    setAmentiesdata(selectedAreaId); 
  };
  const formData = new FormData();
    formData.append("user_id", localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")).id : null);
    formData.append("venue_type", selectedItems.join(','));
    formData.append("whatsapp_msg", "true");
    formData.append("lead_source", "Website");
    formData.append("address", selectedArea ? selectedArea.name : null);
    formData.append("whatsapp", userdata ? userdata.whatsapp : null);
    formData.append("party_date", eventDate);
    formData.append("party_time", arrivalTime);
    formData.append("male", numberMale);
    formData.append("female", numberFemale);
    formData.append("kids", no_of_kids);
    formData.append("area_id", selectedArea ? selectedArea.id : null);
    formData.append("city_id", cityId);
    const handleSubmitLeadform = (e) => {
      e.preventDefault();
      const lastSubmissionDate = localStorage.getItem("lastSubmissionDate");
      const today = new Date().toISOString().split("T")[0];
      if (lastSubmissionDate === today) {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      return;
      }
      const apiUrl = "https://partywitty.com/master/APIs/ClubTable/submitEnquiry";
      Notiflix.Loading.dots({ svgColor: "#00bfff" });
      fetch(apiUrl, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          Notiflix.Loading.remove();
          if (data.status === true) {
            handleOpenthankyou();
            localStorage.setItem("lastSubmissionDate", today);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
  useEffect(() => {
  const firstApiUrl =
      "https://partywitty.com/master/Api/getDataPost/countryWiseState";
    const firstPostData = new FormData();
    firstPostData.append("country_id", "101");
    const combinedData = [];
    axios
      .post(firstApiUrl, firstPostData)
      .then((response) => {
       const stateIds = response.data.data
          .filter(
            (item) =>
              item.id === "4007" || item.id === "4021" || item.id === "4022"
          )
          .map((item) => item.id);
        const secondApiUrl =
          "https://partywitty.com/master/Api/getDataPost/stateWiseCity";
        const secondPostPromises = stateIds.map((stateId) => {
        const secondPostData = new FormData();
        secondPostData.append("state_id", stateId);
        return axios.post(secondApiUrl, secondPostData);
        });
      return Promise.all(secondPostPromises);
      })
      .then((responses) => {
      responses.forEach((response) => {
          combinedData.push(...Array.from(response.data.data));
        });
        const filteredData = combinedData.filter((item) =>
          [
            "134404",
            "134007",
            "133234",
            "131821",
            "58171",
            "132032",
            "131853",
            "133230",
            "132005",
            "131953",
          ].includes(item.id)
        );

        setApiData(filteredData);
      })
      .catch((error) => {
        console.error("API error", error);
      });
  }, []);

  useEffect(() => {}, [apiData]);

  useEffect(() => {
   
    const apiUrl = "https://partywitty.com/master/Api/getDataPost/AreaLead";

    if (cityId) {
     
      const postData = new FormData();
      postData.append("city_id", cityId);
      axios
        .post(apiUrl, postData)
        .then((response) => {
          setLeadarea(response.data.data);
        })
        .catch((error) => {
          console.error("API error:", error);
        });
    }
  }, [cityId]);

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setLocation12(selectedLocation);
    const selectedCity = apiData.find((e) => e.name === selectedLocation);
    if (selectedCity) {
      setCityId(selectedCity.id); 
    }
  };

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getAreaLead")
      .then((response) => {
        setCityData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [clubcategorydata, setClubcategorydata] = useState([]);
  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/ClubPackage/getVenuType")
      .then((response) => {
        setClubcategorydata(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleOpenPlanEvent4 = () => {
    if (userdata === null) {
      setShowLoginPopup(true);
    } else {
      handleNextStep();
    }
  };
  const today = new Date().toISOString().split("T")[0];
  const options = [];
  options.push(
    <option key="default" value={"Select"}>
      Select
    </option>
  );
  for (let i = 1; i <= 20; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  const secondtext = "Please share some details so we can find the best deals for you! ";
  const thirdtext = "Let us reserve your space. Please let us know your expected date and time of arrival. ";
  const fourthtext = "Please share your preferred location so we can get you the best deals!  ";
  return (
    <div>
      {showLoginPopup && <Loginpopup />}
      <div>
        {openPlanEvent && (
          <div className="blur-background">
            <Modal
              open={openPlanEvent}
              onClose={() => setOpenPlanEvent(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box className="responsive-modal-1--popup--1">
                <div className="Plan--your--event--popup">
                  <div className="Plan--your--event--popup--1">
                  <div className="cls--btn" onClick={handleClosePlanEvent}>
                     <p>x</p>
                    </div>
                    <div className="Plan--your--event--popup--1--img--book--banner"></div>
                    {currentStep === 1 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                          Kindly help us with your preferences 
                          </p>
                          <p className="visiting--table--booking--content--p2 ">
                            Choose Your Venue Type
                          </p>

                          <div>
                            <div className="visiting--table--booking--content--tabs">
                              {clubcategorydata.map((item) => (
                                <button
                                  key={item.id}
                                  className={`visiting--table--booking--content--tabs--card ${
                                    selectedItems.includes(item.id)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleToggleItem(item.id)}
                                >
                                  {item.name}
                                </button>
                              ))}
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                              <button
                                onClick={handleOpenPlanEvent4}
                                disabled={selectedItems.length === 0}
                                className={
                                  selectedItems.length === 0
                                    ? "selectedwidgetedbutton"
                                    : ""
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 2 && (
  <div className="Plan--your--event--popup--1--p--visit">
    <div className="visiting--table--booking--content">
      <p className="visiting--table--booking--content--p1">
        {secondtext.toUpperCase()}
      </p>
      <p className="visiting--table--booking--content--p2 ">
        {/* Choose Your Venue Type */}
      </p>
      <div className="visiting--table--booking-second--page">
        <div className="visiting--table--booking-second--page--1">
          <div className="visiting--table--booking-second--page--1--1-h">
            <label>No. of Male</label>
            <input
              type="number"
              value={numberMale}
              onChange={(e) => setNumberMale(e.target.value)}
               min="0"
            />
          </div>
          <div className="visiting--table--booking-second--page--1--1-h">
            <label>No. of Female</label>
            <input
              type="number"
              value={numberFemale}
              onChange={(e) => setNumberFemale(e.target.value)}
               min="0"
            />
          </div>
          <div className="visiting--table--booking-second--page--1--1-h">
            <label>No. of Kids</label>
         

            <input
              type="number"
              value={no_of_kids}
              onChange={(e) => setNoOfKids(e.target.value)}
               min="0"
            />
          </div>
        </div>
        <div className="Plan--your--event--popup--1--p--visit--button">
          <button
            onClick={handleNextStep}
            disabled={!numberMale || !numberFemale || !no_of_kids}
            className={!numberMale || !numberFemale || !no_of_kids ? "selectedwidgetedbutton" : ""}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
                    )}
                    {currentStep === 3 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                            {thirdtext.toUpperCase()}
                          </p>
                          <p className="visiting--table--booking--content--p2 ">
                            {/* Choose Your Venue Type */}
                          </p>
                          <div className="visiting--table--booking-second--page">
                            <div className="visiting--table--booking-second--page--1">
                              <div className="visiting--table--booking-second--page--1--1 ">
                                <label>Date</label>
                                <input
                                  type="date"
                                  value={eventDate}
                                  onChange={(e) => setEventDate(e.target.value)}
                                  min={today}
                                />
                              </div>
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>Time</label>
                                <input
                                  type="time"
                                  placeholder="Enter"
                                  value={arrivalTime}
                                  onChange={(e) =>
                                    setArrivalTime(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                              <button
                                onClick={handleNextStep}
                                disabled={
                                  eventDate.length === 0 ||
                                  arrivalTime.length === 0
                                }
                                className={
                                  eventDate.length === 0 ||
                                  arrivalTime.length === 0
                                    ? "selectedwidgetedbutton"
                                    : ""
                                }
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep === 4 && (
                      <div className="Plan--your--event--popup--1--p--visit">
                        <div className="visiting--table--booking--content">
                          <p className="visiting--table--booking--content--p1">
                            {fourthtext.toUpperCase()}
                          </p>
                         
                          <div className="visiting--table--booking-second--page">
                            <div className="visiting--table--booking-second--page--1">
                              <div className="visiting--table--booking-second--page--1--1">
                                <label>City</label>
                                <select
                                  value={location12}
                                  onChange={handleLocationChange}
                                >
                                  <option>Select City</option>
                                  {citydata.map((e) => (
                                    <option key={e.id} value={e.name}>
                                      {e.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="visiting--table--booking-second--page--1--1">
                                
                                  <label>Area</label>
                                  <select
                                  value={selectedArea ? selectedArea.id : ""}
                                  onChange={handleAreaChange}
                                >
                                  <option>Select Area</option>
                                  {leadarea.map((area) => (
                                    <option key={area.id} value={area.id}>
                                      {area.name}
                                    </option>
                                  ))}
                                </select>
</div>
                            </div>
                            <div className="Plan--your--event--popup--1--p--visit--button">
                              
                            <button
          type="button"
          disabled={!location12 || !selectedArea}
          className={!location12 || !selectedArea ? "selectedwidgetedbutton" : ""}
          onClick={handleSubmitLeadform}
        >
  Submit
</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showAlert && (
        <Alert severity="error" sx={{ backgroundColor: '#D2042D', color: '#fff', borderRadius: '4px', padding: '10px', margin: '10px 40px' }}>
          Form already submitted today. Please try again tomorrow.
        </Alert>
      )}

                    
                  </div>
                </div>
                <div className="Plan--your--event--popup--steps">
                  <ul className="step-dots">
                    {[1, 2, 3, 4].map((step) => (
                      <li
                        key={step}
                        className={currentStep >= step ? "active" : ""}
                      >
                        {step === currentStep ? (
                          <span style={{ fontSize: "22px" }}>
                            <GoDotFill />
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              currentStep > step && handleStepChange(step)
                            }
                          >
                            <GoDotFill />
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Box>
            </Modal>
          </div>
        )}
        <Modal
         open={thankyou}
         onClose={() => setThankyou(false)}
         aria-labelledby="child-modal-title"
         aria-describedby="child-modal-description"
        >
          <Box className="responsive-modal-1">
            <div className="thankyou--page--close">
              <button onClick={handleClosethankyou}>X</button>
            </div>
            <div className="lead--genrate--thankew-page">
              <div className="Plan--your--event--popup-page2">
                <img src={Thanks}></img>
                <p>
                  <b>Your booking is confirmed. </b>
                </p>
                <p>
                  It’s time to get the best deals and offers from the nearby venues. 
                </p>
                <p>
                  Stay tuned!
                </p>
                <div className="about-us-playstore-image_widget">
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Googleplay} alt="playstore"/>
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.party.partywitty_guest">
                  <img src={Applestore} alt="appstore"/>
                </Link>
              </div>
              </div>
              
            </div>
           

            
          </Box>
        </Modal>
      </div>
    </div>
  );
}
