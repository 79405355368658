import menu from '../AboutClub/image/menu.jpg';


const Menu = ()=>{
    return(
        <div>
            <p className='club-menu-p'>Menu</p>
         <div className='all-club-menu'>
         <div className='club-menu-card'>
          <div className='club-menu-card-img'>
            <img src={menu}></img>
          </div>
          <div className='club-menu-name'>
          <p className='club-menu-card-p'>Food Menu</p>
          <p className='club-menu-card-no'>10 page</p>
          </div>
         </div>
         <div className='club-menu-card'>
          <div className='club-menu-card-img'>
            <img src={menu}></img>
          </div>
          <div className='club-menu-name'>
          <p className='club-menu-card-p'>Food Menu</p>
          <p className='club-menu-card-no'>10 page</p>
          </div>
         </div>
         <div className='club-menu-card'>
          <div className='club-menu-card-img'>
            <img src={menu}></img>
          </div>
          <div className='club-menu-name'>
          <p className='club-menu-card-p'>Food Menu</p>
          <p className='club-menu-card-no'>10 page</p>
          </div>
         </div>
         <div className='club-menu-card'>
          <div className='club-menu-card-img'>
            <img src={menu}></img>
          </div>
          <div className='club-menu-name'>
          <p className='club-menu-card-p'>Food Menu</p>
          <p className='club-menu-card-no'>10 page</p>
          </div>
         </div>
         <div className='club-menu-card'>
          <div className='club-menu-card-img'>
            <img src={menu}></img>
          </div>
          <div className='club-menu-name'>
          <p className='club-menu-card-p'>Food Menu</p>
          <p className='club-menu-card-no'>10 page</p>
          </div>
         </div>
         </div>
        </div>
    );
}
export default Menu;