import React from "react";
import "./Payment.css";
import greentick from './Assets/greentick.jpg';
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";

function Successpage() {
  return (
    <div className="payment-page">
    
        <div class="wrapper-1">
          <img src={greentick}></img>
          <div class="wrapper-2">
            <h1>Thank you !</h1>
            <p>Your booking is confirmed!</p>
            <p>We are thrilled to have you join our upcoming event.
            </p>
            <p>If you have any questions or need further assistance, please do not hesitate to contact our support team at <a href="https://www.partywitty@gmail.com"> partywitty@gmail.com</a>.
            </p>
            <p>Follow us on social media for the latest news and updates:</p>
            <div className="thankew_page_link">
            <a href="https://www.facebook.com/party.witt?mibextid=ZbWKwL">
                   <span> <FaFacebookF /></span>
                  </a>
                  <a href="https://instagram.com/partywitty_?igshid=NzZhOTFlYzFmZQ==">
                  <span> <BsInstagram /></span>  
                  </a>
            </div>
            <p>We look forward to seeing you at the event! 
            </p>
           
            <Link to ="/">
              <button class="go-home">Go back to home</button>
            </Link>
          </div>
        
        </div>
     
    </div>
  );
}

export default Successpage;
