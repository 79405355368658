import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './blogdetail.css';
import bar from './image/bar.jpg';
import axios from 'axios';
import angry from './image/angry.png';
import funny from './image/funny.png';
import love from './image/love.png';
import sad from './image/sad.png';
import surprised from './image/surprised.png';
import upvote from './image/upvote.png';

function BlogDetail() {
  const { title } = useParams(); 
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [blogDetails, setBlogDetails] = useState([]); 
  const [categories, setCategories] = useState([]);
  const categoriesApiUrl = "https://partywitty.com/master/APIs/Web/getBlogCategroy";
  const imgUrlPrefix = "https://partywitty.com/master/assets/uploads/";

  useEffect(() => {
    
    const formData = new FormData();
    formData.append('blog_id', title);
    axios.post('https://partywitty.com/master/APIs/Web/BlogsDetails', formData) 
      .then(response => {
        if (response.data && response.data.status) {
          
          setBlogDetails(response.data.data); 
        } else {
          console.error("Failed to fetch blog details:", response.data.msg);
        }
      })
      .catch(error => {
        console.error("Error fetching blog details:", error);
      });

   
    axios.get(categoriesApiUrl)
      .then(response => {
        if (response.data && response.data.status) {
          setCategories(response.data.data);
        } else {
          console.error("Failed to fetch categories data:", response.data.msg);
        }
      })
      .catch(error => {
        console.error("Error fetching categories data:", error);
      });
  }, [title]); 

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  return (
    <div className='blog-page'>
     
    
      <div className='aboutus'>
        <div className="blog-wrapper">
       
          <div className='blog-content'>
            <div className="blog-grid--box-detail">
              <div className="blog-item-link">
                <div className="c--box-1">
                <img src={imgUrlPrefix + (blogDetails ? blogDetails.img : {bar})} alt={blogDetails ? blogDetails.title : 'Default Title'}  />
                  <div className="content">
                    <p className='blog-pink'>{new Date(blogDetails.date_added).toDateString()}</p>
                    <h3 className='blog-h3'>{blogDetails.title}</h3>
                    <div className="blog-p-white"  dangerouslySetInnerHTML={{ __html: blogDetails.des }}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='blog-content-emoji'>
              <p>What do you think?</p>
              <div className='blog-content-emoji-img'>
              <div className='blog-content-emoji-img-content'>
              <img src={upvote}></img>
              <p>upvote</p>
              </div>
              <div className='blog-content-emoji-img-content'>
              <img src={love}></img>
              <p>love</p>
              </div>
              <div className='blog-content-emoji-img-content'>
              <img src={funny}></img>
              <p>funny</p>
              </div>
              <div className='blog-content-emoji-img-content'>
              <img src={surprised}></img>
              <p>surprised</p>
              </div>
              <div className='blog-content-emoji-img-content'>
              <img src={sad}></img>
              <p>sad</p>
              </div>
              <div className='blog-content-emoji-img-content'>
              <img src={angry}></img>
              <p>a</p>
              </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default BlogDetail;
