import Carousel from "react-bootstrap/Carousel";
import React  from "react";
import "../Homepage/multyitem.css";
import sponsed from './image/sponsed.png';
import banner1 from './image/banner1.png';

export default function FavorateHangoutSponserd()
{
    return(
        <div className="Favorate--hangout--sponsered--8">
             <Carousel interval={6000} indicators={false}>
            <Carousel.Item  className="slider_img">
              <img src={banner1} alt="img" />
            </Carousel.Item>
        
        </Carousel>
        </div>
    );
}