import './Services.css';

import c1 from "./image/c1.png"
import c2 from "./image/c2.png"
import c3 from "./image/c3.png"
import c4 from "./image/c4.png"
import c5 from "./image/c5.png"
import c6 from "./image/c6.png"


export default function BookanArtist() {
    return (
        <div className="aboutusPage">
       
            <section className="aboutbaner club_bg">
                <div className="main--box">
                    <div className="title--name">
                        <h3>Artist</h3>
                    </div>
                </div>
            </section>
            <div className="aboutus">
                <section className="main--feacture career">
                    <div className="main--box">
                        <div className="title">
                            <h3>Why Choose Us? / Book An Artist Today </h3>
                            <p>We at <a href="http://www.partywitty.com">party witty</a> are Primary focused and driven towards an objective to enhance the experience for an Artist.
                                Problem solving motive towards the points that comes across as challenges for the artist on regular basis, we have come here as a solution to all those highlighted problems.</p>
                        </div>
                        <div className="grid--box book">
                            <div className="c--box">
                                <img src={c1} alt=""></img>
                                    <div className="content">
                                        <h3>Integrate Artists’ Fan base with yours</h3>
                                        <p>Party Witty screens every entrant and thus has a pool of verified and experienced artists who already have a huge fan base. Clubs can reap economies of scale here by better chances of multiplying the number of visitors to the club. The visitors will now be artists’ followers and club visitors accompanied by some fresh crowd.</p>
                                    </div>
                            </div>
                            <div className="c--box">
                                <img src={c2} alt=""></img>
                                    <div className="content">
                                        <h3>Increase reach for club offers & deals</h3>
                                        <p>We know clubs have a lot of exciting deals to offer. We provide a platform in the form of our official App where you can showcase what trending deal is offered by your club. This way, you reach an already established chain of party goers who might pick your deal quickly. In short, we advertise your club offers on our platform.</p>
                                    </div>
                            </div>
                            <div className="c--box">
                                <img src={c3} alt=""></img>
                                    <div className="content">
                                        <h3>Direct reach to actual party goers</h3>
                                        <p>Your deals on our platform will reap maximum revenue realization as most of your deals will be picked. We are so sure about this because we are working to connect with as many party goers as we can through web & app. At Party Witty, you reach the verified pool of people who are regularly partying. This way, you are also reducing your per customer cost of acquisition. </p>
                                    </div>
                            </div>
                            <div className="c--box">
                                <img src={c4} alt=""></img>
                                    <div className="content">
                                        <h3>Convert happy customers to regular visitors</h3>
                                        <p>Booking an artist with Party Witty brings you a step closer to unlimited enjoyment for your guests. We can shoulder this responsibility of assuring that the professional artists you book with us shall certainly convert your club’s guests to regular visitors. As you have access to various artists, you can also give your guests different experiences of musical gatherings.   </p>
                                    </div>
                            </div>
                            <div className="c--box">
                                <img src={c5} alt=""></img>
                                    <div className="content">
                                        <h3>Offers & Deals retarget party goers</h3>
                                        <p>Each time a guest visits your club, we reserve a special scratch coupon on app access and by fulfilling basic laid down conditions. These scratch coupons feature trending deals from clubs which guests have already visited. So, Party Witty lays down a path tracing back those footsteps back which have visited the club before to redeem the deal revealed in the scratch coupon.</p>
                                    </div>
                            </div>
                            <div className="c--box">
                                <img src={c6} alt=""></img>
                                    <div className="content">
                                        <h3>100% Artist selection Satisfaction through reviews</h3>
                                        <p>Party Witty works on a transparent feedback model where an artist's performance is rated and reviewed on the app. These genuine and regulated reviews serve as a good confidence generator while booking an artist. What more do clubs need apart from verified artists at pocket friendly rates?</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center sticky--btn--center">
                            <button type="button" className="btn--theame--2">Book Now</button>
                           
                        </div>
                    </div>
                </section>
            </div>
        
        </div>
    );
}